export const DadosPtrf2020  = [
  {
    "EOL": "000086",
    "UNIDADE": "EMEI - PAULO CAMILHIER FLORENCANO",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "16.711,20",
    "BAS_2PAR": "16.711,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.711,20",
    "REC_EXTRA2": "100.267,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "190.400,80"
  },
  {
    "EOL": "000094",
    "UNIDADE": "EMEI - VICENTE PAULO DA SILVA",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "16.574,40",
    "BAS_2PAR": "16.574,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.574,40",
    "REC_EXTRA2": "99.446,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "189.169,60"
  },
  {
    "EOL": "000108",
    "UNIDADE": "EMEF - JOSE ERMIRIO DE MORAIS, SEN.",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "20.442,60",
    "BAS_2PAR": "20.442,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.442,60",
    "REC_EXTRA2": "122.655,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "192.983,40"
  },
  {
    "EOL": "000191",
    "UNIDADE": "EMEF - ALIPIO CORREA NETO, PROF.",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "18.088,00",
    "BAS_2PAR": "18.088,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "18.088,00",
    "REC_EXTRA2": "108.529,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "171.793,00"
  },
  {
    "EOL": "000205",
    "UNIDADE": "EMEBS - VERA LUCIA APARECIDA RIBEIRO, PROFA.",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "18.243,00",
    "BAS_2PAR": "18.243,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.243,00",
    "REC_EXTRA2": "109.458,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "2.000,00",
    "TOTAL": "186.187,00"
  },
  {
    "EOL": "000213",
    "UNIDADE": "EMEI - ANTONIO CARLOS PACHECO E SILVA, PROF.",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "13.082,00",
    "BAS_2PAR": "13.082,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.082,00",
    "REC_EXTRA2": "78.494,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "149.740,00"
  },
  {
    "EOL": "000221",
    "UNIDADE": "EMEI - NAIR CORREA BUARQUE",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "14.943,60",
    "BAS_2PAR": "14.943,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.943,60",
    "REC_EXTRA2": "89.661,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "170.492,40"
  },
  {
    "EOL": "000230",
    "UNIDADE": "EMEI - MARIA YOLANDA DE SOUZA PINTO HAHNE, DA.",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "14.886,00",
    "BAS_2PAR": "14.886,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.886,00",
    "REC_EXTRA2": "89.316,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "169.974,00"
  },
  {
    "EOL": "000248",
    "UNIDADE": "EMEI - WALFRIDO DE CARVALHO, CEL.",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "18.604,80",
    "BAS_2PAR": "18.604,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.604,80",
    "REC_EXTRA2": "111.628,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "211.443,20"
  },
  {
    "EOL": "000256",
    "UNIDADE": "EMEI - WILMA ALVARENGA DE OLIVEIRA, PROFA.",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "14.871,60",
    "BAS_2PAR": "14.871,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.871,60",
    "REC_EXTRA2": "89.229,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "169.844,40"
  },
  {
    "EOL": "000264",
    "UNIDADE": "EMEF - SYLVIO HECK, ALM.",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "20.685,60",
    "BAS_2PAR": "20.685,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.685,60",
    "REC_EXTRA2": "124.113,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "195.170,40"
  },
  {
    "EOL": "000272",
    "UNIDADE": "EMEF - CLOVIS GRACIANO (EXTINTA)",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "",
    "BAS_2PAR": "",
    "REC_EXTRA": "",
    "BAS_3PAR": "",
    "REC_EXTRA2": "",
    "EM": "",
    "SALA_LEITURA": "",
    "MAT_PEDAG": "",
    "TOTAL": ""
  },
  {
    "EOL": "000281",
    "UNIDADE": "EMEF - VERA LUCIA FUSCO BORBA, PROFA.",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "26.276,00",
    "BAS_2PAR": "21.276,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "21.276,00",
    "REC_EXTRA2": "127.656,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "205.484,00"
  },
  {
    "EOL": "000299",
    "UNIDADE": "EMEF - ROBERTO PATRICIO, PROF.",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "29.989,40",
    "BAS_2PAR": "24.989,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "24.989,40",
    "REC_EXTRA2": "149.936,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "238.904,60"
  },
  {
    "EOL": "000302",
    "UNIDADE": "EMEF - HELIO FRANCO CHAVES, CEL.",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "36.545,00",
    "BAS_2PAR": "31.545,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "31.545,00",
    "REC_EXTRA2": "189.270,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "299.905,00"
  },
  {
    "EOL": "000311",
    "UNIDADE": "EMEF - ANTONIO ALVES DA SILVA, SG.",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "22.937,40",
    "BAS_2PAR": "22.937,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.937,40",
    "REC_EXTRA2": "137.624,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "215.436,60"
  },
  {
    "EOL": "000329",
    "UNIDADE": "EMEBS - NEUSA BASSETTO, PROFA.",
    "DRE": "PENHA",
    "BAS_1PAR": "18.847,80",
    "BAS_2PAR": "18.847,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.847,80",
    "REC_EXTRA2": "113.086,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "2.000,00",
    "TOTAL": "191.630,20"
  },
  {
    "EOL": "000337",
    "UNIDADE": "EMEBS - LUCIE BRAY, ME.",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "18.313,20",
    "BAS_2PAR": "18.313,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.313,20",
    "REC_EXTRA2": "109.879,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "2.000,00",
    "TOTAL": "186.818,80"
  },
  {
    "EOL": "000388",
    "UNIDADE": "EMEI - RAUL JOVIANO DO AMARAL",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "16.776,00",
    "BAS_2PAR": "16.776,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.776,00",
    "REC_EXTRA2": "100.656,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "190.984,00"
  },
  {
    "EOL": "000418",
    "UNIDADE": "EMEF - JOAO DE SOUZA FERRAZ, PROF.",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "27.165,20",
    "BAS_2PAR": "22.165,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.165,20",
    "REC_EXTRA2": "132.991,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "2.000,00",
    "TOTAL": "211.486,80"
  },
  {
    "EOL": "000426",
    "UNIDADE": "EMEI - CELIA CAMARGO PENTEADO ELIAS, PROFA.",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "12.988,80",
    "BAS_2PAR": "12.988,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "12.988,80",
    "REC_EXTRA2": "77.932,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "148.899,20"
  },
  {
    "EOL": "000434",
    "UNIDADE": "EMEI - CLEMENTE SEGUNDO PINHO, PROF.",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "13.168,80",
    "BAS_2PAR": "13.168,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.168,80",
    "REC_EXTRA2": "79.012,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "150.519,20"
  },
  {
    "EOL": "000442",
    "UNIDADE": "EMEI - ANTONIO RUBBO MULLER, PROF.",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "14.792,40",
    "BAS_2PAR": "14.792,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.792,40",
    "REC_EXTRA2": "88.754,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "169.131,60"
  },
  {
    "EOL": "000451",
    "UNIDADE": "EMEI - MARIA DO CARMO GODOY RAMOS, PROFA.",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "13.021,20",
    "BAS_2PAR": "13.021,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.021,20",
    "REC_EXTRA2": "78.127,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "149.190,80"
  },
  {
    "EOL": "000469",
    "UNIDADE": "EMEI - RODOLFO TREVISAN",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "11.408,40",
    "BAS_2PAR": "11.408,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.408,40",
    "REC_EXTRA2": "68.450,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.675,60"
  },
  {
    "EOL": "000477",
    "UNIDADE": "EMEF - EDA TEREZINHA CHICA MEDEIROS, PROFA.",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "20.205,00",
    "BAS_2PAR": "20.205,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.205,00",
    "REC_EXTRA2": "121.230,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "190.845,00"
  },
  {
    "EOL": "000485",
    "UNIDADE": "EMEF - ASSAD ABDALA",
    "DRE": "PENHA",
    "BAS_1PAR": "19.994,40",
    "BAS_2PAR": "19.994,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "19.994,40",
    "REC_EXTRA2": "119.966,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "188.949,60"
  },
  {
    "EOL": "000493",
    "UNIDADE": "EMEI - AURELIO BUARQUE DE HOLANDA FERREIRA",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "15.076,80",
    "BAS_2PAR": "15.076,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "15.076,80",
    "REC_EXTRA2": "90.460,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "171.691,20"
  },
  {
    "EOL": "000531",
    "UNIDADE": "EMEI - MARIA EUGENIA FAKHOURY",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "20.253,60",
    "BAS_2PAR": "20.253,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "20.253,60",
    "REC_EXTRA2": "121.521,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "28.000,00",
    "TOTAL": "230.282,40"
  },
  {
    "EOL": "000540",
    "UNIDADE": "EMEF - RAUL POMPEIA",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "25.707,60",
    "BAS_2PAR": "25.707,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.707,60",
    "REC_EXTRA2": "154.245,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "242.368,40"
  },
  {
    "EOL": "000558",
    "UNIDADE": "EMEI - SENA MADUREIRA",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "18.921,20",
    "BAS_2PAR": "13.921,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.921,20",
    "REC_EXTRA2": "83.527,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "158.290,80"
  },
  {
    "EOL": "000566",
    "UNIDADE": "EMEF - TERESA MARGARIDA DA SILVA E ORTA",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "29.352,60",
    "BAS_2PAR": "29.352,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "29.352,60",
    "REC_EXTRA2": "176.115,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "277.173,40"
  },
  {
    "EOL": "000574",
    "UNIDADE": "EMEI - ORLANDO DE ALVARENGA GAUDIO, PROF.",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "13.204,80",
    "BAS_2PAR": "13.204,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.204,80",
    "REC_EXTRA2": "79.228,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "150.843,20"
  },
  {
    "EOL": "000892",
    "UNIDADE": "EMEF - HENRIQUE SOUZA FILHO - HENFIL",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "19.956,60",
    "BAS_2PAR": "19.956,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "19.956,60",
    "REC_EXTRA2": "119.739,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "188.609,40"
  },
  {
    "EOL": "008834",
    "UNIDADE": "EMEF - IDEMIA DE GODOY, PROFA.",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "26.717,40",
    "BAS_2PAR": "26.717,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.717,40",
    "REC_EXTRA2": "160.304,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "251.456,60"
  },
  {
    "EOL": "008842",
    "UNIDADE": "EMEF - SEBASTIAO FRANCISCO, O NEGRO",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "18.045,00",
    "BAS_2PAR": "18.045,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "18.045,00",
    "REC_EXTRA2": "108.270,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "171.405,00"
  },
  {
    "EOL": "009130",
    "UNIDADE": "EMEI - OLGA BENARIO PRESTES",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "14.896,80",
    "BAS_2PAR": "14.896,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.896,80",
    "REC_EXTRA2": "89.380,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "170.071,20"
  },
  {
    "EOL": "009148",
    "UNIDADE": "EMEF - ALEXANDRE VANNUCHI LEME",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "22.786,20",
    "BAS_2PAR": "22.786,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.786,20",
    "REC_EXTRA2": "136.717,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "214.075,80"
  },
  {
    "EOL": "009156",
    "UNIDADE": "EMEF - LUIS CARLOS PRESTES, SEN.",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "25.524,00",
    "BAS_2PAR": "25.524,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.524,00",
    "REC_EXTRA2": "153.144,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "240.716,00"
  },
  {
    "EOL": "009270",
    "UNIDADE": "EMEI - DINA KUTNER DE SOUZA - DINA SFAT",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "13.248,00",
    "BAS_2PAR": "13.248,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.248,00",
    "REC_EXTRA2": "79.488,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "151.232,00"
  },
  {
    "EOL": "009326",
    "UNIDADE": "EMEF - VICTOR CIVITA",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "17.364,60",
    "BAS_2PAR": "17.364,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "17.364,60",
    "REC_EXTRA2": "104.187,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "2.000,00",
    "TOTAL": "163.281,40"
  },
  {
    "EOL": "009911",
    "UNIDADE": "EMEI - SAVA POPOVIC",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "13.230,00",
    "BAS_2PAR": "13.230,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.230,00",
    "REC_EXTRA2": "79.380,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "151.070,00"
  },
  {
    "EOL": "009971",
    "UNIDADE": "EMEF - LUIZ GONZAGA DO NASCIMENTO JR. - GONZAGUINHA",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "28.467,00",
    "BAS_2PAR": "28.467,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "28.467,00",
    "REC_EXTRA2": "170.802,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "269.203,00"
  },
  {
    "EOL": "009989",
    "UNIDADE": "EMEI - OTAVIO JOSE DA SILVA JUNIOR",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "14.832,00",
    "BAS_2PAR": "14.832,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.832,00",
    "REC_EXTRA2": "88.992,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "169.488,00"
  },
  {
    "EOL": "010049",
    "UNIDADE": "EMEF - CHICO FALCONI, PE.",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "23.250,60",
    "BAS_2PAR": "23.250,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.250,60",
    "REC_EXTRA2": "139.503,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "220.255,40"
  },
  {
    "EOL": "010103",
    "UNIDADE": "EMEF - FRANCISCO ALVES MENDES FILHO - CHICO MENDES",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "33.132,20",
    "BAS_2PAR": "28.132,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "28.132,20",
    "REC_EXTRA2": "168.793,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "267.189,80"
  },
  {
    "EOL": "010111",
    "UNIDADE": "EMEF - MARIO SCHONBERG, PROF.",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "26.361,00",
    "BAS_2PAR": "26.361,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.361,00",
    "REC_EXTRA2": "158.166,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "248.249,00"
  },
  {
    "EOL": "010120",
    "UNIDADE": "EMEF - CARLOS AUGUSTO DE QUEIROZ ROCHA",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "31.676,00",
    "BAS_2PAR": "26.676,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.676,00",
    "REC_EXTRA2": "160.056,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "254.084,00"
  },
  {
    "EOL": "010138",
    "UNIDADE": "EMEF - ELZA MAIA COSTA FREIRE, PROFA.",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "25.421,40",
    "BAS_2PAR": "25.421,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.421,40",
    "REC_EXTRA2": "152.528,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "239.792,60"
  },
  {
    "EOL": "010146",
    "UNIDADE": "EMEF - MARIA RITA LOPES PONTES - IRMA DULCE",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "25.372,80",
    "BAS_2PAR": "25.372,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.372,80",
    "REC_EXTRA2": "152.236,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "239.355,20"
  },
  {
    "EOL": "010154",
    "UNIDADE": "EMEF - MAURO FACCIO GONCALVES - ZACARIA",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "33.159,20",
    "BAS_2PAR": "28.159,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "28.159,20",
    "REC_EXTRA2": "168.955,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "267.432,80"
  },
  {
    "EOL": "010162",
    "UNIDADE": "EMEF - LORENCO MANOEL SPARAPAN, PROF.",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "23.261,40",
    "BAS_2PAR": "23.261,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.261,40",
    "REC_EXTRA2": "139.568,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "220.352,60"
  },
  {
    "EOL": "010171",
    "UNIDADE": "EMEF - ALDO RIBEIRO LUZ, PROF.",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "20.804,40",
    "BAS_2PAR": "20.804,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.804,40",
    "REC_EXTRA2": "124.826,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "196.239,60"
  },
  {
    "EOL": "010405",
    "UNIDADE": "EMEF - NILDO DO AMARAL JUNIOR, PE.",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "17.748,00",
    "BAS_2PAR": "17.748,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "17.748,00",
    "REC_EXTRA2": "106.488,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "168.732,00"
  },
  {
    "EOL": "010413",
    "UNIDADE": "EMEF - QUIRINO CARNEIRO RENNO, PROF.",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "30.912,80",
    "BAS_2PAR": "25.912,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.912,80",
    "REC_EXTRA2": "155.476,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "247.215,20"
  },
  {
    "EOL": "010421",
    "UNIDADE": "EMEF - CYRO ALBUQUERQUE, DEP.",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "22.537,80",
    "BAS_2PAR": "22.537,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.537,80",
    "REC_EXTRA2": "135.226,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "211.840,20"
  },
  {
    "EOL": "010839",
    "UNIDADE": "EMEI - VILA REMO",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "16.599,60",
    "BAS_2PAR": "16.599,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.599,60",
    "REC_EXTRA2": "99.597,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "189.396,40"
  },
  {
    "EOL": "010910",
    "UNIDADE": "EMEI - RUBENS NASCIMENTO DA SILVA - MEXICANO",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "13.316,40",
    "BAS_2PAR": "13.316,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.316,40",
    "REC_EXTRA2": "79.898,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "151.847,60"
  },
  {
    "EOL": "011045",
    "UNIDADE": "EMEI - EDER SADER",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "11.329,20",
    "BAS_2PAR": "11.329,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.329,20",
    "REC_EXTRA2": "67.975,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.962,80"
  },
  {
    "EOL": "011380",
    "UNIDADE": "EMEI - ANTONIO ROBERTO ALVES BRAGA, DR.",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "16.830,00",
    "BAS_2PAR": "16.830,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.830,00",
    "REC_EXTRA2": "100.980,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "191.470,00"
  },
  {
    "EOL": "011533",
    "UNIDADE": "EMEF - FRANCISCO DA SILVEIRA BUENO, PROF.",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "17.591,40",
    "BAS_2PAR": "17.591,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "17.591,40",
    "REC_EXTRA2": "105.548,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "2.000,00",
    "TOTAL": "165.322,60"
  },
  {
    "EOL": "011541",
    "UNIDADE": "EMEI - YOLANDA BASSI, PROFA.",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "13.168,80",
    "BAS_2PAR": "13.168,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.168,80",
    "REC_EXTRA2": "79.012,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "150.519,20"
  },
  {
    "EOL": "011550",
    "UNIDADE": "EMEI - PAULO ZINGG",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "14.983,20",
    "BAS_2PAR": "14.983,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.983,20",
    "REC_EXTRA2": "89.899,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "170.848,80"
  },
  {
    "EOL": "011568",
    "UNIDADE": "EMEI - JOSE ROSCHEL CHRISTI - JUCA ROCHA",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "20.178,00",
    "BAS_2PAR": "20.178,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "20.178,00",
    "REC_EXTRA2": "121.068,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "28.000,00",
    "TOTAL": "229.602,00"
  },
  {
    "EOL": "011851",
    "UNIDADE": "EMEF - ANALIA FRANCO BASTOS",
    "DRE": "PENHA",
    "BAS_1PAR": "25.318,80",
    "BAS_2PAR": "25.318,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.318,80",
    "REC_EXTRA2": "151.912,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "238.869,20"
  },
  {
    "EOL": "011886",
    "UNIDADE": "EMEI - MARIO BENI, DEP.",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "13.230,00",
    "BAS_2PAR": "13.230,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.230,00",
    "REC_EXTRA2": "79.380,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "151.070,00"
  },
  {
    "EOL": "011924",
    "UNIDADE": "EMEF - MARIA ALICE BORGES GHION, PROFA.",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "25.297,00",
    "BAS_2PAR": "25.297,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.297,00",
    "REC_EXTRA2": "151.783,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "238.674,00"
  },
  {
    "EOL": "012157",
    "UNIDADE": "EMEF - ELIAS SHAMMASS",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "26.047,80",
    "BAS_2PAR": "26.047,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.047,80",
    "REC_EXTRA2": "156.286,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "245.430,20"
  },
  {
    "EOL": "012246",
    "UNIDADE": "EMEI - FAUSTO RIBEIRO DA SILVA FILHO, DR.",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "18.478,80",
    "BAS_2PAR": "18.478,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.478,80",
    "REC_EXTRA2": "110.872,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "210.309,20"
  },
  {
    "EOL": "012688",
    "UNIDADE": "EMEF - ULYSSES DA SYLVEIRA GUIMARAES",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "25.826,40",
    "BAS_2PAR": "25.826,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.826,40",
    "REC_EXTRA2": "154.958,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "243.437,60"
  },
  {
    "EOL": "012874",
    "UNIDADE": "EMEF - BENEDITO DE JESUS BATISTA LAURINDO - PE BATISTA",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "30.394,40",
    "BAS_2PAR": "25.394,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.394,40",
    "REC_EXTRA2": "152.366,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "242.549,60"
  },
  {
    "EOL": "012939",
    "UNIDADE": "EMEF - JOSE OLYMPIO PEREIRA FILHO",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "19.989,00",
    "BAS_2PAR": "19.989,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "19.989,00",
    "REC_EXTRA2": "119.934,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "188.901,00"
  },
  {
    "EOL": "013111",
    "UNIDADE": "EMEI - CAIO GRACO DA SILVA PRADO",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "18.583,00",
    "BAS_2PAR": "18.583,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.583,00",
    "REC_EXTRA2": "111.499,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "211.248,00"
  },
  {
    "EOL": "013218",
    "UNIDADE": "EMEI - SEVERINO DO RAMO",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "18.698,40",
    "BAS_2PAR": "18.698,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.698,40",
    "REC_EXTRA2": "112.190,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "212.285,60"
  },
  {
    "EOL": "013226",
    "UNIDADE": "EMEI CEU - CARLOS OLIVALDO DE SOUZA LOPES MUNIZ, DR.",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "14.814,00",
    "BAS_2PAR": "14.814,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.814,00",
    "REC_EXTRA2": "88.884,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "169.326,00"
  },
  {
    "EOL": "013315",
    "UNIDADE": "EMEI - MARIA LUCIA PETIT DA SILVA, PROFA.",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "18.712,80",
    "BAS_2PAR": "18.712,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.712,80",
    "REC_EXTRA2": "112.276,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "212.415,20"
  },
  {
    "EOL": "013358",
    "UNIDADE": "EMEI - JANIO QUADROS, PRES.",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "20.257,20",
    "BAS_2PAR": "20.257,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "20.257,20",
    "REC_EXTRA2": "121.543,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "28.000,00",
    "TOTAL": "230.314,80"
  },
  {
    "EOL": "013447",
    "UNIDADE": "EMEI - HENRIQUE RICCHETTI, PROF.",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "14.893,20",
    "BAS_2PAR": "14.893,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.893,20",
    "REC_EXTRA2": "89.359,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "170.038,80"
  },
  {
    "EOL": "013471",
    "UNIDADE": "EMEI - MARINA NOGUEIRA DE SOUZA MARTINS",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "15.076,80",
    "BAS_2PAR": "15.076,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "15.076,80",
    "REC_EXTRA2": "90.460,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "171.691,20"
  },
  {
    "EOL": "013498",
    "UNIDADE": "EMEI - JOSE ROBERTO DE CASTRO RIBEIRO, PROF.",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "13.316,40",
    "BAS_2PAR": "13.316,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.316,40",
    "REC_EXTRA2": "79.898,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "151.847,60"
  },
  {
    "EOL": "013501",
    "UNIDADE": "EMEI - DULCE SALLES FERRAZ, PROFA.",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "14.832,00",
    "BAS_2PAR": "14.832,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.832,00",
    "REC_EXTRA2": "88.992,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "169.488,00"
  },
  {
    "EOL": "013510",
    "UNIDADE": "EMEF - OLINDA MENEZES SERRA VIDAL, PROFA.",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "33.796,40",
    "BAS_2PAR": "28.796,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "28.796,40",
    "REC_EXTRA2": "172.778,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "275.167,60"
  },
  {
    "EOL": "013528",
    "UNIDADE": "EMEF - ANNA LAMBERGA ZEGLIO, VER.",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "28.753,20",
    "BAS_2PAR": "28.753,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "28.753,20",
    "REC_EXTRA2": "172.519,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "271.778,80"
  },
  {
    "EOL": "013692",
    "UNIDADE": "EMEI - DORACIL DINA BENICIO, PROFA.",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "18.565,20",
    "BAS_2PAR": "18.565,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.565,20",
    "REC_EXTRA2": "111.391,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "211.086,80"
  },
  {
    "EOL": "013803",
    "UNIDADE": "EMEF - JULIO DE GRAMMONT",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "28.791,00",
    "BAS_2PAR": "28.791,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "28.791,00",
    "REC_EXTRA2": "172.746,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "272.119,00"
  },
  {
    "EOL": "014117",
    "UNIDADE": "EMEI - AYRTON SENNA DA SILVA",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "20.520,00",
    "BAS_2PAR": "20.520,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "20.520,00",
    "REC_EXTRA2": "123.120,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "28.000,00",
    "TOTAL": "232.680,00"
  },
  {
    "EOL": "014125",
    "UNIDADE": "EMEI - VIRGILIO TAVORA",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "20.192,40",
    "BAS_2PAR": "20.192,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "20.192,40",
    "REC_EXTRA2": "121.154,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "28.000,00",
    "TOTAL": "229.731,60"
  },
  {
    "EOL": "014176",
    "UNIDADE": "EMEI - SALOMAO JORGE, DEP.",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "16.628,40",
    "BAS_2PAR": "16.628,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.628,40",
    "REC_EXTRA2": "99.770,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "189.655,60"
  },
  {
    "EOL": "014249",
    "UNIDADE": "EMEF - ERNESTO DE MORAES LEME, PROF.",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "33.969,20",
    "BAS_2PAR": "28.969,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "28.969,20",
    "REC_EXTRA2": "173.815,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "276.722,80"
  },
  {
    "EOL": "014567",
    "UNIDADE": "EMEI - TITO LIVIO FERREIRA, PROF.",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "16.624,80",
    "BAS_2PAR": "16.624,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.624,80",
    "REC_EXTRA2": "99.748,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "189.623,20"
  },
  {
    "EOL": "014575",
    "UNIDADE": "EMEI - CIDADE DO SOL",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "18.554,40",
    "BAS_2PAR": "18.554,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.554,40",
    "REC_EXTRA2": "111.326,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "210.989,60"
  },
  {
    "EOL": "014583",
    "UNIDADE": "EMEF - FLAVIO AUGUSTO ROSA, PROF.",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "30.270,20",
    "BAS_2PAR": "25.270,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.270,20",
    "REC_EXTRA2": "151.621,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "241.431,80"
  },
  {
    "EOL": "014591",
    "UNIDADE": "EMEI - BENEDICTO CASTRUCCI, PROF.",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "13.280,00",
    "BAS_2PAR": "13.280,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.280,00",
    "REC_EXTRA2": "79.682,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "151.522,00"
  },
  {
    "EOL": "014672",
    "UNIDADE": "EMEI - EDUARDO KNEESE DE MELLO, PROF.",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "13.208,00",
    "BAS_2PAR": "13.208,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.208,00",
    "REC_EXTRA2": "79.250,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "150.874,00"
  },
  {
    "EOL": "014681",
    "UNIDADE": "EMEI - ELISIARIO RODRIGUES DE SOUSA, PROF.",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "13.269,60",
    "BAS_2PAR": "13.269,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.269,60",
    "REC_EXTRA2": "79.617,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "151.426,40"
  },
  {
    "EOL": "014699",
    "UNIDADE": "EMEF - ANTONIO D'AVILA, PROF.",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "30.513,20",
    "BAS_2PAR": "25.513,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.513,20",
    "REC_EXTRA2": "153.079,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "243.618,80"
  },
  {
    "EOL": "014893",
    "UNIDADE": "EMEI - MARIA DAILCE MONTEIRO DA SILVA GOMES, PROFA.",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "13.114,80",
    "BAS_2PAR": "13.114,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.114,80",
    "REC_EXTRA2": "78.688,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "150.033,20"
  },
  {
    "EOL": "015024",
    "UNIDADE": "EMEF - CAIO SERGIO POMPEU DE TOLEDO, DEP.",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "22.975,20",
    "BAS_2PAR": "22.975,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.975,20",
    "REC_EXTRA2": "137.851,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "215.776,80"
  },
  {
    "EOL": "015121",
    "UNIDADE": "EMEF - JOSE CARLOS DE FIGUEIREDO FERRAZ, PREF.",
    "DRE": "PENHA",
    "BAS_1PAR": "23.466,60",
    "BAS_2PAR": "23.466,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.466,60",
    "REC_EXTRA2": "140.799,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "222.199,40"
  },
  {
    "EOL": "015130",
    "UNIDADE": "EMEF - JULIO CESAR DE MELO E SOUSA-MALBA TAHAN, PROF.",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "22.532,40",
    "BAS_2PAR": "22.532,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.532,40",
    "REC_EXTRA2": "135.194,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "211.791,60"
  },
  {
    "EOL": "015296",
    "UNIDADE": "EMEF - JOAO FRANZOLIN NETO, PROF.",
    "DRE": "PENHA",
    "BAS_1PAR": "33.608,80",
    "BAS_2PAR": "23.608,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.608,80",
    "REC_EXTRA2": "141.652,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "231.479,20"
  },
  {
    "EOL": "015423",
    "UNIDADE": "EMEF - PRESTES MAIA",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "20.431,80",
    "BAS_2PAR": "20.431,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.431,80",
    "REC_EXTRA2": "122.590,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "192.886,20"
  },
  {
    "EOL": "015431",
    "UNIDADE": "EMEF - ERNANI SILVA BRUNO",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "20.140,20",
    "BAS_2PAR": "20.140,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.140,20",
    "REC_EXTRA2": "120.841,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "190.261,80"
  },
  {
    "EOL": "015440",
    "UNIDADE": "EMEF - EUZEBIO ROCHA FILHO",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "23.369,40",
    "BAS_2PAR": "23.369,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.369,40",
    "REC_EXTRA2": "140.216,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "221.324,60"
  },
  {
    "EOL": "016497",
    "UNIDADE": "EMEF - FERNANDO GRACIOSO",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "20.809,80",
    "BAS_2PAR": "20.809,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.809,80",
    "REC_EXTRA2": "124.858,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "196.288,20"
  },
  {
    "EOL": "016501",
    "UNIDADE": "EMEF - FLORESTAN FERNANDES, PROF.",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "22.834,80",
    "BAS_2PAR": "22.834,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.834,80",
    "REC_EXTRA2": "137.008,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "214.513,20"
  },
  {
    "EOL": "016519",
    "UNIDADE": "EMEFM - RUBENS PAIVA",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "36.794,80",
    "BAS_2PAR": "26.794,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.794,80",
    "REC_EXTRA2": "160.768,80",
    "EM": "10.000,00",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "272.153,20"
  },
  {
    "EOL": "016527",
    "UNIDADE": "EMEF - CLAUDIA BARTOLOMAZI, PROFA.",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "25.216,20",
    "BAS_2PAR": "25.216,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.216,20",
    "REC_EXTRA2": "151.297,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "237.945,80"
  },
  {
    "EOL": "016713",
    "UNIDADE": "EMEF - ANNA SILVEIRA PEDREIRA",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "23.472,00",
    "BAS_2PAR": "23.472,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.472,00",
    "REC_EXTRA2": "140.832,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "222.248,00"
  },
  {
    "EOL": "016721",
    "UNIDADE": "EMEI - CELSO DE SOUSA OLIVEIRA, PROF.",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "20.246,40",
    "BAS_2PAR": "20.246,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "20.246,40",
    "REC_EXTRA2": "121.478,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "28.000,00",
    "TOTAL": "230.217,60"
  },
  {
    "EOL": "016993",
    "UNIDADE": "EMEF - VICENTE AMATO SOBRINHO, COM.",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "25.713,00",
    "BAS_2PAR": "25.713,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.713,00",
    "REC_EXTRA2": "154.278,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "242.417,00"
  },
  {
    "EOL": "017272",
    "UNIDADE": "EMEFM - GUIOMAR CABRAL",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "23.536,80",
    "BAS_2PAR": "23.536,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.536,80",
    "REC_EXTRA2": "141.220,80",
    "EM": "20.000,00",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "242.831,20"
  },
  {
    "EOL": "017281",
    "UNIDADE": "EMEI - CONJ. HABITACIONAL VALO VELHO",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "16.578,00",
    "BAS_2PAR": "16.578,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.578,00",
    "REC_EXTRA2": "99.468,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "189.202,00"
  },
  {
    "EOL": "017329",
    "UNIDADE": "EMEI - VICENTE MATHEUS",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "14.954,40",
    "BAS_2PAR": "14.954,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.954,40",
    "REC_EXTRA2": "89.726,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "170.589,60"
  },
  {
    "EOL": "017337",
    "UNIDADE": "EMEI - DO LAR SIRIO (EM PROCESSO DE EXTINÇÃO)",
    "DRE": "PENHA",
    "BAS_1PAR": "18.298,40",
    "BAS_2PAR": "13.298,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "",
    "REC_EXTRA2": "",
    "EM": "",
    "SALA_LEITURA": "",
    "MAT_PEDAG": "",
    "TOTAL": "46.596,80"
  },
  {
    "EOL": "017442",
    "UNIDADE": "EMEFM - ANTONIO SAMPAIO, VER.",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "30.421,40",
    "BAS_2PAR": "25.421,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.421,40",
    "REC_EXTRA2": "152.528,40",
    "EM": "10.000,00",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "252.792,60"
  },
  {
    "EOL": "017451",
    "UNIDADE": "EMEI - RUTH GONCALVES CHAVES DE SIQUEIRA, PROFA.",
    "DRE": "PENHA",
    "BAS_1PAR": "14.922,00",
    "BAS_2PAR": "14.922,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.922,00",
    "REC_EXTRA2": "89.532,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "170.298,00"
  },
  {
    "EOL": "017515",
    "UNIDADE": "EMEI - MARIA LUIZA MORETTI GENTILE, PROFA.",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "14.839,20",
    "BAS_2PAR": "14.839,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.839,20",
    "REC_EXTRA2": "89.035,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "169.552,80"
  },
  {
    "EOL": "017523",
    "UNIDADE": "EMEF - CLOTILDE ROSA HENRIQUES ELIAS, PROFA.",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "23.358,60",
    "BAS_2PAR": "23.358,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.358,60",
    "REC_EXTRA2": "140.151,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "221.227,40"
  },
  {
    "EOL": "017531",
    "UNIDADE": "EMEI - VALENTINA ANTONINI DA SILVA, PROFA.",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "18.453,50",
    "BAS_2PAR": "18.453,50",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.453,50",
    "REC_EXTRA2": "110.721,50",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "210.082,00"
  },
  {
    "EOL": "017655",
    "UNIDADE": "EMEI - SEBASTIANA DE ARRUDA CRUZ OLIVEIRA, PROFA.",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "16.797,60",
    "BAS_2PAR": "16.797,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.797,60",
    "REC_EXTRA2": "100.785,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "191.178,40"
  },
  {
    "EOL": "017710",
    "UNIDADE": "EMEI - HELENA LOPES SANTANA DA SILVA, PROFA.",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "18.680,40",
    "BAS_2PAR": "18.680,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.680,40",
    "REC_EXTRA2": "112.082,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "212.123,60"
  },
  {
    "EOL": "017728",
    "UNIDADE": "EMEI - JOSE JOAQUIM DA SILVA",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "19.724,00",
    "BAS_2PAR": "14.724,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.724,00",
    "REC_EXTRA2": "88.344,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "165.516,00"
  },
  {
    "EOL": "017736",
    "UNIDADE": "EMEI - ANTONIO FIGUEIREDO AMARAL",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "13.086,00",
    "BAS_2PAR": "13.086,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.086,00",
    "REC_EXTRA2": "78.516,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "149.774,00"
  },
  {
    "EOL": "017973",
    "UNIDADE": "EMEF - MAILSON DELANE, PROF.",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "29.098,80",
    "BAS_2PAR": "29.098,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "29.098,80",
    "REC_EXTRA2": "174.592,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "274.889,20"
  },
  {
    "EOL": "017981",
    "UNIDADE": "EMEF - PERICLES EUGENIO DA SILVA RAMOS",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "18.055,80",
    "BAS_2PAR": "18.055,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "18.055,80",
    "REC_EXTRA2": "108.334,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "171.502,20"
  },
  {
    "EOL": "018201",
    "UNIDADE": "EMEI - LUCILIA DE ANDRADE FERREIRA, PROFA.",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "18.572,40",
    "BAS_2PAR": "18.572,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.572,40",
    "REC_EXTRA2": "111.434,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "211.151,60"
  },
  {
    "EOL": "018210",
    "UNIDADE": "EMEFM - DARCY RIBEIRO",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "25.318,80",
    "BAS_2PAR": "25.318,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.318,80",
    "REC_EXTRA2": "151.912,80",
    "EM": "20.000,00",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "258.869,20"
  },
  {
    "EOL": "018228",
    "UNIDADE": "EMEI - MARIA DE LOURDES COUTINHO TORRES, DA.",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "15.048,00",
    "BAS_2PAR": "15.048,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "15.048,00",
    "REC_EXTRA2": "90.288,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "171.432,00"
  },
  {
    "EOL": "018236",
    "UNIDADE": "EMEI - MARIA APARECIDA VITA PIANTE, PROFA.",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "14.947,00",
    "BAS_2PAR": "14.947,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.947,00",
    "REC_EXTRA2": "89.683,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "170.524,00"
  },
  {
    "EOL": "018244",
    "UNIDADE": "EMEI - CAMILLO ASHCAR, PROF.",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "14.828,00",
    "BAS_2PAR": "14.828,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.828,00",
    "REC_EXTRA2": "88.970,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "169.454,00"
  },
  {
    "EOL": "018261",
    "UNIDADE": "EMEI - ALICE FEITOSA, DA.",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "14.770,80",
    "BAS_2PAR": "14.770,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.770,80",
    "REC_EXTRA2": "88.624,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "168.937,20"
  },
  {
    "EOL": "018279",
    "UNIDADE": "EMEI - CEZAR ROGERIO OLIVEIRA PERAMEZZA, PROF.",
    "DRE": "PENHA",
    "BAS_1PAR": "14.878,80",
    "BAS_2PAR": "14.878,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.878,80",
    "REC_EXTRA2": "89.272,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "169.909,20"
  },
  {
    "EOL": "018287",
    "UNIDADE": "EMEI - ANTONIO MUNHOZ BONILHA",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "13.294,80",
    "BAS_2PAR": "13.294,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.294,80",
    "REC_EXTRA2": "79.768,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "151.653,20"
  },
  {
    "EOL": "018295",
    "UNIDADE": "EMEI - ALEX FREUA NETTO, VER.",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "18.529,20",
    "BAS_2PAR": "18.529,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.529,20",
    "REC_EXTRA2": "111.175,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "210.762,80"
  },
  {
    "EOL": "018309",
    "UNIDADE": "EMEI - VERA ARNONI SCALQUETTI, PROFA.",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "11.516,40",
    "BAS_2PAR": "11.516,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.516,40",
    "REC_EXTRA2": "69.098,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "131.647,60"
  },
  {
    "EOL": "018350",
    "UNIDADE": "EMEI - ANTONIO CARLOS BRASILEIRO DE ALMEIDA JOBIM - TOM JOBIM",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "13.226,40",
    "BAS_2PAR": "13.226,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.226,40",
    "REC_EXTRA2": "79.358,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "151.037,60"
  },
  {
    "EOL": "018384",
    "UNIDADE": "EMEI - MORENO, CTE.",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "13.003,00",
    "BAS_2PAR": "13.003,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.003,00",
    "REC_EXTRA2": "78.019,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "149.028,00"
  },
  {
    "EOL": "018503",
    "UNIDADE": "EMEI - JOSEMARIA ESCRIVA, BEM-AVENTURADO",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "16.768,80",
    "BAS_2PAR": "16.768,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.768,80",
    "REC_EXTRA2": "100.612,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "190.919,20"
  },
  {
    "EOL": "018511",
    "UNIDADE": "EMEF - CARLOS CORREA MASCARO, PROF.",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "23.121,00",
    "BAS_2PAR": "23.121,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.121,00",
    "REC_EXTRA2": "138.726,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "219.089,00"
  },
  {
    "EOL": "018520",
    "UNIDADE": "EMEI - JOAO MENDONCA FALCAO",
    "DRE": "PENHA",
    "BAS_1PAR": "16.603,20",
    "BAS_2PAR": "16.603,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.603,20",
    "REC_EXTRA2": "99.619,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "189.428,80"
  },
  {
    "EOL": "018538",
    "UNIDADE": "EMEI - GESSY GEBARA, PROFA.",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "16.851,60",
    "BAS_2PAR": "16.851,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.851,60",
    "REC_EXTRA2": "101.109,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "191.664,40"
  },
  {
    "EOL": "018546",
    "UNIDADE": "EMEF - DAMIAO, FREI",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "36.869,40",
    "BAS_2PAR": "36.869,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "36.869,40",
    "REC_EXTRA2": "221.216,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "348.824,60"
  },
  {
    "EOL": "018554",
    "UNIDADE": "EMEI - ANTONIO CALLADO",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "14.835,60",
    "BAS_2PAR": "14.835,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.835,60",
    "REC_EXTRA2": "89.013,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "169.520,40"
  },
  {
    "EOL": "018830",
    "UNIDADE": "EMEF - PAULO FREIRE, PROF.",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "25.443,00",
    "BAS_2PAR": "25.443,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.443,00",
    "REC_EXTRA2": "152.658,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "239.987,00"
  },
  {
    "EOL": "019011",
    "UNIDADE": "EMEF - LINO DE MATTOS, SEN.",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "34.906,50",
    "BAS_2PAR": "24.906,50",
    "REC_EXTRA": "",
    "BAS_3PAR": "24.906,50",
    "REC_EXTRA2": "149.439,50",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "243.159,00"
  },
  {
    "EOL": "019020",
    "UNIDADE": "EMEF - JAIRO DE ALMEIDA, PROF.",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "25.524,00",
    "BAS_2PAR": "25.524,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.524,00",
    "REC_EXTRA2": "153.144,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "240.716,00"
  },
  {
    "EOL": "019038",
    "UNIDADE": "EMEI - CHACARA SONHO AZUL",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "13.219,20",
    "BAS_2PAR": "13.219,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.219,20",
    "REC_EXTRA2": "79.315,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "150.972,80"
  },
  {
    "EOL": "019046",
    "UNIDADE": "EMEF - CELIA REGINA LEKEVICIUS CONSOLIN, PROFA.",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "30.362,00",
    "BAS_2PAR": "25.362,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.362,00",
    "REC_EXTRA2": "152.172,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "242.258,00"
  },
  {
    "EOL": "019054",
    "UNIDADE": "EMEI - ADALBERTO PANZAN, DR.",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "14.911,20",
    "BAS_2PAR": "14.911,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.911,20",
    "REC_EXTRA2": "89.467,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "170.200,80"
  },
  {
    "EOL": "019062",
    "UNIDADE": "EMEI - THEREZA THE DE CARVALHO, PROFA.",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "14.968,80",
    "BAS_2PAR": "14.968,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.968,80",
    "REC_EXTRA2": "89.812,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "170.719,20"
  },
  {
    "EOL": "019071",
    "UNIDADE": "EMEI - MARIA CECILIA DEZAN ROCHA, PROFA.",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "15.069,60",
    "BAS_2PAR": "15.069,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "15.069,60",
    "REC_EXTRA2": "90.417,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "171.626,40"
  },
  {
    "EOL": "019089",
    "UNIDADE": "EMEI - JOAO RUBENS MARCELO",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "13.114,80",
    "BAS_2PAR": "13.114,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.114,80",
    "REC_EXTRA2": "78.688,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "150.033,20"
  },
  {
    "EOL": "019097",
    "UNIDADE": "EMEI - ARTHUR BAPTISTA DA LUZ",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "16.567,20",
    "BAS_2PAR": "16.567,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.567,20",
    "REC_EXTRA2": "99.403,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "189.104,80"
  },
  {
    "EOL": "019101",
    "UNIDADE": "EMEI - DINAH FERNANDES COSTA , PROFA.",
    "DRE": "PENHA",
    "BAS_1PAR": "14.767,20",
    "BAS_2PAR": "14.767,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.767,20",
    "REC_EXTRA2": "88.603,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "168.904,80"
  },
  {
    "EOL": "019119",
    "UNIDADE": "EMEI - NEUSA CONCEICAO STINCHI, PROFA.",
    "DRE": "PENHA",
    "BAS_1PAR": "15.087,60",
    "BAS_2PAR": "15.087,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "15.087,60",
    "REC_EXTRA2": "90.525,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "171.788,40"
  },
  {
    "EOL": "019127",
    "UNIDADE": "EMEI - CLEIDE MOREIRA DOS SANTOS, PROFA.",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "14.864,40",
    "BAS_2PAR": "14.864,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.864,40",
    "REC_EXTRA2": "89.186,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "169.779,60"
  },
  {
    "EOL": "019135",
    "UNIDADE": "EMEI - DALMO AMARAL MACHADO, PROF.",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "15.105,60",
    "BAS_2PAR": "15.105,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "15.105,60",
    "REC_EXTRA2": "90.633,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "171.950,40"
  },
  {
    "EOL": "019143",
    "UNIDADE": "EMEF - JOSE QUERINO RIBEIRO",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "22.743,00",
    "BAS_2PAR": "22.743,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.743,00",
    "REC_EXTRA2": "136.458,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "213.687,00"
  },
  {
    "EOL": "019151",
    "UNIDADE": "EMEI - JOAO CANDIDO",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "15.019,20",
    "BAS_2PAR": "15.019,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "15.019,20",
    "REC_EXTRA2": "90.115,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "171.172,80"
  },
  {
    "EOL": "019160",
    "UNIDADE": "EMEF - ILEUSA CAETANO DA SILVA, PROFA.",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "25.530,50",
    "BAS_2PAR": "20.530,50",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.530,50",
    "REC_EXTRA2": "123.184,50",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "2.000,00",
    "TOTAL": "196.776,00"
  },
  {
    "EOL": "019178",
    "UNIDADE": "EMEI - MARGARETH DE FATIMA MARQUES DE AZEVEDO",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "20.214,00",
    "BAS_2PAR": "20.214,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "20.214,00",
    "REC_EXTRA2": "121.284,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "28.000,00",
    "TOTAL": "229.926,00"
  },
  {
    "EOL": "019186",
    "UNIDADE": "EMEI - SILVIO CALDAS, COMPOSITOR",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "19.576,40",
    "BAS_2PAR": "14.576,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.576,40",
    "REC_EXTRA2": "87.458,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "164.187,60"
  },
  {
    "EOL": "019194",
    "UNIDADE": "EMEF - PEDRO GERALDO SCHUNCK",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "17.731,80",
    "BAS_2PAR": "17.731,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "17.731,80",
    "REC_EXTRA2": "106.390,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "168.586,20"
  },
  {
    "EOL": "019195",
    "UNIDADE": "EMEF - JOSE KAUFFMANN, DR.",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "25.540,20",
    "BAS_2PAR": "25.540,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.540,20",
    "REC_EXTRA2": "153.241,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "240.861,80"
  },
  {
    "EOL": "019196",
    "UNIDADE": "EMEF - LILIANE VERZINI SILVA, PROFA.",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "18.012,60",
    "BAS_2PAR": "18.012,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "18.012,60",
    "REC_EXTRA2": "108.075,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "171.113,40"
  },
  {
    "EOL": "019197",
    "UNIDADE": "EMEI - ANGELO KRETA",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "14.925,60",
    "BAS_2PAR": "14.925,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.925,60",
    "REC_EXTRA2": "89.553,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "170.330,40"
  },
  {
    "EOL": "019198",
    "UNIDADE": "EMEI CEU - MILTON SANTOS, PROF.",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "20.329,20",
    "BAS_2PAR": "20.329,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "20.329,20",
    "REC_EXTRA2": "121.975,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "28.000,00",
    "TOTAL": "230.962,80"
  },
  {
    "EOL": "019199",
    "UNIDADE": "EMEI - CLEMENCIA FERREIRA DA SILVA",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "16.725,60",
    "BAS_2PAR": "16.725,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.725,60",
    "REC_EXTRA2": "100.353,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "190.530,40"
  },
  {
    "EOL": "019200",
    "UNIDADE": "EMEI - JOANA MITSUE ISHII, PROFA.",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "15.116,40",
    "BAS_2PAR": "15.116,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "15.116,40",
    "REC_EXTRA2": "90.698,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "172.047,60"
  },
  {
    "EOL": "019201",
    "UNIDADE": "EMEI - MARIA DA LUZ SILVA DE OLIVEIRA, PROFA.",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "18.493,20",
    "BAS_2PAR": "18.493,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.493,20",
    "REC_EXTRA2": "110.959,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "210.438,80"
  },
  {
    "EOL": "019202",
    "UNIDADE": "EMEI - JULIO ALVES PEREIRA",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "16.804,80",
    "BAS_2PAR": "16.804,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.804,80",
    "REC_EXTRA2": "100.828,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "191.243,20"
  },
  {
    "EOL": "019203",
    "UNIDADE": "EMEI - JARDIM FELICIDADE",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "13.111,20",
    "BAS_2PAR": "13.111,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.111,20",
    "REC_EXTRA2": "78.667,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "150.000,80"
  },
  {
    "EOL": "019204",
    "UNIDADE": "EMEI - MARIA CLARA MACHADO",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "18.493,20",
    "BAS_2PAR": "18.493,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.493,20",
    "REC_EXTRA2": "110.959,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "210.438,80"
  },
  {
    "EOL": "019205",
    "UNIDADE": "EMEI - JARDIM MONTE BELO",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "16.632,00",
    "BAS_2PAR": "16.632,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.632,00",
    "REC_EXTRA2": "99.792,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "189.688,00"
  },
  {
    "EOL": "019206",
    "UNIDADE": "EMEI - FRANCISCO ADAUTO RODRIGUES",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "16.776,00",
    "BAS_2PAR": "16.776,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.776,00",
    "REC_EXTRA2": "100.656,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "190.984,00"
  },
  {
    "EOL": "019207",
    "UNIDADE": "EMEI - APARECIDA DE LOURDES CARRILHO JARDIM, PROFA.",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "15.048,00",
    "BAS_2PAR": "15.048,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "15.048,00",
    "REC_EXTRA2": "90.288,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "171.432,00"
  },
  {
    "EOL": "019208",
    "UNIDADE": "EMEF - LUIZ ROBERTO MEGA, PROF.",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "31.341,20",
    "BAS_2PAR": "26.341,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.341,20",
    "REC_EXTRA2": "158.047,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "251.070,80"
  },
  {
    "EOL": "019209",
    "UNIDADE": "EMEI - LUIS BIRAGHI, MONSENHOR",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "16.873,20",
    "BAS_2PAR": "16.873,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.873,20",
    "REC_EXTRA2": "101.239,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "191.858,80"
  },
  {
    "EOL": "019210",
    "UNIDADE": "EMEI - ORLANDO VILLAS BOAS",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "16.812,00",
    "BAS_2PAR": "16.812,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.812,00",
    "REC_EXTRA2": "100.872,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "191.308,00"
  },
  {
    "EOL": "019211",
    "UNIDADE": "EMEI - MITSUTANI",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "16.761,60",
    "BAS_2PAR": "16.761,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.761,60",
    "REC_EXTRA2": "100.569,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "190.854,40"
  },
  {
    "EOL": "019212",
    "UNIDADE": "EMEI - ANTONIETA DE BARROS, PROFA.",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "16.650,00",
    "BAS_2PAR": "16.650,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "",
    "REC_EXTRA2": "",
    "EM": "",
    "SALA_LEITURA": "",
    "MAT_PEDAG": "",
    "TOTAL": "48.300,00"
  },
  {
    "EOL": "019213",
    "UNIDADE": "EMEI - RECANTO CAMPO BELO - DIRCE ZILLESG BORGES DOS SANTOS, PROFA.",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "18.493,20",
    "BAS_2PAR": "18.493,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.493,20",
    "REC_EXTRA2": "110.959,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "210.438,80"
  },
  {
    "EOL": "019214",
    "UNIDADE": "EMEI CEU - JARDIM VILA NOVA",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "18.608,40",
    "BAS_2PAR": "18.608,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.608,40",
    "REC_EXTRA2": "111.650,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "211.475,60"
  },
  {
    "EOL": "019215",
    "UNIDADE": "EMEF - REMO RINALDI NADDEO, PROF.",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "23.245,20",
    "BAS_2PAR": "23.245,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.245,20",
    "REC_EXTRA2": "139.471,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "220.206,80"
  },
  {
    "EOL": "019216",
    "UNIDADE": "EMEF - THEREZA MACIEL DE PAULA, PROFA.",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "28.758,60",
    "BAS_2PAR": "28.758,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "28.758,60",
    "REC_EXTRA2": "172.551,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "271.827,40"
  },
  {
    "EOL": "019217",
    "UNIDADE": "EMEF - RECANTO DOS HUMILDES",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "25.875,00",
    "BAS_2PAR": "25.875,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.875,00",
    "REC_EXTRA2": "155.250,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "243.875,00"
  },
  {
    "EOL": "019218",
    "UNIDADE": "EMEF - JARDIM MONTE BELO",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "22.921,20",
    "BAS_2PAR": "22.921,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.921,20",
    "REC_EXTRA2": "137.527,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "215.290,80"
  },
  {
    "EOL": "019219",
    "UNIDADE": "EMEF - ENZO ANTONIO SILVESTRIN, PROF.",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "25.572,60",
    "BAS_2PAR": "25.572,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.572,60",
    "REC_EXTRA2": "153.435,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "241.153,40"
  },
  {
    "EOL": "019220",
    "UNIDADE": "EMEF - MARIO LAGO",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "26.053,20",
    "BAS_2PAR": "26.053,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.053,20",
    "REC_EXTRA2": "156.319,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "245.478,80"
  },
  {
    "EOL": "019221",
    "UNIDADE": "EMEF - JARDIM DAS LARANJEIRAS",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "25.783,20",
    "BAS_2PAR": "25.783,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.783,20",
    "REC_EXTRA2": "154.699,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "243.048,80"
  },
  {
    "EOL": "019222",
    "UNIDADE": "EMEF - VEREMUNDO TOTH, DOM",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "28.742,40",
    "BAS_2PAR": "28.742,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "28.742,40",
    "REC_EXTRA2": "172.454,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "271.681,60"
  },
  {
    "EOL": "019223",
    "UNIDADE": "EMEF - MARIA APARECIDA VILASBOAS, PROFA.",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "28.483,20",
    "BAS_2PAR": "28.483,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "28.483,20",
    "REC_EXTRA2": "170.899,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "269.348,80"
  },
  {
    "EOL": "019224",
    "UNIDADE": "EMEF - MILENA BENEDICTO, PROFA.",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "26.161,20",
    "BAS_2PAR": "26.161,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.161,20",
    "REC_EXTRA2": "156.967,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "246.450,80"
  },
  {
    "EOL": "019225",
    "UNIDADE": "EMEF - WANDA OVIDIO GONCALVES, PROFA.",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "23.029,20",
    "BAS_2PAR": "23.029,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.029,20",
    "REC_EXTRA2": "138.175,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "216.262,80"
  },
  {
    "EOL": "019226",
    "UNIDADE": "EMEF CEU - MARIA CLARA MACHADO",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "34.700,00",
    "BAS_2PAR": "29.700,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "29.700,00",
    "REC_EXTRA2": "178.200,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "283.300,00"
  },
  {
    "EOL": "019227",
    "UNIDADE": "EMEF CEU - CONCEICAO APARECIDA DE JESUS, PROFA.",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "39.335,00",
    "BAS_2PAR": "34.335,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "34.335,00",
    "REC_EXTRA2": "206.010,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "327.015,00"
  },
  {
    "EOL": "019228",
    "UNIDADE": "EMEF - JOSE PEGORARO, PE.",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "25.896,60",
    "BAS_2PAR": "25.896,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.896,60",
    "REC_EXTRA2": "155.379,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "244.069,40"
  },
  {
    "EOL": "019229",
    "UNIDADE": "EMEF - JOSUE DE CASTRO, PROF.",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "35.988,40",
    "BAS_2PAR": "25.988,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.988,40",
    "REC_EXTRA2": "155.930,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "252.895,60"
  },
  {
    "EOL": "019230",
    "UNIDADE": "EMEI - NENE DO AMANHA",
    "DRE": "PENHA",
    "BAS_1PAR": "16.920,00",
    "BAS_2PAR": "16.920,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.920,00",
    "REC_EXTRA2": "101.520,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "192.280,00"
  },
  {
    "EOL": "019231",
    "UNIDADE": "EMEI - GLEBA DO PESSEGO",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "15.019,20",
    "BAS_2PAR": "15.019,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "15.019,20",
    "REC_EXTRA2": "90.115,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "171.172,80"
  },
  {
    "EOL": "019232",
    "UNIDADE": "EMEF - FAZENDA DA JUTA",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "35.502,40",
    "BAS_2PAR": "25.502,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.502,40",
    "REC_EXTRA2": "153.014,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "248.521,60"
  },
  {
    "EOL": "019233",
    "UNIDADE": "EMEF - EDGARD CARONE",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "17.845,20",
    "BAS_2PAR": "17.845,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "17.845,20",
    "REC_EXTRA2": "107.071,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "169.606,80"
  },
  {
    "EOL": "019234",
    "UNIDADE": "EMEI CEU - PAULO FREIRE, PROF.",
    "DRE": "PENHA",
    "BAS_1PAR": "18.518,40",
    "BAS_2PAR": "18.518,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.518,40",
    "REC_EXTRA2": "111.110,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "210.665,60"
  },
  {
    "EOL": "019235",
    "UNIDADE": "EMEI - CONJ. HABITACIONAL SAO FRANCISCO",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "16.682,40",
    "BAS_2PAR": "16.682,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.682,40",
    "REC_EXTRA2": "100.094,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "190.141,60"
  },
  {
    "EOL": "019236",
    "UNIDADE": "EMEI - CONJ. HABITACIONAL GOITI",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "14.929,00",
    "BAS_2PAR": "14.929,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.929,00",
    "REC_EXTRA2": "89.575,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "170.362,00"
  },
  {
    "EOL": "019237",
    "UNIDADE": "EMEI - SANTO DIAS DA SILVA",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "14.763,60",
    "BAS_2PAR": "14.763,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.763,60",
    "REC_EXTRA2": "88.581,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "168.872,40"
  },
  {
    "EOL": "019238",
    "UNIDADE": "EMEI - GLOBO DO SOL",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "21.833,60",
    "BAS_2PAR": "16.833,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.833,60",
    "REC_EXTRA2": "101.001,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "188.502,40"
  },
  {
    "EOL": "019239",
    "UNIDADE": "EMEI - MAURO BAPTISTA, PE.",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "16.632,00",
    "BAS_2PAR": "16.632,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.632,00",
    "REC_EXTRA2": "99.792,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "189.688,00"
  },
  {
    "EOL": "019240",
    "UNIDADE": "EMEI - ALFREDO VOLPI",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "14.785,20",
    "BAS_2PAR": "14.785,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.785,20",
    "REC_EXTRA2": "88.711,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "169.066,80"
  },
  {
    "EOL": "019241",
    "UNIDADE": "EMEF - HELLIO TAVARES, DR.",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "25.977,60",
    "BAS_2PAR": "25.977,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.977,60",
    "REC_EXTRA2": "155.865,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "244.798,40"
  },
  {
    "EOL": "019242",
    "UNIDADE": "EMEI - MARIAZINHA REZENDE FUSARI, PROFA.",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "15.019,00",
    "BAS_2PAR": "15.019,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "15.019,00",
    "REC_EXTRA2": "90.115,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "171.172,00"
  },
  {
    "EOL": "019243",
    "UNIDADE": "EMEF - MARINA MELANDER COUTINHO, PROFA.",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "23.196,60",
    "BAS_2PAR": "23.196,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.196,60",
    "REC_EXTRA2": "139.179,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "219.769,40"
  },
  {
    "EOL": "019244",
    "UNIDADE": "EMEF - GIUSEPPE TAVOLARO, PROF.",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "23.072,00",
    "BAS_2PAR": "23.072,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.072,00",
    "REC_EXTRA2": "138.434,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "218.650,00"
  },
  {
    "EOL": "019245",
    "UNIDADE": "EMEI - APARECIDA CANDIDA DOS SANTOS DE JESUS - DONA CIDICA",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "13.298,40",
    "BAS_2PAR": "13.298,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.298,40",
    "REC_EXTRA2": "79.790,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "151.685,60"
  },
  {
    "EOL": "019246",
    "UNIDADE": "EMEI - RAUL NEMENZ, PROF.",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "16.876,80",
    "BAS_2PAR": "16.876,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.876,80",
    "REC_EXTRA2": "101.260,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "191.891,20"
  },
  {
    "EOL": "019247",
    "UNIDADE": "EMEF - TEREZINHA MOTA DE FIGUEIREDO",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "20.350,80",
    "BAS_2PAR": "20.350,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.350,80",
    "REC_EXTRA2": "122.104,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "192.157,20"
  },
  {
    "EOL": "019248",
    "UNIDADE": "EMEI - WILSON REIS SANTOS",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "18.687,60",
    "BAS_2PAR": "18.687,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.687,60",
    "REC_EXTRA2": "112.125,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "212.188,40"
  },
  {
    "EOL": "019249",
    "UNIDADE": "EMEF - DAISY AMADIO FUJIWARA, PROFA.",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "22.996,50",
    "BAS_2PAR": "22.996,50",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.996,50",
    "REC_EXTRA2": "137.980,50",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "215.970,00"
  },
  {
    "EOL": "019250",
    "UNIDADE": "EMEI - LAURA DA CONCEICAO PEREIRA QUINTAES, PROFA.",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "18.450,00",
    "BAS_2PAR": "18.450,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.450,00",
    "REC_EXTRA2": "110.700,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "210.050,00"
  },
  {
    "EOL": "019251",
    "UNIDADE": "EMEF - CASARAO (EXTINTA)",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "",
    "BAS_2PAR": "",
    "REC_EXTRA": "",
    "BAS_3PAR": "",
    "REC_EXTRA2": "",
    "EM": "",
    "SALA_LEITURA": "",
    "MAT_PEDAG": "",
    "TOTAL": ""
  },
  {
    "EOL": "019252",
    "UNIDADE": "EMEI - JOSE ROBSON COSTA DE ARAUJO, BOMBEIRO",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "18.396,00",
    "BAS_2PAR": "18.396,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.396,00",
    "REC_EXTRA2": "110.376,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "209.564,00"
  },
  {
    "EOL": "019253",
    "UNIDADE": "EMEI - JOAO PAULO II, PAPA",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "16.646,40",
    "BAS_2PAR": "16.646,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.646,40",
    "REC_EXTRA2": "99.878,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "189.817,60"
  },
  {
    "EOL": "019254",
    "UNIDADE": "EMEF - AYRTON OLIVEIRA SAMPAIO, PROF.",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "22.640,40",
    "BAS_2PAR": "22.640,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.640,40",
    "REC_EXTRA2": "135.842,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "212.763,60"
  },
  {
    "EOL": "019255",
    "UNIDADE": "EMEI - ZELIA GATTAI",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "15.112,80",
    "BAS_2PAR": "15.112,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "15.112,80",
    "REC_EXTRA2": "90.676,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "172.015,20"
  },
  {
    "EOL": "019256",
    "UNIDADE": "EMEI - GEORGE SAVALLA GOMES - CAREQUINHA",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "16.866,00",
    "BAS_2PAR": "16.866,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.866,00",
    "REC_EXTRA2": "101.196,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "191.794,00"
  },
  {
    "EOL": "019257",
    "UNIDADE": "EMEF - ALDO DA TOFORI, PE.",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "26.647,20",
    "BAS_2PAR": "26.647,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.647,20",
    "REC_EXTRA2": "159.883,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "250.824,80"
  },
  {
    "EOL": "019258",
    "UNIDADE": "EMEI - GRAJAU",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "16.873,20",
    "BAS_2PAR": "16.873,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.873,20",
    "REC_EXTRA2": "101.239,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "191.858,80"
  },
  {
    "EOL": "019259",
    "UNIDADE": "EMEI - CARLOS EDUARDO DE CAMARGO ARANHA, DR.",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "15.098,40",
    "BAS_2PAR": "15.098,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "15.098,40",
    "REC_EXTRA2": "90.590,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "171.885,60"
  },
  {
    "EOL": "019260",
    "UNIDADE": "EMEF CEU - PAULO GONCALO DOS SANTOS, PROF.",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "33.515,60",
    "BAS_2PAR": "28.515,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "28.515,60",
    "REC_EXTRA2": "171.093,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "270.640,40"
  },
  {
    "EOL": "019261",
    "UNIDADE": "EMEF CEU - PAULO GOMES CARDIM, PROF. DR.",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "33.499,40",
    "BAS_2PAR": "28.499,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "28.499,40",
    "REC_EXTRA2": "170.996,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "270.494,60"
  },
  {
    "EOL": "019262",
    "UNIDADE": "EMEF CEU - BUTANTA",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "30.572,50",
    "BAS_2PAR": "25.572,50",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.572,50",
    "REC_EXTRA2": "153.435,50",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "244.153,00"
  },
  {
    "EOL": "019263",
    "UNIDADE": "EMEF CEU - CIDADE DUTRA",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "34.213,60",
    "BAS_2PAR": "24.213,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "24.213,60",
    "REC_EXTRA2": "145.281,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "236.922,40"
  },
  {
    "EOL": "019264",
    "UNIDADE": "EMEF CEU - INACIO MONTEIRO",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "32.167,40",
    "BAS_2PAR": "27.167,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "27.167,40",
    "REC_EXTRA2": "163.004,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "260.506,60"
  },
  {
    "EOL": "019265",
    "UNIDADE": "EMEF CEU - JAMBEIRO",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "36.701,60",
    "BAS_2PAR": "31.701,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "31.701,60",
    "REC_EXTRA2": "190.209,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "301.314,40"
  },
  {
    "EOL": "019266",
    "UNIDADE": "EMEF CEU - MENINOS",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "36.433,00",
    "BAS_2PAR": "26.433,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.433,00",
    "REC_EXTRA2": "158.598,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "256.897,00"
  },
  {
    "EOL": "019267",
    "UNIDADE": "EMEF - MARIA RITA DE CASSIA PINHEIRO SIMOES BRAGA, PROFA.",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "25.891,20",
    "BAS_2PAR": "25.891,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.891,20",
    "REC_EXTRA2": "155.347,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "244.020,80"
  },
  {
    "EOL": "019269",
    "UNIDADE": "EMEF CEU - PARQUE SAO CARLOS",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "33.554,80",
    "BAS_2PAR": "23.554,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.554,80",
    "REC_EXTRA2": "141.328,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "230.993,20"
  },
  {
    "EOL": "019271",
    "UNIDADE": "EMEF CEU - PERA MARMELO",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "39.964,60",
    "BAS_2PAR": "29.964,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "29.964,60",
    "REC_EXTRA2": "179.787,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "290.681,40"
  },
  {
    "EOL": "019272",
    "UNIDADE": "EMEF CEU - PERUS",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "37.779,40",
    "BAS_2PAR": "27.779,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "27.779,40",
    "REC_EXTRA2": "166.676,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "271.014,60"
  },
  {
    "EOL": "019274",
    "UNIDADE": "EMEF CEU - CANDIDA DORA PINO PRETINI, PROFA.",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "25.286,40",
    "BAS_2PAR": "25.286,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.286,40",
    "REC_EXTRA2": "151.718,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "238.577,60"
  },
  {
    "EOL": "019275",
    "UNIDADE": "EMEF - FRANCISCO REBOLO",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "22.640,40",
    "BAS_2PAR": "22.640,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.640,40",
    "REC_EXTRA2": "135.842,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "212.763,60"
  },
  {
    "EOL": "019276",
    "UNIDADE": "EMEF CEU - TRES LAGOS",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "40.407,40",
    "BAS_2PAR": "30.407,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "30.407,40",
    "REC_EXTRA2": "182.444,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "294.666,60"
  },
  {
    "EOL": "019277",
    "UNIDADE": "EMEF CEU - VILA ATLANTICA",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "34.937,60",
    "BAS_2PAR": "29.937,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "29.937,60",
    "REC_EXTRA2": "179.625,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "285.438,40"
  },
  {
    "EOL": "019278",
    "UNIDADE": "EMEF CEU - VILA CURUCA",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "35.569,40",
    "BAS_2PAR": "30.569,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "30.569,40",
    "REC_EXTRA2": "183.416,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "291.124,60"
  },
  {
    "EOL": "019279",
    "UNIDADE": "EMEI CEU - ALVARENGA",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "20.210,40",
    "BAS_2PAR": "20.210,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "20.210,40",
    "REC_EXTRA2": "121.262,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "28.000,00",
    "TOTAL": "229.893,60"
  },
  {
    "EOL": "019280",
    "UNIDADE": "EMEI CEU - ARICANDUVA",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "21.797,60",
    "BAS_2PAR": "16.797,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.797,60",
    "REC_EXTRA2": "100.785,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "188.178,40"
  },
  {
    "EOL": "019281",
    "UNIDADE": "EMEI CEU - BUTANTA",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "16.563,50",
    "BAS_2PAR": "16.563,50",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.563,50",
    "REC_EXTRA2": "99.381,50",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "189.072,00"
  },
  {
    "EOL": "019283",
    "UNIDADE": "EMEF - JOSE BLOTA JUNIOR, DEP.",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "37.522,40",
    "BAS_2PAR": "32.522,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "32.522,40",
    "REC_EXTRA2": "195.134,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "308.701,60"
  },
  {
    "EOL": "019284",
    "UNIDADE": "EMEI CEU - INACIO MONTEIRO",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "15.102,00",
    "BAS_2PAR": "15.102,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "15.102,00",
    "REC_EXTRA2": "90.612,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "171.918,00"
  },
  {
    "EOL": "019285",
    "UNIDADE": "EMEI CEU - THERESINHA SQUINCA DA SILVA, PROFA.",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "18.662,40",
    "BAS_2PAR": "18.662,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.662,40",
    "REC_EXTRA2": "111.974,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "211.961,60"
  },
  {
    "EOL": "019287",
    "UNIDADE": "EMEI CEU - NAVEGANTES",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "20.440,80",
    "BAS_2PAR": "20.440,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "20.440,80",
    "REC_EXTRA2": "122.644,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "28.000,00",
    "TOTAL": "231.967,20"
  },
  {
    "EOL": "019289",
    "UNIDADE": "EMEI CEU - ANTON MAKARENKO",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "20.210,40",
    "BAS_2PAR": "20.210,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "20.210,40",
    "REC_EXTRA2": "121.262,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "28.000,00",
    "TOTAL": "229.893,60"
  },
  {
    "EOL": "019290",
    "UNIDADE": "EMEI CEU - PERA MARMELO",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "18.489,60",
    "BAS_2PAR": "18.489,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.489,60",
    "REC_EXTRA2": "110.937,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "210.406,40"
  },
  {
    "EOL": "019291",
    "UNIDADE": "EMEF - VARGEM GRANDE",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "26.074,80",
    "BAS_2PAR": "26.074,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.074,80",
    "REC_EXTRA2": "156.448,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "245.673,20"
  },
  {
    "EOL": "019293",
    "UNIDADE": "EMEI CEU - SAO MATEUS",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "18.432,00",
    "BAS_2PAR": "18.432,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.432,00",
    "REC_EXTRA2": "110.592,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "209.888,00"
  },
  {
    "EOL": "019295",
    "UNIDADE": "EMEI CEU - TRES LAGOS",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "20.498,40",
    "BAS_2PAR": "20.498,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "20.498,40",
    "REC_EXTRA2": "122.990,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "28.000,00",
    "TOTAL": "232.485,60"
  },
  {
    "EOL": "019296",
    "UNIDADE": "EMEI CEU - VILA ATLANTICA",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "16.898,40",
    "BAS_2PAR": "16.898,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.898,40",
    "REC_EXTRA2": "101.390,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "192.085,60"
  },
  {
    "EOL": "019297",
    "UNIDADE": "EMEI CEU - VILA CURUCA",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "20.232,00",
    "BAS_2PAR": "20.232,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "20.232,00",
    "REC_EXTRA2": "121.392,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "28.000,00",
    "TOTAL": "230.088,00"
  },
  {
    "EOL": "019299",
    "UNIDADE": "EMEI CEU - ROSA DA CHINA",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "26.909,20",
    "BAS_2PAR": "16.909,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.909,20",
    "REC_EXTRA2": "101.455,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "194.182,80"
  },
  {
    "EOL": "019300",
    "UNIDADE": "EMEI - JOSE LA TORRE, PROF.",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "15.040,80",
    "BAS_2PAR": "15.040,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "15.040,80",
    "REC_EXTRA2": "90.244,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "171.367,20"
  },
  {
    "EOL": "019301",
    "UNIDADE": "EMEI - MARIA APARECIDA LARA COIADO, PROFA.",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "18.435,60",
    "BAS_2PAR": "18.435,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.435,60",
    "REC_EXTRA2": "110.613,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "209.920,40"
  },
  {
    "EOL": "019302",
    "UNIDADE": "EMEI CEU - CASA BLANCA",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "24.112,80",
    "BAS_2PAR": "24.112,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "24.112,80",
    "REC_EXTRA2": "144.676,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "32.000,00",
    "TOTAL": "269.015,20"
  },
  {
    "EOL": "019303",
    "UNIDADE": "EMEF CEU - CASA BLANCA",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "33.834,20",
    "BAS_2PAR": "28.834,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "28.834,20",
    "REC_EXTRA2": "173.005,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "275.507,80"
  },
  {
    "EOL": "019304",
    "UNIDADE": "EMEI CEU - PAZ",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "18.504,00",
    "BAS_2PAR": "18.504,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.504,00",
    "REC_EXTRA2": "111.024,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "210.536,00"
  },
  {
    "EOL": "019305",
    "UNIDADE": "EMEF - JOAO DA SILVA",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "25.345,80",
    "BAS_2PAR": "25.345,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.345,80",
    "REC_EXTRA2": "152.074,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "239.112,20"
  },
  {
    "EOL": "019307",
    "UNIDADE": "EMEI CEU - LUIZA HELENA FERREIRA, PROFA.",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "20.386,80",
    "BAS_2PAR": "20.386,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "20.386,80",
    "REC_EXTRA2": "122.320,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "28.000,00",
    "TOTAL": "231.481,20"
  },
  {
    "EOL": "019308",
    "UNIDADE": "EMEF CEU - HERMES FERREIRA DE SOUZA",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "36.917,60",
    "BAS_2PAR": "31.917,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "31.917,60",
    "REC_EXTRA2": "191.505,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "303.258,40"
  },
  {
    "EOL": "019309",
    "UNIDADE": "EMEI - FERNANDO SABINO",
    "DRE": "PENHA",
    "BAS_1PAR": "18.414,00",
    "BAS_2PAR": "18.414,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.414,00",
    "REC_EXTRA2": "110.484,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "209.726,00"
  },
  {
    "EOL": "019310",
    "UNIDADE": "CEI CEU - SILVANA APARECIDA TOBIAS, PROFA.",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "11.358,00",
    "BAS_2PAR": "11.358,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.358,00",
    "REC_EXTRA2": "68.148,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.222,00"
  },
  {
    "EOL": "019311",
    "UNIDADE": "EMEI - JARDIM IMPERADOR",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "15.073,20",
    "BAS_2PAR": "15.073,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "15.073,20",
    "REC_EXTRA2": "90.439,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "171.658,80"
  },
  {
    "EOL": "019312",
    "UNIDADE": "EMEI - JARDIM DA CONQUISTA",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "16.596,00",
    "BAS_2PAR": "16.596,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.596,00",
    "REC_EXTRA2": "99.576,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "189.364,00"
  },
  {
    "EOL": "019313",
    "UNIDADE": "EMEF CEU - MANOEL VIEIRA DE QUEIROZ FILHO",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "39.036,20",
    "BAS_2PAR": "34.036,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "34.036,20",
    "REC_EXTRA2": "204.217,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "324.325,80"
  },
  {
    "EOL": "019314",
    "UNIDADE": "EMEF - PHILO GONCALVES DOS SANTOS, PROFA.",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "22.991,40",
    "BAS_2PAR": "22.991,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.991,40",
    "REC_EXTRA2": "137.948,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "215.922,60"
  },
  {
    "EOL": "019315",
    "UNIDADE": "EMEI CEU - EDNA ALVES DE SOUSA, PROFA.",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "16.920,00",
    "BAS_2PAR": "16.920,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.920,00",
    "REC_EXTRA2": "101.520,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "192.280,00"
  },
  {
    "EOL": "019316",
    "UNIDADE": "EMEI - CELIA RIBEIRO LANDIM, PROFA.",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "16.851,60",
    "BAS_2PAR": "16.851,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.851,60",
    "REC_EXTRA2": "101.109,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "191.664,40"
  },
  {
    "EOL": "019318",
    "UNIDADE": "EMEI - EDI GREENFIELD, PROFA.",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "14.990,40",
    "BAS_2PAR": "14.990,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.990,40",
    "REC_EXTRA2": "89.942,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "170.913,60"
  },
  {
    "EOL": "019319",
    "UNIDADE": "EMEI - FATIMA REGINA DA CRUZ SABINO CALAÇA, PROFA.",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "18.554,40",
    "BAS_2PAR": "18.554,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.554,40",
    "REC_EXTRA2": "111.326,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "210.989,60"
  },
  {
    "EOL": "019320",
    "UNIDADE": "EMEI - JARDIM DA CONQUISTA II",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "18.468,00",
    "BAS_2PAR": "18.468,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.468,00",
    "REC_EXTRA2": "110.808,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "210.212,00"
  },
  {
    "EOL": "019321",
    "UNIDADE": "EMEF - LOURIVAL BRANDAO DOS SANTOS",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "17.321,40",
    "BAS_2PAR": "17.321,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "17.321,40",
    "REC_EXTRA2": "103.928,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "2.000,00",
    "TOTAL": "162.892,60"
  },
  {
    "EOL": "019322",
    "UNIDADE": "EMEF - CITY JARAGUA IV",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "22.527,00",
    "BAS_2PAR": "22.527,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.527,00",
    "REC_EXTRA2": "135.162,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "211.743,00"
  },
  {
    "EOL": "019323",
    "UNIDADE": "EMEI - ODILEA BOTTA DE MATTOS, PROFA.",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "18.435,60",
    "BAS_2PAR": "18.435,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.435,60",
    "REC_EXTRA2": "110.613,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "209.920,40"
  },
  {
    "EOL": "019324",
    "UNIDADE": "EMEI - JARDIM NOVO SANTO AMARO - ALICE ALVES MARTINS",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "16.696,80",
    "BAS_2PAR": "16.696,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.696,80",
    "REC_EXTRA2": "100.180,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "190.271,20"
  },
  {
    "EOL": "019325",
    "UNIDADE": "EMEI - MANOEL DE ALVARENGA FREIRE JUNIOR, PROF.",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "16.574,40",
    "BAS_2PAR": "16.574,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.574,40",
    "REC_EXTRA2": "99.446,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "189.169,60"
  },
  {
    "EOL": "019326",
    "UNIDADE": "EMEI - JAGUARE",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "16.862,40",
    "BAS_2PAR": "16.862,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.862,40",
    "REC_EXTRA2": "101.174,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "191.761,60"
  },
  {
    "EOL": "019327",
    "UNIDADE": "EMEI - RIO PEQUENO I",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "19.662,50",
    "BAS_2PAR": "14.662,50",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.662,50",
    "REC_EXTRA2": "87.976,50",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "164.964,00"
  },
  {
    "EOL": "019328",
    "UNIDADE": "EMEI CEU - IRENE MANKE MARQUES, PROFA.",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "13.305,60",
    "BAS_2PAR": "13.305,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.305,60",
    "REC_EXTRA2": "79.833,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "151.750,40"
  },
  {
    "EOL": "019330",
    "UNIDADE": "EMEI CEU - JORGE AMADO",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "16.862,40",
    "BAS_2PAR": "16.862,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.862,40",
    "REC_EXTRA2": "101.174,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "191.761,60"
  },
  {
    "EOL": "019331",
    "UNIDADE": "EMEI CEU - VILA RUBI",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "18.676,80",
    "BAS_2PAR": "18.676,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.676,80",
    "REC_EXTRA2": "112.060,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "212.091,20"
  },
  {
    "EOL": "019332",
    "UNIDADE": "EMEI CEU - JACANA",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "20.217,60",
    "BAS_2PAR": "20.217,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "20.217,60",
    "REC_EXTRA2": "121.305,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "28.000,00",
    "TOTAL": "229.958,40"
  },
  {
    "EOL": "019333",
    "UNIDADE": "EMEF CEU - AGUA AZUL",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "41.027,00",
    "BAS_2PAR": "36.027,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "36.027,00",
    "REC_EXTRA2": "216.162,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "342.243,00"
  },
  {
    "EOL": "019335",
    "UNIDADE": "EMEF CEU - FEITICO DA VILA",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "36.856,40",
    "BAS_2PAR": "31.856,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "31.856,40",
    "REC_EXTRA2": "191.138,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "302.707,60"
  },
  {
    "EOL": "019336",
    "UNIDADE": "EMEF CEU - VILA RUBI",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "36.973,00",
    "BAS_2PAR": "26.973,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.973,00",
    "REC_EXTRA2": "161.838,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "263.757,00"
  },
  {
    "EOL": "019337",
    "UNIDADE": "EMEF CEU - JACANA",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "35.180,60",
    "BAS_2PAR": "30.180,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "30.180,60",
    "REC_EXTRA2": "181.083,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "287.625,40"
  },
  {
    "EOL": "019338",
    "UNIDADE": "EMEI CEU - FEITICO DA VILA",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "16.837,20",
    "BAS_2PAR": "16.837,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.837,20",
    "REC_EXTRA2": "101.023,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "191.534,80"
  },
  {
    "EOL": "019339",
    "UNIDADE": "EMEI - CIDADE A E CARVALHO",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "16.783,20",
    "BAS_2PAR": "16.783,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.783,20",
    "REC_EXTRA2": "100.699,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "191.048,80"
  },
  {
    "EOL": "019340",
    "UNIDADE": "EMEI - ROBERTO BURLE MARX",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "16.891,20",
    "BAS_2PAR": "16.891,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.891,20",
    "REC_EXTRA2": "101.347,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "192.020,80"
  },
  {
    "EOL": "019341",
    "UNIDADE": "EMEF - JARDIM GUARANI - JOSE ALFREDO APOLINARIO, PROF.",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "25.939,80",
    "BAS_2PAR": "25.939,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.939,80",
    "REC_EXTRA2": "155.638,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "244.458,20"
  },
  {
    "EOL": "019342",
    "UNIDADE": "EMEI - JARDIM KERALUX",
    "DRE": "PENHA",
    "BAS_1PAR": "14.965,20",
    "BAS_2PAR": "14.965,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.965,20",
    "REC_EXTRA2": "89.791,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "170.686,80"
  },
  {
    "EOL": "019344",
    "UNIDADE": "EMEI - ELIZA MARA TORRES, PROFA.",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "16.599,60",
    "BAS_2PAR": "16.599,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.599,60",
    "REC_EXTRA2": "99.597,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "189.396,40"
  },
  {
    "EOL": "019345",
    "UNIDADE": "EMEI - ESTRADA TURISTICA DO JARAGUA",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "14.774,40",
    "BAS_2PAR": "14.774,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.774,40",
    "REC_EXTRA2": "88.646,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "168.969,60"
  },
  {
    "EOL": "019346",
    "UNIDADE": "EMEI - NAJLA CURI IZAR",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "20.214,00",
    "BAS_2PAR": "20.214,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "20.214,00",
    "REC_EXTRA2": "121.284,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "28.000,00",
    "TOTAL": "229.926,00"
  },
  {
    "EOL": "019347",
    "UNIDADE": "EMEF - JARDIM DA CONQUISTA",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "19.805,40",
    "BAS_2PAR": "19.805,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "19.805,40",
    "REC_EXTRA2": "118.832,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "187.248,60"
  },
  {
    "EOL": "019348",
    "UNIDADE": "EMEF - GIANFRANCESCO GUARNIERI",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "20.205,00",
    "BAS_2PAR": "20.205,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.205,00",
    "REC_EXTRA2": "121.230,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "190.845,00"
  },
  {
    "EOL": "019349",
    "UNIDADE": "EMEF - JARDIM DAMASCENO I",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "20.772,00",
    "BAS_2PAR": "20.772,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.772,00",
    "REC_EXTRA2": "124.632,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "195.948,00"
  },
  {
    "EOL": "019350",
    "UNIDADE": "EMEF - JOSE HERMINIO RODRIGUES, CEL. PM",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "25.448,40",
    "BAS_2PAR": "25.448,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.448,40",
    "REC_EXTRA2": "152.690,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "240.035,60"
  },
  {
    "EOL": "019351",
    "UNIDADE": "EMEF - MARILI DIAS, PROFA.",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "25.529,40",
    "BAS_2PAR": "25.529,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.529,40",
    "REC_EXTRA2": "153.176,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "240.764,60"
  },
  {
    "EOL": "019352",
    "UNIDADE": "EMEI CEU - GUARAPIRANGA",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "16.855,20",
    "BAS_2PAR": "16.855,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.855,20",
    "REC_EXTRA2": "101.131,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "191.696,80"
  },
  {
    "EOL": "019353",
    "UNIDADE": "EMEI CEU - CANTOS DO AMANHECER",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "18.547,20",
    "BAS_2PAR": "18.547,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.547,20",
    "REC_EXTRA2": "111.283,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "210.924,80"
  },
  {
    "EOL": "019354",
    "UNIDADE": "EMEI CEU - VILA DO SOL",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "18.536,40",
    "BAS_2PAR": "18.536,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.536,40",
    "REC_EXTRA2": "111.218,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "210.827,60"
  },
  {
    "EOL": "019355",
    "UNIDADE": "EMEI CEU - JARDIM PAULISTANO",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "18.525,60",
    "BAS_2PAR": "18.525,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.525,60",
    "REC_EXTRA2": "111.153,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "210.730,40"
  },
  {
    "EOL": "019357",
    "UNIDADE": "EMEI CEU - BRAZ JAIME ROMANO",
    "DRE": "PENHA",
    "BAS_1PAR": "18.540,00",
    "BAS_2PAR": "18.540,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.540,00",
    "REC_EXTRA2": "111.240,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "210.860,00"
  },
  {
    "EOL": "019358",
    "UNIDADE": "EMEI CEU - ERIKA DE SOUZA BRITO MATOS, PROFA.",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "13.276,80",
    "BAS_2PAR": "13.276,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.276,80",
    "REC_EXTRA2": "79.660,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "151.491,20"
  },
  {
    "EOL": "019359",
    "UNIDADE": "EMEI CEU - TRES PONTES",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "20.257,20",
    "BAS_2PAR": "20.257,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "20.257,20",
    "REC_EXTRA2": "121.543,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "28.000,00",
    "TOTAL": "230.314,80"
  },
  {
    "EOL": "019360",
    "UNIDADE": "EMEF CEU - MARIO FITTIPALDI",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "39.484,00",
    "BAS_2PAR": "29.484,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "29.484,00",
    "REC_EXTRA2": "176.904,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "286.356,00"
  },
  {
    "EOL": "019361",
    "UNIDADE": "EMEF CEU - CANTOS DO AMANHECER",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "45.166,60",
    "BAS_2PAR": "35.166,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "35.166,60",
    "REC_EXTRA2": "210.999,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "339.499,40"
  },
  {
    "EOL": "019362",
    "UNIDADE": "EMEF CEU - VILA DO SOL",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "47.508,40",
    "BAS_2PAR": "37.508,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "37.508,40",
    "REC_EXTRA2": "225.050,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "10.000,00",
    "TOTAL": "362.575,60"
  },
  {
    "EOL": "019363",
    "UNIDADE": "EMEF CEU - JARDIM PAULISTANO",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "35.407,40",
    "BAS_2PAR": "30.407,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "30.407,40",
    "REC_EXTRA2": "182.444,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "289.666,60"
  },
  {
    "EOL": "019364",
    "UNIDADE": "EMEI CEU - ROQUE SPENCER MACIEL DE BARROS, PROF.",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "18.608,40",
    "BAS_2PAR": "18.608,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.608,40",
    "REC_EXTRA2": "111.650,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "211.475,60"
  },
  {
    "EOL": "019365",
    "UNIDADE": "EMEF CEU - ROSANGELA RODRIGUES VIEIRA, PROFA.",
    "DRE": "PENHA",
    "BAS_1PAR": "43.318,00",
    "BAS_2PAR": "33.318,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "33.318,00",
    "REC_EXTRA2": "199.908,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "322.862,00"
  },
  {
    "EOL": "019366",
    "UNIDADE": "EMEF CEU - TATIANA BELINKY",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "34.413,80",
    "BAS_2PAR": "29.413,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "29.413,80",
    "REC_EXTRA2": "176.482,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "280.724,20"
  },
  {
    "EOL": "019367",
    "UNIDADE": "EMEF CEU - TRES PONTES",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "34.700,00",
    "BAS_2PAR": "29.700,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "29.700,00",
    "REC_EXTRA2": "178.200,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "283.300,00"
  },
  {
    "EOL": "019369",
    "UNIDADE": "EMEF CEU - LAJEADO",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "37.506,20",
    "BAS_2PAR": "32.506,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "32.506,20",
    "REC_EXTRA2": "195.037,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "308.555,80"
  },
  {
    "EOL": "019370",
    "UNIDADE": "EMEF - JARDIM MITSUTANI I - PAULO PATARRA, JORNALISTA",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "25.237,80",
    "BAS_2PAR": "25.237,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.237,80",
    "REC_EXTRA2": "151.426,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "238.140,20"
  },
  {
    "EOL": "019371",
    "UNIDADE": "EMEI CEU - CAMINHO DO MAR",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "18.568,80",
    "BAS_2PAR": "18.568,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.568,80",
    "REC_EXTRA2": "111.412,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "211.119,20"
  },
  {
    "EOL": "019372",
    "UNIDADE": "EMEF - CHACARA SONHO AZUL",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "22.672,80",
    "BAS_2PAR": "22.672,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.672,80",
    "REC_EXTRA2": "136.036,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "213.055,20"
  },
  {
    "EOL": "019373",
    "UNIDADE": "EMEF CEU - CAMINHO DO MAR",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "40.798,00",
    "BAS_2PAR": "30.798,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "30.798,00",
    "REC_EXTRA2": "184.788,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "298.182,00"
  },
  {
    "EOL": "019374",
    "UNIDADE": "EMEI CEU - LOREANE LALLO, PROFA.",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "18.514,80",
    "BAS_2PAR": "18.514,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.514,80",
    "REC_EXTRA2": "111.088,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "210.633,20"
  },
  {
    "EOL": "019375",
    "UNIDADE": "EMEI CEU - PARAISOPOLIS",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "18.439,20",
    "BAS_2PAR": "18.439,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.439,20",
    "REC_EXTRA2": "110.635,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "209.952,80"
  },
  {
    "EOL": "019376",
    "UNIDADE": "EMEI CEU - LUCIANA AZEVEDO POMPERMAYER, PROFA.",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "18.478,80",
    "BAS_2PAR": "18.478,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.478,80",
    "REC_EXTRA2": "110.872,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "210.309,20"
  },
  {
    "EOL": "019377",
    "UNIDADE": "EMEI CEU - FORMOSA",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "18.669,60",
    "BAS_2PAR": "18.669,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.669,60",
    "REC_EXTRA2": "112.017,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "212.026,40"
  },
  {
    "EOL": "019378",
    "UNIDADE": "EMEI CEU - UIRAPURU",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "16.898,00",
    "BAS_2PAR": "16.898,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.898,00",
    "REC_EXTRA2": "101.390,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "192.084,00"
  },
  {
    "EOL": "019380",
    "UNIDADE": "EMEI CEU - PARQUE ANHANGUERA",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "18.507,60",
    "BAS_2PAR": "18.507,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.507,60",
    "REC_EXTRA2": "111.045,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "210.568,40"
  },
  {
    "EOL": "019381",
    "UNIDADE": "EMEI - EDALZIR SAMPAIO LIPORONI, PROFA.",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "16.772,40",
    "BAS_2PAR": "16.772,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.772,40",
    "REC_EXTRA2": "100.634,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "190.951,60"
  },
  {
    "EOL": "019382",
    "UNIDADE": "EMEI CEU - ALTO ALEGRE",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "20.217,60",
    "BAS_2PAR": "20.217,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "20.217,60",
    "REC_EXTRA2": "121.305,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "28.000,00",
    "TOTAL": "229.958,40"
  },
  {
    "EOL": "019383",
    "UNIDADE": "EMEF CEU - JOSE SARAMAGO",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "25.977,60",
    "BAS_2PAR": "25.977,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.977,60",
    "REC_EXTRA2": "155.865,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "244.798,40"
  },
  {
    "EOL": "019384",
    "UNIDADE": "EMEF CEU - PARAISOPOLIS",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "43.161,40",
    "BAS_2PAR": "33.161,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "33.161,40",
    "REC_EXTRA2": "198.968,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "321.452,60"
  },
  {
    "EOL": "019385",
    "UNIDADE": "EMEF CEU - MARA CRISTINA TARTAGLIA SENA, PROFA.",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "40.366,00",
    "BAS_2PAR": "30.366,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "30.366,00",
    "REC_EXTRA2": "182.196,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "294.294,00"
  },
  {
    "EOL": "019386",
    "UNIDADE": "EMEF CEU - MARIA APARECIDA DE SOUZA CAMPOS, PROFA.",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "31.368,20",
    "BAS_2PAR": "26.368,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.368,20",
    "REC_EXTRA2": "158.209,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "251.313,80"
  },
  {
    "EOL": "019388",
    "UNIDADE": "EMEF CEU - ANTONIO CARLOS DA ROCHA, PROF.",
    "DRE": "PENHA",
    "BAS_1PAR": "31.616,60",
    "BAS_2PAR": "26.616,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.616,60",
    "REC_EXTRA2": "159.699,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "253.549,40"
  },
  {
    "EOL": "019389",
    "UNIDADE": "EMEF CEU - PARQUE ANHANGUERA",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "34.575,80",
    "BAS_2PAR": "29.575,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "29.575,80",
    "REC_EXTRA2": "177.454,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "282.182,20"
  },
  {
    "EOL": "019390",
    "UNIDADE": "EMEF CEU - JAGUARE",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "34.028,60",
    "BAS_2PAR": "29.028,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "29.028,60",
    "REC_EXTRA2": "174.171,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "277.257,40"
  },
  {
    "EOL": "019391",
    "UNIDADE": "EMEF CEU - ALTO ALEGRE",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "43.058,80",
    "BAS_2PAR": "33.058,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "33.058,80",
    "REC_EXTRA2": "198.352,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "320.529,20"
  },
  {
    "EOL": "019392",
    "UNIDADE": "EMEI CEU - JAGUARE",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "16.772,40",
    "BAS_2PAR": "16.772,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.772,40",
    "REC_EXTRA2": "100.634,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "190.951,60"
  },
  {
    "EOL": "019393",
    "UNIDADE": "EMEI - BERNARDINO PIMENTEL MENDES, DR.",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "18.439,20",
    "BAS_2PAR": "18.439,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.439,20",
    "REC_EXTRA2": "110.635,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "209.952,80"
  },
  {
    "EOL": "019394",
    "UNIDADE": "EMEF - CAIRA ALAYDE ALVARENGA MEDEA, PROFA.",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "20.502,00",
    "BAS_2PAR": "20.502,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.502,00",
    "REC_EXTRA2": "123.012,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "193.518,00"
  },
  {
    "EOL": "019395",
    "UNIDADE": "EMEF - NEIR AUGUSTO LOPES, PROF.",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "23.196,60",
    "BAS_2PAR": "23.196,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.196,60",
    "REC_EXTRA2": "139.179,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "219.769,40"
  },
  {
    "EOL": "019396",
    "UNIDADE": "EMEF - SERAFIN MARTINEZ GUTIERREZ, PE.",
    "DRE": "PENHA",
    "BAS_1PAR": "23.077,80",
    "BAS_2PAR": "23.077,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.077,80",
    "REC_EXTRA2": "138.466,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "218.700,20"
  },
  {
    "EOL": "019397",
    "UNIDADE": "EMEF - GILMAR TACCOLA, PROF.",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "20.583,00",
    "BAS_2PAR": "20.583,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.583,00",
    "REC_EXTRA2": "123.498,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "194.247,00"
  },
  {
    "EOL": "019398",
    "UNIDADE": "EMEF - AGUAS DE MARCO",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "23.315,40",
    "BAS_2PAR": "23.315,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.315,40",
    "REC_EXTRA2": "139.892,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "220.838,60"
  },
  {
    "EOL": "019399",
    "UNIDADE": "EMEF - JANUARIO MANTELLI NETO, DEP.",
    "DRE": "PENHA",
    "BAS_1PAR": "31.514,00",
    "BAS_2PAR": "26.514,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.514,00",
    "REC_EXTRA2": "159.084,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "252.626,00"
  },
  {
    "EOL": "019400",
    "UNIDADE": "EMEF - DAMA ENTRE RIOS VERDES",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "23.315,40",
    "BAS_2PAR": "23.315,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.315,40",
    "REC_EXTRA2": "139.892,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "220.838,60"
  },
  {
    "EOL": "019401",
    "UNIDADE": "EMEF - JARDIM FONTALIS",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "26.658,00",
    "BAS_2PAR": "26.658,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.658,00",
    "REC_EXTRA2": "159.948,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "250.922,00"
  },
  {
    "EOL": "019402",
    "UNIDADE": "EMEF - ZILKA SALABERRY DE CARVALHO",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "43.010,20",
    "BAS_2PAR": "33.010,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "33.010,20",
    "REC_EXTRA2": "198.061,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "320.091,80"
  },
  {
    "EOL": "019403",
    "UNIDADE": "EMEI - VILA VERDE",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "16.848,00",
    "BAS_2PAR": "16.848,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.848,00",
    "REC_EXTRA2": "101.088,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "191.632,00"
  },
  {
    "EOL": "019404",
    "UNIDADE": "EMEF - NEUZA AVELINO DA SILVA MELO",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "23.034,60",
    "BAS_2PAR": "23.034,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.034,60",
    "REC_EXTRA2": "138.207,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "216.311,40"
  },
  {
    "EOL": "019405",
    "UNIDADE": "EMEF - GILBERTO DUPAS, PROF.",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "22.813,20",
    "BAS_2PAR": "22.813,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.813,20",
    "REC_EXTRA2": "136.879,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "214.318,80"
  },
  {
    "EOL": "019406",
    "UNIDADE": "EMEF - CONSTELACAO DO INDIO",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "22.737,60",
    "BAS_2PAR": "22.737,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.737,60",
    "REC_EXTRA2": "136.425,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "213.638,40"
  },
  {
    "EOL": "019407",
    "UNIDADE": "EMEF - VIRGINIA LORISA ZEITOUNIAN CAMARGO, PROFA",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "23.067,00",
    "BAS_2PAR": "23.067,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.067,00",
    "REC_EXTRA2": "138.402,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "218.603,00"
  },
  {
    "EOL": "019408",
    "UNIDADE": "EMEF - PARQUE BOA ESPERANCA II",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "23.029,20",
    "BAS_2PAR": "23.029,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.029,20",
    "REC_EXTRA2": "138.175,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "216.262,80"
  },
  {
    "EOL": "019409",
    "UNIDADE": "EMEF - IZABEL APARECIDA CRISTOVAO DA LUZ, PROFA.",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "23.007,60",
    "BAS_2PAR": "23.007,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.007,60",
    "REC_EXTRA2": "138.045,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "216.068,40"
  },
  {
    "EOL": "019410",
    "UNIDADE": "EMEF - MURURES",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "23.369,40",
    "BAS_2PAR": "23.369,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.369,40",
    "REC_EXTRA2": "140.216,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "221.324,60"
  },
  {
    "EOL": "019412",
    "UNIDADE": "EMEF - BADRA",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "33.753,20",
    "BAS_2PAR": "28.753,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "28.753,20",
    "REC_EXTRA2": "172.519,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "274.778,80"
  },
  {
    "EOL": "019413",
    "UNIDADE": "EMEF - ELIANE BENUTE LESSA AYRES GONÇALVES, PROFESSORA – NANY BENUTE",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "25.815,60",
    "BAS_2PAR": "25.815,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.815,60",
    "REC_EXTRA2": "154.893,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "243.340,40"
  },
  {
    "EOL": "019414",
    "UNIDADE": "EMEF - MOISES ELIAS DE SOUZA, TTE.",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "25.356,60",
    "BAS_2PAR": "25.356,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.356,60",
    "REC_EXTRA2": "152.139,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "239.209,40"
  },
  {
    "EOL": "019415",
    "UNIDADE": "EMEF - ALICE MEIRELLES REIS, PROFA.",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "25.799,40",
    "BAS_2PAR": "25.799,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.799,40",
    "REC_EXTRA2": "154.796,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "243.194,60"
  },
  {
    "EOL": "019416",
    "UNIDADE": "EMEF - VILA MUNCK",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "28.360,00",
    "BAS_2PAR": "23.360,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.360,00",
    "REC_EXTRA2": "140.162,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "224.242,00"
  },
  {
    "EOL": "019417",
    "UNIDADE": "EMEF - JARDIM PAULO VI",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "20.588,00",
    "BAS_2PAR": "20.588,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.588,00",
    "REC_EXTRA2": "123.530,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "194.294,00"
  },
  {
    "EOL": "019418",
    "UNIDADE": "EMEF - ALEX MARTINS COSTA, PROF., MAESTRO",
    "DRE": "PENHA",
    "BAS_1PAR": "22.710,60",
    "BAS_2PAR": "22.710,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.710,60",
    "REC_EXTRA2": "136.263,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "213.395,40"
  },
  {
    "EOL": "019419",
    "UNIDADE": "EMEF - ANA MARIA ALVES BENETTI, PROFA.",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "22.748,40",
    "BAS_2PAR": "22.748,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.748,40",
    "REC_EXTRA2": "136.490,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "213.735,60"
  },
  {
    "EOL": "019420",
    "UNIDADE": "EMEF - MARIA LUCIA DOS SANTOS, PROFA.",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "22.689,00",
    "BAS_2PAR": "22.689,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.689,00",
    "REC_EXTRA2": "136.134,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "213.201,00"
  },
  {
    "EOL": "019421",
    "UNIDADE": "EMEF - JOSE FRANCISCO CAVALCANTE, PROF.",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "20.329,20",
    "BAS_2PAR": "20.329,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.329,20",
    "REC_EXTRA2": "121.975,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "191.962,80"
  },
  {
    "EOL": "019422",
    "UNIDADE": "EMEF - M'BOI MIRIM I",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "20.637,00",
    "BAS_2PAR": "20.637,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.637,00",
    "REC_EXTRA2": "123.822,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "194.733,00"
  },
  {
    "EOL": "019423",
    "UNIDADE": "EMEF - M'BOI MIRIM II",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "23.002,20",
    "BAS_2PAR": "23.002,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.002,20",
    "REC_EXTRA2": "138.013,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "216.019,80"
  },
  {
    "EOL": "019424",
    "UNIDADE": "EMEF - MARLI FERRAZ TORRES BONFIM",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "22.710,60",
    "BAS_2PAR": "22.710,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.710,60",
    "REC_EXTRA2": "136.263,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "213.395,40"
  },
  {
    "EOL": "019425",
    "UNIDADE": "EMEF - MARTIN LUTHER KING JR",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "20.323,80",
    "BAS_2PAR": "20.323,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.323,80",
    "REC_EXTRA2": "121.942,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "191.914,20"
  },
  {
    "EOL": "019426",
    "UNIDADE": "EMEF - MARISA MORETTI CAMARA, PROFA.",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "23.018,40",
    "BAS_2PAR": "23.018,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.018,40",
    "REC_EXTRA2": "138.110,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "216.165,60"
  },
  {
    "EOL": "019427",
    "UNIDADE": "EMEF - HERCILIA DE CAMPOS COSTA",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "20.728,50",
    "BAS_2PAR": "20.728,50",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.728,50",
    "REC_EXTRA2": "124.372,50",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "195.558,00"
  },
  {
    "EOL": "019428",
    "UNIDADE": "EMEF - SOCRATES BRASILEIRO SAMPAIO DE SOUSA VIEIRA DE OLIVEIRA",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "23.358,60",
    "BAS_2PAR": "23.358,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.358,60",
    "REC_EXTRA2": "140.151,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "221.227,40"
  },
  {
    "EOL": "019429",
    "UNIDADE": "EMEI - ALDO GIANNINI, ENG.",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "14.781,60",
    "BAS_2PAR": "14.781,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.781,60",
    "REC_EXTRA2": "88.689,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "169.034,40"
  },
  {
    "EOL": "019430",
    "UNIDADE": "EMEF - CAMPO LIMPO III",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "23.266,80",
    "BAS_2PAR": "23.266,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.266,80",
    "REC_EXTRA2": "139.600,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "220.401,20"
  },
  {
    "EOL": "019431",
    "UNIDADE": "EMEF - MILLOR FERNANDES, JORNALISTA",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "33.693,80",
    "BAS_2PAR": "28.693,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "28.693,80",
    "REC_EXTRA2": "172.162,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "274.244,20"
  },
  {
    "EOL": "019432",
    "UNIDADE": "CEMEI - PARQUE DO LAGO",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "18.514,80",
    "BAS_2PAR": "18.514,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.514,80",
    "REC_EXTRA2": "111.088,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "210.633,20"
  },
  {
    "EOL": "019433",
    "UNIDADE": "EMEF - JOSEFA NICACIO ARAUJO, PROFA.",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "23.482,80",
    "BAS_2PAR": "23.482,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.482,80",
    "REC_EXTRA2": "140.896,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "222.345,20"
  },
  {
    "EOL": "019434",
    "UNIDADE": "EMEF - CAMPO LIMPO II",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "20.869,20",
    "BAS_2PAR": "20.869,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.869,20",
    "REC_EXTRA2": "125.215,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "196.822,80"
  },
  {
    "EOL": "019436",
    "UNIDADE": "EMEF - CARLOS FRANCISCO GASPAR",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "31.406,00",
    "BAS_2PAR": "26.406,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.406,00",
    "REC_EXTRA2": "158.436,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "251.654,00"
  },
  {
    "EOL": "019437",
    "UNIDADE": "EMEF - DIAS GOMES",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "25.934,40",
    "BAS_2PAR": "25.934,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.934,40",
    "REC_EXTRA2": "155.606,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "244.409,60"
  },
  {
    "EOL": "019439",
    "UNIDADE": "EMEF - ALDINA ANALIA AGOSTINHA TADDEO CONDE, PROFA.",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "20.707,20",
    "BAS_2PAR": "20.707,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.707,20",
    "REC_EXTRA2": "124.243,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "195.364,80"
  },
  {
    "EOL": "019440",
    "UNIDADE": "EMEF - GENY MARIA MUNIZ ALMEIDA KLEIN PUSSINELLI, PROFA.",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "20.658,60",
    "BAS_2PAR": "20.658,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.658,60",
    "REC_EXTRA2": "123.951,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "194.927,40"
  },
  {
    "EOL": "019441",
    "UNIDADE": "EMEI CEU - PARELHEIROS",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "18.709,20",
    "BAS_2PAR": "18.709,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.709,20",
    "REC_EXTRA2": "112.255,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "212.382,80"
  },
  {
    "EOL": "019443",
    "UNIDADE": "EMEF - CONJ. HABITACIONAL BARRO BRANCO II - C",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "26.172,00",
    "BAS_2PAR": "26.172,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.172,00",
    "REC_EXTRA2": "157.032,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "246.548,00"
  },
  {
    "EOL": "019444",
    "UNIDADE": "EMEI - VILA EMA",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "13.201,20",
    "BAS_2PAR": "13.201,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.201,20",
    "REC_EXTRA2": "79.207,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "150.810,80"
  },
  {
    "EOL": "019445",
    "UNIDADE": "EMEF - ROGE FERREIRA, DEP.",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "23.369,40",
    "BAS_2PAR": "23.369,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.369,40",
    "REC_EXTRA2": "140.216,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "221.324,60"
  },
  {
    "EOL": "019446",
    "UNIDADE": "EMEI - RECANTO DOS HUMILDES I",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "14.904,00",
    "BAS_2PAR": "14.904,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.904,00",
    "REC_EXTRA2": "89.424,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "170.136,00"
  },
  {
    "EOL": "019447",
    "UNIDADE": "EMEF - JOEL FERNANDES DE SOUZA",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "32.716,00",
    "BAS_2PAR": "22.716,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.716,00",
    "REC_EXTRA2": "136.296,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "223.444,00"
  },
  {
    "EOL": "019448",
    "UNIDADE": "EMEI - JARDIM NOVO PARELHEIROS I",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "16.686,00",
    "BAS_2PAR": "16.686,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.686,00",
    "REC_EXTRA2": "100.116,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "190.174,00"
  },
  {
    "EOL": "019449",
    "UNIDADE": "EMEF - JURANDI GOMES DE ARAUJO, PROF.",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "23.326,20",
    "BAS_2PAR": "23.326,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.326,20",
    "REC_EXTRA2": "139.957,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "220.935,80"
  },
  {
    "EOL": "019450",
    "UNIDADE": "EMEI - JARDIM PREMIANO",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "16.596,00",
    "BAS_2PAR": "16.596,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.596,00",
    "REC_EXTRA2": "99.576,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "189.364,00"
  },
  {
    "EOL": "019451",
    "UNIDADE": "EMEI - PARQUE COCAIA II",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "18.460,80",
    "BAS_2PAR": "18.460,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.460,80",
    "REC_EXTRA2": "110.764,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "210.147,20"
  },
  {
    "EOL": "019452",
    "UNIDADE": "EMEI - MARIA THEREZA FUMAGALLI, PROFA.",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "18.550,80",
    "BAS_2PAR": "18.550,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.550,80",
    "REC_EXTRA2": "111.304,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "210.957,20"
  },
  {
    "EOL": "019453",
    "UNIDADE": "EMEBS - MARIO PEREIRA BICUDO, PROF.",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "18.388,80",
    "BAS_2PAR": "18.388,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.388,80",
    "REC_EXTRA2": "110.332,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "2.000,00",
    "TOTAL": "187.499,20"
  },
  {
    "EOL": "019454",
    "UNIDADE": "EMEF - BARTOLOMEU CAMPOS DE QUEIROS",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "20.588,40",
    "BAS_2PAR": "20.588,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.588,40",
    "REC_EXTRA2": "123.530,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "194.295,60"
  },
  {
    "EOL": "019455",
    "UNIDADE": "EMEF - CELIA REGINA ANDERY BRAGA, PROFA.",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "22.683,60",
    "BAS_2PAR": "22.683,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.683,60",
    "REC_EXTRA2": "136.101,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "213.152,40"
  },
  {
    "EOL": "019456",
    "UNIDADE": "EMEF - HELINA COUTINHO LOURENÇO ALVES, PROF.",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "22.996,80",
    "BAS_2PAR": "22.996,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.996,80",
    "REC_EXTRA2": "137.980,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "215.971,20"
  },
  {
    "EOL": "019457",
    "UNIDADE": "EMEF - MARIA APARECIDA MAGNANELLI FERNANDES, PROFA.",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "22.586,40",
    "BAS_2PAR": "22.586,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.586,40",
    "REC_EXTRA2": "135.518,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "212.277,60"
  },
  {
    "EOL": "019458",
    "UNIDADE": "EMEF - MARIA APARECIDA RODRIGUES CINTRA, PROFA.",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "25.962,80",
    "BAS_2PAR": "20.962,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.962,80",
    "REC_EXTRA2": "125.776,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "202.665,20"
  },
  {
    "EOL": "019459",
    "UNIDADE": "EMEI - UNIAO DE VILA NOVA I",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "20.250,00",
    "BAS_2PAR": "20.250,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "20.250,00",
    "REC_EXTRA2": "121.500,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "28.000,00",
    "TOTAL": "230.250,00"
  },
  {
    "EOL": "019460",
    "UNIDADE": "EMEF - JOSE CARLOS NICOLETO - ZITO, PROFESSOR",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "23.396,40",
    "BAS_2PAR": "23.396,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.396,40",
    "REC_EXTRA2": "140.378,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "221.567,60"
  },
  {
    "EOL": "019461",
    "UNIDADE": "EMEI - MORRO DOCE",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "14.918,40",
    "BAS_2PAR": "14.918,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.918,40",
    "REC_EXTRA2": "89.510,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "170.265,60"
  },
  {
    "EOL": "019462",
    "UNIDADE": "EMEF - LILIAN MASO, PROFA.",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "25.308,00",
    "BAS_2PAR": "25.308,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.308,00",
    "REC_EXTRA2": "151.848,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "238.772,00"
  },
  {
    "EOL": "019463",
    "UNIDADE": "EMEF - ESTACAO JARAGUA",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "22.640,40",
    "BAS_2PAR": "22.640,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.640,40",
    "REC_EXTRA2": "135.842,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "212.763,60"
  },
  {
    "EOL": "019464",
    "UNIDADE": "EMEF - JARDIM MARILIA I (EXTINTA)",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "",
    "BAS_2PAR": "",
    "REC_EXTRA": "",
    "BAS_3PAR": "",
    "REC_EXTRA2": "",
    "EM": "",
    "SALA_LEITURA": "",
    "MAT_PEDAG": "",
    "TOTAL": ""
  },
  {
    "EOL": "019465",
    "UNIDADE": "EMEF - AMELIA RODRIGUES DE OLIVEIRA, PROFA.",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "26.085,60",
    "BAS_2PAR": "26.085,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.085,60",
    "REC_EXTRA2": "156.513,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "245.770,40"
  },
  {
    "EOL": "019466",
    "UNIDADE": "EMEF - PERIMETRAL",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "20.426,40",
    "BAS_2PAR": "20.426,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.426,40",
    "REC_EXTRA2": "122.558,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "192.837,60"
  },
  {
    "EOL": "019467",
    "UNIDADE": "EMEI - THAIS MOTTA DE OLIVEIRA E SILVA RODRIGUES, PROFA.",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "20.214,00",
    "BAS_2PAR": "20.214,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "20.214,00",
    "REC_EXTRA2": "121.284,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "28.000,00",
    "TOTAL": "229.926,00"
  },
  {
    "EOL": "019468",
    "UNIDADE": "EMEI - LOURO ROSA",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "16.693,20",
    "BAS_2PAR": "16.693,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.693,20",
    "REC_EXTRA2": "100.159,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "190.238,80"
  },
  {
    "EOL": "019469",
    "UNIDADE": "EMEI - JARDIM MONJOLO I",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "14.994,00",
    "BAS_2PAR": "14.994,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.994,00",
    "REC_EXTRA2": "89.964,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "170.946,00"
  },
  {
    "EOL": "019470",
    "UNIDADE": "EMEI - PEDRO ALVARES CABRAL MORAES, PROF.",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "21.718,40",
    "BAS_2PAR": "16.718,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.718,40",
    "REC_EXTRA2": "100.310,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "187.465,60"
  },
  {
    "EOL": "019471",
    "UNIDADE": "EMEI - DORINA NOWILL, PROFESSORA",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "18.388,80",
    "BAS_2PAR": "18.388,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.388,80",
    "REC_EXTRA2": "110.332,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "209.499,20"
  },
  {
    "EOL": "019472",
    "UNIDADE": "EMEI - CIDADE ADEMAR II",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "13.201,20",
    "BAS_2PAR": "13.201,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.201,20",
    "REC_EXTRA2": "79.207,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "150.810,80"
  },
  {
    "EOL": "019473",
    "UNIDADE": "EMEI - VARGEM GRANDE I",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "18.428,40",
    "BAS_2PAR": "18.428,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.428,40",
    "REC_EXTRA2": "110.570,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "209.855,60"
  },
  {
    "EOL": "019474",
    "UNIDADE": "EMEF - VARGEM GRANDE II",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "19.913,40",
    "BAS_2PAR": "19.913,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "19.913,40",
    "REC_EXTRA2": "119.480,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "188.220,60"
  },
  {
    "EOL": "019475",
    "UNIDADE": "EMEI - CIDADE ADEMAR III",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "23.835,60",
    "BAS_2PAR": "23.835,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "23.835,60",
    "REC_EXTRA2": "143.013,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "32.000,00",
    "TOTAL": "266.520,40"
  },
  {
    "EOL": "019476",
    "UNIDADE": "EMEF - MARIA APARECIDA DO NASCIMENTO, PROFA.",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "23.337,00",
    "BAS_2PAR": "23.337,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.337,00",
    "REC_EXTRA2": "140.022,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "221.033,00"
  },
  {
    "EOL": "019477",
    "UNIDADE": "EMEI - ISOLINA LEONEL FERREIRA, PROFA.",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "16.664,40",
    "BAS_2PAR": "16.664,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.664,40",
    "REC_EXTRA2": "99.986,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "189.979,60"
  },
  {
    "EOL": "019478",
    "UNIDADE": "EMEI - MARISA RICCA XIMENES, PROFA.",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "19.673,60",
    "BAS_2PAR": "14.673,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.673,60",
    "REC_EXTRA2": "88.041,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "165.062,40"
  },
  {
    "EOL": "019479",
    "UNIDADE": "EMEI - VILA NATAL",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "18.705,60",
    "BAS_2PAR": "18.705,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.705,60",
    "REC_EXTRA2": "112.233,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "212.350,40"
  },
  {
    "EOL": "019480",
    "UNIDADE": "EMEF - ABRAO HUCK, DR.",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "23.558,40",
    "BAS_2PAR": "23.558,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.558,40",
    "REC_EXTRA2": "141.350,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "223.025,60"
  },
  {
    "EOL": "019481",
    "UNIDADE": "EMEI - ALFAZEMAS",
    "DRE": "PENHA",
    "BAS_1PAR": "15.105,60",
    "BAS_2PAR": "15.105,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "15.105,60",
    "REC_EXTRA2": "90.633,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "171.950,40"
  },
  {
    "EOL": "019482",
    "UNIDADE": "EMEI - PORTO NACIONAL",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "20.458,80",
    "BAS_2PAR": "20.458,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "20.458,80",
    "REC_EXTRA2": "122.752,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "28.000,00",
    "TOTAL": "232.129,20"
  },
  {
    "EOL": "019483",
    "UNIDADE": "CEI - JARDIM SANTA TEREZA",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "11.426,40",
    "BAS_2PAR": "11.426,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.426,40",
    "REC_EXTRA2": "68.558,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.837,60"
  },
  {
    "EOL": "019484",
    "UNIDADE": "EMEI - ESTRADA DO CORREDOR",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "16.686,00",
    "BAS_2PAR": "16.686,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.686,00",
    "REC_EXTRA2": "100.116,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "190.174,00"
  },
  {
    "EOL": "019485",
    "UNIDADE": "EMEI - FULVIA ROSEMBERG, PROFA.",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "14.907,60",
    "BAS_2PAR": "14.907,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.907,60",
    "REC_EXTRA2": "89.445,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "170.168,40"
  },
  {
    "EOL": "019486",
    "UNIDADE": "EMEI - PARQUE DAS NACOES I",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "14.799,60",
    "BAS_2PAR": "14.799,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.799,60",
    "REC_EXTRA2": "88.797,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "169.196,40"
  },
  {
    "EOL": "019487",
    "UNIDADE": "EMEF - CHACARA TURISTICA",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "22.802,40",
    "BAS_2PAR": "22.802,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.802,40",
    "REC_EXTRA2": "136.814,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "214.221,60"
  },
  {
    "EOL": "019488",
    "UNIDADE": "EMEF - PAULO CARNEIRO THOMAZ ALVES, GAL.",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "28.515,60",
    "BAS_2PAR": "28.515,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "28.515,60",
    "REC_EXTRA2": "171.093,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "269.640,40"
  },
  {
    "EOL": "019489",
    "UNIDADE": "EMEI - PARQUE SANTA RITA I",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "18.428,40",
    "BAS_2PAR": "18.428,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.428,40",
    "REC_EXTRA2": "110.570,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "209.855,60"
  },
  {
    "EOL": "019490",
    "UNIDADE": "EMEI - CAMPO LIMPO VI",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "18.705,50",
    "BAS_2PAR": "18.705,50",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.705,50",
    "REC_EXTRA2": "112.233,50",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "212.350,00"
  },
  {
    "EOL": "019492",
    "UNIDADE": "EMEF - EDIVALDO DOS SANTOS DANTAS, PROF.",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "28.758,60",
    "BAS_2PAR": "28.758,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "28.758,60",
    "REC_EXTRA2": "172.551,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "271.827,40"
  },
  {
    "EOL": "019493",
    "UNIDADE": "CEMEI - CAPAO REDONDO",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "23.799,60",
    "BAS_2PAR": "23.799,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "23.799,60",
    "REC_EXTRA2": "142.797,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "32.000,00",
    "TOTAL": "266.196,40"
  },
  {
    "EOL": "019494",
    "UNIDADE": "EMEI - VILA CALU I",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "16.866,00",
    "BAS_2PAR": "16.866,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.866,00",
    "REC_EXTRA2": "101.196,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "191.794,00"
  },
  {
    "EOL": "019495",
    "UNIDADE": "EMEI - PARQUE SANTO ANTONIO I",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "16.783,20",
    "BAS_2PAR": "16.783,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.783,20",
    "REC_EXTRA2": "100.699,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "191.048,80"
  },
  {
    "EOL": "019496",
    "UNIDADE": "EMEF - PLINIO MARCOS",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "22.602,60",
    "BAS_2PAR": "22.602,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.602,60",
    "REC_EXTRA2": "135.615,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "212.423,40"
  },
  {
    "EOL": "019497",
    "UNIDADE": "CEMEI - LAZARA VEIGA CATELLANI",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "23.875,20",
    "BAS_2PAR": "23.875,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "23.875,20",
    "REC_EXTRA2": "143.251,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "32.000,00",
    "TOTAL": "266.876,80"
  },
  {
    "EOL": "019498",
    "UNIDADE": "EMEI - JARDIM KAGOHARA I",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "15.033,60",
    "BAS_2PAR": "15.033,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "15.033,60",
    "REC_EXTRA2": "90.201,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "171.302,40"
  },
  {
    "EOL": "019499",
    "UNIDADE": "EMEI - ROSILDA SILVIO SOUZA, PROFA.",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "16.718,40",
    "BAS_2PAR": "16.718,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.718,40",
    "REC_EXTRA2": "100.310,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "190.465,60"
  },
  {
    "EOL": "019500",
    "UNIDADE": "EMEF - HERBERT DE SOUZA - BETINHO",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "23.239,80",
    "BAS_2PAR": "23.239,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.239,80",
    "REC_EXTRA2": "139.438,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "220.158,20"
  },
  {
    "EOL": "019501",
    "UNIDADE": "EMEI - PARQUE FIGUEIRA GRANDE I",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "16.675,20",
    "BAS_2PAR": "16.675,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.675,20",
    "REC_EXTRA2": "100.051,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "190.076,80"
  },
  {
    "EOL": "019502",
    "UNIDADE": "EMEI - CDHU ITAIM A 1",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "18.396,00",
    "BAS_2PAR": "18.396,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.396,00",
    "REC_EXTRA2": "110.376,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "209.564,00"
  },
  {
    "EOL": "019503",
    "UNIDADE": "EMEF - JARDIM BARTIRA",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "20.869,20",
    "BAS_2PAR": "20.869,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.869,20",
    "REC_EXTRA2": "125.215,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "196.822,80"
  },
  {
    "EOL": "019504",
    "UNIDADE": "EMEI - BEGONIA REAL",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "14.947,20",
    "BAS_2PAR": "14.947,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.947,20",
    "REC_EXTRA2": "89.683,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "170.524,80"
  },
  {
    "EOL": "019505",
    "UNIDADE": "EMEF - JOSE MARIO PIRES AZANHA, PROF.",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "23.358,60",
    "BAS_2PAR": "23.358,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.358,60",
    "REC_EXTRA2": "140.151,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "221.227,40"
  },
  {
    "EOL": "019506",
    "UNIDADE": "EMEF - VILA JACUI",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "22.851,00",
    "BAS_2PAR": "22.851,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.851,00",
    "REC_EXTRA2": "137.106,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "214.659,00"
  },
  {
    "EOL": "019507",
    "UNIDADE": "EMEF - PEDRO FUKUYEI YAMAGUCHI FERREIRA",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "23.158,80",
    "BAS_2PAR": "23.158,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.158,80",
    "REC_EXTRA2": "138.952,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "219.429,20"
  },
  {
    "EOL": "019508",
    "UNIDADE": "EMEI - PAULA CRISTINA RODRIGUES, PROFA.",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "16.563,60",
    "BAS_2PAR": "16.563,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.563,60",
    "REC_EXTRA2": "99.381,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "189.072,40"
  },
  {
    "EOL": "019509",
    "UNIDADE": "EMEF - ACLAMADO",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "27.008,60",
    "BAS_2PAR": "22.008,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.008,60",
    "REC_EXTRA2": "132.051,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "2.000,00",
    "TOTAL": "210.077,40"
  },
  {
    "EOL": "019510",
    "UNIDADE": "EMEI - ROSA MARIA DOGO DE RESENDE, PROFA.",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "16.606,80",
    "BAS_2PAR": "16.606,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.606,80",
    "REC_EXTRA2": "99.640,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "189.461,20"
  },
  {
    "EOL": "019511",
    "UNIDADE": "EMEI - DORACI DOS SANTOS RAMOS, PROFA.",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "14.817,60",
    "BAS_2PAR": "14.817,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.817,60",
    "REC_EXTRA2": "88.905,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "169.358,40"
  },
  {
    "EOL": "019512",
    "UNIDADE": "CEMEI - JARDIM ANGELA",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "20.476,80",
    "BAS_2PAR": "20.476,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "20.476,80",
    "REC_EXTRA2": "122.860,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "28.000,00",
    "TOTAL": "232.291,20"
  },
  {
    "EOL": "019513",
    "UNIDADE": "EMEI - PARQUE BOLOGNE",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "18.424,80",
    "BAS_2PAR": "18.424,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.424,80",
    "REC_EXTRA2": "110.548,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "209.823,20"
  },
  {
    "EOL": "019514",
    "UNIDADE": "EMEI - PIRAJUSSARA",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "15.069,60",
    "BAS_2PAR": "15.069,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "15.069,60",
    "REC_EXTRA2": "90.417,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "171.626,40"
  },
  {
    "EOL": "019515",
    "UNIDADE": "EMEI - PERIMETRAL I",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "18.572,40",
    "BAS_2PAR": "18.572,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.572,40",
    "REC_EXTRA2": "111.434,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "211.151,60"
  },
  {
    "EOL": "019516",
    "UNIDADE": "EMEI - ANDORINHA DOS BERAIS",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "13.320,00",
    "BAS_2PAR": "13.320,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.320,00",
    "REC_EXTRA2": "79.920,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "151.880,00"
  },
  {
    "EOL": "019517",
    "UNIDADE": "EMEI - CHACARA SANTA MARIA",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "20.350,80",
    "BAS_2PAR": "20.350,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "20.350,80",
    "REC_EXTRA2": "122.104,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "28.000,00",
    "TOTAL": "231.157,20"
  },
  {
    "EOL": "019518",
    "UNIDADE": "EMEI - CECILIA SANT ANNA DE SOUZA, PROFA.",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "20.246,40",
    "BAS_2PAR": "20.246,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "20.246,40",
    "REC_EXTRA2": "121.478,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "28.000,00",
    "TOTAL": "230.217,60"
  },
  {
    "EOL": "019519",
    "UNIDADE": "EMEI - JORGE ADILSON CANDIDO, PROF.",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "15.073,00",
    "BAS_2PAR": "15.073,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "15.073,00",
    "REC_EXTRA2": "90.439,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "171.658,00"
  },
  {
    "EOL": "019520",
    "UNIDADE": "CEMEI - LEILA GALLACCI METZKER, PROFA.",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "20.268,00",
    "BAS_2PAR": "20.268,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "20.268,00",
    "REC_EXTRA2": "121.608,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "28.000,00",
    "TOTAL": "230.412,00"
  },
  {
    "EOL": "019521",
    "UNIDADE": "EMEI - JARDIM GAIVOTAS",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "16.880,40",
    "BAS_2PAR": "16.880,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.880,40",
    "REC_EXTRA2": "101.282,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "191.923,60"
  },
  {
    "EOL": "019522",
    "UNIDADE": "EMEI - JARDIM MYRNA",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "24.174,00",
    "BAS_2PAR": "24.174,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "24.174,00",
    "REC_EXTRA2": "145.044,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "36.000,00",
    "TOTAL": "273.566,00"
  },
  {
    "EOL": "019523",
    "UNIDADE": "EMEI - PEDREIRA I",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "24.138,00",
    "BAS_2PAR": "24.138,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "24.138,00",
    "REC_EXTRA2": "144.828,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "36.000,00",
    "TOTAL": "273.242,00"
  },
  {
    "EOL": "019524",
    "UNIDADE": "EMEI - LAJEADO I",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "16.624,80",
    "BAS_2PAR": "16.624,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.624,80",
    "REC_EXTRA2": "99.748,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "189.623,20"
  },
  {
    "EOL": "019525",
    "UNIDADE": "EMEI - JARDIM MARIA LUIZA",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "24.217,20",
    "BAS_2PAR": "24.217,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "24.217,20",
    "REC_EXTRA2": "145.303,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "36.000,00",
    "TOTAL": "273.954,80"
  },
  {
    "EOL": "019526",
    "UNIDADE": "EMEF - DONATO SUSUMU KIMURA",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "23.963,00",
    "BAS_2PAR": "18.963,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "18.963,00",
    "REC_EXTRA2": "113.778,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "2.000,00",
    "TOTAL": "182.667,00"
  },
  {
    "EOL": "019527",
    "UNIDADE": "EMEI - JARDIM AMERICANOPOLIS",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "23.824,80",
    "BAS_2PAR": "23.824,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "23.824,80",
    "REC_EXTRA2": "142.948,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "32.000,00",
    "TOTAL": "266.423,20"
  },
  {
    "EOL": "019528",
    "UNIDADE": "CEMEI - VILA DO SOL I",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "23.950,80",
    "BAS_2PAR": "23.950,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "23.950,80",
    "REC_EXTRA2": "143.704,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "32.000,00",
    "TOTAL": "267.557,20"
  },
  {
    "EOL": "019529",
    "UNIDADE": "EMEF - JARDIM SILVA TELES",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "27.127,40",
    "BAS_2PAR": "22.127,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.127,40",
    "REC_EXTRA2": "132.764,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "2.000,00",
    "TOTAL": "211.146,60"
  },
  {
    "EOL": "019530",
    "UNIDADE": "EMEI - VILA SANTA CATARINA",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "16.804,80",
    "BAS_2PAR": "16.804,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.804,80",
    "REC_EXTRA2": "100.828,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "191.243,20"
  },
  {
    "EOL": "019531",
    "UNIDADE": "CEMEI - CASA BLANCA I",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "13.100,40",
    "BAS_2PAR": "13.100,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.100,40",
    "REC_EXTRA2": "78.602,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "149.903,60"
  },
  {
    "EOL": "019532",
    "UNIDADE": "CEMEI - IRAPARA",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "13.230,00",
    "BAS_2PAR": "13.230,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.230,00",
    "REC_EXTRA2": "79.380,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "151.070,00"
  },
  {
    "EOL": "019533",
    "UNIDADE": "EMEI - CAMINHO DOS MARTINS",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "11.386,80",
    "BAS_2PAR": "11.386,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.386,80",
    "REC_EXTRA2": "68.320,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.481,20"
  },
  {
    "EOL": "019534",
    "UNIDADE": "EMEI - JOAQUIM ANTONIO DA ROCHA",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "19.601,60",
    "BAS_2PAR": "14.601,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.601,60",
    "REC_EXTRA2": "87.609,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "164.414,40"
  },
  {
    "EOL": "019535",
    "UNIDADE": "EMEI - JARDIM SAO BERNARDO",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "18.435,60",
    "BAS_2PAR": "18.435,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.435,60",
    "REC_EXTRA2": "110.613,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "209.920,40"
  },
  {
    "EOL": "019536",
    "UNIDADE": "EMEI - CANAL DO COCAIA",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "20.376,00",
    "BAS_2PAR": "20.376,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "20.376,00",
    "REC_EXTRA2": "122.256,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "28.000,00",
    "TOTAL": "231.384,00"
  },
  {
    "EOL": "019542",
    "UNIDADE": "EMEI - ANA MARCHIONE SALLES, PROFA.",
    "DRE": "PENHA",
    "BAS_1PAR": "18.460,80",
    "BAS_2PAR": "18.460,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.460,80",
    "REC_EXTRA2": "110.764,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "210.147,20"
  },
  {
    "EOL": "019551",
    "UNIDADE": "EMEF CEU - CESAR ARRUDA CASTANHO, DEP.",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "40.673,50",
    "BAS_2PAR": "30.673,50",
    "REC_EXTRA": "",
    "BAS_3PAR": "30.673,50",
    "REC_EXTRA2": "184.042,50",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "297.063,00"
  },
  {
    "EOL": "019569",
    "UNIDADE": "EMEF - ROBERTO PLINIO COLACIOPPO, PROF.",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "22.780,80",
    "BAS_2PAR": "22.780,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.780,80",
    "REC_EXTRA2": "136.684,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "214.027,20"
  },
  {
    "EOL": "019577",
    "UNIDADE": "EMEI - PARADA DE TAIPAS",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "18.522,00",
    "BAS_2PAR": "18.522,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.522,00",
    "REC_EXTRA2": "111.132,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "210.698,00"
  },
  {
    "EOL": "019585",
    "UNIDADE": "EMEF - WLADIMIR DE TOLEDO PIZA, PREF.",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "33.429,20",
    "BAS_2PAR": "28.429,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "28.429,20",
    "REC_EXTRA2": "170.575,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "269.862,80"
  },
  {
    "EOL": "019593",
    "UNIDADE": "EMEI - IGUATEMI",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "20.264,40",
    "BAS_2PAR": "20.264,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "20.264,40",
    "REC_EXTRA2": "121.586,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "28.000,00",
    "TOTAL": "230.379,60"
  },
  {
    "EOL": "019607",
    "UNIDADE": "EMEF - ANTONIO RODRIGUES DE CAMPOS, PROF.",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "20.685,60",
    "BAS_2PAR": "20.685,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.685,60",
    "REC_EXTRA2": "124.113,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "195.170,40"
  },
  {
    "EOL": "019611",
    "UNIDADE": "EMEI - JARDIM IDEAL",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "",
    "BAS_2PAR": "",
    "REC_EXTRA": "",
    "BAS_3PAR": "11.484,00",
    "REC_EXTRA2": "68.904,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "93.388,00"
  },
  {
    "EOL": "019615",
    "UNIDADE": "EMEF - DIRCE GENESIO DOS SANTOS, PROFA.",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "26.172,00",
    "BAS_2PAR": "26.172,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.172,00",
    "REC_EXTRA2": "157.032,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "246.548,00"
  },
  {
    "EOL": "019623",
    "UNIDADE": "EMEI - RUMI OIKAWA, PROFA.",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "15.087,60",
    "BAS_2PAR": "15.087,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "15.087,60",
    "REC_EXTRA2": "90.525,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "171.788,40"
  },
  {
    "EOL": "019631",
    "UNIDADE": "EMEF - NILCE CRUZ FIGUEIREDO, PROFA.",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "32.493,20",
    "BAS_2PAR": "27.493,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "27.493,20",
    "REC_EXTRA2": "164.959,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "261.438,80"
  },
  {
    "EOL": "019640",
    "UNIDADE": "EMEF CEU - MARIA LISBOA DA SILVA, PROFA.",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "37.232,20",
    "BAS_2PAR": "27.232,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "27.232,20",
    "REC_EXTRA2": "163.393,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "266.089,80"
  },
  {
    "EOL": "019641",
    "UNIDADE": "CEMEI - JARDIM KIOTO",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "18.716,40",
    "BAS_2PAR": "18.716,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.716,40",
    "REC_EXTRA2": "112.298,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "212.447,60"
  },
  {
    "EOL": "019642",
    "UNIDADE": "EMEI - PARQUE SAVOY CITY",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "16.700,40",
    "BAS_2PAR": "16.700,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.700,40",
    "REC_EXTRA2": "100.202,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "190.303,60"
  },
  {
    "EOL": "019643",
    "UNIDADE": "CEMEI - ANDAGUAÇU",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "13.100,40",
    "BAS_2PAR": "13.100,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.100,40",
    "REC_EXTRA2": "78.602,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "149.903,60"
  },
  {
    "EOL": "019644",
    "UNIDADE": "CEMEI - JARDIM NORONHA",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "14.965,20",
    "BAS_2PAR": "14.965,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.965,20",
    "REC_EXTRA2": "89.791,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "170.686,80"
  },
  {
    "EOL": "019645",
    "UNIDADE": "CEMEI - JARDIM DOM JOSE I",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "13.237,20",
    "BAS_2PAR": "13.237,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.237,20",
    "REC_EXTRA2": "79.423,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "151.134,80"
  },
  {
    "EOL": "019646",
    "UNIDADE": "EMEI - JARDIM LUCELIA",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "20.174,40",
    "BAS_2PAR": "20.174,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "20.174,40",
    "REC_EXTRA2": "121.046,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "28.000,00",
    "TOTAL": "229.569,60"
  },
  {
    "EOL": "019647",
    "UNIDADE": "EMEI - JARDIM CASA GRANDE",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "20.386,80",
    "BAS_2PAR": "20.386,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "20.386,80",
    "REC_EXTRA2": "122.320,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "28.000,00",
    "TOTAL": "231.481,20"
  },
  {
    "EOL": "019648",
    "UNIDADE": "CEMEI - CAMELO CALI",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "14.806,80",
    "BAS_2PAR": "14.806,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.806,80",
    "REC_EXTRA2": "88.840,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "169.261,20"
  },
  {
    "EOL": "019649",
    "UNIDADE": "CEMEI - JARDIM DAS PALMAS",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "13.147,20",
    "BAS_2PAR": "13.147,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.147,20",
    "REC_EXTRA2": "78.883,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "150.324,80"
  },
  {
    "EOL": "019650",
    "UNIDADE": "EMEI - MARECHAL TITO",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "16.815,60",
    "BAS_2PAR": "16.815,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.815,60",
    "REC_EXTRA2": "100.893,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "191.340,40"
  },
  {
    "EOL": "019651",
    "UNIDADE": "EMEI - PARQUE BRASIL",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "16.894,80",
    "BAS_2PAR": "16.894,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.894,80",
    "REC_EXTRA2": "101.368,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "192.053,20"
  },
  {
    "EOL": "019652",
    "UNIDADE": "EMEI - JARDIM APURA",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "14.936,40",
    "BAS_2PAR": "14.936,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.936,40",
    "REC_EXTRA2": "89.618,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "170.427,60"
  },
  {
    "EOL": "019653",
    "UNIDADE": "CEMEI - MORUMBI",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "15.109,20",
    "BAS_2PAR": "15.109,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "15.109,20",
    "REC_EXTRA2": "90.655,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "171.982,80"
  },
  {
    "EOL": "019656",
    "UNIDADE": "CEMEI - PACHECO GATO",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "",
    "BAS_2PAR": "",
    "REC_EXTRA": "",
    "BAS_3PAR": "13.050,00",
    "REC_EXTRA2": "78.300,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "108.350,00"
  },
  {
    "EOL": "019658",
    "UNIDADE": "EMEI - FERNANDO CAMARGO SOARES, PROF.",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "19.598,00",
    "BAS_2PAR": "14.598,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.598,00",
    "REC_EXTRA2": "87.588,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "164.382,00"
  },
  {
    "EOL": "019659",
    "UNIDADE": "CEMEI CEU - VILA ALPINA",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "",
    "BAS_2PAR": "",
    "REC_EXTRA": "",
    "BAS_3PAR": "13.010,40",
    "REC_EXTRA2": "78.062,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "108.072,80"
  },
  {
    "EOL": "019666",
    "UNIDADE": "EMEI - DINAH GALVAO, PROFA.",
    "DRE": "PENHA",
    "BAS_1PAR": "16.610,40",
    "BAS_2PAR": "16.610,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.610,40",
    "REC_EXTRA2": "99.662,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "189.493,60"
  },
  {
    "EOL": "019674",
    "UNIDADE": "EMEF CEU - JARDIM ELIANA",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "39.478,60",
    "BAS_2PAR": "29.478,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "29.478,60",
    "REC_EXTRA2": "176.871,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "286.307,40"
  },
  {
    "EOL": "019682",
    "UNIDADE": "EMEI - LUIZ GONZAGA DO NASCIMENTO - GONZAGAO",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "16.765,20",
    "BAS_2PAR": "16.765,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.765,20",
    "REC_EXTRA2": "100.591,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "190.886,80"
  },
  {
    "EOL": "019691",
    "UNIDADE": "EMEF - MARIO COVAS, GOV.",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "26.010,00",
    "BAS_2PAR": "26.010,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.010,00",
    "REC_EXTRA2": "156.060,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "245.090,00"
  },
  {
    "EOL": "019704",
    "UNIDADE": "EMEF - PRIMO PASCOLI MELARE, PROF.",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "26.209,80",
    "BAS_2PAR": "26.209,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.209,80",
    "REC_EXTRA2": "157.258,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "246.888,20"
  },
  {
    "EOL": "019712",
    "UNIDADE": "EMEF - MARIA BERENICE DOS SANTOS, PROFA.",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "17.596,80",
    "BAS_2PAR": "17.596,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "17.596,80",
    "REC_EXTRA2": "105.580,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "2.000,00",
    "TOTAL": "165.371,20"
  },
  {
    "EOL": "087319",
    "UNIDADE": "EMEF - THEODOMIRO MONTEIRO DO AMARAL, PROF.",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "25.216,20",
    "BAS_2PAR": "25.216,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.216,20",
    "REC_EXTRA2": "151.297,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "237.945,80"
  },
  {
    "EOL": "090018",
    "UNIDADE": "EMEI - MONTE CASTELO",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "23.932,00",
    "BAS_2PAR": "13.932,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.932,00",
    "REC_EXTRA2": "83.592,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "163.388,00"
  },
  {
    "EOL": "090026",
    "UNIDADE": "EMEI - EMIR MACEDO NOGUEIRA",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "13.222,50",
    "BAS_2PAR": "13.222,50",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.222,50",
    "REC_EXTRA2": "79.336,50",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "151.004,00"
  },
  {
    "EOL": "090034",
    "UNIDADE": "EMEI - ANTONIO BENTO",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "19.558,00",
    "BAS_2PAR": "14.558,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.558,00",
    "REC_EXTRA2": "87.350,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "164.024,00"
  },
  {
    "EOL": "090042",
    "UNIDADE": "EMEI - DALMO DO VALLE NOGUEIRA, DES.",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "14.868,00",
    "BAS_2PAR": "14.868,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.868,00",
    "REC_EXTRA2": "89.208,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "169.812,00"
  },
  {
    "EOL": "090051",
    "UNIDADE": "EMEI - JOAO NEGRAO, CEL.",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "11.419,00",
    "BAS_2PAR": "11.419,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.419,00",
    "REC_EXTRA2": "68.515,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.772,00"
  },
  {
    "EOL": "090069",
    "UNIDADE": "EMEI - ALUISIO DE ALMEIDA",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "13.032,00",
    "BAS_2PAR": "13.032,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.032,00",
    "REC_EXTRA2": "78.192,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "149.288,00"
  },
  {
    "EOL": "090077",
    "UNIDADE": "EMEI - CAROLINA RIBEIRO, PROFA.",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "14.828,00",
    "BAS_2PAR": "14.828,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.828,00",
    "REC_EXTRA2": "88.970,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "169.454,00"
  },
  {
    "EOL": "090085",
    "UNIDADE": "EMEI - NIDA MALDI CORAZZA, EDUCADORA",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "13.114,50",
    "BAS_2PAR": "13.114,50",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.114,50",
    "REC_EXTRA2": "78.688,50",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "150.032,00"
  },
  {
    "EOL": "090093",
    "UNIDADE": "EMEI - RONALDO PORTO MACEDO, PROF.",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "13.280,00",
    "BAS_2PAR": "13.280,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.280,00",
    "REC_EXTRA2": "79.682,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "151.522,00"
  },
  {
    "EOL": "090107",
    "UNIDADE": "EMEI - GILBERTO CHAVES, DEP.",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "14.832,00",
    "BAS_2PAR": "14.832,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.832,00",
    "REC_EXTRA2": "88.992,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "169.488,00"
  },
  {
    "EOL": "090115",
    "UNIDADE": "EMEI - CAROLINA MARIA DE JESUS",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "13.266,00",
    "BAS_2PAR": "13.266,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.266,00",
    "REC_EXTRA2": "79.596,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "151.394,00"
  },
  {
    "EOL": "090123",
    "UNIDADE": "EMEI - FERNANDO PESSOA",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "13.291,00",
    "BAS_2PAR": "13.291,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.291,00",
    "REC_EXTRA2": "79.747,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "151.620,00"
  },
  {
    "EOL": "090131",
    "UNIDADE": "EMEI - FRANCISCA JULIA DA SILVA",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "18.644,40",
    "BAS_2PAR": "18.644,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.644,40",
    "REC_EXTRA2": "111.866,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "211.799,60"
  },
  {
    "EOL": "090140",
    "UNIDADE": "EMEI - ASTROGILDA DE ABREU SEVILHA, PROFA.",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "15.112,80",
    "BAS_2PAR": "15.112,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "15.112,80",
    "REC_EXTRA2": "90.676,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "172.015,20"
  },
  {
    "EOL": "090158",
    "UNIDADE": "EMEI - ANISIO TEIXEIRA",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "16.617,60",
    "BAS_2PAR": "16.617,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.617,60",
    "REC_EXTRA2": "99.705,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "189.558,40"
  },
  {
    "EOL": "090166",
    "UNIDADE": "EMEI - LUIZ DA CAMARA CASCUDO",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "18.529,20",
    "BAS_2PAR": "18.529,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.529,20",
    "REC_EXTRA2": "111.175,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "210.762,80"
  },
  {
    "EOL": "090174",
    "UNIDADE": "EMEI - MARIO ARY PIRES, MAL.",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "18.428,40",
    "BAS_2PAR": "18.428,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.428,40",
    "REC_EXTRA2": "110.570,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "209.855,60"
  },
  {
    "EOL": "090182",
    "UNIDADE": "EMEI - MARIO SETTE",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "18.622,80",
    "BAS_2PAR": "18.622,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.622,80",
    "REC_EXTRA2": "111.736,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "211.605,20"
  },
  {
    "EOL": "090191",
    "UNIDADE": "EMEI - JOAQUIM MANUEL DE MACEDO",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "18.536,40",
    "BAS_2PAR": "18.536,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.536,40",
    "REC_EXTRA2": "111.218,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "210.827,60"
  },
  {
    "EOL": "090204",
    "UNIDADE": "EMEI - CATULO DA PAIXAO CEARENSE",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "16.606,80",
    "BAS_2PAR": "16.606,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.606,80",
    "REC_EXTRA2": "99.640,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "189.461,20"
  },
  {
    "EOL": "090212",
    "UNIDADE": "EMEI - ASSIS CHATEAUBRIAND",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "20.412,00",
    "BAS_2PAR": "20.412,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "20.412,00",
    "REC_EXTRA2": "122.472,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "28.000,00",
    "TOTAL": "231.708,00"
  },
  {
    "EOL": "090221",
    "UNIDADE": "EMEI - ALAIDE BUENO RODRIGUES, PROFA.",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "14.835,60",
    "BAS_2PAR": "14.835,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.835,60",
    "REC_EXTRA2": "89.013,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "169.520,40"
  },
  {
    "EOL": "090239",
    "UNIDADE": "EMEI - BARBARA HELIODORA GUILHERMINA DA SILVEIRA",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "13.176,00",
    "BAS_2PAR": "13.176,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.176,00",
    "REC_EXTRA2": "79.056,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "150.584,00"
  },
  {
    "EOL": "090247",
    "UNIDADE": "EMEI - JULITTA PRADO ALVES DE LIMA, DA.",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "16.750,80",
    "BAS_2PAR": "16.750,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.750,80",
    "REC_EXTRA2": "100.504,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "190.757,20"
  },
  {
    "EOL": "090255",
    "UNIDADE": "EMEI - ROSEMARY SILVA, PROFA.",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "15.008,40",
    "BAS_2PAR": "15.008,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "15.008,40",
    "REC_EXTRA2": "90.050,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "171.075,60"
  },
  {
    "EOL": "090263",
    "UNIDADE": "EMEI - CAMPO LIMPO",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "13.132,80",
    "BAS_2PAR": "13.132,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.132,80",
    "REC_EXTRA2": "78.796,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "150.195,20"
  },
  {
    "EOL": "090271",
    "UNIDADE": "EMEI - CLARICE LISPECTOR",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "14.943,60",
    "BAS_2PAR": "14.943,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.943,60",
    "REC_EXTRA2": "89.661,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "170.492,40"
  },
  {
    "EOL": "090280",
    "UNIDADE": "EMEI - JANETE CLAIR",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "14.918,40",
    "BAS_2PAR": "14.918,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.918,40",
    "REC_EXTRA2": "89.510,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "170.265,60"
  },
  {
    "EOL": "090298",
    "UNIDADE": "EMEI - DOLORES DURAN",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "16.873,20",
    "BAS_2PAR": "16.873,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.873,20",
    "REC_EXTRA2": "101.239,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "191.858,80"
  },
  {
    "EOL": "090301",
    "UNIDADE": "EMEI - DANTE MOREIRA LEITE, PROF.",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "14.803,20",
    "BAS_2PAR": "14.803,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.803,20",
    "REC_EXTRA2": "88.819,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "169.228,80"
  },
  {
    "EOL": "090310",
    "UNIDADE": "EMEI - ANGENOR DE OLIVEIRA - CARTOLA",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "14.907,60",
    "BAS_2PAR": "14.907,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.907,60",
    "REC_EXTRA2": "89.445,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "170.168,40"
  },
  {
    "EOL": "090328",
    "UNIDADE": "EMEI - GUIOMAR PICCINALI, PROFA.",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "16.610,40",
    "BAS_2PAR": "16.610,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.610,40",
    "REC_EXTRA2": "99.662,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "189.493,60"
  },
  {
    "EOL": "090336",
    "UNIDADE": "EMEI - CARLOS DE LAET",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "20.214,00",
    "BAS_2PAR": "20.214,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "20.214,00",
    "REC_EXTRA2": "121.284,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "28.000,00",
    "TOTAL": "229.926,00"
  },
  {
    "EOL": "090344",
    "UNIDADE": "EMEI - JEAN PIAGET",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "18.921,20",
    "BAS_2PAR": "13.921,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.921,20",
    "REC_EXTRA2": "83.527,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "158.290,80"
  },
  {
    "EOL": "090352",
    "UNIDADE": "EMEI - LEOPOLDINA, DA.",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "21.761,60",
    "BAS_2PAR": "16.761,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.761,60",
    "REC_EXTRA2": "100.569,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "187.854,40"
  },
  {
    "EOL": "090361",
    "UNIDADE": "EMEI - SARITA CAMARGO, PROFA.",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "19.500,80",
    "BAS_2PAR": "14.500,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.500,80",
    "REC_EXTRA2": "87.004,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "163.507,20"
  },
  {
    "EOL": "090379",
    "UNIDADE": "EMEI - NEYDE GUZZI DE CHIACCHIO, PROFA.",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "19.616,00",
    "BAS_2PAR": "14.616,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.616,00",
    "REC_EXTRA2": "87.696,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "164.544,00"
  },
  {
    "EOL": "090387",
    "UNIDADE": "EMEI - RICARDO GONCALVES",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "19.493,60",
    "BAS_2PAR": "14.493,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.493,60",
    "REC_EXTRA2": "86.961,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "163.442,40"
  },
  {
    "EOL": "090395",
    "UNIDADE": "EMEI - ANA MARIA POPPOVIC, PROFA.",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "23.334,40",
    "BAS_2PAR": "13.334,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.334,40",
    "REC_EXTRA2": "80.006,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "158.009,60"
  },
  {
    "EOL": "090409",
    "UNIDADE": "EMEI - NOEMIA IPPOLITO",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "21.686,00",
    "BAS_2PAR": "16.686,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.686,00",
    "REC_EXTRA2": "100.116,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "187.174,00"
  },
  {
    "EOL": "090417",
    "UNIDADE": "EMEI - SANTOS DUMONT",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "21.808,40",
    "BAS_2PAR": "16.808,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.808,40",
    "REC_EXTRA2": "100.850,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "188.275,60"
  },
  {
    "EOL": "090425",
    "UNIDADE": "EMEI - MONTEIRO LOBATO",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "13.294,80",
    "BAS_2PAR": "13.294,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.294,80",
    "REC_EXTRA2": "79.768,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "151.653,20"
  },
  {
    "EOL": "090433",
    "UNIDADE": "EMEI - TIDE SETUBAL",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "19.706,00",
    "BAS_2PAR": "14.706,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.706,00",
    "REC_EXTRA2": "88.236,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "165.354,00"
  },
  {
    "EOL": "090441",
    "UNIDADE": "EMEI - PEDROSO DE MORAES",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "11.235,50",
    "BAS_2PAR": "11.235,50",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.235,50",
    "REC_EXTRA2": "67.413,50",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.120,00"
  },
  {
    "EOL": "090450",
    "UNIDADE": "EMEI - ANTONIO BRANCO LEFEVRE, PROF.",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "21.657,00",
    "BAS_2PAR": "16.657,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.657,00",
    "REC_EXTRA2": "99.943,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "186.914,00"
  },
  {
    "EOL": "090468",
    "UNIDADE": "EMEI - ZILDA DE FRANCESCHI, PROFA.",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "18.885,00",
    "BAS_2PAR": "13.885,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.885,00",
    "REC_EXTRA2": "83.311,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "157.966,00"
  },
  {
    "EOL": "090476",
    "UNIDADE": "EMEI - GELOIRA DE CAMPOS",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "13.287,60",
    "BAS_2PAR": "13.287,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.287,60",
    "REC_EXTRA2": "79.725,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "151.588,40"
  },
  {
    "EOL": "090484",
    "UNIDADE": "EMEI - ISABEL COLOMBO, PROFA.",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "19.659,00",
    "BAS_2PAR": "14.659,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.659,00",
    "REC_EXTRA2": "87.955,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "164.932,00"
  },
  {
    "EOL": "090492",
    "UNIDADE": "EMEI - BORBA GATO",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "13.212,00",
    "BAS_2PAR": "13.212,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.212,00",
    "REC_EXTRA2": "79.272,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "150.908,00"
  },
  {
    "EOL": "090506",
    "UNIDADE": "EMEI - ANHANGUERA",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "19.688,00",
    "BAS_2PAR": "14.688,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.688,00",
    "REC_EXTRA2": "88.128,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "165.192,00"
  },
  {
    "EOL": "090514",
    "UNIDADE": "EMEI - FRANCISCO MANUEL DA SILVA",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "13.165,20",
    "BAS_2PAR": "13.165,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.165,20",
    "REC_EXTRA2": "78.991,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "150.486,80"
  },
  {
    "EOL": "090522",
    "UNIDADE": "EMEI - CELSO FERREIRA DA SILVA, PROF.",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "18.504,00",
    "BAS_2PAR": "18.504,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.504,00",
    "REC_EXTRA2": "111.024,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "210.536,00"
  },
  {
    "EOL": "090531",
    "UNIDADE": "EMEI - JOAO DE DEUS BUENO DOS REIS, DR.",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "13.244,40",
    "BAS_2PAR": "13.244,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.244,40",
    "REC_EXTRA2": "79.466,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "151.199,60"
  },
  {
    "EOL": "090549",
    "UNIDADE": "EMEI - VIRIATO CORREIA",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "14.785,20",
    "BAS_2PAR": "14.785,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.785,20",
    "REC_EXTRA2": "88.711,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "169.066,80"
  },
  {
    "EOL": "090557",
    "UNIDADE": "EMEI - CARLOS DRUMMOND DE ANDRADE",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "19.623,20",
    "BAS_2PAR": "14.623,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.623,20",
    "REC_EXTRA2": "87.739,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "164.608,80"
  },
  {
    "EOL": "090565",
    "UNIDADE": "EMEI - CASTRO ALVES",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "16.887,60",
    "BAS_2PAR": "16.887,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.887,60",
    "REC_EXTRA2": "101.325,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "191.988,40"
  },
  {
    "EOL": "090573",
    "UNIDADE": "EMEI - RIO BRANCO, BR. DO",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "18.705,60",
    "BAS_2PAR": "18.705,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.705,60",
    "REC_EXTRA2": "112.233,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "212.350,40"
  },
  {
    "EOL": "090581",
    "UNIDADE": "EMEI - LUIS TRAVASSOS",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "13.302,00",
    "BAS_2PAR": "13.302,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.302,00",
    "REC_EXTRA2": "79.812,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "151.718,00"
  },
  {
    "EOL": "090590",
    "UNIDADE": "EMEI - ARISTIDES NOGUEIRA, DR.",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "14.968,80",
    "BAS_2PAR": "14.968,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.968,80",
    "REC_EXTRA2": "89.812,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "170.719,20"
  },
  {
    "EOL": "090603",
    "UNIDADE": "EMEI - ZUMBI DOS PALMARES",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "18.630,00",
    "BAS_2PAR": "18.630,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.630,00",
    "REC_EXTRA2": "111.780,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "211.670,00"
  },
  {
    "EOL": "090611",
    "UNIDADE": "EMEI - CORA CORALINA",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "14.770,80",
    "BAS_2PAR": "14.770,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.770,80",
    "REC_EXTRA2": "88.624,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "168.937,20"
  },
  {
    "EOL": "090620",
    "UNIDADE": "EMEI - MARIALICE MENCARINI FORACCHI",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "15.102,00",
    "BAS_2PAR": "15.102,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "15.102,00",
    "REC_EXTRA2": "90.612,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "171.918,00"
  },
  {
    "EOL": "090638",
    "UNIDADE": "EMEI - SERGIO CARDOSO",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "14.997,60",
    "BAS_2PAR": "14.997,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.997,60",
    "REC_EXTRA2": "89.985,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "170.978,40"
  },
  {
    "EOL": "090646",
    "UNIDADE": "EMEI - CLARA NUNES",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "13.298,40",
    "BAS_2PAR": "13.298,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.298,40",
    "REC_EXTRA2": "79.790,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "151.685,60"
  },
  {
    "EOL": "090654",
    "UNIDADE": "EMEI - PIRATININGA",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "15.112,80",
    "BAS_2PAR": "15.112,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "15.112,80",
    "REC_EXTRA2": "90.676,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "172.015,20"
  },
  {
    "EOL": "090662",
    "UNIDADE": "EMEI - MARIA MONTESSORI",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "13.154,40",
    "BAS_2PAR": "13.154,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.154,40",
    "REC_EXTRA2": "78.926,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "150.389,60"
  },
  {
    "EOL": "090671",
    "UNIDADE": "EMEI - LEONARDO ARROYO",
    "DRE": "PENHA",
    "BAS_1PAR": "18.201,20",
    "BAS_2PAR": "13.201,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.201,20",
    "REC_EXTRA2": "79.207,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "147.810,80"
  },
  {
    "EOL": "090689",
    "UNIDADE": "EMEI - MARIA VITORIA DA CUNHA, PROFA.",
    "DRE": "PENHA",
    "BAS_1PAR": "11.242,80",
    "BAS_2PAR": "11.242,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.242,80",
    "REC_EXTRA2": "67.456,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.185,20"
  },
  {
    "EOL": "090697",
    "UNIDADE": "EMEI - PEDRO BRASIL BANDECCHI",
    "DRE": "PENHA",
    "BAS_1PAR": "13.158,00",
    "BAS_2PAR": "13.158,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.158,00",
    "REC_EXTRA2": "78.948,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "150.422,00"
  },
  {
    "EOL": "090701",
    "UNIDADE": "EMEI - IBIAPABA MARTINS",
    "DRE": "PENHA",
    "BAS_1PAR": "13.143,60",
    "BAS_2PAR": "13.143,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.143,60",
    "REC_EXTRA2": "78.861,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "150.292,40"
  },
  {
    "EOL": "090719",
    "UNIDADE": "EMEI - LUCIANO ROBERTO, PROF.",
    "DRE": "PENHA",
    "BAS_1PAR": "11.329,20",
    "BAS_2PAR": "11.329,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.329,20",
    "REC_EXTRA2": "67.975,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.962,80"
  },
  {
    "EOL": "090727",
    "UNIDADE": "EMEI - ROBERTO VICTOR CORDEIRO",
    "DRE": "PENHA",
    "BAS_1PAR": "13.078,80",
    "BAS_2PAR": "13.078,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.078,80",
    "REC_EXTRA2": "78.472,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "149.709,20"
  },
  {
    "EOL": "090735",
    "UNIDADE": "EMEI - JOSE DUARTE",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "18.471,60",
    "BAS_2PAR": "18.471,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.471,60",
    "REC_EXTRA2": "110.829,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "210.244,40"
  },
  {
    "EOL": "090743",
    "UNIDADE": "EMEI - DENISE MERCIER RODRIGUES AGUIAR, PROFA.",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "13.172,40",
    "BAS_2PAR": "13.172,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.172,40",
    "REC_EXTRA2": "79.034,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "150.551,60"
  },
  {
    "EOL": "090751",
    "UNIDADE": "EMEI - BUENO DE AZEVEDO, DR.",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "11.516,40",
    "BAS_2PAR": "11.516,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.516,40",
    "REC_EXTRA2": "69.098,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "131.647,60"
  },
  {
    "EOL": "090760",
    "UNIDADE": "EMEI - SYLVIA VARONI DE CASTRO, PROFA.",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "14.770,80",
    "BAS_2PAR": "14.770,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.770,80",
    "REC_EXTRA2": "88.624,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "168.937,20"
  },
  {
    "EOL": "090778",
    "UNIDADE": "EMEI - LEONARDO VAN ACKER, PROF.",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "13.212,00",
    "BAS_2PAR": "13.212,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.212,00",
    "REC_EXTRA2": "79.272,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "150.908,00"
  },
  {
    "EOL": "090786",
    "UNIDADE": "EMEI - FLORIANO PEIXOTO, MAL.",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "11.412,00",
    "BAS_2PAR": "11.412,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.412,00",
    "REC_EXTRA2": "68.472,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.708,00"
  },
  {
    "EOL": "090794",
    "UNIDADE": "EMEI - ANTONIO GONCALVES DIAS",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "16.776,00",
    "BAS_2PAR": "16.776,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.776,00",
    "REC_EXTRA2": "100.656,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "190.984,00"
  },
  {
    "EOL": "090808",
    "UNIDADE": "EMEI - MANOEL DA NOBREGA, PE.",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "16.707,60",
    "BAS_2PAR": "16.707,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.707,60",
    "REC_EXTRA2": "100.245,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "190.368,40"
  },
  {
    "EOL": "090816",
    "UNIDADE": "EMEI - MARIA HELENA BARBOSA MARTINS, PROFA.",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "14.990,40",
    "BAS_2PAR": "14.990,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.990,40",
    "REC_EXTRA2": "89.942,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "170.913,60"
  },
  {
    "EOL": "090824",
    "UNIDADE": "EMEI - RONALD DE CARVALHO",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "13.244,40",
    "BAS_2PAR": "13.244,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.244,40",
    "REC_EXTRA2": "79.466,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "151.199,60"
  },
  {
    "EOL": "090832",
    "UNIDADE": "EMEI - RODRIGUES DE ABREU",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "18.385,20",
    "BAS_2PAR": "18.385,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.385,20",
    "REC_EXTRA2": "110.311,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "209.466,80"
  },
  {
    "EOL": "090841",
    "UNIDADE": "EMEI - GIANFEDERICO PORTA, PROF.",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "14.814,00",
    "BAS_2PAR": "14.814,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.814,00",
    "REC_EXTRA2": "88.884,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "169.326,00"
  },
  {
    "EOL": "090859",
    "UNIDADE": "EMEI - TOMAS GALHARDO",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "15.087,60",
    "BAS_2PAR": "15.087,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "15.087,60",
    "REC_EXTRA2": "90.525,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "171.788,40"
  },
  {
    "EOL": "090867",
    "UNIDADE": "EMEI - PESTALOZZI",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "18.716,40",
    "BAS_2PAR": "18.716,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.716,40",
    "REC_EXTRA2": "112.298,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "212.447,60"
  },
  {
    "EOL": "090875",
    "UNIDADE": "EMEI - FELIPE D'OLIVEIRA",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "16.592,40",
    "BAS_2PAR": "16.592,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.592,40",
    "REC_EXTRA2": "99.554,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "189.331,60"
  },
  {
    "EOL": "090883",
    "UNIDADE": "EMEI - JESUINA NUNES BARBOSA",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "18.511,20",
    "BAS_2PAR": "18.511,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.511,20",
    "REC_EXTRA2": "111.067,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "210.600,80"
  },
  {
    "EOL": "090891",
    "UNIDADE": "EMEI - CECILIA MEIRELLES",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "18.482,40",
    "BAS_2PAR": "18.482,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.482,40",
    "REC_EXTRA2": "110.894,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "210.341,60"
  },
  {
    "EOL": "090905",
    "UNIDADE": "EMEI CEU - JOSE GASPAR, D.",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "23.874,80",
    "BAS_2PAR": "18.874,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.874,80",
    "REC_EXTRA2": "113.248,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "210.873,20"
  },
  {
    "EOL": "090913",
    "UNIDADE": "EMEI - FLORINDA ROBERTO QUEIROZ DE CASTRO",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "13.125,60",
    "BAS_2PAR": "13.125,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.125,60",
    "REC_EXTRA2": "78.753,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "150.130,40"
  },
  {
    "EOL": "090921",
    "UNIDADE": "EMEI - PEDRO CHAVES, MIN.",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "19.720,40",
    "BAS_2PAR": "14.720,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.720,40",
    "REC_EXTRA2": "88.322,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "165.483,60"
  },
  {
    "EOL": "090930",
    "UNIDADE": "EMEI - ERNANI SILVA BRUNO",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "13.172,40",
    "BAS_2PAR": "13.172,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.172,40",
    "REC_EXTRA2": "79.034,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "150.551,60"
  },
  {
    "EOL": "090948",
    "UNIDADE": "EMEI - MIROEL SILVEIRA",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "13.316,40",
    "BAS_2PAR": "13.316,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.316,40",
    "REC_EXTRA2": "79.898,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "151.847,60"
  },
  {
    "EOL": "090956",
    "UNIDADE": "EMEI - CARLOS JEREISSATI, SEN.",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "13.140,00",
    "BAS_2PAR": "13.140,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.140,00",
    "REC_EXTRA2": "78.840,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "150.260,00"
  },
  {
    "EOL": "090964",
    "UNIDADE": "EMEI - APARECIDA MARIA DE MENDONCA, PROFA.",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "12.970,80",
    "BAS_2PAR": "12.970,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "12.970,80",
    "REC_EXTRA2": "77.824,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "148.737,20"
  },
  {
    "EOL": "090972",
    "UNIDADE": "EMEI - MARGARIDA MARIA ALVES",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "15.012,00",
    "BAS_2PAR": "15.012,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "15.012,00",
    "REC_EXTRA2": "90.072,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "171.108,00"
  },
  {
    "EOL": "090981",
    "UNIDADE": "EMEI - CARLOTA PEREIRA DE QUEIROZ",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "15.073,20",
    "BAS_2PAR": "15.073,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "15.073,20",
    "REC_EXTRA2": "90.439,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "171.658,80"
  },
  {
    "EOL": "090999",
    "UNIDADE": "EMEI - ADONIRAN BARBOSA",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "14.868,00",
    "BAS_2PAR": "14.868,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.868,00",
    "REC_EXTRA2": "89.208,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "169.812,00"
  },
  {
    "EOL": "091006",
    "UNIDADE": "EMEI - NILDO DO AMARAL JUNIOR, PE.",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "14.914,80",
    "BAS_2PAR": "14.914,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.914,80",
    "REC_EXTRA2": "89.488,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "170.233,20"
  },
  {
    "EOL": "091014",
    "UNIDADE": "EMEI - AMACIO MAZZAROPI",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "15.048,00",
    "BAS_2PAR": "15.048,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "15.048,00",
    "REC_EXTRA2": "90.288,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "171.432,00"
  },
  {
    "EOL": "091022",
    "UNIDADE": "EMEI - ODUVALDO VIANA FILHO",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "14.882,40",
    "BAS_2PAR": "14.882,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.882,40",
    "REC_EXTRA2": "89.294,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "169.941,60"
  },
  {
    "EOL": "091031",
    "UNIDADE": "EMEI - SAMUEL WAINER",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "15.004,80",
    "BAS_2PAR": "15.004,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "15.004,80",
    "REC_EXTRA2": "90.028,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "171.043,20"
  },
  {
    "EOL": "091057",
    "UNIDADE": "EMEI - LUIZ PEREIRA, PROF.",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "12.981,60",
    "BAS_2PAR": "12.981,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "12.981,60",
    "REC_EXTRA2": "77.889,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "148.834,40"
  },
  {
    "EOL": "091065",
    "UNIDADE": "EMEI - ELIS REGINA",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "15.098,40",
    "BAS_2PAR": "15.098,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "15.098,40",
    "REC_EXTRA2": "90.590,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "171.885,60"
  },
  {
    "EOL": "091073",
    "UNIDADE": "EMEI - AUGUSTO FROEBEL",
    "DRE": "PENHA",
    "BAS_1PAR": "15.033,60",
    "BAS_2PAR": "15.033,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "15.033,60",
    "REC_EXTRA2": "90.201,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "171.302,40"
  },
  {
    "EOL": "091081",
    "UNIDADE": "EMEI - MAX WOLF FILHO, SGT.",
    "DRE": "PENHA",
    "BAS_1PAR": "13.089,60",
    "BAS_2PAR": "13.089,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.089,60",
    "REC_EXTRA2": "78.537,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "149.806,40"
  },
  {
    "EOL": "091090",
    "UNIDADE": "EMEI - MIGUEL COSTA, GEN.",
    "DRE": "PENHA",
    "BAS_1PAR": "14.785,20",
    "BAS_2PAR": "14.785,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.785,20",
    "REC_EXTRA2": "88.711,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "169.066,80"
  },
  {
    "EOL": "091103",
    "UNIDADE": "EMEI - JARDIM PEDRO JOSE NUNES",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "21.700,40",
    "BAS_2PAR": "16.700,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.700,40",
    "REC_EXTRA2": "100.202,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "187.303,60"
  },
  {
    "EOL": "091111",
    "UNIDADE": "EMEI - EPITACIO PESSOA",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "14.932,80",
    "BAS_2PAR": "14.932,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.932,80",
    "REC_EXTRA2": "89.596,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "170.395,20"
  },
  {
    "EOL": "091120",
    "UNIDADE": "EMEI - AFRANIO PEIXOTO",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "20.473,20",
    "BAS_2PAR": "20.473,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "20.473,20",
    "REC_EXTRA2": "122.839,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "28.000,00",
    "TOTAL": "232.258,80"
  },
  {
    "EOL": "091138",
    "UNIDADE": "EMEI - ANTONIO LAPENNA",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "15.012,00",
    "BAS_2PAR": "15.012,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "15.012,00",
    "REC_EXTRA2": "90.072,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "171.108,00"
  },
  {
    "EOL": "091146",
    "UNIDADE": "EMEI - GRACILIANO RAMOS",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "14.781,60",
    "BAS_2PAR": "14.781,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.781,60",
    "REC_EXTRA2": "88.689,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "169.034,40"
  },
  {
    "EOL": "091154",
    "UNIDADE": "EMEI - ANTONIO PEREIRA LIMA",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "18.396,00",
    "BAS_2PAR": "18.396,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.396,00",
    "REC_EXTRA2": "110.376,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "209.564,00"
  },
  {
    "EOL": "091162",
    "UNIDADE": "EMEI - MAGDALENA TAGLIAFERRO",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "16.718,40",
    "BAS_2PAR": "16.718,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.718,40",
    "REC_EXTRA2": "100.310,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "190.465,60"
  },
  {
    "EOL": "091171",
    "UNIDADE": "EMEI - LUIZA HELENA DE BARROS",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "14.907,60",
    "BAS_2PAR": "14.907,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.907,60",
    "REC_EXTRA2": "89.445,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "170.168,40"
  },
  {
    "EOL": "091189",
    "UNIDADE": "EMEI - JOSE DE ALENCAR",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "15.105,60",
    "BAS_2PAR": "15.105,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "15.105,60",
    "REC_EXTRA2": "90.633,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "171.950,40"
  },
  {
    "EOL": "091197",
    "UNIDADE": "EMEI - PEDRO II, D.",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "16.570,80",
    "BAS_2PAR": "16.570,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.570,80",
    "REC_EXTRA2": "99.424,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "189.137,20"
  },
  {
    "EOL": "091201",
    "UNIDADE": "EMEI - MARIA QUITERIA",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "16.624,80",
    "BAS_2PAR": "16.624,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.624,80",
    "REC_EXTRA2": "99.748,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "189.623,20"
  },
  {
    "EOL": "091219",
    "UNIDADE": "EMEI - ALBERTO MENDES JUNIOR, CAP.",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "18.694,80",
    "BAS_2PAR": "18.694,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.694,80",
    "REC_EXTRA2": "112.168,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "212.253,20"
  },
  {
    "EOL": "091227",
    "UNIDADE": "EMEI - OVIDIO DECROLY",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "16.610,40",
    "BAS_2PAR": "16.610,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.610,40",
    "REC_EXTRA2": "99.662,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "189.493,60"
  },
  {
    "EOL": "091235",
    "UNIDADE": "EMEI - HELENA DE PAULA MARIN, PROFA.",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "16.732,80",
    "BAS_2PAR": "16.732,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.732,80",
    "REC_EXTRA2": "100.396,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "190.595,20"
  },
  {
    "EOL": "091243",
    "UNIDADE": "EMEI - LEILA DINIZ",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "21.779,60",
    "BAS_2PAR": "16.779,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.779,60",
    "REC_EXTRA2": "100.677,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "188.016,40"
  },
  {
    "EOL": "091251",
    "UNIDADE": "EMEI - GLAUBER ROCHA",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "14.893,20",
    "BAS_2PAR": "14.893,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.893,20",
    "REC_EXTRA2": "89.359,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "170.038,80"
  },
  {
    "EOL": "091260",
    "UNIDADE": "EMEI - IRENE FAVRET LOPES, PROFA.",
    "DRE": "PENHA",
    "BAS_1PAR": "11.397,60",
    "BAS_2PAR": "11.397,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.397,60",
    "REC_EXTRA2": "68.385,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.578,40"
  },
  {
    "EOL": "091278",
    "UNIDADE": "EMEI - GOMES CARDIM, INTENDENTE",
    "DRE": "PENHA",
    "BAS_1PAR": "18.291,20",
    "BAS_2PAR": "13.291,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.291,20",
    "REC_EXTRA2": "79.747,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "148.620,80"
  },
  {
    "EOL": "091286",
    "UNIDADE": "EMEI - MARIA LAURA DE SOUZA CAMPOS, PROFA.",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "11.502,00",
    "BAS_2PAR": "11.502,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.502,00",
    "REC_EXTRA2": "69.012,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "131.518,00"
  },
  {
    "EOL": "091294",
    "UNIDADE": "EMEI - QUINTINO BOCAIUVA",
    "DRE": "PENHA",
    "BAS_1PAR": "13.050,00",
    "BAS_2PAR": "13.050,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.050,00",
    "REC_EXTRA2": "78.300,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "149.450,00"
  },
  {
    "EOL": "091308",
    "UNIDADE": "EMEI - RAFAEL TOBIAS DE AGUIAR, BRIG.",
    "DRE": "PENHA",
    "BAS_1PAR": "13.082,40",
    "BAS_2PAR": "13.082,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.082,40",
    "REC_EXTRA2": "78.494,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "149.741,60"
  },
  {
    "EOL": "091316",
    "UNIDADE": "EMEI - LUIZ GOMES CARDIM SANGIRARDI, ENG.",
    "DRE": "PENHA",
    "BAS_1PAR": "13.201,20",
    "BAS_2PAR": "13.201,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.201,20",
    "REC_EXTRA2": "79.207,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "150.810,80"
  },
  {
    "EOL": "091324",
    "UNIDADE": "EMEI - EVARISTO DA VEIGA",
    "DRE": "PENHA",
    "BAS_1PAR": "15.022,80",
    "BAS_2PAR": "15.022,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "15.022,80",
    "REC_EXTRA2": "90.136,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "171.205,20"
  },
  {
    "EOL": "091332",
    "UNIDADE": "EMEI - TOMAS ANTONIO GONZAGA",
    "DRE": "PENHA",
    "BAS_1PAR": "14.954,40",
    "BAS_2PAR": "14.954,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.954,40",
    "REC_EXTRA2": "89.726,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "170.589,60"
  },
  {
    "EOL": "091341",
    "UNIDADE": "EMEI - JOSE RUBENS PERES FERNANDES, PROF.",
    "DRE": "PENHA",
    "BAS_1PAR": "16.862,40",
    "BAS_2PAR": "16.862,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.862,40",
    "REC_EXTRA2": "101.174,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "191.761,60"
  },
  {
    "EOL": "091359",
    "UNIDADE": "EMEI - MARIO GRACIOTTI",
    "DRE": "PENHA",
    "BAS_1PAR": "13.032,00",
    "BAS_2PAR": "13.032,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.032,00",
    "REC_EXTRA2": "78.192,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "149.288,00"
  },
  {
    "EOL": "091367",
    "UNIDADE": "EMEI - GOULART, ENG.",
    "DRE": "PENHA",
    "BAS_1PAR": "16.704,00",
    "BAS_2PAR": "16.704,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.704,00",
    "REC_EXTRA2": "100.224,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "190.336,00"
  },
  {
    "EOL": "091375",
    "UNIDADE": "EMEI - SILVIO ROMERO",
    "DRE": "PENHA",
    "BAS_1PAR": "11.217,60",
    "BAS_2PAR": "11.217,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.217,60",
    "REC_EXTRA2": "67.305,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "128.958,40"
  },
  {
    "EOL": "091383",
    "UNIDADE": "EMEI - DUTRA, PRES.",
    "DRE": "PENHA",
    "BAS_1PAR": "19.504,00",
    "BAS_2PAR": "14.504,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.504,00",
    "REC_EXTRA2": "87.026,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "163.538,00"
  },
  {
    "EOL": "091391",
    "UNIDADE": "EMEI - MARY BUARQUE",
    "DRE": "PENHA",
    "BAS_1PAR": "19.572,80",
    "BAS_2PAR": "14.572,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.572,80",
    "REC_EXTRA2": "87.436,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "164.155,20"
  },
  {
    "EOL": "091405",
    "UNIDADE": "EMEI - MARIA LACERDA DE MOURA",
    "DRE": "PENHA",
    "BAS_1PAR": "16.574,40",
    "BAS_2PAR": "16.574,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.574,40",
    "REC_EXTRA2": "99.446,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "189.169,60"
  },
  {
    "EOL": "091413",
    "UNIDADE": "EMEI - RODRIGO SOARES JUNIOR",
    "DRE": "PENHA",
    "BAS_1PAR": "18.522,00",
    "BAS_2PAR": "18.522,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.522,00",
    "REC_EXTRA2": "111.132,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "210.698,00"
  },
  {
    "EOL": "091421",
    "UNIDADE": "EMEI - ALFREDO DA ROCHA VIANA FILHO - PIXINGUINHA",
    "DRE": "PENHA",
    "BAS_1PAR": "15.087,60",
    "BAS_2PAR": "15.087,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "15.087,60",
    "REC_EXTRA2": "90.525,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "171.788,40"
  },
  {
    "EOL": "091430",
    "UNIDADE": "EMEI - MANOEL PRETO",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "16.794,00",
    "BAS_2PAR": "16.794,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.794,00",
    "REC_EXTRA2": "100.764,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "191.146,00"
  },
  {
    "EOL": "091448",
    "UNIDADE": "EMEI - OLAVO BILAC",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "13.208,40",
    "BAS_2PAR": "13.208,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.208,40",
    "REC_EXTRA2": "79.250,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "150.875,60"
  },
  {
    "EOL": "091456",
    "UNIDADE": "EMEI - FELIPE MESTRE JOU",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "18.410,40",
    "BAS_2PAR": "18.410,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.410,40",
    "REC_EXTRA2": "110.462,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "209.693,60"
  },
  {
    "EOL": "091464",
    "UNIDADE": "EMEI - 25 DE JANEIRO",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "16.610,40",
    "BAS_2PAR": "16.610,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.610,40",
    "REC_EXTRA2": "99.662,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "189.493,60"
  },
  {
    "EOL": "091472",
    "UNIDADE": "EMEI - JOSE CANAVO FILHO, CEL.",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "15.076,80",
    "BAS_2PAR": "15.076,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "15.076,80",
    "REC_EXTRA2": "90.460,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "171.691,20"
  },
  {
    "EOL": "091481",
    "UNIDADE": "EMEI - ALEXANDRE GAMA, CEL.",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "13.222,80",
    "BAS_2PAR": "13.222,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.222,80",
    "REC_EXTRA2": "79.336,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "151.005,20"
  },
  {
    "EOL": "091499",
    "UNIDADE": "EMEI - ANITA GARIBALDI",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "16.722,00",
    "BAS_2PAR": "16.722,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.722,00",
    "REC_EXTRA2": "100.332,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "190.498,00"
  },
  {
    "EOL": "091502",
    "UNIDADE": "EMEI - MARTINS FONTES",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "15.040,80",
    "BAS_2PAR": "15.040,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "15.040,80",
    "REC_EXTRA2": "90.244,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "171.367,20"
  },
  {
    "EOL": "091511",
    "UNIDADE": "EMEI - LUCAS NOGUEIRA GARCEZ, GOV.",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "16.606,80",
    "BAS_2PAR": "16.606,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.606,80",
    "REC_EXTRA2": "99.640,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "189.461,20"
  },
  {
    "EOL": "091529",
    "UNIDADE": "EMEI - BILAC PINTO, MIN.",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "16.578,00",
    "BAS_2PAR": "16.578,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.578,00",
    "REC_EXTRA2": "99.468,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "189.202,00"
  },
  {
    "EOL": "091537",
    "UNIDADE": "EMEI - ROSA E CAROLINA AGAZZI",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "16.736,40",
    "BAS_2PAR": "16.736,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.736,40",
    "REC_EXTRA2": "100.418,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "190.627,60"
  },
  {
    "EOL": "091545",
    "UNIDADE": "EMEI - JULIO DE MESQUITA FILHO",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "18.558,00",
    "BAS_2PAR": "18.558,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.558,00",
    "REC_EXTRA2": "111.348,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "211.022,00"
  },
  {
    "EOL": "091553",
    "UNIDADE": "EMEI - NELSON MANDELA",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "21.664,40",
    "BAS_2PAR": "16.664,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.664,40",
    "REC_EXTRA2": "99.986,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "186.979,60"
  },
  {
    "EOL": "091561",
    "UNIDADE": "EMEI - ABELARDO GALDINO PINTO - PIOLIN",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "14.886,00",
    "BAS_2PAR": "14.886,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.886,00",
    "REC_EXTRA2": "89.316,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "169.974,00"
  },
  {
    "EOL": "091570",
    "UNIDADE": "EMEI - EDUARDO GOMES, BRIG.",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "15.094,80",
    "BAS_2PAR": "15.094,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "15.094,80",
    "REC_EXTRA2": "90.568,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "171.853,20"
  },
  {
    "EOL": "091588",
    "UNIDADE": "EMEI - BERTHA LUTZ",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "18.666,00",
    "BAS_2PAR": "18.666,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.666,00",
    "REC_EXTRA2": "111.996,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "211.994,00"
  },
  {
    "EOL": "091596",
    "UNIDADE": "EMEI - ENZO SILVEIRA, DR.",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "14.832,00",
    "BAS_2PAR": "14.832,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.832,00",
    "REC_EXTRA2": "88.992,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "169.488,00"
  },
  {
    "EOL": "091600",
    "UNIDADE": "EMEI - OLGA CALIL MENAH, PROFA.",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "13.082,40",
    "BAS_2PAR": "13.082,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.082,40",
    "REC_EXTRA2": "78.494,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "149.741,60"
  },
  {
    "EOL": "091618",
    "UNIDADE": "EMEI - OLGA MARIA GERMANO MARTINS DOMINGOS, PROFA.",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "11.368,80",
    "BAS_2PAR": "11.368,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.368,80",
    "REC_EXTRA2": "68.212,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.319,20"
  },
  {
    "EOL": "091626",
    "UNIDADE": "EMEI - EUNICE DOS SANTOS, PROFA.",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "14.832,00",
    "BAS_2PAR": "14.832,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.832,00",
    "REC_EXTRA2": "88.992,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "169.488,00"
  },
  {
    "EOL": "091634",
    "UNIDADE": "EMEI - CONJ. RESIDENCIAL ELISIO TEIXEIRA LEITE",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "18.529,20",
    "BAS_2PAR": "18.529,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.529,20",
    "REC_EXTRA2": "111.175,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "210.762,80"
  },
  {
    "EOL": "091642",
    "UNIDADE": "EMEI - FERNANDO DE AZEVEDO",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "16.768,80",
    "BAS_2PAR": "16.768,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.768,80",
    "REC_EXTRA2": "100.612,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "190.919,20"
  },
  {
    "EOL": "091651",
    "UNIDADE": "EMEI - OLIVEIRA LIMA",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "16.912,80",
    "BAS_2PAR": "16.912,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.912,80",
    "REC_EXTRA2": "101.476,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "192.215,20"
  },
  {
    "EOL": "091669",
    "UNIDADE": "EMEI - PEROLA ELLIS BYINGTON",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "12.967,20",
    "BAS_2PAR": "12.967,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "12.967,20",
    "REC_EXTRA2": "77.803,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "148.704,80"
  },
  {
    "EOL": "091677",
    "UNIDADE": "EMEI - AFONSO SARDINHA",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "15.098,40",
    "BAS_2PAR": "15.098,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "15.098,40",
    "REC_EXTRA2": "90.590,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "171.885,60"
  },
  {
    "EOL": "091685",
    "UNIDADE": "EMEI - PAULO VI",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "13.294,80",
    "BAS_2PAR": "13.294,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.294,80",
    "REC_EXTRA2": "79.768,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "151.653,20"
  },
  {
    "EOL": "091693",
    "UNIDADE": "EMEI - ANTONIO RAPOSO TAVARES",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "14.875,20",
    "BAS_2PAR": "14.875,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.875,20",
    "REC_EXTRA2": "89.251,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "169.876,80"
  },
  {
    "EOL": "091707",
    "UNIDADE": "EMEI - PEDRO DE TOLEDO",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "13.082,40",
    "BAS_2PAR": "13.082,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.082,40",
    "REC_EXTRA2": "78.494,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "149.741,60"
  },
  {
    "EOL": "091715",
    "UNIDADE": "EMEI - CARMEN DA SILVA",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "14.796,00",
    "BAS_2PAR": "14.796,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.796,00",
    "REC_EXTRA2": "88.776,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "169.164,00"
  },
  {
    "EOL": "091723",
    "UNIDADE": "EMEI - MANUEL BANDEIRA",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "14.914,80",
    "BAS_2PAR": "14.914,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.914,80",
    "REC_EXTRA2": "89.488,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "170.233,20"
  },
  {
    "EOL": "091731",
    "UNIDADE": "EMEI - 7 DE SETEMBRO",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "16.570,80",
    "BAS_2PAR": "16.570,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.570,80",
    "REC_EXTRA2": "99.424,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "189.137,20"
  },
  {
    "EOL": "091740",
    "UNIDADE": "EMEI - JOSE BONIFACIO DE ANDRADA E SILVA",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "21.826,40",
    "BAS_2PAR": "16.826,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.826,40",
    "REC_EXTRA2": "100.958,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "188.437,60"
  },
  {
    "EOL": "091758",
    "UNIDADE": "EMEI - JOSE AUGUSTO CESAR, DR.",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "11.250,00",
    "BAS_2PAR": "11.250,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.250,00",
    "REC_EXTRA2": "67.500,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.250,00"
  },
  {
    "EOL": "091766",
    "UNIDADE": "EMEI - GINA DE MARTINO, DRA.",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "13.132,80",
    "BAS_2PAR": "13.132,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.132,80",
    "REC_EXTRA2": "78.796,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "150.195,20"
  },
  {
    "EOL": "091774",
    "UNIDADE": "EMEI - ENIO CORREA, PROF.",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "15.033,60",
    "BAS_2PAR": "15.033,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "15.033,60",
    "REC_EXTRA2": "90.201,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "171.302,40"
  },
  {
    "EOL": "091782",
    "UNIDADE": "EMEI - EUDOXIA DE BARROS, PROFA.",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "15.102,00",
    "BAS_2PAR": "15.102,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "15.102,00",
    "REC_EXTRA2": "90.612,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "171.918,00"
  },
  {
    "EOL": "091791",
    "UNIDADE": "EMEI - ARTHUR ETZEL",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "13.320,00",
    "BAS_2PAR": "13.320,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.320,00",
    "REC_EXTRA2": "79.920,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "151.880,00"
  },
  {
    "EOL": "091804",
    "UNIDADE": "EMEF - PAULO NOGUEIRA FILHO",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "27.824,00",
    "BAS_2PAR": "22.824,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.824,00",
    "REC_EXTRA2": "136.944,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "219.416,00"
  },
  {
    "EOL": "091812",
    "UNIDADE": "EMEBS - HELEN KELLER",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "18.918,00",
    "BAS_2PAR": "18.918,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.918,00",
    "REC_EXTRA2": "113.508,00",
    "EM": "10.000,00",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "2.000,00",
    "TOTAL": "202.262,00"
  },
  {
    "EOL": "091821",
    "UNIDADE": "EMEI - FEIJO, REG.",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "13.186,80",
    "BAS_2PAR": "13.186,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.186,80",
    "REC_EXTRA2": "79.120,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "150.681,20"
  },
  {
    "EOL": "091839",
    "UNIDADE": "EMEI - ALBERTO DE OLIVEIRA",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "13.248,00",
    "BAS_2PAR": "13.248,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.248,00",
    "REC_EXTRA2": "79.488,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "151.232,00"
  },
  {
    "EOL": "091847",
    "UNIDADE": "EMEI - ANGELO MARTINO",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "14.806,80",
    "BAS_2PAR": "14.806,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.806,80",
    "REC_EXTRA2": "88.840,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "169.261,20"
  },
  {
    "EOL": "091855",
    "UNIDADE": "EMEI - PATRICIA GALVAO",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "14.868,00",
    "BAS_2PAR": "14.868,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.868,00",
    "REC_EXTRA2": "89.208,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "169.812,00"
  },
  {
    "EOL": "091863",
    "UNIDADE": "EMEI - GABRIEL PRESTES",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "12.988,80",
    "BAS_2PAR": "12.988,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "12.988,80",
    "REC_EXTRA2": "77.932,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "148.899,20"
  },
  {
    "EOL": "091871",
    "UNIDADE": "EMEI - ARMANDO DE ARRUDA PEREIRA",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "15.062,40",
    "BAS_2PAR": "15.062,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "15.062,40",
    "REC_EXTRA2": "90.374,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "171.561,60"
  },
  {
    "EOL": "091880",
    "UNIDADE": "EMEI - JOAO THEODORO",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "13.132,80",
    "BAS_2PAR": "13.132,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.132,80",
    "REC_EXTRA2": "78.796,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "150.195,20"
  },
  {
    "EOL": "091898",
    "UNIDADE": "EMEI - ALCEU MAYNARD DE ARAUJO, PROF.",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "13.284,00",
    "BAS_2PAR": "13.284,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.284,00",
    "REC_EXTRA2": "79.704,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "151.556,00"
  },
  {
    "EOL": "091901",
    "UNIDADE": "EMEI - CASPER LIBERO",
    "DRE": "PENHA",
    "BAS_1PAR": "14.810,40",
    "BAS_2PAR": "14.810,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.810,40",
    "REC_EXTRA2": "88.862,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "169.293,60"
  },
  {
    "EOL": "091910",
    "UNIDADE": "EMEI - DELFINO AZEVEDO",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "11.386,80",
    "BAS_2PAR": "11.386,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.386,80",
    "REC_EXTRA2": "68.320,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.481,20"
  },
  {
    "EOL": "091928",
    "UNIDADE": "EMEI - MILTON IMPROTA, PROF.",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "18.287,60",
    "BAS_2PAR": "13.287,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.287,60",
    "REC_EXTRA2": "79.725,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "148.588,40"
  },
  {
    "EOL": "091936",
    "UNIDADE": "EMEI - MANUEL SOARES NEIVA, CEL.",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "11.426,40",
    "BAS_2PAR": "11.426,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.426,40",
    "REC_EXTRA2": "68.558,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.837,60"
  },
  {
    "EOL": "091944",
    "UNIDADE": "EMEI - THEREZINHA BATISTA PETTAN, PROFA.",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "19.562,00",
    "BAS_2PAR": "14.562,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.562,00",
    "REC_EXTRA2": "87.372,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "164.058,00"
  },
  {
    "EOL": "091952",
    "UNIDADE": "EMEI - MONTESE",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "14.922,00",
    "BAS_2PAR": "14.922,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.922,00",
    "REC_EXTRA2": "89.532,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "170.298,00"
  },
  {
    "EOL": "091961",
    "UNIDADE": "EMEI - MARIO MARQUES E SERRA, PE.",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "18.507,60",
    "BAS_2PAR": "18.507,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.507,60",
    "REC_EXTRA2": "111.045,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "210.568,40"
  },
  {
    "EOL": "091979",
    "UNIDADE": "EMEI - ANTONIO FRANCISCO LISBOA",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "14.936,40",
    "BAS_2PAR": "14.936,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.936,40",
    "REC_EXTRA2": "89.618,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "170.427,60"
  },
  {
    "EOL": "091987",
    "UNIDADE": "EMEI CEU - BENNO HUBERT STOLLENWERK, PE.",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "16.704,00",
    "BAS_2PAR": "16.704,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.704,00",
    "REC_EXTRA2": "100.224,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "190.336,00"
  },
  {
    "EOL": "091995",
    "UNIDADE": "EMEI - BATISTA CEPELOS",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "13.273,20",
    "BAS_2PAR": "13.273,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.273,20",
    "REC_EXTRA2": "79.639,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "151.458,80"
  },
  {
    "EOL": "092002",
    "UNIDADE": "EMEI - ISABEL, PRINC.",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "16.653,60",
    "BAS_2PAR": "16.653,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.653,60",
    "REC_EXTRA2": "99.921,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "189.882,40"
  },
  {
    "EOL": "092011",
    "UNIDADE": "EMEI - PEDRO I, D.",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "14.796,00",
    "BAS_2PAR": "14.796,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.796,00",
    "REC_EXTRA2": "88.776,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "169.164,00"
  },
  {
    "EOL": "092029",
    "UNIDADE": "EMEI - TAMANDARE, ALM.",
    "DRE": "PENHA",
    "BAS_1PAR": "13.176,00",
    "BAS_2PAR": "13.176,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.176,00",
    "REC_EXTRA2": "79.056,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "150.584,00"
  },
  {
    "EOL": "092037",
    "UNIDADE": "EMEI - MARCILIO DIAS",
    "DRE": "PENHA",
    "BAS_1PAR": "15.091,20",
    "BAS_2PAR": "15.091,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "15.091,20",
    "REC_EXTRA2": "90.547,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "171.820,80"
  },
  {
    "EOL": "092045",
    "UNIDADE": "EMEI - GUILHERME RUDGE",
    "DRE": "PENHA",
    "BAS_1PAR": "11.480,40",
    "BAS_2PAR": "11.480,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.480,40",
    "REC_EXTRA2": "68.882,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "131.323,60"
  },
  {
    "EOL": "092053",
    "UNIDADE": "EMEI - ALUISIO DE AZEVEDO",
    "DRE": "PENHA",
    "BAS_1PAR": "13.165,20",
    "BAS_2PAR": "13.165,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.165,20",
    "REC_EXTRA2": "78.991,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "150.486,80"
  },
  {
    "EOL": "092061",
    "UNIDADE": "EMEI - CORNELIO PIRES",
    "DRE": "PENHA",
    "BAS_1PAR": "18.903,20",
    "BAS_2PAR": "13.903,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.903,20",
    "REC_EXTRA2": "83.419,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "158.128,80"
  },
  {
    "EOL": "092070",
    "UNIDADE": "EMEI - MARIO ALVES DE CARVALHO, DR.",
    "DRE": "PENHA",
    "BAS_1PAR": "13.140,00",
    "BAS_2PAR": "13.140,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.140,00",
    "REC_EXTRA2": "78.840,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "150.260,00"
  },
  {
    "EOL": "092088",
    "UNIDADE": "EMEI - FRANCISCO ROQUETTI",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "14.835,60",
    "BAS_2PAR": "14.835,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.835,60",
    "REC_EXTRA2": "89.013,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "169.520,40"
  },
  {
    "EOL": "092096",
    "UNIDADE": "EMEI - JOAO ORTALE, PROF.",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "18.888,80",
    "BAS_2PAR": "13.888,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.888,80",
    "REC_EXTRA2": "83.332,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "157.999,20"
  },
  {
    "EOL": "092100",
    "UNIDADE": "EMEI - ALBERTO MESQUITA DE CAMARGO, PROF.",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "12.978,00",
    "BAS_2PAR": "12.978,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "12.978,00",
    "REC_EXTRA2": "77.868,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "148.802,00"
  },
  {
    "EOL": "092118",
    "UNIDADE": "EMEI - NEYL GOMEZ MARTIN, PROFA.",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "15.026,40",
    "BAS_2PAR": "15.026,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "15.026,40",
    "REC_EXTRA2": "90.158,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "171.237,60"
  },
  {
    "EOL": "092126",
    "UNIDADE": "EMEI - JOSE VICENTE DA CUNHA, PROF.",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "16.588,80",
    "BAS_2PAR": "16.588,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.588,80",
    "REC_EXTRA2": "99.532,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "189.299,20"
  },
  {
    "EOL": "092134",
    "UNIDADE": "EMEI - OLANDYA PERES RIBEIRO, PROFA.",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "16.617,60",
    "BAS_2PAR": "16.617,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.617,60",
    "REC_EXTRA2": "99.705,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "189.558,40"
  },
  {
    "EOL": "092142",
    "UNIDADE": "EMEI - SUZANA EVANGELINA FELIPPE, PROFA.",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "16.873,20",
    "BAS_2PAR": "16.873,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.873,20",
    "REC_EXTRA2": "101.239,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "191.858,80"
  },
  {
    "EOL": "092151",
    "UNIDADE": "EMEI - ELDY POLI BIFONE, PROFA.",
    "DRE": "PENHA",
    "BAS_1PAR": "16.905,60",
    "BAS_2PAR": "16.905,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.905,60",
    "REC_EXTRA2": "101.433,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "192.150,40"
  },
  {
    "EOL": "092169",
    "UNIDADE": "EMEI - ANCHIETA, PE.",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "14.932,80",
    "BAS_2PAR": "14.932,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.932,80",
    "REC_EXTRA2": "89.596,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "170.395,20"
  },
  {
    "EOL": "092177",
    "UNIDADE": "EMEI - LOURENCO FILHO, PROF.",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "18.374,40",
    "BAS_2PAR": "18.374,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.374,40",
    "REC_EXTRA2": "110.246,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "209.369,60"
  },
  {
    "EOL": "092185",
    "UNIDADE": "EMEI - ANA NERI",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "16.909,20",
    "BAS_2PAR": "16.909,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.909,20",
    "REC_EXTRA2": "101.455,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "192.182,80"
  },
  {
    "EOL": "092193",
    "UNIDADE": "EMEI - YUKIO OZAKI, PROF.",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "14.821,20",
    "BAS_2PAR": "14.821,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.821,20",
    "REC_EXTRA2": "88.927,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "169.390,80"
  },
  {
    "EOL": "092207",
    "UNIDADE": "EMEI - CARLOS GOMES",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "19.634,00",
    "BAS_2PAR": "14.634,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.634,00",
    "REC_EXTRA2": "87.804,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "164.706,00"
  },
  {
    "EOL": "092215",
    "UNIDADE": "EMEI - EDUARDO CARLOS PEREIRA",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "14.997,60",
    "BAS_2PAR": "14.997,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.997,60",
    "REC_EXTRA2": "89.985,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "170.978,40"
  },
  {
    "EOL": "092223",
    "UNIDADE": "EMEI - MARIA ISABEL PACHECO DE ALMEIDA RIBEIRO, PROFA.",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "15.044,40",
    "BAS_2PAR": "15.044,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "15.044,40",
    "REC_EXTRA2": "90.266,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "171.399,60"
  },
  {
    "EOL": "092231",
    "UNIDADE": "EMEI - CIDADE FERNAO DIAS",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "14.817,60",
    "BAS_2PAR": "14.817,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.817,60",
    "REC_EXTRA2": "88.905,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "169.358,40"
  },
  {
    "EOL": "092240",
    "UNIDADE": "EMEI - EDU CHAVES, AVIADOR",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "18.655,20",
    "BAS_2PAR": "18.655,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.655,20",
    "REC_EXTRA2": "111.931,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "211.896,80"
  },
  {
    "EOL": "092258",
    "UNIDADE": "EMEI - OTTILIA DE JESUS PIRES, PROFA.",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "18.370,80",
    "BAS_2PAR": "18.370,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.370,80",
    "REC_EXTRA2": "110.224,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "209.337,20"
  },
  {
    "EOL": "092266",
    "UNIDADE": "EMEI - TAUFIK DAUD KURBAN",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "14.842,80",
    "BAS_2PAR": "14.842,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.842,80",
    "REC_EXTRA2": "89.056,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "169.585,20"
  },
  {
    "EOL": "092274",
    "UNIDADE": "EMEI - ITALO BETTARELLO, PROF.",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "18.547,20",
    "BAS_2PAR": "18.547,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.547,20",
    "REC_EXTRA2": "111.283,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "210.924,80"
  },
  {
    "EOL": "092282",
    "UNIDADE": "EMEI - LAURA FUNFAS LE SUEUR, PROFA.",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "14.929,00",
    "BAS_2PAR": "14.929,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.929,00",
    "REC_EXTRA2": "89.575,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "170.362,00"
  },
  {
    "EOL": "092291",
    "UNIDADE": "EMEI - FLAVIO IMPERIO",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "16.866,00",
    "BAS_2PAR": "16.866,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.866,00",
    "REC_EXTRA2": "101.196,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "191.794,00"
  },
  {
    "EOL": "092304",
    "UNIDADE": "EMEI - MACHADO DE ASSIS",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "18.612,00",
    "BAS_2PAR": "18.612,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.612,00",
    "REC_EXTRA2": "111.672,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "211.508,00"
  },
  {
    "EOL": "092312",
    "UNIDADE": "EMEI - CASIMIRO DE ABREU",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "16.581,60",
    "BAS_2PAR": "16.581,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.581,60",
    "REC_EXTRA2": "99.489,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "189.234,40"
  },
  {
    "EOL": "092321",
    "UNIDADE": "EMEI - LEONOR MENDES DE BARROS, DA.",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "14.922,00",
    "BAS_2PAR": "14.922,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.922,00",
    "REC_EXTRA2": "89.532,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "170.298,00"
  },
  {
    "EOL": "092339",
    "UNIDADE": "EMEI - NEUSA MARIA ROSSI",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "13.100,40",
    "BAS_2PAR": "13.100,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.100,40",
    "REC_EXTRA2": "78.602,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "149.903,60"
  },
  {
    "EOL": "092347",
    "UNIDADE": "EMEI - LAUDO FERREIRA DE CAMARGO",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "13.233,60",
    "BAS_2PAR": "13.233,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.233,60",
    "REC_EXTRA2": "79.401,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "151.102,40"
  },
  {
    "EOL": "092355",
    "UNIDADE": "EMEI - LOURDES HEREDIA MELLO, PROFA.",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "18.917,50",
    "BAS_2PAR": "13.917,50",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.917,50",
    "REC_EXTRA2": "83.505,50",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "158.258,00"
  },
  {
    "EOL": "092363",
    "UNIDADE": "EMEI - LUCY GARCIA SALGADO, PROFA.",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "18.914,00",
    "BAS_2PAR": "13.914,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.914,00",
    "REC_EXTRA2": "83.484,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "158.226,00"
  },
  {
    "EOL": "092371",
    "UNIDADE": "EMEI - IGNACIO HENRIQUE ROMEIRO, PROF.",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "11.203,20",
    "BAS_2PAR": "11.203,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.203,20",
    "REC_EXTRA2": "67.219,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "128.828,80"
  },
  {
    "EOL": "092380",
    "UNIDADE": "EMEI - ANITA COSTA, DA.",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "23.939,00",
    "BAS_2PAR": "13.939,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.939,00",
    "REC_EXTRA2": "83.635,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "163.452,00"
  },
  {
    "EOL": "092398",
    "UNIDADE": "EMEI - SAO PAULO",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "19.558,40",
    "BAS_2PAR": "14.558,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.558,40",
    "REC_EXTRA2": "87.350,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "164.025,60"
  },
  {
    "EOL": "092401",
    "UNIDADE": "EMEI - HEITOR VILLA LOBOS",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "19.544,00",
    "BAS_2PAR": "14.544,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.544,00",
    "REC_EXTRA2": "87.264,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "163.896,00"
  },
  {
    "EOL": "092410",
    "UNIDADE": "EMEI - ANA ROSA DE ARAUJO, DA.",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "14.925,60",
    "BAS_2PAR": "14.925,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.925,60",
    "REC_EXTRA2": "89.553,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "170.330,40"
  },
  {
    "EOL": "092428",
    "UNIDADE": "EMEI - OSWALDO CRUZ",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "11.142,00",
    "BAS_2PAR": "11.142,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.142,00",
    "REC_EXTRA2": "66.852,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "124.278,00"
  },
  {
    "EOL": "092436",
    "UNIDADE": "EMEI - PAULO ALVES, TTE.",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "13.248,00",
    "BAS_2PAR": "13.248,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.248,00",
    "REC_EXTRA2": "79.488,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "151.232,00"
  },
  {
    "EOL": "092444",
    "UNIDADE": "EMEI - ALEXANDRE CORREIA, PROF.",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "14.997,60",
    "BAS_2PAR": "14.997,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.997,60",
    "REC_EXTRA2": "89.985,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "170.978,40"
  },
  {
    "EOL": "092452",
    "UNIDADE": "EMEI - QUINTA DAS PAINEIRAS",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "13.107,60",
    "BAS_2PAR": "13.107,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.107,60",
    "REC_EXTRA2": "78.645,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "149.968,40"
  },
  {
    "EOL": "092461",
    "UNIDADE": "EMEI - AFONSO CELSO",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "13.057,20",
    "BAS_2PAR": "13.057,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.057,20",
    "REC_EXTRA2": "78.343,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "149.514,80"
  },
  {
    "EOL": "092479",
    "UNIDADE": "EMEI - ZENAIDE GRANDINI, PROFA.",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "14.792,40",
    "BAS_2PAR": "14.792,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.792,40",
    "REC_EXTRA2": "88.754,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "169.131,60"
  },
  {
    "EOL": "092487",
    "UNIDADE": "EMEI - SEBASTIAO SANCHES MARTINES, PROF.",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "13.093,20",
    "BAS_2PAR": "13.093,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.093,20",
    "REC_EXTRA2": "78.559,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "149.838,80"
  },
  {
    "EOL": "092495",
    "UNIDADE": "EMEI - JOSE VERISSIMO",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "14.835,60",
    "BAS_2PAR": "14.835,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.835,60",
    "REC_EXTRA2": "89.013,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "169.520,40"
  },
  {
    "EOL": "092509",
    "UNIDADE": "EMEI - BRENNO FERRAZ DO AMARAL",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "13.176,00",
    "BAS_2PAR": "13.176,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.176,00",
    "REC_EXTRA2": "79.056,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "150.584,00"
  },
  {
    "EOL": "092517",
    "UNIDADE": "EMEI - ORIGENES LESSA",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "16.783,20",
    "BAS_2PAR": "16.783,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.783,20",
    "REC_EXTRA2": "100.699,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "191.048,80"
  },
  {
    "EOL": "092525",
    "UNIDADE": "EMEI - BENJAMIN CONSTANT",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "14.922,00",
    "BAS_2PAR": "14.922,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.922,00",
    "REC_EXTRA2": "89.532,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "170.298,00"
  },
  {
    "EOL": "092533",
    "UNIDADE": "EMEI - JOSE CLEMENTE PEREIRA",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "16.840,80",
    "BAS_2PAR": "16.840,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.840,80",
    "REC_EXTRA2": "101.044,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "191.567,20"
  },
  {
    "EOL": "092541",
    "UNIDADE": "EMEI - VICENTE DE CARVALHO",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "14.871,60",
    "BAS_2PAR": "14.871,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.871,60",
    "REC_EXTRA2": "89.229,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "169.844,40"
  },
  {
    "EOL": "092550",
    "UNIDADE": "EMEI - ATAULFO ALVES",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "19.634,00",
    "BAS_2PAR": "14.634,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.634,00",
    "REC_EXTRA2": "87.804,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "164.706,00"
  },
  {
    "EOL": "092568",
    "UNIDADE": "EMEI - MARIO DE ANDRADE",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "14.799,60",
    "BAS_2PAR": "14.799,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.799,60",
    "REC_EXTRA2": "88.797,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "169.196,40"
  },
  {
    "EOL": "092576",
    "UNIDADE": "EMEI - VITAL BRAZIL, DR.",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "14.914,80",
    "BAS_2PAR": "14.914,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.914,80",
    "REC_EXTRA2": "89.488,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "170.233,20"
  },
  {
    "EOL": "092584",
    "UNIDADE": "EMEI - ANTONIA DE OLIVEIRA MOTA DE ARAUJO, PROFA.",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "13.183,20",
    "BAS_2PAR": "13.183,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.183,20",
    "REC_EXTRA2": "79.099,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "150.648,80"
  },
  {
    "EOL": "092592",
    "UNIDADE": "EMEI - MANOEL FIEL FILHO",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "13.021,20",
    "BAS_2PAR": "13.021,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.021,20",
    "REC_EXTRA2": "78.127,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "149.190,80"
  },
  {
    "EOL": "092606",
    "UNIDADE": "EMEI - ADEVALDO DE MORAES",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "13.150,80",
    "BAS_2PAR": "13.150,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.150,80",
    "REC_EXTRA2": "78.904,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "150.357,20"
  },
  {
    "EOL": "092614",
    "UNIDADE": "EMEI - CARLOS HUMBERTO VOLPON, PROF.",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "13.276,80",
    "BAS_2PAR": "13.276,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.276,80",
    "REC_EXTRA2": "79.660,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "151.491,20"
  },
  {
    "EOL": "092622",
    "UNIDADE": "EMEF - ARLINDO CAETANO FILHO, PROF.",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "20.280,60",
    "BAS_2PAR": "20.280,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.280,60",
    "REC_EXTRA2": "121.683,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "191.525,40"
  },
  {
    "EOL": "092631",
    "UNIDADE": "EMEI - CLOVIS BEVILACQUA",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "18.374,40",
    "BAS_2PAR": "18.374,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.374,40",
    "REC_EXTRA2": "110.246,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "209.369,60"
  },
  {
    "EOL": "092649",
    "UNIDADE": "EMEI - CHIQUINHA GONZAGA",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "13.125,60",
    "BAS_2PAR": "13.125,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.125,60",
    "REC_EXTRA2": "78.753,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "150.130,40"
  },
  {
    "EOL": "092657",
    "UNIDADE": "EMEI - NINI DUARTE",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "14.814,00",
    "BAS_2PAR": "14.814,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.814,00",
    "REC_EXTRA2": "88.884,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "169.326,00"
  },
  {
    "EOL": "092665",
    "UNIDADE": "EMEI - CARMEM MIRANDA",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "29.108,80",
    "BAS_2PAR": "19.108,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "19.108,80",
    "REC_EXTRA2": "114.652,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "217.979,20"
  },
  {
    "EOL": "092673",
    "UNIDADE": "EMEI - JOSE MAURO DE VASCONCELOS",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "16.588,80",
    "BAS_2PAR": "16.588,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.588,80",
    "REC_EXTRA2": "99.532,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "189.299,20"
  },
  {
    "EOL": "092681",
    "UNIDADE": "EMEF - JOSE DE ALCANTARA MACHADO FILHO",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "20.642,00",
    "BAS_2PAR": "20.642,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.642,00",
    "REC_EXTRA2": "123.854,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "194.780,00"
  },
  {
    "EOL": "092690",
    "UNIDADE": "EMEF - AMORIM LIMA, DES.",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "22.915,50",
    "BAS_2PAR": "22.915,50",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.915,50",
    "REC_EXTRA2": "137.494,50",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "215.241,00"
  },
  {
    "EOL": "092703",
    "UNIDADE": "EMEF - THEODOMIRO DIAS, DES.",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "36.800,00",
    "BAS_2PAR": "26.800,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.800,00",
    "REC_EXTRA2": "160.801,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "262.201,00"
  },
  {
    "EOL": "092711",
    "UNIDADE": "EMEF - DEODORO DA FONSECA, MAL.",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "25.896,50",
    "BAS_2PAR": "25.896,50",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.896,50",
    "REC_EXTRA2": "155.379,50",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "244.069,00"
  },
  {
    "EOL": "092720",
    "UNIDADE": "EMEF - ARTHUR WHITAKER, DES.",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "22.910,00",
    "BAS_2PAR": "22.910,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.910,00",
    "REC_EXTRA2": "137.462,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "215.192,00"
  },
  {
    "EOL": "092738",
    "UNIDADE": "EMEF - TARSILA DO AMARAL",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "36.460,00",
    "BAS_2PAR": "26.460,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.460,00",
    "REC_EXTRA2": "158.760,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "257.140,00"
  },
  {
    "EOL": "092746",
    "UNIDADE": "EMEF - ALIPIO ANDRADA SERPA, TTE.",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "19.989,00",
    "BAS_2PAR": "19.989,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "19.989,00",
    "REC_EXTRA2": "119.934,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "188.901,00"
  },
  {
    "EOL": "092754",
    "UNIDADE": "EMEF - ALCIDES GONCALVES ETCHEGOYEN, GEN.",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "22.775,00",
    "BAS_2PAR": "22.775,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.775,00",
    "REC_EXTRA2": "136.652,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "213.977,00"
  },
  {
    "EOL": "092762",
    "UNIDADE": "EMEF - JOAO XXIII",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "26.809,00",
    "BAS_2PAR": "26.809,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.809,00",
    "REC_EXTRA2": "160.855,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "252.282,00"
  },
  {
    "EOL": "092771",
    "UNIDADE": "EMEF - SOLANO TRINDADE",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "22.656,50",
    "BAS_2PAR": "22.656,50",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.656,50",
    "REC_EXTRA2": "135.939,50",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "212.909,00"
  },
  {
    "EOL": "092789",
    "UNIDADE": "EMEF - LUIZ EDUARDO MATARAZZO, CDE.",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "25.734,50",
    "BAS_2PAR": "25.734,50",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.734,50",
    "REC_EXTRA2": "154.407,50",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "242.611,00"
  },
  {
    "EOL": "092797",
    "UNIDADE": "EMEF - JULIO MESQUITA",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "23.526,00",
    "BAS_2PAR": "23.526,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.526,00",
    "REC_EXTRA2": "141.156,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "222.734,00"
  },
  {
    "EOL": "092801",
    "UNIDADE": "EMEF - IBRAHIM NOBRE",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "20.377,50",
    "BAS_2PAR": "20.377,50",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.377,50",
    "REC_EXTRA2": "122.266,50",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "192.399,00"
  },
  {
    "EOL": "092819",
    "UNIDADE": "EMEF - BRASIL-JAPAO",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "31.951,00",
    "BAS_2PAR": "26.951,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.951,00",
    "REC_EXTRA2": "161.708,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "258.561,00"
  },
  {
    "EOL": "092827",
    "UNIDADE": "EMEF - ALVARO SILVA BRAGA, GEN.",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "19.908,00",
    "BAS_2PAR": "19.908,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "19.908,00",
    "REC_EXTRA2": "119.448,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "188.172,00"
  },
  {
    "EOL": "092835",
    "UNIDADE": "EMEF - ESPIRIDIAO ROSAS, MAL.",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "25.983,00",
    "BAS_2PAR": "25.983,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.983,00",
    "REC_EXTRA2": "155.898,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "244.847,00"
  },
  {
    "EOL": "092843",
    "UNIDADE": "EMEF - ROBERTO MANGE, PROF.",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "22.689,00",
    "BAS_2PAR": "22.689,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.689,00",
    "REC_EXTRA2": "136.134,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "213.201,00"
  },
  {
    "EOL": "092851",
    "UNIDADE": "EMEF - EUCLYDES DE OLIVEIRA FIGUEIREDO, GEN.",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "23.153,00",
    "BAS_2PAR": "23.153,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.153,00",
    "REC_EXTRA2": "138.920,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "219.379,00"
  },
  {
    "EOL": "092860",
    "UNIDADE": "EMEF - MARIO MOURA E ALBUQUERQUE, BEL.",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "23.131,80",
    "BAS_2PAR": "23.131,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.131,80",
    "REC_EXTRA2": "138.790,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "219.186,20"
  },
  {
    "EOL": "092878",
    "UNIDADE": "EMEF - CAROLINA RENNO RIBEIRO DE OLIVEIRA, PROFA.",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "25.669,80",
    "BAS_2PAR": "25.669,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.669,80",
    "REC_EXTRA2": "154.018,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "242.028,20"
  },
  {
    "EOL": "092886",
    "UNIDADE": "EMEF - OLIVEIRA VIANA",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "26.042,40",
    "BAS_2PAR": "26.042,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.042,40",
    "REC_EXTRA2": "156.254,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "245.381,60"
  },
  {
    "EOL": "092894",
    "UNIDADE": "EMEF - MARIO MARQUES DE OLIVEIRA, PROF.",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "26.447,40",
    "BAS_2PAR": "26.447,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.447,40",
    "REC_EXTRA2": "158.684,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "249.026,60"
  },
  {
    "EOL": "092908",
    "UNIDADE": "EMEF - CLEMENTE PASTORE, PROF.",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "28.796,00",
    "BAS_2PAR": "28.796,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "28.796,00",
    "REC_EXTRA2": "172.778,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "272.166,00"
  },
  {
    "EOL": "092916",
    "UNIDADE": "EMEF - ANTONIO ESTANISLAU DO AMARAL",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "32.118,80",
    "BAS_2PAR": "27.118,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "27.118,80",
    "REC_EXTRA2": "162.712,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "260.069,20"
  },
  {
    "EOL": "092924",
    "UNIDADE": "EMEF - MARIO RANGEL, CEL.",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "28.699,20",
    "BAS_2PAR": "28.699,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "28.699,20",
    "REC_EXTRA2": "172.195,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "271.292,80"
  },
  {
    "EOL": "092932",
    "UNIDADE": "EMEF - JORGE AMERICANO, PROF.",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "26.215,20",
    "BAS_2PAR": "26.215,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.215,20",
    "REC_EXTRA2": "157.291,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "246.936,80"
  },
  {
    "EOL": "092941",
    "UNIDADE": "EMEF - EUCLIDES DA CUNHA",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "22.834,80",
    "BAS_2PAR": "22.834,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.834,80",
    "REC_EXTRA2": "137.008,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "214.513,20"
  },
  {
    "EOL": "092959",
    "UNIDADE": "EMEF - IRACEMA MARQUES DA SILVEIRA, PROFA.",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "29.255,40",
    "BAS_2PAR": "29.255,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "29.255,40",
    "REC_EXTRA2": "175.532,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "276.298,60"
  },
  {
    "EOL": "092967",
    "UNIDADE": "EMEF - LEVY DE AZEVEDO SODRE, PROF.",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "22.608,00",
    "BAS_2PAR": "22.608,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.608,00",
    "REC_EXTRA2": "135.648,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "212.472,00"
  },
  {
    "EOL": "092975",
    "UNIDADE": "EMEF - OTONIEL MOTA",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "23.034,60",
    "BAS_2PAR": "23.034,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.034,60",
    "REC_EXTRA2": "138.207,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "216.311,40"
  },
  {
    "EOL": "092983",
    "UNIDADE": "EMEF - PROCOPIO FERREIRA",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "26.161,20",
    "BAS_2PAR": "26.161,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.161,20",
    "REC_EXTRA2": "156.967,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "246.450,80"
  },
  {
    "EOL": "092991",
    "UNIDADE": "EMEF - AIRTON ARANTES RIBEIRO, PROF.",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "25.443,00",
    "BAS_2PAR": "25.443,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.443,00",
    "REC_EXTRA2": "152.658,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "239.987,00"
  },
  {
    "EOL": "093009",
    "UNIDADE": "EMEF - LUIZ TENORIO DE BRITO, CEL.",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "33.386,00",
    "BAS_2PAR": "28.386,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "28.386,00",
    "REC_EXTRA2": "170.316,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "269.474,00"
  },
  {
    "EOL": "093017",
    "UNIDADE": "EMEF - ADHEMAR DE BARROS, PREF.",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "34.131,20",
    "BAS_2PAR": "29.131,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "29.131,20",
    "REC_EXTRA2": "174.787,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "278.180,80"
  },
  {
    "EOL": "093025",
    "UNIDADE": "EMEF - PALIMERCIO DE REZENDE, CEL.",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "20.647,80",
    "BAS_2PAR": "20.647,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.647,80",
    "REC_EXTRA2": "123.886,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "194.830,20"
  },
  {
    "EOL": "093033",
    "UNIDADE": "EMEF - MAURICIO SIMAO",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "25.966,80",
    "BAS_2PAR": "25.966,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.966,80",
    "REC_EXTRA2": "155.800,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "244.701,20"
  },
  {
    "EOL": "093041",
    "UNIDADE": "EMEF - LEONARDO VILLAS BOAS",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "26.317,80",
    "BAS_2PAR": "26.317,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.317,80",
    "REC_EXTRA2": "157.906,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "247.860,20"
  },
  {
    "EOL": "093050",
    "UNIDADE": "EMEF - SYNESIO ROCHA, MIN.",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "23.358,60",
    "BAS_2PAR": "23.358,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.358,60",
    "REC_EXTRA2": "140.151,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "221.227,40"
  },
  {
    "EOL": "093068",
    "UNIDADE": "EMEF - ZULMIRA CAVALHEIRO FAUSTINO",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "25.421,40",
    "BAS_2PAR": "25.421,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.421,40",
    "REC_EXTRA2": "152.528,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "239.792,60"
  },
  {
    "EOL": "093076",
    "UNIDADE": "EMEF - PAULO COLOMBO PEREIRA DE QUEIROZ, DES.",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "25.880,40",
    "BAS_2PAR": "25.880,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.880,40",
    "REC_EXTRA2": "155.282,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "243.923,60"
  },
  {
    "EOL": "093084",
    "UNIDADE": "EMEF - DE GAULLE, GEN.",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "25.648,20",
    "BAS_2PAR": "25.648,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.648,20",
    "REC_EXTRA2": "153.889,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "241.833,80"
  },
  {
    "EOL": "093092",
    "UNIDADE": "EMEF - FAGUNDES VARELLA",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "25.405,20",
    "BAS_2PAR": "25.405,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.405,20",
    "REC_EXTRA2": "152.431,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "239.646,80"
  },
  {
    "EOL": "093106",
    "UNIDADE": "EMEF - DILERMANDO DIAS DOS SANTOS",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "30.038,00",
    "BAS_2PAR": "25.038,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.038,00",
    "REC_EXTRA2": "150.228,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "239.342,00"
  },
  {
    "EOL": "093114",
    "UNIDADE": "EMEF - ANIBAL FREIRE, MIN.",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "19.929,60",
    "BAS_2PAR": "19.929,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "19.929,60",
    "REC_EXTRA2": "119.577,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "188.366,40"
  },
  {
    "EOL": "093122",
    "UNIDADE": "EMEF - JOSE MARIA PINTO DUARTE, TTE.",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "20.199,60",
    "BAS_2PAR": "20.199,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.199,60",
    "REC_EXTRA2": "121.197,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "190.796,40"
  },
  {
    "EOL": "093131",
    "UNIDADE": "EMEF - MARIA ANTONIETA D'ALKIMIN BASTO, PROFA.",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "19.994,00",
    "BAS_2PAR": "19.994,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "19.994,00",
    "REC_EXTRA2": "119.966,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "188.948,00"
  },
  {
    "EOL": "093149",
    "UNIDADE": "EMEF - OLAVO PEZZOTTI, PROF.",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "27.602,50",
    "BAS_2PAR": "22.602,50",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.602,50",
    "REC_EXTRA2": "135.615,50",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "217.423,00"
  },
  {
    "EOL": "093157",
    "UNIDADE": "EMEF - CHIQUINHA RODRIGUES",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "27.321,80",
    "BAS_2PAR": "22.321,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.321,80",
    "REC_EXTRA2": "133.930,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "214.896,20"
  },
  {
    "EOL": "093165",
    "UNIDADE": "EMEF - JOSE DIAS DA SILVEIRA, DR.",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "30.934,00",
    "BAS_2PAR": "25.934,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.934,00",
    "REC_EXTRA2": "155.606,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "247.408,00"
  },
  {
    "EOL": "093173",
    "UNIDADE": "EMEF - JOAQUIM CANDIDO DE AZEVEDO MARQUES, DES.",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "25.806,20",
    "BAS_2PAR": "20.806,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.806,20",
    "REC_EXTRA2": "124.837,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "201.255,80"
  },
  {
    "EOL": "093181",
    "UNIDADE": "EMEFM - LINNEU PRESTES, PROF.",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "25.556,40",
    "BAS_2PAR": "25.556,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.556,40",
    "REC_EXTRA2": "153.338,40",
    "EM": "20.000,00",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "261.007,60"
  },
  {
    "EOL": "093190",
    "UNIDADE": "EMEF - CARLOS DE ANDRADE RIZZINI",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "33.126,80",
    "BAS_2PAR": "28.126,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "28.126,80",
    "REC_EXTRA2": "168.760,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "267.141,20"
  },
  {
    "EOL": "093203",
    "UNIDADE": "EMEF - LAERTE RAMOS DE CARVALHO, PROF.",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "20.836,80",
    "BAS_2PAR": "20.836,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.836,80",
    "REC_EXTRA2": "125.020,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "196.531,20"
  },
  {
    "EOL": "093211",
    "UNIDADE": "EMEF - JOAO GUALBERTO DO AMARAL CARVALHO",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "22.683,60",
    "BAS_2PAR": "22.683,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.683,60",
    "REC_EXTRA2": "136.101,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "213.152,40"
  },
  {
    "EOL": "093220",
    "UNIDADE": "EMEF - ANTONIO CARLOS DE ABREU SODRE, DR.",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "20.480,00",
    "BAS_2PAR": "20.480,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.480,00",
    "REC_EXTRA2": "122.882,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "193.322,00"
  },
  {
    "EOL": "093238",
    "UNIDADE": "EMEF - ANTENOR NASCENTES",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "22.942,80",
    "BAS_2PAR": "22.942,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.942,80",
    "REC_EXTRA2": "137.656,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "215.485,20"
  },
  {
    "EOL": "093246",
    "UNIDADE": "EMEF - MANOEL CARLOS DE FIGUEIREDO FERRAZ, DES.",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "25.659,00",
    "BAS_2PAR": "25.659,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.659,00",
    "REC_EXTRA2": "153.954,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "241.931,00"
  },
  {
    "EOL": "093254",
    "UNIDADE": "EMEF - JOAO SUSSUMU HIRATA, DEP.",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "29.363,40",
    "BAS_2PAR": "29.363,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "29.363,40",
    "REC_EXTRA2": "176.180,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "277.270,60"
  },
  {
    "EOL": "093262",
    "UNIDADE": "EMEF - ISABEL VIEIRA FERREIRA, PROFA.",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "25.437,60",
    "BAS_2PAR": "25.437,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.437,60",
    "REC_EXTRA2": "152.625,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "239.938,40"
  },
  {
    "EOL": "093271",
    "UNIDADE": "EMEF - TIRADENTES, ALF.",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "34.791,50",
    "BAS_2PAR": "29.791,50",
    "REC_EXTRA": "",
    "BAS_3PAR": "29.791,50",
    "REC_EXTRA2": "178.750,50",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "284.125,00"
  },
  {
    "EOL": "093289",
    "UNIDADE": "EMEF - PEREIRA CARNEIRO, CDE.",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "22.926,60",
    "BAS_2PAR": "22.926,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.926,60",
    "REC_EXTRA2": "137.559,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "215.339,40"
  },
  {
    "EOL": "093297",
    "UNIDADE": "EMEF - PLACIDO DE CASTRO",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "20.259,00",
    "BAS_2PAR": "20.259,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.259,00",
    "REC_EXTRA2": "121.554,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "191.331,00"
  },
  {
    "EOL": "093301",
    "UNIDADE": "EMEF - HEITOR DE ANDRADE",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "22.678,20",
    "BAS_2PAR": "22.678,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.678,20",
    "REC_EXTRA2": "136.069,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "213.103,80"
  },
  {
    "EOL": "093319",
    "UNIDADE": "EMEF - ALMEIDA JUNIOR, PROF.",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "19.805,40",
    "BAS_2PAR": "19.805,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "19.805,40",
    "REC_EXTRA2": "118.832,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "187.248,60"
  },
  {
    "EOL": "093327",
    "UNIDADE": "EMEF - AFRANIO DE MELLO FRANCO, DR.",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "22.770,00",
    "BAS_2PAR": "22.770,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.770,00",
    "REC_EXTRA2": "136.620,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "213.930,00"
  },
  {
    "EOL": "093335",
    "UNIDADE": "EMEF - JOAO DE DEUS CARDOSO DE MELLO",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "26.074,80",
    "BAS_2PAR": "26.074,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.074,80",
    "REC_EXTRA2": "156.448,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "245.673,20"
  },
  {
    "EOL": "093343",
    "UNIDADE": "EMEF - OLEGARIO MARIANO",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "28.477,80",
    "BAS_2PAR": "28.477,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "28.477,80",
    "REC_EXTRA2": "170.866,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "269.300,20"
  },
  {
    "EOL": "093351",
    "UNIDADE": "EMEF - MILTON FERREIRA DE ALBUQUERQUE, PROF.",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "25.788,60",
    "BAS_2PAR": "25.788,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.788,60",
    "REC_EXTRA2": "154.731,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "243.097,40"
  },
  {
    "EOL": "093360",
    "UNIDADE": "EMEF - ELIZA RACHEL MACEDO DE SOUZA, PROFA.",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "25.227,00",
    "BAS_2PAR": "25.227,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.227,00",
    "REC_EXTRA2": "151.362,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "238.043,00"
  },
  {
    "EOL": "093378",
    "UNIDADE": "EMEF - MANOEL DE ABREU, DR.",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "31.831,20",
    "BAS_2PAR": "31.831,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "31.831,20",
    "REC_EXTRA2": "190.987,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "299.480,80"
  },
  {
    "EOL": "093386",
    "UNIDADE": "EMEF - JOAQUIM BENTO ALVES DE LIMA NETO",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "23.148,00",
    "BAS_2PAR": "23.148,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.148,00",
    "REC_EXTRA2": "138.888,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "219.332,00"
  },
  {
    "EOL": "093394",
    "UNIDADE": "EMEF - TEODOMIRO TOLEDO PIZA, DES.",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "29.282,40",
    "BAS_2PAR": "29.282,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "29.282,40",
    "REC_EXTRA2": "175.694,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "276.541,60"
  },
  {
    "EOL": "093408",
    "UNIDADE": "EMEF - PAULO SETUBAL",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "22.624,20",
    "BAS_2PAR": "22.624,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.624,20",
    "REC_EXTRA2": "135.745,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "212.617,80"
  },
  {
    "EOL": "093416",
    "UNIDADE": "EMEF - JOSE AMADEI, ENG.",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "22.926,50",
    "BAS_2PAR": "22.926,50",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.926,50",
    "REC_EXTRA2": "137.559,50",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "215.339,00"
  },
  {
    "EOL": "093424",
    "UNIDADE": "EMEF - MIGUEL VIEIRA FERREIRA, DR.",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "25.945,20",
    "BAS_2PAR": "25.945,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.945,20",
    "REC_EXTRA2": "155.671,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "244.506,80"
  },
  {
    "EOL": "093432",
    "UNIDADE": "EMEF - CORREIA DE MELLO, BRIG.",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "22.629,60",
    "BAS_2PAR": "22.629,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.629,60",
    "REC_EXTRA2": "135.777,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "212.666,40"
  },
  {
    "EOL": "093441",
    "UNIDADE": "EMEF - CARLOS CHAGAS",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "28.737,00",
    "BAS_2PAR": "28.737,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "28.737,00",
    "REC_EXTRA2": "172.422,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "271.633,00"
  },
  {
    "EOL": "093459",
    "UNIDADE": "EMEF - EDUARDO PRADO",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "22.824,00",
    "BAS_2PAR": "22.824,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.824,00",
    "REC_EXTRA2": "136.944,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "214.416,00"
  },
  {
    "EOL": "093467",
    "UNIDADE": "EMEF - DANYLO JOSE FERNANDES",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "23.013,00",
    "BAS_2PAR": "23.013,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.013,00",
    "REC_EXTRA2": "138.078,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "216.117,00"
  },
  {
    "EOL": "093475",
    "UNIDADE": "EMEF - AYRES MARTINS TORRES",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "23.412,60",
    "BAS_2PAR": "23.412,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.412,60",
    "REC_EXTRA2": "140.475,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "221.713,40"
  },
  {
    "EOL": "093483",
    "UNIDADE": "EMEF - HAROLDO VELOSO, BRIG.",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "23.277,60",
    "BAS_2PAR": "23.277,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.277,60",
    "REC_EXTRA2": "139.665,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "220.498,40"
  },
  {
    "EOL": "093491",
    "UNIDADE": "EMEF - ARTUR NEIVA",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "23.250,60",
    "BAS_2PAR": "23.250,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.250,60",
    "REC_EXTRA2": "139.503,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "220.255,40"
  },
  {
    "EOL": "093505",
    "UNIDADE": "EMEF - BENEDITO CALIXTO",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "26.674,20",
    "BAS_2PAR": "26.674,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.674,20",
    "REC_EXTRA2": "160.045,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "251.067,80"
  },
  {
    "EOL": "093513",
    "UNIDADE": "EMEF - JOAO DE LIMA PAIVA, PROF.",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "22.899,60",
    "BAS_2PAR": "22.899,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.899,60",
    "REC_EXTRA2": "137.397,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "215.096,40"
  },
  {
    "EOL": "093521",
    "UNIDADE": "EMEF - VINTE E CINCO DE JANEIRO",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "17.618,40",
    "BAS_2PAR": "17.618,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "17.618,40",
    "REC_EXTRA2": "105.710,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "2.000,00",
    "TOTAL": "165.565,60"
  },
  {
    "EOL": "093530",
    "UNIDADE": "EMEF - JOANA ANGELICA DE JESUS, MADRE",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "40.776,40",
    "BAS_2PAR": "30.776,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "30.776,40",
    "REC_EXTRA2": "184.658,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "297.987,60"
  },
  {
    "EOL": "093548",
    "UNIDADE": "EMEF - SATURNINO PEREIRA",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "26.339,40",
    "BAS_2PAR": "26.339,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.339,40",
    "REC_EXTRA2": "158.036,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "248.054,60"
  },
  {
    "EOL": "093556",
    "UNIDADE": "EMEF - FELICIO PAGLIUSO, PROF.",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "26.312,40",
    "BAS_2PAR": "26.312,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.312,40",
    "REC_EXTRA2": "157.874,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "247.811,60"
  },
  {
    "EOL": "093564",
    "UNIDADE": "EMEF - JOAQUIM OSORIO DUQUE ESTRADA",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "23.045,40",
    "BAS_2PAR": "23.045,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.045,40",
    "REC_EXTRA2": "138.272,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "218.408,60"
  },
  {
    "EOL": "093572",
    "UNIDADE": "EMEF - COELHO NETO",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "37.020,20",
    "BAS_2PAR": "32.020,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "32.020,20",
    "REC_EXTRA2": "192.121,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "304.181,80"
  },
  {
    "EOL": "093581",
    "UNIDADE": "EMEF - PLINIO DE QUEIROZ",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "25.578,00",
    "BAS_2PAR": "25.578,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.578,00",
    "REC_EXTRA2": "153.468,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "241.202,00"
  },
  {
    "EOL": "093599",
    "UNIDADE": "EMEF - JOSE MARIA WHITAKER",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "28.585,80",
    "BAS_2PAR": "28.585,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "28.585,80",
    "REC_EXTRA2": "171.514,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "270.272,20"
  },
  {
    "EOL": "093602",
    "UNIDADE": "EMEF - RODRIGO MELLO FRANCO DE ANDRADE",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "23.088,60",
    "BAS_2PAR": "23.088,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.088,60",
    "REC_EXTRA2": "138.531,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "218.797,40"
  },
  {
    "EOL": "093611",
    "UNIDADE": "EMEF - RIVADAVIA MARQUES JUNIOR, PROF.",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "23.396,40",
    "BAS_2PAR": "23.396,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.396,40",
    "REC_EXTRA2": "140.378,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "221.567,60"
  },
  {
    "EOL": "093629",
    "UNIDADE": "EMEF - AURELIO ARROBAS MARTINS, PROF.",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "22.537,80",
    "BAS_2PAR": "22.537,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.537,80",
    "REC_EXTRA2": "135.226,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "211.840,20"
  },
  {
    "EOL": "093637",
    "UNIDADE": "EMEFM - OSWALDO ARANHA BANDEIRA DE MELLO",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "32.117,40",
    "BAS_2PAR": "32.117,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "32.117,40",
    "REC_EXTRA2": "192.704,40",
    "EM": "30.000,00",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "332.056,60"
  },
  {
    "EOL": "093645",
    "UNIDADE": "EMEF - MAURICIO GOULART",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "23.320,80",
    "BAS_2PAR": "23.320,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.320,80",
    "REC_EXTRA2": "139.924,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "220.887,20"
  },
  {
    "EOL": "093653",
    "UNIDADE": "EMEF - VLADIMIR HERZOG",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "33.305,00",
    "BAS_2PAR": "28.305,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "28.305,00",
    "REC_EXTRA2": "169.830,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "268.745,00"
  },
  {
    "EOL": "093661",
    "UNIDADE": "EMEF - OCTAVIO MANGABEIRA",
    "DRE": "PENHA",
    "BAS_1PAR": "20.647,50",
    "BAS_2PAR": "20.647,50",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.647,50",
    "REC_EXTRA2": "123.886,50",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "194.829,00"
  },
  {
    "EOL": "093670",
    "UNIDADE": "EMEF - AMADEU AMARAL",
    "DRE": "PENHA",
    "BAS_1PAR": "22.537,80",
    "BAS_2PAR": "22.537,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.537,80",
    "REC_EXTRA2": "135.226,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "211.840,20"
  },
  {
    "EOL": "093688",
    "UNIDADE": "EMEF - PEDRO ALEIXO, DR.",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "20.836,80",
    "BAS_2PAR": "20.836,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.836,80",
    "REC_EXTRA2": "125.020,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "196.531,20"
  },
  {
    "EOL": "093696",
    "UNIDADE": "EMEF - EPITACIO PESSOA, PRES.",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "25.216,20",
    "BAS_2PAR": "25.216,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.216,20",
    "REC_EXTRA2": "151.297,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "237.945,80"
  },
  {
    "EOL": "093700",
    "UNIDADE": "EMEF - RAUL PILLA",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "20.863,80",
    "BAS_2PAR": "20.863,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.863,80",
    "REC_EXTRA2": "125.182,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "196.774,20"
  },
  {
    "EOL": "093718",
    "UNIDADE": "EMEF - ANTONIO DUARTE DE ALMEIDA, PROF.",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "34.115,00",
    "BAS_2PAR": "29.115,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "29.115,00",
    "REC_EXTRA2": "174.690,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "278.035,00"
  },
  {
    "EOL": "093726",
    "UNIDADE": "EMEF - PEDRO TEIXEIRA",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "34.613,60",
    "BAS_2PAR": "29.613,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "29.613,60",
    "REC_EXTRA2": "177.681,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "282.522,40"
  },
  {
    "EOL": "093734",
    "UNIDADE": "EMEF - ANTONIO CARLOS DE ANDRADA E SILVA",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "23.050,80",
    "BAS_2PAR": "23.050,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.050,80",
    "REC_EXTRA2": "138.304,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "218.457,20"
  },
  {
    "EOL": "093742",
    "UNIDADE": "EMEF - LUIS SAIA, ARQ.",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "25.918,20",
    "BAS_2PAR": "25.918,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.918,20",
    "REC_EXTRA2": "155.509,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "244.263,80"
  },
  {
    "EOL": "093751",
    "UNIDADE": "EMEF - PAULO ROLIM LOUREIRO, D.",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "40.416,80",
    "BAS_2PAR": "35.416,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "35.416,80",
    "REC_EXTRA2": "212.500,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "336.751,20"
  },
  {
    "EOL": "093769",
    "UNIDADE": "EMEF - PEDRO DE FRONTIN, ALM.",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "23.580,00",
    "BAS_2PAR": "23.580,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.580,00",
    "REC_EXTRA2": "141.480,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "223.220,00"
  },
  {
    "EOL": "093777",
    "UNIDADE": "EMEF - FERNANDO DE AZEVEDO, PROF.",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "33.321,20",
    "BAS_2PAR": "28.321,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "28.321,20",
    "REC_EXTRA2": "169.927,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "268.890,80"
  },
  {
    "EOL": "093785",
    "UNIDADE": "EMEF - JOAO RIBEIRO DE BARROS",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "29.881,80",
    "BAS_2PAR": "29.881,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "29.881,80",
    "REC_EXTRA2": "179.290,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "281.936,20"
  },
  {
    "EOL": "093793",
    "UNIDADE": "EMEF - JOSE DE ANCHIETA, PE.",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "33.353,60",
    "BAS_2PAR": "28.353,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "28.353,60",
    "REC_EXTRA2": "170.121,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "269.182,40"
  },
  {
    "EOL": "093807",
    "UNIDADE": "EMEF - JOAO AUGUSTO BREVES, DR.",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "36.707,00",
    "BAS_2PAR": "31.707,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "31.707,00",
    "REC_EXTRA2": "190.242,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "301.363,00"
  },
  {
    "EOL": "093815",
    "UNIDADE": "EMEF - ALEXANDRE DE GUSMAO",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "25.853,40",
    "BAS_2PAR": "25.853,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.853,40",
    "REC_EXTRA2": "155.120,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "243.680,60"
  },
  {
    "EOL": "093823",
    "UNIDADE": "EMEF - HENRIQUE FELIPE DA COSTA - HENRICAO",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "23.261,40",
    "BAS_2PAR": "23.261,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.261,40",
    "REC_EXTRA2": "139.568,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "220.352,60"
  },
  {
    "EOL": "093831",
    "UNIDADE": "EMEF - ANTONIA E ARTUR BEGBIE",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "26.598,60",
    "BAS_2PAR": "26.598,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.598,60",
    "REC_EXTRA2": "159.591,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "250.387,40"
  },
  {
    "EOL": "093840",
    "UNIDADE": "EMEF - NEWTON REIS, GEN.",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "26.523,00",
    "BAS_2PAR": "26.523,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.523,00",
    "REC_EXTRA2": "159.138,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "249.707,00"
  },
  {
    "EOL": "093858",
    "UNIDADE": "EMEF - MARIA IMILDA DO SANTISSIMO SACRAMENTO, ME.",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "23.277,60",
    "BAS_2PAR": "23.277,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.277,60",
    "REC_EXTRA2": "139.665,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "220.498,40"
  },
  {
    "EOL": "093866",
    "UNIDADE": "EMEF - RAIMUNDO CORREIA",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "23.013,00",
    "BAS_2PAR": "23.013,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.013,00",
    "REC_EXTRA2": "138.078,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "216.117,00"
  },
  {
    "EOL": "093874",
    "UNIDADE": "EMEF - JOSE AMERICO DE ALMEIDA",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "25.842,60",
    "BAS_2PAR": "25.842,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.842,60",
    "REC_EXTRA2": "155.055,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "243.583,40"
  },
  {
    "EOL": "093882",
    "UNIDADE": "EMEF - JOSE BENTO DE ASSIS, PROF.",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "25.578,00",
    "BAS_2PAR": "25.578,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.578,00",
    "REC_EXTRA2": "153.468,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "241.202,00"
  },
  {
    "EOL": "093891",
    "UNIDADE": "EMEF - ARMANDO CRIDEY RIGHETTI",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "23.574,60",
    "BAS_2PAR": "23.574,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.574,60",
    "REC_EXTRA2": "141.447,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "223.171,40"
  },
  {
    "EOL": "093904",
    "UNIDADE": "EMEF - CAPISTRANO DE ABREU",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "26.539,20",
    "BAS_2PAR": "26.539,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.539,20",
    "REC_EXTRA2": "159.235,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "249.852,80"
  },
  {
    "EOL": "093912",
    "UNIDADE": "EMEF - CARLOS PASQUALE, PROF.",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "32.317,20",
    "BAS_2PAR": "32.317,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "32.317,20",
    "REC_EXTRA2": "193.903,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "10.000,00",
    "TOTAL": "305.854,80"
  },
  {
    "EOL": "093921",
    "UNIDADE": "EMEF - EZEQUIEL RAMOS JUNIOR",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "36.834,80",
    "BAS_2PAR": "31.834,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "31.834,80",
    "REC_EXTRA2": "191.008,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "302.513,20"
  },
  {
    "EOL": "093939",
    "UNIDADE": "EMEF - SUD MENNUCCI",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "29.223,00",
    "BAS_2PAR": "29.223,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "29.223,00",
    "REC_EXTRA2": "175.338,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "276.007,00"
  },
  {
    "EOL": "093947",
    "UNIDADE": "EMEF - OTHELO FRANCO, GEN.",
    "DRE": "PENHA",
    "BAS_1PAR": "19.897,20",
    "BAS_2PAR": "19.897,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "19.897,20",
    "REC_EXTRA2": "119.383,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "188.074,80"
  },
  {
    "EOL": "093955",
    "UNIDADE": "EMEF - JACKSON DE FIGUEIREDO",
    "DRE": "PENHA",
    "BAS_1PAR": "22.818,60",
    "BAS_2PAR": "22.818,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.818,60",
    "REC_EXTRA2": "136.911,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "214.367,40"
  },
  {
    "EOL": "093963",
    "UNIDADE": "EMEF - JOAO PINHEIRO, PRES.",
    "DRE": "PENHA",
    "BAS_1PAR": "25.216,20",
    "BAS_2PAR": "25.216,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.216,20",
    "REC_EXTRA2": "151.297,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "237.945,80"
  },
  {
    "EOL": "093971",
    "UNIDADE": "EMEF - 19 DE NOVEMBRO",
    "DRE": "PENHA",
    "BAS_1PAR": "20.518,20",
    "BAS_2PAR": "20.518,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.518,20",
    "REC_EXTRA2": "123.109,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "193.663,80"
  },
  {
    "EOL": "093980",
    "UNIDADE": "EMEF - FIRMINO TIBURCIO DA COSTA",
    "DRE": "PENHA",
    "BAS_1PAR": "20.334,60",
    "BAS_2PAR": "20.334,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.334,60",
    "REC_EXTRA2": "122.007,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "192.011,40"
  },
  {
    "EOL": "093998",
    "UNIDADE": "EMEF - ABRAO DE MORAES, PROF.",
    "DRE": "PENHA",
    "BAS_1PAR": "20.404,80",
    "BAS_2PAR": "20.404,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.404,80",
    "REC_EXTRA2": "122.428,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "192.643,20"
  },
  {
    "EOL": "094005",
    "UNIDADE": "EMEF - HUMBERTO DE CAMPOS",
    "DRE": "PENHA",
    "BAS_1PAR": "29.933,60",
    "BAS_2PAR": "24.933,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "24.933,60",
    "REC_EXTRA2": "149.601,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "238.402,40"
  },
  {
    "EOL": "094013",
    "UNIDADE": "EMEF - JOSE BONIFACIO",
    "DRE": "PENHA",
    "BAS_1PAR": "18.136,80",
    "BAS_2PAR": "18.136,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "18.136,80",
    "REC_EXTRA2": "108.820,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "172.231,20"
  },
  {
    "EOL": "094021",
    "UNIDADE": "EMEF - CAIRU, VISC. DE",
    "DRE": "PENHA",
    "BAS_1PAR": "18.072,00",
    "BAS_2PAR": "18.072,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "18.072,00",
    "REC_EXTRA2": "108.432,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "171.648,00"
  },
  {
    "EOL": "094030",
    "UNIDADE": "EMEF - ARTHUR ALVIM",
    "DRE": "PENHA",
    "BAS_1PAR": "22.753,80",
    "BAS_2PAR": "22.753,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.753,80",
    "REC_EXTRA2": "136.522,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "213.784,20"
  },
  {
    "EOL": "094048",
    "UNIDADE": "EMEF - ANTONIO VIEIRA, PE.",
    "DRE": "PENHA",
    "BAS_1PAR": "22.678,20",
    "BAS_2PAR": "22.678,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.678,20",
    "REC_EXTRA2": "136.069,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "213.103,80"
  },
  {
    "EOL": "094056",
    "UNIDADE": "EMEF - JENNY GOMES, DA.",
    "DRE": "PENHA",
    "BAS_1PAR": "23.391,00",
    "BAS_2PAR": "23.391,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.391,00",
    "REC_EXTRA2": "140.346,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "221.519,00"
  },
  {
    "EOL": "094064",
    "UNIDADE": "EMEF - 8 DE MAIO",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "22.818,60",
    "BAS_2PAR": "22.818,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.818,60",
    "REC_EXTRA2": "136.911,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "214.367,40"
  },
  {
    "EOL": "094072",
    "UNIDADE": "EMEF - WANNY SALGADO ROCHA, PROFA.",
    "DRE": "PENHA",
    "BAS_1PAR": "22.845,60",
    "BAS_2PAR": "22.845,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.845,60",
    "REC_EXTRA2": "137.073,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "214.610,40"
  },
  {
    "EOL": "094081",
    "UNIDADE": "EMEF - HENRIQUE PEGADO, PROF.",
    "DRE": "PENHA",
    "BAS_1PAR": "30.772,40",
    "BAS_2PAR": "25.772,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.772,40",
    "REC_EXTRA2": "154.634,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "245.951,60"
  },
  {
    "EOL": "094099",
    "UNIDADE": "EMEF - JUAREZ TAVORA, MAL.",
    "DRE": "PENHA",
    "BAS_1PAR": "22.953,60",
    "BAS_2PAR": "22.953,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.953,60",
    "REC_EXTRA2": "137.721,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "215.582,40"
  },
  {
    "EOL": "094102",
    "UNIDADE": "EMEF - FRANCISCO DE MONT' ALVERNE, FREI",
    "DRE": "PENHA",
    "BAS_1PAR": "36.514,00",
    "BAS_2PAR": "26.514,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.514,00",
    "REC_EXTRA2": "159.084,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "257.626,00"
  },
  {
    "EOL": "094111",
    "UNIDADE": "EMEF - LUIS WASHINGTON VITA",
    "DRE": "PENHA",
    "BAS_1PAR": "22.721,40",
    "BAS_2PAR": "22.721,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.721,40",
    "REC_EXTRA2": "136.328,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "213.492,60"
  },
  {
    "EOL": "094129",
    "UNIDADE": "EMEF - GUILHERME DE ALMEIDA",
    "DRE": "PENHA",
    "BAS_1PAR": "23.563,80",
    "BAS_2PAR": "23.563,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.563,80",
    "REC_EXTRA2": "141.382,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "223.074,20"
  },
  {
    "EOL": "094137",
    "UNIDADE": "EMEF - CECILIA MEIRELES",
    "DRE": "PENHA",
    "BAS_1PAR": "25.572,60",
    "BAS_2PAR": "25.572,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.572,60",
    "REC_EXTRA2": "153.435,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "241.153,40"
  },
  {
    "EOL": "094145",
    "UNIDADE": "EMEF - EDGARD CAVALHEIRO",
    "DRE": "PENHA",
    "BAS_1PAR": "26.020,80",
    "BAS_2PAR": "26.020,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.020,80",
    "REC_EXTRA2": "156.124,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "245.187,20"
  },
  {
    "EOL": "094153",
    "UNIDADE": "EMEF - ARTHUR AZEVEDO",
    "DRE": "PENHA",
    "BAS_1PAR": "20.691,00",
    "BAS_2PAR": "20.691,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.691,00",
    "REC_EXTRA2": "124.146,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "195.219,00"
  },
  {
    "EOL": "094161",
    "UNIDADE": "EMEF - NILO PECANHA, PRES.",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "27.028,40",
    "BAS_2PAR": "22.028,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.028,40",
    "REC_EXTRA2": "132.170,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "212.255,60"
  },
  {
    "EOL": "094170",
    "UNIDADE": "EMEF - PLINIO AYROSA",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "23.337,00",
    "BAS_2PAR": "23.337,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.337,00",
    "REC_EXTRA2": "140.022,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "221.033,00"
  },
  {
    "EOL": "094188",
    "UNIDADE": "EMEF - JOANINHA GRASSI FAGUNDES, PROFA.",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "20.583,00",
    "BAS_2PAR": "20.583,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.583,00",
    "REC_EXTRA2": "123.498,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "194.247,00"
  },
  {
    "EOL": "094196",
    "UNIDADE": "EMEF - ERICO VERISSIMO",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "20.664,00",
    "BAS_2PAR": "20.664,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.664,00",
    "REC_EXTRA2": "123.984,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "194.976,00"
  },
  {
    "EOL": "094200",
    "UNIDADE": "EMEF - MILTOM CAMPOS, SEN.",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "22.629,60",
    "BAS_2PAR": "22.629,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.629,60",
    "REC_EXTRA2": "135.777,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "212.666,40"
  },
  {
    "EOL": "094218",
    "UNIDADE": "EMEF - ANDRE RODRIGUES DE ALCKMIN, PROF.",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "26.285,40",
    "BAS_2PAR": "26.285,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.285,40",
    "REC_EXTRA2": "157.712,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "247.568,60"
  },
  {
    "EOL": "094226",
    "UNIDADE": "EMEF - RAUL FERNANDES, EMB.",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "22.591,80",
    "BAS_2PAR": "22.591,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.591,80",
    "REC_EXTRA2": "135.550,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "212.326,20"
  },
  {
    "EOL": "094234",
    "UNIDADE": "EMEF - THEO DUTRA",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "23.455,80",
    "BAS_2PAR": "23.455,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.455,80",
    "REC_EXTRA2": "140.734,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "222.102,20"
  },
  {
    "EOL": "094242",
    "UNIDADE": "EMEF - ANTONIO PRUDENTE, PROF.",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "20.512,50",
    "BAS_2PAR": "20.512,50",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.512,50",
    "REC_EXTRA2": "123.076,50",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "193.614,00"
  },
  {
    "EOL": "094251",
    "UNIDADE": "EMEF - SEBASTIAO NOGUEIRA DE LIMA, DES.",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "25.896,60",
    "BAS_2PAR": "25.896,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.896,60",
    "REC_EXTRA2": "155.379,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "244.069,40"
  },
  {
    "EOL": "094269",
    "UNIDADE": "EMEF - CASTRO ALVES",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "36.550,40",
    "BAS_2PAR": "31.550,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "31.550,40",
    "REC_EXTRA2": "189.302,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "299.953,60"
  },
  {
    "EOL": "094277",
    "UNIDADE": "EMEF - JOAO AMOS COMENIUS",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "25.750,80",
    "BAS_2PAR": "25.750,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.750,80",
    "REC_EXTRA2": "154.504,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "242.757,20"
  },
  {
    "EOL": "094285",
    "UNIDADE": "EMEF - RAUL DE LEONI",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "37.293,40",
    "BAS_2PAR": "27.293,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "27.293,40",
    "REC_EXTRA2": "163.760,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "264.640,60"
  },
  {
    "EOL": "094293",
    "UNIDADE": "EMEF - OSVALDO QUIRINO SIMOES",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "26.236,80",
    "BAS_2PAR": "26.236,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.236,80",
    "REC_EXTRA2": "157.420,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "247.131,20"
  },
  {
    "EOL": "094307",
    "UNIDADE": "EMEF - FREDERICO GUSTAVO DOS SANTOS, TTE. AVIADOR",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "25.578,00",
    "BAS_2PAR": "25.578,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.578,00",
    "REC_EXTRA2": "153.468,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "241.202,00"
  },
  {
    "EOL": "094315",
    "UNIDADE": "EMEF - PEDRO AMERICO",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "20.847,60",
    "BAS_2PAR": "20.847,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.847,60",
    "REC_EXTRA2": "125.085,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "196.628,40"
  },
  {
    "EOL": "094323",
    "UNIDADE": "EMEF - ELIAS DE SIQUEIRA CAVALCANTI, DR.",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "20.858,40",
    "BAS_2PAR": "20.858,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.858,40",
    "REC_EXTRA2": "125.150,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "196.725,60"
  },
  {
    "EOL": "094331",
    "UNIDADE": "EMEF - HUMBERTO DANTAS",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "22.813,20",
    "BAS_2PAR": "22.813,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.813,20",
    "REC_EXTRA2": "136.879,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "214.318,80"
  },
  {
    "EOL": "094340",
    "UNIDADE": "EMEF - AROLDO DE AZEVEDO, PROF.",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "33.164,60",
    "BAS_2PAR": "28.164,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "28.164,60",
    "REC_EXTRA2": "168.987,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "267.481,40"
  },
  {
    "EOL": "094358",
    "UNIDADE": "EMEF - ANGELINA MAFFEI VITA, DA.",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "26.280,00",
    "BAS_2PAR": "26.280,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.280,00",
    "REC_EXTRA2": "157.680,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "247.520,00"
  },
  {
    "EOL": "094366",
    "UNIDADE": "EMEF - GARCIA D'AVILA, CTE.",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "23.320,80",
    "BAS_2PAR": "23.320,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.320,80",
    "REC_EXTRA2": "139.924,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "220.887,20"
  },
  {
    "EOL": "094374",
    "UNIDADE": "EMEF CEU - TEOTONIO VILELA, SEN.",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "39.905,20",
    "BAS_2PAR": "29.905,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "29.905,20",
    "REC_EXTRA2": "179.431,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "290.146,80"
  },
  {
    "EOL": "094382",
    "UNIDADE": "EMEF - CECILIA MORAES DE VASCONCELOS, PROFA.",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "26.323,20",
    "BAS_2PAR": "26.323,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.323,20",
    "REC_EXTRA2": "157.939,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "247.908,80"
  },
  {
    "EOL": "094391",
    "UNIDADE": "EMEF - MARIO KOSEL FILHO",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "",
    "BAS_2PAR": "",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.664,00",
    "REC_EXTRA2": "123.984,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "153.648,00"
  },
  {
    "EOL": "094404",
    "UNIDADE": "EMEF - MONTEIRO LOBATO",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "26.555,40",
    "BAS_2PAR": "26.555,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.555,40",
    "REC_EXTRA2": "159.332,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "249.998,60"
  },
  {
    "EOL": "094412",
    "UNIDADE": "EMEF - GABRIEL PRESTES, PROF.",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "20.610,00",
    "BAS_2PAR": "20.610,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.610,00",
    "REC_EXTRA2": "123.660,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "194.490,00"
  },
  {
    "EOL": "094421",
    "UNIDADE": "EMEF - LIBERATO BITTENCOURT, GEN.",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "25.410,60",
    "BAS_2PAR": "25.410,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.410,60",
    "REC_EXTRA2": "152.463,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "239.695,40"
  },
  {
    "EOL": "094439",
    "UNIDADE": "EMEF - LEOPOLDINA, IMP.",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "28.753,20",
    "BAS_2PAR": "28.753,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "28.753,20",
    "REC_EXTRA2": "172.519,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "271.778,80"
  },
  {
    "EOL": "094447",
    "UNIDADE": "EMEF - RENATO ANTONIO CHECCHIA, PROF.",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "25.410,60",
    "BAS_2PAR": "25.410,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.410,60",
    "REC_EXTRA2": "152.463,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "239.695,40"
  },
  {
    "EOL": "094455",
    "UNIDADE": "EMEF - VICENTE DE PAULO DALE COUTINHO, GEN.",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "35.326,40",
    "BAS_2PAR": "30.326,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "30.326,40",
    "REC_EXTRA2": "181.958,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "288.937,60"
  },
  {
    "EOL": "094463",
    "UNIDADE": "EMEF - LEONEL FRANCA, PE.",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "26.161,20",
    "BAS_2PAR": "26.161,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.161,20",
    "REC_EXTRA2": "156.967,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "246.450,80"
  },
  {
    "EOL": "094471",
    "UNIDADE": "EMEF - LUIZ DAVID SOBRINHO, PROF.",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "23.509,50",
    "BAS_2PAR": "23.509,50",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.509,50",
    "REC_EXTRA2": "141.058,50",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "222.587,00"
  },
  {
    "EOL": "094480",
    "UNIDADE": "EMEF - JULIO DE OLIVEIRA",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "23.272,20",
    "BAS_2PAR": "23.272,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.272,20",
    "REC_EXTRA2": "139.633,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "220.449,80"
  },
  {
    "EOL": "094498",
    "UNIDADE": "EMEF - CANDIDO PORTINARI",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "35.360,60",
    "BAS_2PAR": "30.360,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "30.360,60",
    "REC_EXTRA2": "182.163,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "289.245,40"
  },
  {
    "EOL": "094501",
    "UNIDADE": "EMEF - PAULO PRADO",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "26.501,40",
    "BAS_2PAR": "26.501,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.501,40",
    "REC_EXTRA2": "159.008,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "249.512,60"
  },
  {
    "EOL": "094510",
    "UNIDADE": "EMEF - HENRIQUE RAYMUNDO DYOTT FONTENELLE, BRIG.",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "20.750,40",
    "BAS_2PAR": "20.750,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.750,40",
    "REC_EXTRA2": "124.502,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "195.753,60"
  },
  {
    "EOL": "094528",
    "UNIDADE": "EMEF - HENRIQUE GEISEL, GEN.",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "20.593,80",
    "BAS_2PAR": "20.593,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.593,80",
    "REC_EXTRA2": "123.562,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "194.344,20"
  },
  {
    "EOL": "094536",
    "UNIDADE": "EMEF - JAIRO RAMOS",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "23.299,20",
    "BAS_2PAR": "23.299,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.299,20",
    "REC_EXTRA2": "139.795,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "220.692,80"
  },
  {
    "EOL": "094544",
    "UNIDADE": "EMEF - RUI BLOEM",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "20.367,00",
    "BAS_2PAR": "20.367,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.367,00",
    "REC_EXTRA2": "122.202,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "192.303,00"
  },
  {
    "EOL": "094552",
    "UNIDADE": "EMEF - AMADEU MENDES, PROF.",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "20.302,20",
    "BAS_2PAR": "20.302,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.302,20",
    "REC_EXTRA2": "121.813,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "191.719,80"
  },
  {
    "EOL": "094561",
    "UNIDADE": "EMEF - EUCLIDES CUSTODIO DA SILVEIRA, DES.",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "20.313,00",
    "BAS_2PAR": "20.313,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.313,00",
    "REC_EXTRA2": "121.878,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "191.817,00"
  },
  {
    "EOL": "094579",
    "UNIDADE": "EMEF - JOSE FERRAZ DE CAMPOS, PROF.",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "20.750,40",
    "BAS_2PAR": "20.750,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.750,40",
    "REC_EXTRA2": "124.502,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "195.753,60"
  },
  {
    "EOL": "094587",
    "UNIDADE": "EMEF - GABRIEL SYLVESTRE TEIXEIRA DE CARVALHO, PROF.",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "20.361,60",
    "BAS_2PAR": "20.361,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.361,60",
    "REC_EXTRA2": "122.169,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "192.254,40"
  },
  {
    "EOL": "094595",
    "UNIDADE": "EMEF - SILVIO PORTUGAL, DES.",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "25.448,40",
    "BAS_2PAR": "25.448,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.448,40",
    "REC_EXTRA2": "152.690,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "240.035,60"
  },
  {
    "EOL": "094609",
    "UNIDADE": "EMEFM - ANTONIO ALVES VERISSIMO",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "37.878,80",
    "BAS_2PAR": "32.878,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "32.878,80",
    "REC_EXTRA2": "197.272,80",
    "EM": "20.000,00",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "331.909,20"
  },
  {
    "EOL": "094617",
    "UNIDADE": "EMEF - FARIA LIMA, BRIG.",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "33.776,20",
    "BAS_2PAR": "23.776,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.776,20",
    "REC_EXTRA2": "142.657,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "232.985,80"
  },
  {
    "EOL": "094625",
    "UNIDADE": "EMEF - CAXIAS, DUQUE DE",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "25.470,00",
    "BAS_2PAR": "25.470,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.470,00",
    "REC_EXTRA2": "152.820,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "240.230,00"
  },
  {
    "EOL": "094633",
    "UNIDADE": "EMEF - CELSO LEITE RIBEIRO FILHO",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "25.864,20",
    "BAS_2PAR": "25.864,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.864,20",
    "REC_EXTRA2": "155.185,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "243.777,80"
  },
  {
    "EOL": "094641",
    "UNIDADE": "EMEF - HENRIQUE, INFANTE D.",
    "DRE": "PENHA",
    "BAS_1PAR": "33.218,60",
    "BAS_2PAR": "28.218,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "28.218,60",
    "REC_EXTRA2": "169.311,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "267.967,40"
  },
  {
    "EOL": "094668",
    "UNIDADE": "EMEFM - DERVILLE ALLEGRETTI, PROF.",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "28.947,60",
    "BAS_2PAR": "28.947,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "28.947,60",
    "REC_EXTRA2": "173.685,60",
    "EM": "30.000,00",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "303.528,40"
  },
  {
    "EOL": "094676",
    "UNIDADE": "EMEF - ADOLPHO OTTO DE LAET, PROF.",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "30.768,40",
    "BAS_2PAR": "20.768,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.768,40",
    "REC_EXTRA2": "124.610,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "205.915,60"
  },
  {
    "EOL": "094684",
    "UNIDADE": "EMEF - MARCOS MELEGA",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "36.967,60",
    "BAS_2PAR": "26.967,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.967,60",
    "REC_EXTRA2": "161.805,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "263.708,40"
  },
  {
    "EOL": "094692",
    "UNIDADE": "EMEF - MARCILIO DIAS",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "23.142,60",
    "BAS_2PAR": "23.142,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.142,60",
    "REC_EXTRA2": "138.855,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "219.283,40"
  },
  {
    "EOL": "094706",
    "UNIDADE": "EMEF - GASTAO MOUTINHO, CTE.",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "20.523,60",
    "BAS_2PAR": "20.523,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.523,60",
    "REC_EXTRA2": "123.141,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "193.712,40"
  },
  {
    "EOL": "094714",
    "UNIDADE": "EMEF - NOE AZEVEDO, PROF.",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "22.807,80",
    "BAS_2PAR": "22.807,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.807,80",
    "REC_EXTRA2": "136.846,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "214.270,20"
  },
  {
    "EOL": "094722",
    "UNIDADE": "EMEF - JOAO RAMOS - PERNAMBUCO - ABOLICIONISTA",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "27.802,40",
    "BAS_2PAR": "22.802,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.802,40",
    "REC_EXTRA2": "136.814,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "219.221,60"
  },
  {
    "EOL": "094731",
    "UNIDADE": "EMEF - ESMERALDA SALLES PEREIRA RAMOS, PROFA.",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "33.726,20",
    "BAS_2PAR": "28.726,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "28.726,20",
    "REC_EXTRA2": "172.357,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "274.535,80"
  },
  {
    "EOL": "094749",
    "UNIDADE": "EMEF - MARTIN FRANCISCO RIBEIRO DE ANDRADA",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "31.541,00",
    "BAS_2PAR": "26.541,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.541,00",
    "REC_EXTRA2": "159.246,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "252.869,00"
  },
  {
    "EOL": "094757",
    "UNIDADE": "EMEF - RONDON, MAL.",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "25.210,80",
    "BAS_2PAR": "25.210,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.210,80",
    "REC_EXTRA2": "151.264,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "237.897,20"
  },
  {
    "EOL": "094765",
    "UNIDADE": "EMEF - MAXIMO DE MOURA SANTOS, PROF.",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "30.756,20",
    "BAS_2PAR": "25.756,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.756,20",
    "REC_EXTRA2": "154.537,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "245.805,80"
  },
  {
    "EOL": "094773",
    "UNIDADE": "EMEF - OLIVA IRENE BAYERLEIN SILVA",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "27.192,20",
    "BAS_2PAR": "22.192,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.192,20",
    "REC_EXTRA2": "133.153,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "2.000,00",
    "TOTAL": "211.729,80"
  },
  {
    "EOL": "094781",
    "UNIDADE": "EMEF - RUY BARBOSA",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "20.647,80",
    "BAS_2PAR": "20.647,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.647,80",
    "REC_EXTRA2": "123.886,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "194.830,20"
  },
  {
    "EOL": "094790",
    "UNIDADE": "EMEF - HELENA LOMBARDI BRAGA, PROFA.",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "33.126,80",
    "BAS_2PAR": "28.126,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "28.126,80",
    "REC_EXTRA2": "168.760,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "267.141,20"
  },
  {
    "EOL": "094803",
    "UNIDADE": "EMEF - ARY GOMES, CEL.",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "33.850,40",
    "BAS_2PAR": "28.850,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "28.850,40",
    "REC_EXTRA2": "173.102,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "275.653,60"
  },
  {
    "EOL": "094811",
    "UNIDADE": "EMEF - ROMAO GOMES, CEL.",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "",
    "BAS_2PAR": "",
    "REC_EXTRA": "",
    "BAS_3PAR": "28.623,60",
    "REC_EXTRA2": "171.741,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "213.365,20"
  },
  {
    "EOL": "094820",
    "UNIDADE": "EMEF - JOAO DOMINGUES SAMPAIO",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "31.816,40",
    "BAS_2PAR": "26.816,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.816,40",
    "REC_EXTRA2": "160.898,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "257.347,60"
  },
  {
    "EOL": "094838",
    "UNIDADE": "EMEF - PEDRO I, D.",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "34.782,40",
    "BAS_2PAR": "24.782,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "24.782,40",
    "REC_EXTRA2": "148.694,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "242.041,60"
  },
  {
    "EOL": "094846",
    "UNIDADE": "EMEF - TAMANDARE, ALM.",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "33.899,00",
    "BAS_2PAR": "28.899,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "28.899,00",
    "REC_EXTRA2": "173.394,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "276.091,00"
  },
  {
    "EOL": "094854",
    "UNIDADE": "EMEF - ENEAS CARVALHO DE AGUIAR",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "23.229,00",
    "BAS_2PAR": "23.229,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.229,00",
    "REC_EXTRA2": "139.374,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "220.061,00"
  },
  {
    "EOL": "094862",
    "UNIDADE": "EMEF - ALBERTO SANTOS DUMONT",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "38.283,40",
    "BAS_2PAR": "28.283,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "28.283,40",
    "REC_EXTRA2": "169.700,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "273.550,60"
  },
  {
    "EOL": "094871",
    "UNIDADE": "EMEF - JULIO MARCONDES SALGADO, GEN.",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "32.021,60",
    "BAS_2PAR": "27.021,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "27.021,60",
    "REC_EXTRA2": "162.129,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "259.194,40"
  },
  {
    "EOL": "094889",
    "UNIDADE": "EMEF - ANTONIO SANT'ANA GALVAO, FREI",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "27.154,40",
    "BAS_2PAR": "22.154,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.154,40",
    "REC_EXTRA2": "132.926,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "2.000,00",
    "TOTAL": "211.389,60"
  },
  {
    "EOL": "094897",
    "UNIDADE": "EMEF - OCTAVIO PEREIRA LOPES",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "39.713,00",
    "BAS_2PAR": "34.713,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "34.713,00",
    "REC_EXTRA2": "208.278,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "330.417,00"
  },
  {
    "EOL": "094901",
    "UNIDADE": "EMEF - EDSON RODRIGUES",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "22.678,20",
    "BAS_2PAR": "22.678,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.678,20",
    "REC_EXTRA2": "136.069,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "213.103,80"
  },
  {
    "EOL": "094919",
    "UNIDADE": "EMEF - LOURENCO FILHO",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "33.294,20",
    "BAS_2PAR": "28.294,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "28.294,20",
    "REC_EXTRA2": "169.765,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "268.647,80"
  },
  {
    "EOL": "094927",
    "UNIDADE": "EMEF - MARIA HELENA FARIA LIMA, PROFA.",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "26.031,60",
    "BAS_2PAR": "26.031,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.031,60",
    "REC_EXTRA2": "156.189,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "245.284,40"
  },
  {
    "EOL": "094935",
    "UNIDADE": "EMEF - FRANKLYN AUGUSTO DE MOURA CAMPOS, PROF.",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "31.130,20",
    "BAS_2PAR": "21.130,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "21.130,20",
    "REC_EXTRA2": "126.781,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "209.171,80"
  },
  {
    "EOL": "094943",
    "UNIDADE": "EMEF - RODRIGUES ALVES",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "20.242,80",
    "BAS_2PAR": "20.242,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.242,80",
    "REC_EXTRA2": "121.456,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "191.185,20"
  },
  {
    "EOL": "094951",
    "UNIDADE": "EMEF - SHIRLEY GUIO, PROFA.",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "34.280,20",
    "BAS_2PAR": "24.280,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "24.280,20",
    "REC_EXTRA2": "145.681,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "237.521,80"
  },
  {
    "EOL": "094960",
    "UNIDADE": "EMEF - JOSE MARIA LISBOA",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "20.745,00",
    "BAS_2PAR": "20.745,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.745,00",
    "REC_EXTRA2": "124.470,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "195.705,00"
  },
  {
    "EOL": "094978",
    "UNIDADE": "EMEF - EURICO GASPAR DUTRA, MAL.",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "23.115,60",
    "BAS_2PAR": "23.115,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.115,60",
    "REC_EXTRA2": "138.693,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "219.040,40"
  },
  {
    "EOL": "094986",
    "UNIDADE": "EMEF - SYLVIA MARTIN PIRES, PROFA.",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "20.712,60",
    "BAS_2PAR": "20.712,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.712,60",
    "REC_EXTRA2": "124.275,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "195.413,40"
  },
  {
    "EOL": "094994",
    "UNIDADE": "EMEF - OLAVO FONTOURA",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "26.199,00",
    "BAS_2PAR": "26.199,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.199,00",
    "REC_EXTRA2": "157.194,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "246.791,00"
  },
  {
    "EOL": "095001",
    "UNIDADE": "EMEF - JOAQUIM NABUCO",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "22.527,00",
    "BAS_2PAR": "22.527,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.527,00",
    "REC_EXTRA2": "135.162,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "211.743,00"
  },
  {
    "EOL": "095010",
    "UNIDADE": "EMEF - CASSIANO RICARDO",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "22.608,00",
    "BAS_2PAR": "22.608,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.608,00",
    "REC_EXTRA2": "135.648,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "212.472,00"
  },
  {
    "EOL": "095028",
    "UNIDADE": "EMEF - JOSE DO PATROCINIO",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "22.645,80",
    "BAS_2PAR": "22.645,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.645,80",
    "REC_EXTRA2": "135.874,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "212.812,20"
  },
  {
    "EOL": "095036",
    "UNIDADE": "EMEF - LEAO MACHADO, PROF.",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "20.777,40",
    "BAS_2PAR": "20.777,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.777,40",
    "REC_EXTRA2": "124.664,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "195.996,60"
  },
  {
    "EOL": "095044",
    "UNIDADE": "EMEF CEU - CAMPOS SALLES, PRES.",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "32.216,00",
    "BAS_2PAR": "27.216,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "27.216,00",
    "REC_EXTRA2": "163.296,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "260.944,00"
  },
  {
    "EOL": "095052",
    "UNIDADE": "EMEF - ANTONIO DE ALCANTARA MACHADO",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "20.043,00",
    "BAS_2PAR": "20.043,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.043,00",
    "REC_EXTRA2": "120.258,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "189.387,00"
  },
  {
    "EOL": "095061",
    "UNIDADE": "EMEF - FRANCISCO MEIRELLES, DES.",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "23.504,40",
    "BAS_2PAR": "23.504,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.504,40",
    "REC_EXTRA2": "141.026,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "222.539,60"
  },
  {
    "EOL": "095079",
    "UNIDADE": "EMEF - FABIO DA SILVA PRADO, DR.",
    "DRE": "PENHA",
    "BAS_1PAR": "22.986,00",
    "BAS_2PAR": "22.986,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.986,00",
    "REC_EXTRA2": "137.916,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "215.874,00"
  },
  {
    "EOL": "095087",
    "UNIDADE": "EMEF - SILVIO FLEMING, MJ.",
    "DRE": "PENHA",
    "BAS_1PAR": "20.685,50",
    "BAS_2PAR": "20.685,50",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.685,50",
    "REC_EXTRA2": "124.113,50",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "195.170,00"
  },
  {
    "EOL": "095095",
    "UNIDADE": "EMEF - EMILIANO DI CAVALCANTI",
    "DRE": "PENHA",
    "BAS_1PAR": "27.008,60",
    "BAS_2PAR": "22.008,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.008,60",
    "REC_EXTRA2": "132.051,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "2.000,00",
    "TOTAL": "210.077,40"
  },
  {
    "EOL": "095109",
    "UNIDADE": "EMEF - LEONOR MENDES DE BARROS",
    "DRE": "PENHA",
    "BAS_1PAR": "17.883,00",
    "BAS_2PAR": "17.883,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "17.883,00",
    "REC_EXTRA2": "107.298,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "169.947,00"
  },
  {
    "EOL": "095117",
    "UNIDADE": "EMEF - KENNEDY, PRES.",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "22.581,00",
    "BAS_2PAR": "22.581,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.581,00",
    "REC_EXTRA2": "135.486,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "212.229,00"
  },
  {
    "EOL": "095125",
    "UNIDADE": "EMEF - BARTOLOMEU LOURENCO DE GUSMAO",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "20.637,00",
    "BAS_2PAR": "20.637,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.637,00",
    "REC_EXTRA2": "123.822,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "194.733,00"
  },
  {
    "EOL": "095133",
    "UNIDADE": "EMEF - JOAO NAOKI SUMITA, DR.",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "20.863,80",
    "BAS_2PAR": "20.863,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.863,80",
    "REC_EXTRA2": "125.182,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "196.774,20"
  },
  {
    "EOL": "095141",
    "UNIDADE": "EMEF - VICENTINA RIBEIRO DA LUZ",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "20.696,40",
    "BAS_2PAR": "20.696,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.696,40",
    "REC_EXTRA2": "124.178,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "195.267,60"
  },
  {
    "EOL": "095150",
    "UNIDADE": "EMEF - ROQUETTE PINTO",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "22.786,20",
    "BAS_2PAR": "22.786,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.786,20",
    "REC_EXTRA2": "136.717,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "214.075,80"
  },
  {
    "EOL": "095168",
    "UNIDADE": "EMEF - ACHILLES DE OLIVEIRA RIBEIRO, DES.",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "23.013,00",
    "BAS_2PAR": "23.013,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.013,00",
    "REC_EXTRA2": "138.078,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "216.117,00"
  },
  {
    "EOL": "095176",
    "UNIDADE": "EMEF - JOSE LINS DO REGO",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "20.610,00",
    "BAS_2PAR": "20.610,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.610,00",
    "REC_EXTRA2": "123.660,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "194.490,00"
  },
  {
    "EOL": "095184",
    "UNIDADE": "EMEF - SERGIO MILLIET",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "22.559,40",
    "BAS_2PAR": "22.559,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.559,40",
    "REC_EXTRA2": "135.356,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "212.034,60"
  },
  {
    "EOL": "095192",
    "UNIDADE": "EMEF - GUIMARAES ROSA",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "26.704,40",
    "BAS_2PAR": "21.704,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "21.704,40",
    "REC_EXTRA2": "130.226,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "209.339,60"
  },
  {
    "EOL": "095206",
    "UNIDADE": "EMEF - CALOGERAS, MIN.",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "19.837,80",
    "BAS_2PAR": "19.837,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "19.837,80",
    "REC_EXTRA2": "119.026,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "187.540,20"
  },
  {
    "EOL": "095214",
    "UNIDADE": "EMEF - BERNARDO O'HIGGINS",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "23.175,00",
    "BAS_2PAR": "23.175,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.175,00",
    "REC_EXTRA2": "139.050,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "219.575,00"
  },
  {
    "EOL": "095222",
    "UNIDADE": "EMEF - ARY PARREIRAS, ALM.",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "20.566,80",
    "BAS_2PAR": "20.566,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.566,80",
    "REC_EXTRA2": "123.400,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "194.101,20"
  },
  {
    "EOL": "095231",
    "UNIDADE": "EMEF - MARINA VIEIRA DE CARVALHO MESQUITA",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "22.581,00",
    "BAS_2PAR": "22.581,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.581,00",
    "REC_EXTRA2": "135.486,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "212.229,00"
  },
  {
    "EOL": "095249",
    "UNIDADE": "EMEF - HABIB CARLOS KYRILLOS, DR.",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "22.786,20",
    "BAS_2PAR": "22.786,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.786,20",
    "REC_EXTRA2": "136.717,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "214.075,80"
  },
  {
    "EOL": "095257",
    "UNIDADE": "EMEF - ANTONIO DE SAMPAIO DORIA, PROF.",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "26.760,60",
    "BAS_2PAR": "26.760,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.760,60",
    "REC_EXTRA2": "160.563,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "251.845,40"
  },
  {
    "EOL": "095265",
    "UNIDADE": "EMEF - ARMANDO ARRUDA PEREIRA",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "23.455,80",
    "BAS_2PAR": "23.455,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.455,80",
    "REC_EXTRA2": "140.734,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "222.102,20"
  },
  {
    "EOL": "095273",
    "UNIDADE": "EMEF - CACILDA BECKER",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "26.393,40",
    "BAS_2PAR": "26.393,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.393,40",
    "REC_EXTRA2": "158.360,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "248.540,60"
  },
  {
    "EOL": "095281",
    "UNIDADE": "EMEF - NELSON PIMENTEL QUEIROZ, PROF.",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "22.937,40",
    "BAS_2PAR": "22.937,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.937,40",
    "REC_EXTRA2": "137.624,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "215.436,60"
  },
  {
    "EOL": "095290",
    "UNIDADE": "EMEF - JOAO CARLOS DA SILVA BORGES, PROF.",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "26.909,60",
    "BAS_2PAR": "21.909,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "21.909,60",
    "REC_EXTRA2": "131.457,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "211.186,40"
  },
  {
    "EOL": "095303",
    "UNIDADE": "EMEF - MANOEL DE PAIVA, PE.",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "29.096,60",
    "BAS_2PAR": "24.096,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "24.096,60",
    "REC_EXTRA2": "144.579,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "230.869,40"
  },
  {
    "EOL": "095311",
    "UNIDADE": "EMEF - JEAN MERMOZ",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "26.618,00",
    "BAS_2PAR": "21.618,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "21.618,00",
    "REC_EXTRA2": "129.708,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "208.562,00"
  },
  {
    "EOL": "095320",
    "UNIDADE": "EMEF - IRINEU MARINHO",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "17.845,20",
    "BAS_2PAR": "17.845,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "17.845,20",
    "REC_EXTRA2": "107.071,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "169.606,80"
  },
  {
    "EOL": "095338",
    "UNIDADE": "EMEF - RUTH LOPES ANDRADE, PROFA.",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "17.148,60",
    "BAS_2PAR": "17.148,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "17.148,60",
    "REC_EXTRA2": "102.891,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "2.000,00",
    "TOTAL": "161.337,40"
  },
  {
    "EOL": "095346",
    "UNIDADE": "EMEF - QUEIROZ FILHO, PROF.",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "23.580,00",
    "BAS_2PAR": "23.580,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.580,00",
    "REC_EXTRA2": "141.480,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "223.220,00"
  },
  {
    "EOL": "095354",
    "UNIDADE": "EMEF - OSORIO, GEN.",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "18.007,20",
    "BAS_2PAR": "18.007,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "18.007,20",
    "REC_EXTRA2": "108.043,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "171.064,80"
  },
  {
    "EOL": "095362",
    "UNIDADE": "EMEF - MAUA, BR. DE",
    "DRE": "PENHA",
    "BAS_1PAR": "19.972,80",
    "BAS_2PAR": "19.972,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "19.972,80",
    "REC_EXTRA2": "119.836,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "188.755,20"
  },
  {
    "EOL": "095371",
    "UNIDADE": "EMEF - CLEOMENES CAMPOS",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "22.770,00",
    "BAS_2PAR": "22.770,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.770,00",
    "REC_EXTRA2": "136.620,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "213.930,00"
  },
  {
    "EOL": "095389",
    "UNIDADE": "EMEF - AUREA RIBEIRO XAVIER LOPES, PROFA.",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "20.604,60",
    "BAS_2PAR": "20.604,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.604,60",
    "REC_EXTRA2": "123.627,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "194.441,40"
  },
  {
    "EOL": "095397",
    "UNIDADE": "EMEF - MARLENE RONDELLI, PROFA.",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "20.421,00",
    "BAS_2PAR": "20.421,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.421,00",
    "REC_EXTRA2": "122.526,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "192.789,00"
  },
  {
    "EOL": "095401",
    "UNIDADE": "EMEF - PRUDENTE DE MORAIS, PRES.",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "22.548,60",
    "BAS_2PAR": "22.548,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.548,60",
    "REC_EXTRA2": "135.291,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "211.937,40"
  },
  {
    "EOL": "095419",
    "UNIDADE": "EMEF - MASCARENHAS DE MORAES, MAL.",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "20.048,40",
    "BAS_2PAR": "20.048,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.048,40",
    "REC_EXTRA2": "120.290,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "189.435,60"
  },
  {
    "EOL": "095427",
    "UNIDADE": "EMEF - ALVARES DE AZEVEDO",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "22.635,00",
    "BAS_2PAR": "22.635,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.635,00",
    "REC_EXTRA2": "135.810,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "212.715,00"
  },
  {
    "EOL": "095435",
    "UNIDADE": "EMEF - ALTINO ARANTES",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "26.263,80",
    "BAS_2PAR": "26.263,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.263,80",
    "REC_EXTRA2": "157.582,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "247.374,20"
  },
  {
    "EOL": "095443",
    "UNIDADE": "EMEF - HENRIQUE MELEGA, PROF.",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "19.935,00",
    "BAS_2PAR": "19.935,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "19.935,00",
    "REC_EXTRA2": "119.610,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "188.415,00"
  },
  {
    "EOL": "095451",
    "UNIDADE": "EMEF - TAUNAY, VISC. DE (EXTINTA)",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "",
    "BAS_2PAR": "",
    "REC_EXTRA": "",
    "BAS_3PAR": "",
    "REC_EXTRA2": "",
    "EM": "",
    "SALA_LEITURA": "",
    "MAT_PEDAG": "",
    "TOTAL": ""
  },
  {
    "EOL": "095460",
    "UNIDADE": "EMEF - EMILIO RIBAS",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "20.010,60",
    "BAS_2PAR": "20.010,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.010,60",
    "REC_EXTRA2": "120.063,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "189.095,40"
  },
  {
    "EOL": "095478",
    "UNIDADE": "EMEF - FLORES DA CUNHA, DEP.",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "22.851,00",
    "BAS_2PAR": "22.851,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.851,00",
    "REC_EXTRA2": "137.106,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "214.659,00"
  },
  {
    "EOL": "095494",
    "UNIDADE": "EMEF - ARMANDO DE SALLES OLIVEIRA",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "23.202,00",
    "BAS_2PAR": "23.202,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.202,00",
    "REC_EXTRA2": "139.212,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "219.818,00"
  },
  {
    "EOL": "095508",
    "UNIDADE": "EMEF - BRASILIO MACHADO NETO",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "20.005,20",
    "BAS_2PAR": "20.005,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.005,20",
    "REC_EXTRA2": "120.031,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "189.046,80"
  },
  {
    "EOL": "095516",
    "UNIDADE": "EMEF - HERALDO BARBUY",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "22.662,00",
    "BAS_2PAR": "22.662,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.662,00",
    "REC_EXTRA2": "135.972,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "212.958,00"
  },
  {
    "EOL": "095524",
    "UNIDADE": "EMEF - VINICIUS DE MORAES",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "33.943,60",
    "BAS_2PAR": "23.943,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.943,60",
    "REC_EXTRA2": "143.661,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "234.492,40"
  },
  {
    "EOL": "095532",
    "UNIDADE": "EMEF - RODRIGUES DE CARVALHO",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "22.910,40",
    "BAS_2PAR": "22.910,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.910,40",
    "REC_EXTRA2": "137.462,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "215.193,60"
  },
  {
    "EOL": "095541",
    "UNIDADE": "EMEF - OLIVAL COSTA",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "22.840,20",
    "BAS_2PAR": "22.840,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.840,20",
    "REC_EXTRA2": "137.041,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "214.561,80"
  },
  {
    "EOL": "095559",
    "UNIDADE": "EMEF - CLAUDIO MANOEL DA COSTA",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "26.112,60",
    "BAS_2PAR": "26.112,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.112,60",
    "REC_EXTRA2": "156.675,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "246.013,40"
  },
  {
    "EOL": "095567",
    "UNIDADE": "EMEF - ALCEU AMOROSO LIMA",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "22.851,00",
    "BAS_2PAR": "22.851,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.851,00",
    "REC_EXTRA2": "137.106,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "214.659,00"
  },
  {
    "EOL": "095575",
    "UNIDADE": "EMEF - CIDADE DE OSAKA",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "23.439,60",
    "BAS_2PAR": "23.439,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.439,60",
    "REC_EXTRA2": "140.637,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "221.956,40"
  },
  {
    "EOL": "095583",
    "UNIDADE": "EMEF - GREGORIO WESTRUPP, PE.",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "20.534,40",
    "BAS_2PAR": "20.534,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.534,40",
    "REC_EXTRA2": "123.206,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "193.809,60"
  },
  {
    "EOL": "095591",
    "UNIDADE": "EMEF - IVETE VARGAS, DEP.",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "19.875,60",
    "BAS_2PAR": "19.875,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "19.875,60",
    "REC_EXTRA2": "119.253,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "187.880,40"
  },
  {
    "EOL": "095605",
    "UNIDADE": "EMEF - VILANOVA ARTIGAS, ARQ.",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "25.653,60",
    "BAS_2PAR": "25.653,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "25.653,60",
    "REC_EXTRA2": "153.921,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "241.882,40"
  },
  {
    "EOL": "095613",
    "UNIDADE": "EMEF - PAULO DUARTE",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "23.455,50",
    "BAS_2PAR": "23.455,50",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.455,50",
    "REC_EXTRA2": "140.734,50",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "222.101,00"
  },
  {
    "EOL": "097047",
    "UNIDADE": "EMEF - EDUCANDARIO DOM DUARTE, ANEXA AO",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "30.428,00",
    "BAS_2PAR": "20.428,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.428,00",
    "REC_EXTRA2": "122.569,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "2.000,00",
    "TOTAL": "200.853,00"
  },
  {
    "EOL": "097055",
    "UNIDADE": "EMEF - JUSCELINO KUBITSCHEK DE OLIVEIRA, PRES.",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "26.215,20",
    "BAS_2PAR": "26.215,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.215,20",
    "REC_EXTRA2": "157.291,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "246.936,80"
  },
  {
    "EOL": "097063",
    "UNIDADE": "EMEF - ANTONIO PEREIRA IGNACIO",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "18.115,20",
    "BAS_2PAR": "18.115,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "18.115,20",
    "REC_EXTRA2": "108.691,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "172.036,80"
  },
  {
    "EOL": "097071",
    "UNIDADE": "EMEI - KLAUS REINACH, ENG.",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "13.287,60",
    "BAS_2PAR": "13.287,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.287,60",
    "REC_EXTRA2": "79.725,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "151.588,40"
  },
  {
    "EOL": "097080",
    "UNIDADE": "EMEI - ELISA KAUFFMANN ABRAMOVICH",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "13.280,40",
    "BAS_2PAR": "13.280,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.280,40",
    "REC_EXTRA2": "79.682,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "151.523,60"
  },
  {
    "EOL": "097098",
    "UNIDADE": "EMEI - VALDIR AZEVEDO",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "14.767,20",
    "BAS_2PAR": "14.767,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.767,20",
    "REC_EXTRA2": "88.603,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "168.904,80"
  },
  {
    "EOL": "097101",
    "UNIDADE": "EMEI - TANCREDO NEVES, PRES.",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "15.073,20",
    "BAS_2PAR": "15.073,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "15.073,20",
    "REC_EXTRA2": "90.439,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "171.658,80"
  },
  {
    "EOL": "097144",
    "UNIDADE": "EMEI - PERO NETO",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "14.918,00",
    "BAS_2PAR": "14.918,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.918,00",
    "REC_EXTRA2": "89.510,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "170.264,00"
  },
  {
    "EOL": "097152",
    "UNIDADE": "EMEI - OSCAR PEDROSO HORTA",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "13.284,00",
    "BAS_2PAR": "13.284,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.284,00",
    "REC_EXTRA2": "79.704,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "151.556,00"
  },
  {
    "EOL": "097161",
    "UNIDADE": "EMEI - ZULEIKA PEREIRA LEITE, PROFA.",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "16.722,00",
    "BAS_2PAR": "16.722,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.722,00",
    "REC_EXTRA2": "100.332,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "190.498,00"
  },
  {
    "EOL": "097691",
    "UNIDADE": "EMEI - DILSON FUNARO",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "14.904,00",
    "BAS_2PAR": "14.904,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.904,00",
    "REC_EXTRA2": "89.424,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "170.136,00"
  },
  {
    "EOL": "097705",
    "UNIDADE": "EMEF - AURELIANO LEITE",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "17.413,00",
    "BAS_2PAR": "17.413,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "17.413,00",
    "REC_EXTRA2": "104.479,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "2.000,00",
    "TOTAL": "163.718,00"
  },
  {
    "EOL": "097713",
    "UNIDADE": "EMEF - JOSE PEDRO LEITE CORDEIRO, DR.",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "26.793,00",
    "BAS_2PAR": "26.793,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.793,00",
    "REC_EXTRA2": "160.758,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "252.137,00"
  },
  {
    "EOL": "097721",
    "UNIDADE": "EMEF - JOSE AUGUSTO CESAR SALGADO, DR.",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "23.455,80",
    "BAS_2PAR": "23.455,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.455,80",
    "REC_EXTRA2": "140.734,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "222.102,20"
  },
  {
    "EOL": "098515",
    "UNIDADE": "EMEI - ALBERT SABIN, DR.",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "13.111,20",
    "BAS_2PAR": "13.111,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.111,20",
    "REC_EXTRA2": "78.667,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "150.000,80"
  },
  {
    "EOL": "098604",
    "UNIDADE": "EMEI - CARVALHO PINTO, GOV.",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "14.929,20",
    "BAS_2PAR": "14.929,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.929,20",
    "REC_EXTRA2": "89.575,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "170.362,80"
  },
  {
    "EOL": "098884",
    "UNIDADE": "EMEI - ALIOMAR BALEEIRO, MIN.",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "16.826,40",
    "BAS_2PAR": "16.826,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.826,40",
    "REC_EXTRA2": "100.958,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "191.437,60"
  },
  {
    "EOL": "098931",
    "UNIDADE": "EMEI - OSVALDO CORDEIRO DE FARIAS, MAL.",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "16.646,40",
    "BAS_2PAR": "16.646,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.646,40",
    "REC_EXTRA2": "99.878,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "189.817,60"
  },
  {
    "EOL": "098949",
    "UNIDADE": "EMEI - CRUZ E SOUSA",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "18.622,80",
    "BAS_2PAR": "18.622,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.622,80",
    "REC_EXTRA2": "111.736,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "211.605,20"
  },
  {
    "EOL": "098957",
    "UNIDADE": "EMEI - ARLINDO VEIGA DOS SANTOS, PROF.",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "16.732,80",
    "BAS_2PAR": "16.732,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.732,80",
    "REC_EXTRA2": "100.396,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "190.595,20"
  },
  {
    "EOL": "098965",
    "UNIDADE": "EMEI - ODILIO DENYS, MAL.",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "13.237,20",
    "BAS_2PAR": "13.237,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.237,20",
    "REC_EXTRA2": "79.423,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "151.134,80"
  },
  {
    "EOL": "098973",
    "UNIDADE": "EMEI - 9 DE JULHO",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "16.790,40",
    "BAS_2PAR": "16.790,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.790,40",
    "REC_EXTRA2": "100.742,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "191.113,60"
  },
  {
    "EOL": "098981",
    "UNIDADE": "EMEI - LUIS GAMA",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "16.592,40",
    "BAS_2PAR": "16.592,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.592,40",
    "REC_EXTRA2": "99.554,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "189.331,60"
  },
  {
    "EOL": "098990",
    "UNIDADE": "EMEI - JONISE MAXIMO DA FONSECA, PROFA.",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "16.761,60",
    "BAS_2PAR": "16.761,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.761,60",
    "REC_EXTRA2": "100.569,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "190.854,40"
  },
  {
    "EOL": "099007",
    "UNIDADE": "EMEI - CHARBONNEAU, PE.",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "11.422,80",
    "BAS_2PAR": "11.422,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.422,80",
    "REC_EXTRA2": "68.536,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.805,20"
  },
  {
    "EOL": "099015",
    "UNIDADE": "EMEI - EURIPEDES SIMOES DE PAULA",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "14.922,00",
    "BAS_2PAR": "14.922,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.922,00",
    "REC_EXTRA2": "89.532,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "170.298,00"
  },
  {
    "EOL": "099023",
    "UNIDADE": "EMEI - CLYCIE MENDES CARNEIRO",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "13.269,50",
    "BAS_2PAR": "13.269,50",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.269,50",
    "REC_EXTRA2": "79.617,50",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "151.426,00"
  },
  {
    "EOL": "099031",
    "UNIDADE": "EMEI - MARIA JOSE GALVAO DE FRANCA PINTO, PROFA.",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "13.158,00",
    "BAS_2PAR": "13.158,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.158,00",
    "REC_EXTRA2": "78.948,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "150.422,00"
  },
  {
    "EOL": "099040",
    "UNIDADE": "EMEI - VANDA COELHO DE MORAES",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "15.102,00",
    "BAS_2PAR": "15.102,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "15.102,00",
    "REC_EXTRA2": "90.612,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "171.918,00"
  },
  {
    "EOL": "099058",
    "UNIDADE": "EMEI - ARACY DE ALMEIDA",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "16.628,40",
    "BAS_2PAR": "16.628,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.628,40",
    "REC_EXTRA2": "99.770,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "189.655,60"
  },
  {
    "EOL": "099066",
    "UNIDADE": "EMEI - DINAH SILVEIRA DE QUEIROZ",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "14.850,00",
    "BAS_2PAR": "14.850,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.850,00",
    "REC_EXTRA2": "89.100,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "169.650,00"
  },
  {
    "EOL": "099074",
    "UNIDADE": "EMEI - DANTON CASTILHO CABRAL",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "13.161,60",
    "BAS_2PAR": "13.161,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.161,60",
    "REC_EXTRA2": "78.969,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "150.454,40"
  },
  {
    "EOL": "099082",
    "UNIDADE": "EMEI - JOSE ALOYSIO RODRIGUES CORREA, PROF.",
    "DRE": "PENHA",
    "BAS_1PAR": "11.430,00",
    "BAS_2PAR": "11.430,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.430,00",
    "REC_EXTRA2": "68.580,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.870,00"
  },
  {
    "EOL": "099091",
    "UNIDADE": "EMEF - HIPOLITO JOSE DA COSTA",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "49.321,00",
    "BAS_2PAR": "39.321,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "39.321,00",
    "REC_EXTRA2": "235.926,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "10.000,00",
    "TOTAL": "378.889,00"
  },
  {
    "EOL": "099104",
    "UNIDADE": "EMEF - DEZOITO DO FORTE",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "19.935,00",
    "BAS_2PAR": "19.935,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "19.935,00",
    "REC_EXTRA2": "119.610,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "188.415,00"
  },
  {
    "EOL": "099112",
    "UNIDADE": "EMEF - PRACINHAS DA FEB",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "23.045,40",
    "BAS_2PAR": "23.045,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.045,40",
    "REC_EXTRA2": "138.272,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "218.408,60"
  },
  {
    "EOL": "099121",
    "UNIDADE": "EMEF CEU - DOMINGOS RUBINO, PROF.",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "36.449,20",
    "BAS_2PAR": "26.449,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.449,20",
    "REC_EXTRA2": "158.695,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "257.042,80"
  },
  {
    "EOL": "099139",
    "UNIDADE": "EMEF - VIRGILIO DE MELLO FRANCO",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "22.964,40",
    "BAS_2PAR": "22.964,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.964,40",
    "REC_EXTRA2": "137.786,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "215.679,60"
  },
  {
    "EOL": "099180",
    "UNIDADE": "EMEBS - ANNE SULLIVAN",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "18.945,00",
    "BAS_2PAR": "18.945,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.945,00",
    "REC_EXTRA2": "113.670,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "2.000,00",
    "TOTAL": "192.505,00"
  },
  {
    "EOL": "099210",
    "UNIDADE": "EMEI - CHARLES CHAPLIN",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "11.512,80",
    "BAS_2PAR": "11.512,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.512,80",
    "REC_EXTRA2": "69.076,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "131.615,20"
  },
  {
    "EOL": "099228",
    "UNIDADE": "EMEF - JOAO PEDRO DE CARVALHO NETO, DR.",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "27.775,00",
    "BAS_2PAR": "22.775,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.775,00",
    "REC_EXTRA2": "136.652,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "218.977,00"
  },
  {
    "EOL": "099236",
    "UNIDADE": "EMEF - MODESTO SCAGLIUSI",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "22.743,00",
    "BAS_2PAR": "22.743,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.743,00",
    "REC_EXTRA2": "136.458,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "213.687,00"
  },
  {
    "EOL": "099244",
    "UNIDADE": "EMEI - SYLVIO DE MAGALHAES FIGUEIREDO, ALM.",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "14.824,80",
    "BAS_2PAR": "14.824,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.824,80",
    "REC_EXTRA2": "88.948,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "169.423,20"
  },
  {
    "EOL": "099252",
    "UNIDADE": "EMEI - NORIMAR TEIXEIRA, PROFA.",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "16.614,00",
    "BAS_2PAR": "16.614,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "16.614,00",
    "REC_EXTRA2": "99.684,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "20.000,00",
    "TOTAL": "189.526,00"
  },
  {
    "EOL": "099261",
    "UNIDADE": "EMEF - TEOFILO BENEDITO OTTONI",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "20.642,00",
    "BAS_2PAR": "20.642,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.642,00",
    "REC_EXTRA2": "123.854,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "194.780,00"
  },
  {
    "EOL": "099279",
    "UNIDADE": "EMEI - ISA SILVEIRA LEAL",
    "DRE": "PENHA",
    "BAS_1PAR": "15.055,20",
    "BAS_2PAR": "15.055,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "15.055,20",
    "REC_EXTRA2": "90.331,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "171.496,80"
  },
  {
    "EOL": "099287",
    "UNIDADE": "EMEF - VIANNA MOOG",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "23.045,00",
    "BAS_2PAR": "23.045,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.045,00",
    "REC_EXTRA2": "138.272,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "218.407,00"
  },
  {
    "EOL": "099325",
    "UNIDADE": "EMEF - BENEDITO MONTENEGRO, PROF.",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "22.543,20",
    "BAS_2PAR": "22.543,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.543,20",
    "REC_EXTRA2": "135.259,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "211.888,80"
  },
  {
    "EOL": "099333",
    "UNIDADE": "EMEI - JARDIM MARILIA",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "13.226,40",
    "BAS_2PAR": "13.226,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.226,40",
    "REC_EXTRA2": "79.358,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "151.037,60"
  },
  {
    "EOL": "099341",
    "UNIDADE": "EMEI - MARIA DA CONCEICAO VIEIRA PEREIRA, PROFA.",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "13.212,00",
    "BAS_2PAR": "13.212,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.212,00",
    "REC_EXTRA2": "79.272,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "150.908,00"
  },
  {
    "EOL": "099350",
    "UNIDADE": "EMEF - 22 DE MARCO",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "20.518,20",
    "BAS_2PAR": "20.518,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.518,20",
    "REC_EXTRA2": "123.109,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "193.663,80"
  },
  {
    "EOL": "099538",
    "UNIDADE": "EMEI - RACHEL MESQUITA DE SALLES OLIVEIRA",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "14.799,60",
    "BAS_2PAR": "14.799,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.799,60",
    "REC_EXTRA2": "88.797,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "169.196,40"
  },
  {
    "EOL": "099589",
    "UNIDADE": "EMEF - PEDRO NAVA",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "20.091,50",
    "BAS_2PAR": "20.091,50",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.091,50",
    "REC_EXTRA2": "120.549,50",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "189.824,00"
  },
  {
    "EOL": "099643",
    "UNIDADE": "EMEI - DULCE HAUCK",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "13.294,80",
    "BAS_2PAR": "13.294,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.294,80",
    "REC_EXTRA2": "79.768,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "151.653,20"
  },
  {
    "EOL": "099651",
    "UNIDADE": "EMEF - JOSE HONORIO RODRIGUES",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "17.985,60",
    "BAS_2PAR": "17.985,60",
    "REC_EXTRA": "",
    "BAS_3PAR": "17.985,60",
    "REC_EXTRA2": "107.913,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "170.870,40"
  },
  {
    "EOL": "099660",
    "UNIDADE": "EMEF - AMELIA, IMP. DA.",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "20.750,40",
    "BAS_2PAR": "20.750,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "20.750,40",
    "REC_EXTRA2": "124.502,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "195.753,60"
  },
  {
    "EOL": "099678",
    "UNIDADE": "EMEF - MALLET, MAL.",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "18.018,00",
    "BAS_2PAR": "18.018,00",
    "REC_EXTRA": "",
    "BAS_3PAR": "18.018,00",
    "REC_EXTRA2": "108.108,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "171.162,00"
  },
  {
    "EOL": "099686",
    "UNIDADE": "EMEF - RICARDO VITIELLO, PROF.",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "22.570,20",
    "BAS_2PAR": "22.570,20",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.570,20",
    "REC_EXTRA2": "135.421,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "212.131,80"
  },
  {
    "EOL": "099783",
    "UNIDADE": "EMEF - GERALDO SESSO JUNIOR",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "23.239,80",
    "BAS_2PAR": "23.239,80",
    "REC_EXTRA": "",
    "BAS_3PAR": "23.239,80",
    "REC_EXTRA2": "139.438,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "220.158,20"
  },
  {
    "EOL": "099791",
    "UNIDADE": "EMEF - PLINIO SALGADO",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "26.231,40",
    "BAS_2PAR": "26.231,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "26.231,40",
    "REC_EXTRA2": "157.388,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "247.082,60"
  },
  {
    "EOL": "099792",
    "UNIDADE": "EMEF - FORTE DOS REIS MAGOS",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "22.532,40",
    "BAS_2PAR": "22.532,40",
    "REC_EXTRA": "",
    "BAS_3PAR": "22.532,40",
    "REC_EXTRA2": "135.194,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "211.791,60"
  },
  {
    "EOL": "099805",
    "UNIDADE": "EMEI - J.G. DE ARAUJO JORGE",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "20.170,80",
    "BAS_2PAR": "20.170,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "20.170,80",
    "REC_EXTRA2": "121.024,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "28.000,00",
    "TOTAL": "229.537,20"
  },
  {
    "EOL": "099988",
    "UNIDADE": "EMEI - MARIA JOSE DUPRE",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "18.543,60",
    "BAS_2PAR": "18.543,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.543,60",
    "REC_EXTRA2": "111.261,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "24.000,00",
    "TOTAL": "210.892,40"
  },
  {
    "EOL": 200042,
    "UNIDADE": "CIEJA - CENTRO INT ED JOVENS E ADULTOS - ERMELINO MATARAZZO",
    "DRE": "PENHA",
    "BAS_1PAR": "20.426,40",
    "BAS_2PAR": "20.426,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "20.426,40",
    "REC_EXTRA2": "122.558,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "207.837,60"
  },
  {
    "EOL": 200043,
    "UNIDADE": "CIEJA - IGUATEMI I",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "18.082,80",
    "BAS_2PAR": "18.082,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "18.082,80",
    "REC_EXTRA2": "108.496,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "186.745,20"
  },
  {
    "EOL": 200044,
    "UNIDADE": "CIEJA - PERUS I",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "31.912,20",
    "BAS_2PAR": "31.912,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "31.912,20",
    "REC_EXTRA2": "191.473,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "315.209,80"
  },
  {
    "EOL": 200050,
    "UNIDADE": "CIEJA - CENTRO INT ED JOVENS E ADULTOS - VILA PRUDENTE/SAPOPEMBA",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "23.320,80",
    "BAS_2PAR": "23.320,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "23.320,80",
    "REC_EXTRA2": "139.924,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "235.887,20"
  },
  {
    "EOL": 200069,
    "UNIDADE": "CIEJA - CENTRO INT ED JOVENS E ADULTOS - VILA MARIA/VILA GUILHERME",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "19.918,80",
    "BAS_2PAR": "19.918,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "19.918,80",
    "REC_EXTRA2": "119.512,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "203.269,20"
  },
  {
    "EOL": 200077,
    "UNIDADE": "CIEJA - CENTRO INT ED JOVENS E ADULTOS - ITAQUERA",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "20.545,20",
    "BAS_2PAR": "20.545,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "20.545,20",
    "REC_EXTRA2": "123.271,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "208.906,80"
  },
  {
    "EOL": 200085,
    "UNIDADE": "CIEJA - CENTRO INT ED JOVENS E ADULTOS - SE",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "20.572,20",
    "BAS_2PAR": "20.572,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "20.572,20",
    "REC_EXTRA2": "123.433,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "209.149,80"
  },
  {
    "EOL": 200093,
    "UNIDADE": "CIEJA - ROSA KAZUE INAKAKE DE SOUZA, PROFA.",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "25.405,20",
    "BAS_2PAR": "25.405,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "25.405,20",
    "REC_EXTRA2": "152.431,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "254.646,80"
  },
  {
    "EOL": 200115,
    "UNIDADE": "CIEJA - CENTRO INT ED JOVENS E ADULTOS - SANTO AMARO",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "20.280,60",
    "BAS_2PAR": "20.280,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "20.280,60",
    "REC_EXTRA2": "121.683,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "206.525,40"
  },
  {
    "EOL": 200131,
    "UNIDADE": "CIEJA - CENTRO INT ED JOVENS E ADULTOS - CAMPO LIMPO",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "31.788,00",
    "BAS_2PAR": "31.788,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "31.788,00",
    "REC_EXTRA2": "190.728,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "314.092,00"
  },
  {
    "EOL": 200140,
    "UNIDADE": "CIEJA - MARLUCIA GONCALVES DE ABREU, PROFESSORA",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "20.858,40",
    "BAS_2PAR": "20.858,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "20.858,40",
    "REC_EXTRA2": "125.150,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "211.725,60"
  },
  {
    "EOL": 200158,
    "UNIDADE": "CIEJA - ALUNA JESSICA NUNES HERCULANO",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "22.775,00",
    "BAS_2PAR": "22.775,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "22.775,00",
    "REC_EXTRA2": "136.652,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "228.977,00"
  },
  {
    "EOL": 200166,
    "UNIDADE": "CIEJA - CLOVIS CAITANO MIQUELAZZO - IPIRANGA",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "17.872,20",
    "BAS_2PAR": "17.872,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "17.872,20",
    "REC_EXTRA2": "107.233,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "184.849,80"
  },
  {
    "EOL": 200174,
    "UNIDADE": "CIEJA - CENTRO INT ED JOVENS E ADULTOS - SANTANA/TUCURUVI",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "17.866,80",
    "BAS_2PAR": "17.866,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "17.866,80",
    "REC_EXTRA2": "107.200,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "184.801,20"
  },
  {
    "EOL": 200182,
    "UNIDADE": "CIEJA - ROSE MARY FRASSON, PROFA.",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "23.293,80",
    "BAS_2PAR": "23.293,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "23.293,80",
    "REC_EXTRA2": "139.762,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "6.000,00",
    "TOTAL": "235.644,20"
  },
  {
    "EOL": 200183,
    "UNIDADE": "CEU - ALVARENGA",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "26.844,50",
    "BAS_2PAR": "26.844,50",
    "REC_EXTRA": "22.000,00",
    "BAS_3PAR": "26.844,50",
    "REC_EXTRA2": "161.067,50",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "",
    "TOTAL": "268.601,00"
  },
  {
    "EOL": 200184,
    "UNIDADE": "CEU - ARICANDUVA - PROFESSORA IRENE GALVÃO DE SOUZA",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "27.660,00",
    "BAS_2PAR": "27.660,00",
    "REC_EXTRA": "22.000,00",
    "BAS_3PAR": "27.660,00",
    "REC_EXTRA2": "165.960,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "",
    "TOTAL": "275.940,00"
  },
  {
    "EOL": 200185,
    "UNIDADE": "CEU - BUTANTÃ - ELIZABETH GASPAR TUNALA",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "26.817,50",
    "BAS_2PAR": "26.817,50",
    "REC_EXTRA": "22.000,00",
    "BAS_3PAR": "26.817,50",
    "REC_EXTRA2": "160.905,50",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "",
    "TOTAL": "268.358,00"
  },
  {
    "EOL": 200186,
    "UNIDADE": "CEU - CIDADE DUTRA - ADIB SALOMAO",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "28.162,20",
    "BAS_2PAR": "28.162,20",
    "REC_EXTRA": "22.000,00",
    "BAS_3PAR": "28.162,20",
    "REC_EXTRA2": "168.973,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "",
    "TOTAL": "280.459,80"
  },
  {
    "EOL": 200187,
    "UNIDADE": "CEU - INACIO MONTEIRO",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "27.411,60",
    "BAS_2PAR": "27.411,60",
    "REC_EXTRA": "22.000,00",
    "BAS_3PAR": "27.411,60",
    "REC_EXTRA2": "164.469,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "",
    "TOTAL": "273.704,40"
  },
  {
    "EOL": 200188,
    "UNIDADE": "CEU - JAMBEIRO - JOSE GUILHERME GIANETTI",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "32.633,00",
    "BAS_2PAR": "32.633,00",
    "REC_EXTRA": "22.000,00",
    "BAS_3PAR": "32.633,00",
    "REC_EXTRA2": "195.800,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "",
    "TOTAL": "320.699,00"
  },
  {
    "EOL": 200189,
    "UNIDADE": "CEU - MENINOS - ARTUR ALBERTO DE MOTA GONCALVES, PROF. PR.",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "27.708,60",
    "BAS_2PAR": "27.708,60",
    "REC_EXTRA": "22.000,00",
    "BAS_3PAR": "27.708,60",
    "REC_EXTRA2": "166.251,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "",
    "TOTAL": "276.377,40"
  },
  {
    "EOL": 200190,
    "UNIDADE": "CEU - NAVEGANTES - JOSE EVERARDO RODRIGUES COSME",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "29.587,80",
    "BAS_2PAR": "29.587,80",
    "REC_EXTRA": "22.000,00",
    "BAS_3PAR": "29.587,80",
    "REC_EXTRA2": "177.526,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "",
    "TOTAL": "293.290,20"
  },
  {
    "EOL": 200191,
    "UNIDADE": "CEU - PARQUE SAO CARLOS",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "25.413,60",
    "BAS_2PAR": "25.413,60",
    "REC_EXTRA": "22.000,00",
    "BAS_3PAR": "25.413,60",
    "REC_EXTRA2": "152.481,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "",
    "TOTAL": "255.722,40"
  },
  {
    "EOL": 200192,
    "UNIDADE": "CEU - PARQUE VEREDAS - JOAO ANTONIO DA SILVA",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "30.473,40",
    "BAS_2PAR": "30.473,40",
    "REC_EXTRA": "22.000,00",
    "BAS_3PAR": "30.473,40",
    "REC_EXTRA2": "182.840,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "",
    "TOTAL": "301.260,60"
  },
  {
    "EOL": 200193,
    "UNIDADE": "CEU - PERA MARMELO",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "29.679,60",
    "BAS_2PAR": "29.679,60",
    "REC_EXTRA": "22.000,00",
    "BAS_3PAR": "29.679,60",
    "REC_EXTRA2": "178.077,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "",
    "TOTAL": "294.116,40"
  },
  {
    "EOL": 200194,
    "UNIDADE": "CEU - PERUS",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "27.962,00",
    "BAS_2PAR": "27.962,00",
    "REC_EXTRA": "22.000,00",
    "BAS_3PAR": "27.962,00",
    "REC_EXTRA2": "167.774,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "",
    "TOTAL": "278.660,00"
  },
  {
    "EOL": 200195,
    "UNIDADE": "CEU - SAO MATEUS",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "27.260,40",
    "BAS_2PAR": "27.260,40",
    "REC_EXTRA": "22.000,00",
    "BAS_3PAR": "27.260,40",
    "REC_EXTRA2": "163.562,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "",
    "TOTAL": "272.343,60"
  },
  {
    "EOL": 200196,
    "UNIDADE": "CEU - SAO RAFAEL",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "29.123,40",
    "BAS_2PAR": "29.123,40",
    "REC_EXTRA": "22.000,00",
    "BAS_3PAR": "29.123,40",
    "REC_EXTRA2": "174.740,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "",
    "TOTAL": "289.110,60"
  },
  {
    "EOL": 200197,
    "UNIDADE": "CEU - TRES LAGOS",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "30.592,00",
    "BAS_2PAR": "30.592,00",
    "REC_EXTRA": "22.000,00",
    "BAS_3PAR": "30.592,00",
    "REC_EXTRA2": "183.553,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "",
    "TOTAL": "302.329,00"
  },
  {
    "EOL": 200198,
    "UNIDADE": "CEU - VILA ATLÂNTICA - JOÃO SOARES FILHO",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "29.431,20",
    "BAS_2PAR": "29.431,20",
    "REC_EXTRA": "22.000,00",
    "BAS_3PAR": "29.431,20",
    "REC_EXTRA2": "176.587,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "",
    "TOTAL": "291.880,80"
  },
  {
    "EOL": 200199,
    "UNIDADE": "CEU - VILA CURUCA - IRENE RAMALHO",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "32.061,00",
    "BAS_2PAR": "32.061,00",
    "REC_EXTRA": "22.000,00",
    "BAS_3PAR": "32.061,00",
    "REC_EXTRA2": "192.366,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "",
    "TOTAL": "315.549,00"
  },
  {
    "EOL": 200200,
    "UNIDADE": "CEU - ROSA DA CHINA",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "26.002,20",
    "BAS_2PAR": "26.002,20",
    "REC_EXTRA": "22.000,00",
    "BAS_3PAR": "26.002,20",
    "REC_EXTRA2": "156.013,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "",
    "TOTAL": "261.019,80"
  },
  {
    "EOL": 200201,
    "UNIDADE": "CEU - CASA BLANCA - SOLON BORGES DOS REIS",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "30.235,80",
    "BAS_2PAR": "30.235,80",
    "REC_EXTRA": "22.000,00",
    "BAS_3PAR": "30.235,80",
    "REC_EXTRA2": "181.414,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "",
    "TOTAL": "299.122,20"
  },
  {
    "EOL": 200202,
    "UNIDADE": "CEU - PAZ",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "29.679,60",
    "BAS_2PAR": "29.679,60",
    "REC_EXTRA": "22.000,00",
    "BAS_3PAR": "29.679,60",
    "REC_EXTRA2": "178.077,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "",
    "TOTAL": "294.116,40"
  },
  {
    "EOL": 200203,
    "UNIDADE": "CEU - CAMPO LIMPO - DOM AGNELO ROSSI",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "29.928,00",
    "BAS_2PAR": "29.928,00",
    "REC_EXTRA": "22.000,00",
    "BAS_3PAR": "29.928,00",
    "REC_EXTRA2": "179.568,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "",
    "TOTAL": "296.352,00"
  },
  {
    "EOL": 200204,
    "UNIDADE": "CECI - JARAGUA",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "11.250,00",
    "BAS_2PAR": "11.250,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.250,00",
    "REC_EXTRA2": "67.500,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.250,00"
  },
  {
    "EOL": 200205,
    "UNIDADE": "CECI - KRUKUTU",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "10.954,80",
    "BAS_2PAR": "10.954,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "10.954,80",
    "REC_EXTRA2": "65.728,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "122.593,20"
  },
  {
    "EOL": 200206,
    "UNIDADE": "CECI - TENONDE PORA",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "11.221,20",
    "BAS_2PAR": "11.221,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.221,20",
    "REC_EXTRA2": "67.327,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "128.990,80"
  },
  {
    "EOL": 200207,
    "UNIDADE": "CIEJA - CENTRO INT ED JOVENS E ADULTOS - PARELHEIROS",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "20.340,00",
    "BAS_2PAR": "20.340,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "20.340,00",
    "REC_EXTRA2": "122.040,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "207.060,00"
  },
  {
    "EOL": 200237,
    "UNIDADE": "CEU - ÁGUA AZUL - PAULO RENATO COSTA SOUZA",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "28.902,00",
    "BAS_2PAR": "28.902,00",
    "REC_EXTRA": "22.000,00",
    "BAS_3PAR": "28.902,00",
    "REC_EXTRA2": "173.412,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "",
    "TOTAL": "287.118,00"
  },
  {
    "EOL": 200238,
    "UNIDADE": "CEU - AZUL DA COR DO MAR - PROFESSOR JOSSEI TODA",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "31.558,80",
    "BAS_2PAR": "31.558,80",
    "REC_EXTRA": "22.000,00",
    "BAS_3PAR": "31.558,80",
    "REC_EXTRA2": "189.352,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "",
    "TOTAL": "311.029,20"
  },
  {
    "EOL": 200239,
    "UNIDADE": "CEU - FEITIÇO DA VILA - JOSÉ FREITAS NOBRE",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "28.723,80",
    "BAS_2PAR": "28.723,80",
    "REC_EXTRA": "22.000,00",
    "BAS_3PAR": "28.723,80",
    "REC_EXTRA2": "172.342,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "",
    "TOTAL": "285.514,20"
  },
  {
    "EOL": 200240,
    "UNIDADE": "CEU - VILA RUBI - ALEXANDRE KADUNC",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "27.730,20",
    "BAS_2PAR": "27.730,20",
    "REC_EXTRA": "22.000,00",
    "BAS_3PAR": "27.730,20",
    "REC_EXTRA2": "166.381,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "",
    "TOTAL": "276.571,80"
  },
  {
    "EOL": 200241,
    "UNIDADE": "CEU - JACANA",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "29.879,00",
    "BAS_2PAR": "29.879,00",
    "REC_EXTRA": "22.000,00",
    "BAS_3PAR": "29.879,00",
    "REC_EXTRA2": "179.276,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "",
    "TOTAL": "295.913,00"
  },
  {
    "EOL": 200243,
    "UNIDADE": "CEU - GUARAPIRANGA - FLORINDA LOTAIF SCHAHIN",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "27.379,20",
    "BAS_2PAR": "27.379,20",
    "REC_EXTRA": "22.000,00",
    "BAS_3PAR": "27.379,20",
    "REC_EXTRA2": "164.275,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "",
    "TOTAL": "273.412,80"
  },
  {
    "EOL": 200244,
    "UNIDADE": "CEU - CANTOS DO AMANHECER",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "31.121,40",
    "BAS_2PAR": "31.121,40",
    "REC_EXTRA": "22.000,00",
    "BAS_3PAR": "31.121,40",
    "REC_EXTRA2": "186.728,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "",
    "TOTAL": "307.092,60"
  },
  {
    "EOL": 200245,
    "UNIDADE": "CEU - VILA DO SOL",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "33.621,60",
    "BAS_2PAR": "33.621,60",
    "REC_EXTRA": "22.000,00",
    "BAS_3PAR": "33.621,60",
    "REC_EXTRA2": "201.729,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "",
    "TOTAL": "329.594,40"
  },
  {
    "EOL": 200246,
    "UNIDADE": "CEU - JARDIM PAULISTANO",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "29.965,50",
    "BAS_2PAR": "29.965,50",
    "REC_EXTRA": "22.000,00",
    "BAS_3PAR": "29.965,50",
    "REC_EXTRA2": "179.794,50",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "",
    "TOTAL": "296.691,00"
  },
  {
    "EOL": 200247,
    "UNIDADE": "CEU - LAJEADO",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "27.600,60",
    "BAS_2PAR": "27.600,60",
    "REC_EXTRA": "22.000,00",
    "BAS_3PAR": "27.600,60",
    "REC_EXTRA2": "165.603,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "",
    "TOTAL": "275.405,40"
  },
  {
    "EOL": 200248,
    "UNIDADE": "CEU - QUINTA DO SOL",
    "DRE": "PENHA",
    "BAS_1PAR": "30.727,20",
    "BAS_2PAR": "30.727,20",
    "REC_EXTRA": "22.000,00",
    "BAS_3PAR": "30.727,20",
    "REC_EXTRA2": "184.363,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "",
    "TOTAL": "303.544,80"
  },
  {
    "EOL": 200249,
    "UNIDADE": "CEU - SAPOPEMBA",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "27.157,80",
    "BAS_2PAR": "27.157,80",
    "REC_EXTRA": "22.000,00",
    "BAS_3PAR": "27.157,80",
    "REC_EXTRA2": "162.946,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "",
    "TOTAL": "271.420,20"
  },
  {
    "EOL": 200250,
    "UNIDADE": "CEU - TRES PONTES - NILZETE LETICIA BISPO DOS SANTOS LIMA",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "28.896,60",
    "BAS_2PAR": "28.896,60",
    "REC_EXTRA": "22.000,00",
    "BAS_3PAR": "28.896,60",
    "REC_EXTRA2": "173.379,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "",
    "TOTAL": "287.069,40"
  },
  {
    "EOL": 200251,
    "UNIDADE": "CEU - CAMINHO DO MAR - DULCE SALLES CUNHA BRAGA",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "28.097,40",
    "BAS_2PAR": "28.097,40",
    "REC_EXTRA": "22.000,00",
    "BAS_3PAR": "28.097,40",
    "REC_EXTRA2": "168.584,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "",
    "TOTAL": "279.876,60"
  },
  {
    "EOL": 200252,
    "UNIDADE": "CEU - CAPÃO REDONDO - CELSO SEIXAS RIBEIRO BASTOS",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "29.042,00",
    "BAS_2PAR": "29.042,00",
    "REC_EXTRA": "22.000,00",
    "BAS_3PAR": "29.042,00",
    "REC_EXTRA2": "174.254,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "",
    "TOTAL": "288.380,00"
  },
  {
    "EOL": 200253,
    "UNIDADE": "CEU - PARAISÓPOLIS",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "28.205,40",
    "BAS_2PAR": "28.205,40",
    "REC_EXTRA": "22.000,00",
    "BAS_3PAR": "28.205,40",
    "REC_EXTRA2": "169.232,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "",
    "TOTAL": "280.848,60"
  },
  {
    "EOL": 200254,
    "UNIDADE": "CEU - PARQUE BRISTOL",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "27.854,00",
    "BAS_2PAR": "27.854,00",
    "REC_EXTRA": "22.000,00",
    "BAS_3PAR": "27.854,00",
    "REC_EXTRA2": "167.126,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "",
    "TOTAL": "277.688,00"
  },
  {
    "EOL": 200255,
    "UNIDADE": "CEU - FORMOSA - EDEN SIVERIO DE OLIVEIRA, PROF.",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "26.887,50",
    "BAS_2PAR": "26.887,50",
    "REC_EXTRA": "22.000,00",
    "BAS_3PAR": "26.887,50",
    "REC_EXTRA2": "161.326,50",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "",
    "TOTAL": "268.989,00"
  },
  {
    "EOL": 200256,
    "UNIDADE": "CEU - UIRAPURU",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "28.675,00",
    "BAS_2PAR": "28.675,00",
    "REC_EXTRA": "22.000,00",
    "BAS_3PAR": "28.675,00",
    "REC_EXTRA2": "172.051,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "",
    "TOTAL": "285.076,00"
  },
  {
    "EOL": 200257,
    "UNIDADE": "CEU - TIQUATIRA",
    "DRE": "PENHA",
    "BAS_1PAR": "26.747,40",
    "BAS_2PAR": "26.747,40",
    "REC_EXTRA": "22.000,00",
    "BAS_3PAR": "26.747,40",
    "REC_EXTRA2": "160.484,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "",
    "TOTAL": "267.726,60"
  },
  {
    "EOL": 200258,
    "UNIDADE": "CEU - PARQUE ANHANGUERA",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "28.615,80",
    "BAS_2PAR": "28.615,80",
    "REC_EXTRA": "22.000,00",
    "BAS_3PAR": "28.615,80",
    "REC_EXTRA2": "171.694,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "",
    "TOTAL": "284.542,20"
  },
  {
    "EOL": 200259,
    "UNIDADE": "CEU - JAGUARE - HENRIQUE GAMBA",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "26.099,40",
    "BAS_2PAR": "26.099,40",
    "REC_EXTRA": "22.000,00",
    "BAS_3PAR": "26.099,40",
    "REC_EXTRA2": "156.596,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "",
    "TOTAL": "261.894,60"
  },
  {
    "EOL": 200260,
    "UNIDADE": "CEU - ALTO ALEGRE - PAULO SUYOSH MINAMI",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "30.473,40",
    "BAS_2PAR": "30.473,40",
    "REC_EXTRA": "22.000,00",
    "BAS_3PAR": "30.473,40",
    "REC_EXTRA2": "182.840,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "",
    "TOTAL": "301.260,60"
  },
  {
    "EOL": 200261,
    "UNIDADE": "CEU - PARELHEIROS - ENEIDA PALMA LEITE",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "31.742,40",
    "BAS_2PAR": "31.742,40",
    "REC_EXTRA": "22.000,00",
    "BAS_3PAR": "31.742,40",
    "REC_EXTRA2": "190.454,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "",
    "TOTAL": "312.681,60"
  },
  {
    "EOL": 200262,
    "UNIDADE": "CEU - HELIÓPOLIS - ARLETE PERSOLI, PROFA.",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "25.996,80",
    "BAS_2PAR": "25.996,80",
    "REC_EXTRA": "22.000,00",
    "BAS_3PAR": "25.996,80",
    "REC_EXTRA2": "155.980,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "",
    "TOTAL": "260.971,20"
  },
  {
    "EOL": 400001,
    "UNIDADE": "CEI - ADELAIDE LOPES RODRIGUES",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "11.192,40",
    "BAS_2PAR": "11.192,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.192,40",
    "REC_EXTRA2": "67.154,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "128.731,60"
  },
  {
    "EOL": 400002,
    "UNIDADE": "CEI - ALASTAIR QUINTAS GONCALVES",
    "DRE": "PENHA",
    "BAS_1PAR": "11.300,40",
    "BAS_2PAR": "11.300,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.300,40",
    "REC_EXTRA2": "67.802,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.703,60"
  },
  {
    "EOL": 400003,
    "UNIDADE": "CEI - ALOYSIO DE MENEZES GREENHALGH, VER.",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "11.160,00",
    "BAS_2PAR": "11.160,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.160,00",
    "REC_EXTRA2": "66.960,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "124.440,00"
  },
  {
    "EOL": 400004,
    "UNIDADE": "CEI - AMERICO DE SOUZA",
    "DRE": "PENHA",
    "BAS_1PAR": "11.386,80",
    "BAS_2PAR": "11.386,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.386,80",
    "REC_EXTRA2": "68.320,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.481,20"
  },
  {
    "EOL": 400005,
    "UNIDADE": "CEI - ANITA CASTALDI ZAMPIROLLO, PROFA.",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "11.365,20",
    "BAS_2PAR": "11.365,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.365,20",
    "REC_EXTRA2": "68.191,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.286,80"
  },
  {
    "EOL": 400006,
    "UNIDADE": "CEI - ANITA GARIBALDI",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "11.412,00",
    "BAS_2PAR": "11.412,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.412,00",
    "REC_EXTRA2": "68.472,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.708,00"
  },
  {
    "EOL": 400007,
    "UNIDADE": "CEI - ANNA FLORENCIO ROMAO",
    "DRE": "PENHA",
    "BAS_1PAR": "11.311,20",
    "BAS_2PAR": "11.311,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.311,20",
    "REC_EXTRA2": "67.867,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.800,80"
  },
  {
    "EOL": 400008,
    "UNIDADE": "CEI - ANTONIA MARIA TORRES DA SILVA",
    "DRE": "PENHA",
    "BAS_1PAR": "11.325,60",
    "BAS_2PAR": "11.325,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.325,60",
    "REC_EXTRA2": "67.953,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.930,40"
  },
  {
    "EOL": 400009,
    "UNIDADE": "CEI - ANTONIA MUOTRI LAMBERGA",
    "DRE": "PENHA",
    "BAS_1PAR": "11.300,40",
    "BAS_2PAR": "11.300,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.300,40",
    "REC_EXTRA2": "67.802,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.703,60"
  },
  {
    "EOL": 400010,
    "UNIDADE": "CEI - ANTONIO JOAO ABDALLA, DR.",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "11.246,00",
    "BAS_2PAR": "11.246,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.246,00",
    "REC_EXTRA2": "67.478,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.216,00"
  },
  {
    "EOL": 400011,
    "UNIDADE": "CEI - ANTON MEROTH, PE.",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "11.502,00",
    "BAS_2PAR": "11.502,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.502,00",
    "REC_EXTRA2": "69.012,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "131.518,00"
  },
  {
    "EOL": 400012,
    "UNIDADE": "CEI - ARAUCARIAS",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "11.257,20",
    "BAS_2PAR": "11.257,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.257,20",
    "REC_EXTRA2": "67.543,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.314,80"
  },
  {
    "EOL": 400013,
    "UNIDADE": "CEI - ARTURO DE ANGELIS, MAESTRO",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "11.253,60",
    "BAS_2PAR": "11.253,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.253,60",
    "REC_EXTRA2": "67.521,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.282,40"
  },
  {
    "EOL": 400014,
    "UNIDADE": "CEI - ASSMA CURIATI",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "11.304,00",
    "BAS_2PAR": "11.304,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.304,00",
    "REC_EXTRA2": "67.824,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.736,00"
  },
  {
    "EOL": 400015,
    "UNIDADE": "CEI - AYRTON SENNA DA SILVA",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "11.325,60",
    "BAS_2PAR": "11.325,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.325,60",
    "REC_EXTRA2": "67.953,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.930,40"
  },
  {
    "EOL": 400016,
    "UNIDADE": "CEI - BARBARA HELIODORA",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "11.282,40",
    "BAS_2PAR": "11.282,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.282,40",
    "REC_EXTRA2": "67.694,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.541,60"
  },
  {
    "EOL": 400017,
    "UNIDADE": "CEI - BENEDITO BUENO",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "11.311,20",
    "BAS_2PAR": "11.311,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.311,20",
    "REC_EXTRA2": "67.867,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.800,80"
  },
  {
    "EOL": 400018,
    "UNIDADE": "CEI - BENEDICTO ROCHA, VER.",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "11.142,00",
    "BAS_2PAR": "11.142,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.142,00",
    "REC_EXTRA2": "66.852,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "124.278,00"
  },
  {
    "EOL": 400019,
    "UNIDADE": "CEI - ZILDA ARNS NEUMANN, DRA.",
    "DRE": "PENHA",
    "BAS_1PAR": "11.347,20",
    "BAS_2PAR": "11.347,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.347,20",
    "REC_EXTRA2": "68.083,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.124,80"
  },
  {
    "EOL": 400020,
    "UNIDADE": "CEI - CABREUVAS",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "11.214,00",
    "BAS_2PAR": "11.214,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.214,00",
    "REC_EXTRA2": "67.284,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "128.926,00"
  },
  {
    "EOL": 400021,
    "UNIDADE": "CEI - CANTIDIO NOGUEIRA SAMPAIO, VER.",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "11.466,00",
    "BAS_2PAR": "11.466,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.466,00",
    "REC_EXTRA2": "68.796,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "131.194,00"
  },
  {
    "EOL": 400022,
    "UNIDADE": "CEI - ELFRIDA ZUKOWSKI JARDIM",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "11.347,20",
    "BAS_2PAR": "11.347,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.347,20",
    "REC_EXTRA2": "68.083,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.124,80"
  },
  {
    "EOL": 400023,
    "UNIDADE": "CEI - CASA VERDE - WALTER ABRAHAO",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "11.512,80",
    "BAS_2PAR": "11.512,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.512,80",
    "REC_EXTRA2": "69.076,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "131.615,20"
  },
  {
    "EOL": 400024,
    "UNIDADE": "CEI - CELIA PERES SUNHIGA",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "11.268,00",
    "BAS_2PAR": "11.268,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.268,00",
    "REC_EXTRA2": "67.608,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.412,00"
  },
  {
    "EOL": 400025,
    "UNIDADE": "CEI - CHACARA DONA OLIVIA",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "11.224,80",
    "BAS_2PAR": "11.224,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.224,80",
    "REC_EXTRA2": "67.348,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.023,20"
  },
  {
    "EOL": 400026,
    "UNIDADE": "CEI - CID FRANCO, VER.",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "11.120,40",
    "BAS_2PAR": "11.120,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.120,40",
    "REC_EXTRA2": "66.722,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "124.083,60"
  },
  {
    "EOL": 400027,
    "UNIDADE": "CEI - ELIAS PEREIRA DE MELO, PE.",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "11.300,40",
    "BAS_2PAR": "11.300,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.300,40",
    "REC_EXTRA2": "67.802,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.703,60"
  },
  {
    "EOL": 400028,
    "UNIDADE": "CEI - INEZITA BARROSO",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "11.239,20",
    "BAS_2PAR": "11.239,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.239,20",
    "REC_EXTRA2": "67.435,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.152,80"
  },
  {
    "EOL": 400029,
    "UNIDADE": "CEI - DOROTHY STANG",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "11.178,00",
    "BAS_2PAR": "11.178,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.178,00",
    "REC_EXTRA2": "67.068,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "128.602,00"
  },
  {
    "EOL": 400030,
    "UNIDADE": "CEI - CIDADE NOVA DO PARQUE NOVO MUNDO",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "11.386,80",
    "BAS_2PAR": "11.386,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.386,80",
    "REC_EXTRA2": "68.320,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.481,20"
  },
  {
    "EOL": 400031,
    "UNIDADE": "CEI - CIDADE PEDRO JOSE NUNES",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "11.314,80",
    "BAS_2PAR": "11.314,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.314,80",
    "REC_EXTRA2": "67.888,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.833,20"
  },
  {
    "EOL": 400032,
    "UNIDADE": "CEI - CIDADE TIRADENTES",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "11.318,40",
    "BAS_2PAR": "11.318,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.318,40",
    "REC_EXTRA2": "67.910,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.865,60"
  },
  {
    "EOL": 400033,
    "UNIDADE": "CEI - COHAB FARIA LIMA, BRIG.",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "11.221,20",
    "BAS_2PAR": "11.221,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.221,20",
    "REC_EXTRA2": "67.327,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "128.990,80"
  },
  {
    "EOL": 400034,
    "UNIDADE": "CEI - COHAB RAPOSO TAVARES",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "11.376,00",
    "BAS_2PAR": "11.376,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.376,00",
    "REC_EXTRA2": "68.256,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.384,00"
  },
  {
    "EOL": 400035,
    "UNIDADE": "CEI - CONJ. PRESTES MAIA",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "11.253,60",
    "BAS_2PAR": "11.253,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.253,60",
    "REC_EXTRA2": "67.521,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.282,40"
  },
  {
    "EOL": 400036,
    "UNIDADE": "CEI - CORYNTHO BALDOINO DA COSTA FILHO, VER.",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "11.221,20",
    "BAS_2PAR": "11.221,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.221,20",
    "REC_EXTRA2": "67.327,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "128.990,80"
  },
  {
    "EOL": 400037,
    "UNIDADE": "CEI - CURUCA VELHA",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "11.221,20",
    "BAS_2PAR": "11.221,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.221,20",
    "REC_EXTRA2": "67.327,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "128.990,80"
  },
  {
    "EOL": 400038,
    "UNIDADE": "CEI - DELSON DOMINGUES",
    "DRE": "PENHA",
    "BAS_1PAR": "11.235,60",
    "BAS_2PAR": "11.235,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.235,60",
    "REC_EXTRA2": "67.413,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.120,40"
  },
  {
    "EOL": 400039,
    "UNIDADE": "CEI - INCONFIDENTES",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "11.163,60",
    "BAS_2PAR": "11.163,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.163,60",
    "REC_EXTRA2": "66.981,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "128.472,40"
  },
  {
    "EOL": 400040,
    "UNIDADE": "CEI - DOUGLAS DANIEL NASCIMENTO",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "12.512,40",
    "BAS_2PAR": "12.512,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "12.512,40",
    "REC_EXTRA2": "75.074,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "144.611,60"
  },
  {
    "EOL": 400041,
    "UNIDADE": "CEI - DURVAL MIOLA, PROF.",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "11.329,20",
    "BAS_2PAR": "11.329,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.329,20",
    "REC_EXTRA2": "67.975,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.962,80"
  },
  {
    "EOL": 400042,
    "UNIDADE": "CEI - EDNA ROSELY ALVES",
    "DRE": "PENHA",
    "BAS_1PAR": "11.293,20",
    "BAS_2PAR": "11.293,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.293,20",
    "REC_EXTRA2": "67.759,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.638,80"
  },
  {
    "EOL": 400043,
    "UNIDADE": "CEI - ELISIO TEIXEIRA LEITE",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "11.304,00",
    "BAS_2PAR": "11.304,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.304,00",
    "REC_EXTRA2": "67.824,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.736,00"
  },
  {
    "EOL": 400044,
    "UNIDADE": "CEI - ELIZABETH SOUZA LOBO GARCIA",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "11.250,00",
    "BAS_2PAR": "11.250,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.250,00",
    "REC_EXTRA2": "67.500,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.250,00"
  },
  {
    "EOL": 400045,
    "UNIDADE": "CEI - ENEDINA DE SOUSA CARVALHO",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "11.293,20",
    "BAS_2PAR": "11.293,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.293,20",
    "REC_EXTRA2": "67.759,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.638,80"
  },
  {
    "EOL": 400046,
    "UNIDADE": "CEI - ERMANO MARCHETTI, VER.",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "11.365,00",
    "BAS_2PAR": "11.365,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.365,00",
    "REC_EXTRA2": "68.191,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.286,00"
  },
  {
    "EOL": 400047,
    "UNIDADE": "CEI - FERNAO DIAS",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "11.397,60",
    "BAS_2PAR": "11.397,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.397,60",
    "REC_EXTRA2": "68.385,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.578,40"
  },
  {
    "EOL": 400048,
    "UNIDADE": "CEI - FRANCISCO MARCONDES OLIVEIRA, VER.",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "11.379,60",
    "BAS_2PAR": "11.379,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.379,60",
    "REC_EXTRA2": "68.277,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.416,40"
  },
  {
    "EOL": 400049,
    "UNIDADE": "CEI - FRANCISCO PEREZ, VER.",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "11.228,40",
    "BAS_2PAR": "11.228,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.228,40",
    "REC_EXTRA2": "67.370,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.055,60"
  },
  {
    "EOL": 400050,
    "UNIDADE": "CEI - GABRIEL NOGUEIRA DE QUADROS, VER.",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "11.235,60",
    "BAS_2PAR": "11.235,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.235,60",
    "REC_EXTRA2": "67.413,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.120,40"
  },
  {
    "EOL": 400051,
    "UNIDADE": "CEI - GENOVEVA DASCOLI",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "10.944,00",
    "BAS_2PAR": "10.944,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "10.944,00",
    "REC_EXTRA2": "65.664,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "122.496,00"
  },
  {
    "EOL": 400052,
    "UNIDADE": "CEI - GERALDO DE ARRUDA PENTEADO, CEL.",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "11.098,80",
    "BAS_2PAR": "11.098,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.098,80",
    "REC_EXTRA2": "66.592,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "123.889,20"
  },
  {
    "EOL": 400053,
    "UNIDADE": "CEI - GERALDO MAGELA PERON",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "11.221,20",
    "BAS_2PAR": "11.221,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.221,20",
    "REC_EXTRA2": "67.327,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "128.990,80"
  },
  {
    "EOL": 400054,
    "UNIDADE": "CEI - GUMERCINDO DE PADUA FLEURY, VER.",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "11.095,20",
    "BAS_2PAR": "11.095,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.095,20",
    "REC_EXTRA2": "66.571,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "123.856,80"
  },
  {
    "EOL": 400055,
    "UNIDADE": "CEI - HELENA IRACY JUNQUEIRA",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "11.278,80",
    "BAS_2PAR": "11.278,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.278,80",
    "REC_EXTRA2": "67.672,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.509,20"
  },
  {
    "EOL": 400056,
    "UNIDADE": "CEI - HELENA PEREIRA DE MORAES",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "11.242,80",
    "BAS_2PAR": "11.242,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.242,80",
    "REC_EXTRA2": "67.456,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.185,20"
  },
  {
    "EOL": 400057,
    "UNIDADE": "CEI - HIGINO PELLEGRINI, VER.",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "11.120,40",
    "BAS_2PAR": "11.120,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.120,40",
    "REC_EXTRA2": "66.722,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "124.083,60"
  },
  {
    "EOL": 400058,
    "UNIDADE": "CEI - HOMERO DOMINGUES DA SILVA, VER.",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "11.314,80",
    "BAS_2PAR": "11.314,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.314,80",
    "REC_EXTRA2": "67.888,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.833,20"
  },
  {
    "EOL": 400059,
    "UNIDADE": "CEI - INACIO MONTEIRO",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "11.185,20",
    "BAS_2PAR": "11.185,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.185,20",
    "REC_EXTRA2": "67.111,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "128.666,80"
  },
  {
    "EOL": 400060,
    "UNIDADE": "CEI - INEZ MENEZES MARIA",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "11.361,60",
    "BAS_2PAR": "11.361,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.361,60",
    "REC_EXTRA2": "68.169,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.254,40"
  },
  {
    "EOL": 400061,
    "UNIDADE": "CEI - JACARANDA",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "11.196,00",
    "BAS_2PAR": "11.196,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.196,00",
    "REC_EXTRA2": "67.176,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "128.764,00"
  },
  {
    "EOL": 400062,
    "UNIDADE": "CEI - JACOB SALVADOR ZVEIBIL, VER.",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "11.102,40",
    "BAS_2PAR": "11.102,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.102,40",
    "REC_EXTRA2": "66.614,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "123.921,60"
  },
  {
    "EOL": 400063,
    "UNIDADE": "CEI - JARDIM ADUTORA",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "11.152,80",
    "BAS_2PAR": "11.152,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.152,80",
    "REC_EXTRA2": "66.916,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "124.375,20"
  },
  {
    "EOL": 400064,
    "UNIDADE": "CEI - MARIA APARECIDA SIQUEIRA CAMPOS",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "11.188,50",
    "BAS_2PAR": "11.188,50",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.188,50",
    "REC_EXTRA2": "67.132,50",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "128.698,00"
  },
  {
    "EOL": 400065,
    "UNIDADE": "CEI - JARDIM CAMARGO NOVO",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "11.448,00",
    "BAS_2PAR": "11.448,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.448,00",
    "REC_EXTRA2": "68.688,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "131.032,00"
  },
  {
    "EOL": 400066,
    "UNIDADE": "CEI - JARDIM CAMARGO VELHO",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "11.239,20",
    "BAS_2PAR": "11.239,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.239,20",
    "REC_EXTRA2": "67.435,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.152,80"
  },
  {
    "EOL": 400067,
    "UNIDADE": "CEI - PEDRO HENRIQUE SIQUEIRA LIMA",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "11.304,00",
    "BAS_2PAR": "11.304,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.304,00",
    "REC_EXTRA2": "67.824,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.736,00"
  },
  {
    "EOL": 400068,
    "UNIDADE": "CEI - JARDIM CAMPOS",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "11.206,80",
    "BAS_2PAR": "11.206,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.206,80",
    "REC_EXTRA2": "67.240,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "128.861,20"
  },
  {
    "EOL": 400069,
    "UNIDADE": "CEI - JARDIM CAPELA",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "11.242,80",
    "BAS_2PAR": "11.242,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.242,80",
    "REC_EXTRA2": "67.456,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.185,20"
  },
  {
    "EOL": 400070,
    "UNIDADE": "CEI - JARDIM CATANDUVA",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "11.178,00",
    "BAS_2PAR": "11.178,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.178,00",
    "REC_EXTRA2": "67.068,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "128.602,00"
  },
  {
    "EOL": 400071,
    "UNIDADE": "CEI - DANIELLE MONTEIRO, GCMF",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "11.206,80",
    "BAS_2PAR": "11.206,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.206,80",
    "REC_EXTRA2": "67.240,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "128.861,20"
  },
  {
    "EOL": 400072,
    "UNIDADE": "CEI - JARDIM COLONIAL",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "11.210,40",
    "BAS_2PAR": "11.210,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.210,40",
    "REC_EXTRA2": "67.262,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "128.893,60"
  },
  {
    "EOL": 400073,
    "UNIDADE": "CEI - JARDIM COLORADO",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "11.228,40",
    "BAS_2PAR": "11.228,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.228,40",
    "REC_EXTRA2": "67.370,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.055,60"
  },
  {
    "EOL": 400074,
    "UNIDADE": "CEI - JARDIM COPACABANA",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "11.257,20",
    "BAS_2PAR": "11.257,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.257,20",
    "REC_EXTRA2": "67.543,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.314,80"
  },
  {
    "EOL": 400075,
    "UNIDADE": "CEI - JARDIM COTINHA",
    "DRE": "PENHA",
    "BAS_1PAR": "11.127,60",
    "BAS_2PAR": "11.127,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.127,60",
    "REC_EXTRA2": "66.765,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "124.148,40"
  },
  {
    "EOL": 400076,
    "UNIDADE": "CEI - MARIA MARGARIDA RODRIGUES DE OLIVEIRA, PROFA.",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "11.264,00",
    "BAS_2PAR": "11.264,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.264,00",
    "REC_EXTRA2": "67.586,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.378,00"
  },
  {
    "EOL": 400077,
    "UNIDADE": "CEI - JARDIM DAS PEDRAS",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "12.494,40",
    "BAS_2PAR": "12.494,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "12.494,40",
    "REC_EXTRA2": "74.966,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "144.449,60"
  },
  {
    "EOL": 400078,
    "UNIDADE": "CEI - JARDIM DIONISIO",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "11.340,00",
    "BAS_2PAR": "11.340,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.340,00",
    "REC_EXTRA2": "68.040,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.060,00"
  },
  {
    "EOL": 400079,
    "UNIDADE": "CEI - JARDIM DOM JOSE",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "11.106,00",
    "BAS_2PAR": "11.106,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.106,00",
    "REC_EXTRA2": "66.636,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "123.954,00"
  },
  {
    "EOL": 400080,
    "UNIDADE": "CEI - JARDIM ELIANA",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "11.314,80",
    "BAS_2PAR": "11.314,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.314,80",
    "REC_EXTRA2": "67.888,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.833,20"
  },
  {
    "EOL": 400081,
    "UNIDADE": "CEI - JARDIM EVA",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "11.250,00",
    "BAS_2PAR": "11.250,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.250,00",
    "REC_EXTRA2": "67.500,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.250,00"
  },
  {
    "EOL": 400082,
    "UNIDADE": "CEI - JARDIM GUAIRACA",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "11.210,40",
    "BAS_2PAR": "11.210,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.210,40",
    "REC_EXTRA2": "67.262,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "128.893,60"
  },
  {
    "EOL": 400083,
    "UNIDADE": "CEI - JARDIM GUARUJA",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "11.260,80",
    "BAS_2PAR": "11.260,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.260,80",
    "REC_EXTRA2": "67.564,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.347,20"
  },
  {
    "EOL": 400084,
    "UNIDADE": "CEI - JARDIM HELENA",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "11.286,00",
    "BAS_2PAR": "11.286,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.286,00",
    "REC_EXTRA2": "67.716,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.574,00"
  },
  {
    "EOL": 400085,
    "UNIDADE": "CEI - JARDIM HERCILIA",
    "DRE": "PENHA",
    "BAS_1PAR": "11.224,80",
    "BAS_2PAR": "11.224,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.224,80",
    "REC_EXTRA2": "67.348,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.023,20"
  },
  {
    "EOL": 400086,
    "UNIDADE": "CEI - BRYAN BIGUINATI JARDIM",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "11.412,00",
    "BAS_2PAR": "11.412,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.412,00",
    "REC_EXTRA2": "68.472,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.708,00"
  },
  {
    "EOL": 400087,
    "UNIDADE": "CEI - JARDIM IPANEMA",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "11.181,60",
    "BAS_2PAR": "11.181,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.181,60",
    "REC_EXTRA2": "67.089,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "128.634,40"
  },
  {
    "EOL": 400088,
    "UNIDADE": "CEI - JARDIM JAPAO",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "11.340,00",
    "BAS_2PAR": "11.340,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.340,00",
    "REC_EXTRA2": "68.040,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.060,00"
  },
  {
    "EOL": 400089,
    "UNIDADE": "CEI - JARDIM JULIETA",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "11.293,00",
    "BAS_2PAR": "11.293,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.293,00",
    "REC_EXTRA2": "67.759,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.638,00"
  },
  {
    "EOL": 400090,
    "UNIDADE": "CEI - JARDIM KAGOHARA",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "11.350,50",
    "BAS_2PAR": "11.350,50",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.350,50",
    "REC_EXTRA2": "68.104,50",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.156,00"
  },
  {
    "EOL": 400091,
    "UNIDADE": "CEI - JARDIM KLEIN",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "11.379,60",
    "BAS_2PAR": "11.379,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.379,60",
    "REC_EXTRA2": "68.277,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.416,40"
  },
  {
    "EOL": 400092,
    "UNIDADE": "CEI - JARDIM LUSO",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "12.498,00",
    "BAS_2PAR": "12.498,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "12.498,00",
    "REC_EXTRA2": "74.988,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "144.482,00"
  },
  {
    "EOL": 400093,
    "UNIDADE": "CEI - JARDIM MACEDONIA",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "12.627,60",
    "BAS_2PAR": "12.627,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "12.627,60",
    "REC_EXTRA2": "75.765,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "145.648,40"
  },
  {
    "EOL": 400094,
    "UNIDADE": "CEI - JARDIM MAIA",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "11.239,20",
    "BAS_2PAR": "11.239,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.239,20",
    "REC_EXTRA2": "67.435,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.152,80"
  },
  {
    "EOL": 400095,
    "UNIDADE": "CEI - WILSON DANGELO BRAZ, PROF.",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "11.332,80",
    "BAS_2PAR": "11.332,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.332,80",
    "REC_EXTRA2": "67.996,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.995,20"
  },
  {
    "EOL": 400096,
    "UNIDADE": "CEI - JARDIM MARIA ALICE",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "11.394,00",
    "BAS_2PAR": "11.394,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.394,00",
    "REC_EXTRA2": "68.364,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.546,00"
  },
  {
    "EOL": 400097,
    "UNIDADE": "CEI - JOAO BENTO DE CARVALHO",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "11.469,60",
    "BAS_2PAR": "11.469,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.469,60",
    "REC_EXTRA2": "68.817,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "131.226,40"
  },
  {
    "EOL": 400098,
    "UNIDADE": "CEI - JARDIM MATARAZZO",
    "DRE": "PENHA",
    "BAS_1PAR": "11.199,60",
    "BAS_2PAR": "11.199,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.199,60",
    "REC_EXTRA2": "67.197,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "128.796,40"
  },
  {
    "EOL": 400099,
    "UNIDADE": "CEI - JARDIM MILIUNAS",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "11.268,00",
    "BAS_2PAR": "11.268,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.268,00",
    "REC_EXTRA2": "67.608,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.412,00"
  },
  {
    "EOL": 400100,
    "UNIDADE": "CEI - ALBERTINA RODRIGUES SIMON, PROFA.",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "11.174,40",
    "BAS_2PAR": "11.174,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.174,40",
    "REC_EXTRA2": "67.046,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "128.569,60"
  },
  {
    "EOL": 400101,
    "UNIDADE": "CEI - JARDIM MONJOLO",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "11.311,20",
    "BAS_2PAR": "11.311,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.311,20",
    "REC_EXTRA2": "67.867,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.800,80"
  },
  {
    "EOL": 400102,
    "UNIDADE": "CEI - JARDIM MONTE ALEGRE",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "11.520,00",
    "BAS_2PAR": "11.520,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.520,00",
    "REC_EXTRA2": "69.120,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "131.680,00"
  },
  {
    "EOL": 400103,
    "UNIDADE": "CEI - JARDIM NAKAMURA",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "11.376,00",
    "BAS_2PAR": "11.376,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.376,00",
    "REC_EXTRA2": "68.256,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.384,00"
  },
  {
    "EOL": 400104,
    "UNIDADE": "CEI - JARDIM NAZARE",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "11.260,80",
    "BAS_2PAR": "11.260,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.260,80",
    "REC_EXTRA2": "67.564,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.347,20"
  },
  {
    "EOL": 400105,
    "UNIDADE": "CEI - MARIA APARECIDA DOS SANTOS",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "11.253,60",
    "BAS_2PAR": "11.253,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.253,60",
    "REC_EXTRA2": "67.521,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.282,40"
  },
  {
    "EOL": 400106,
    "UNIDADE": "CEI - JARDIM NOVE DE JULHO",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "11.422,80",
    "BAS_2PAR": "11.422,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.422,80",
    "REC_EXTRA2": "68.536,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.805,20"
  },
  {
    "EOL": 400107,
    "UNIDADE": "CEI - JARDIM NOVO PARELHEIROS",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "11.332,80",
    "BAS_2PAR": "11.332,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.332,80",
    "REC_EXTRA2": "67.996,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.995,20"
  },
  {
    "EOL": 400108,
    "UNIDADE": "CEI - JARDIM PANAMERICANO",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "11.311,20",
    "BAS_2PAR": "11.311,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.311,20",
    "REC_EXTRA2": "67.867,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.800,80"
  },
  {
    "EOL": 400109,
    "UNIDADE": "CEI - JARDIM PERI",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "11.329,20",
    "BAS_2PAR": "11.329,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.329,20",
    "REC_EXTRA2": "67.975,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.962,80"
  },
  {
    "EOL": 400110,
    "UNIDADE": "CEI - JARDIM POPULAR",
    "DRE": "PENHA",
    "BAS_1PAR": "11.178,00",
    "BAS_2PAR": "11.178,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.178,00",
    "REC_EXTRA2": "67.068,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "128.602,00"
  },
  {
    "EOL": 400111,
    "UNIDADE": "CEI - JARDIM PRIMAVERA I",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "11.322,00",
    "BAS_2PAR": "11.322,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.322,00",
    "REC_EXTRA2": "67.932,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.898,00"
  },
  {
    "EOL": 400112,
    "UNIDADE": "CEI - AURINDO DOS SANTOS FREIRE",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "11.181,60",
    "BAS_2PAR": "11.181,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.181,60",
    "REC_EXTRA2": "67.089,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "128.634,40"
  },
  {
    "EOL": 400113,
    "UNIDADE": "CEI - JARDIM REIMBERG",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "11.311,20",
    "BAS_2PAR": "11.311,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.311,20",
    "REC_EXTRA2": "67.867,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.800,80"
  },
  {
    "EOL": 400114,
    "UNIDADE": "CEI - JARDIM RINCAO",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "11.275,20",
    "BAS_2PAR": "11.275,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.275,20",
    "REC_EXTRA2": "67.651,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.476,80"
  },
  {
    "EOL": 400115,
    "UNIDADE": "CEI - ILDEFRANCA, IRMA",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "11.217,60",
    "BAS_2PAR": "11.217,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.217,60",
    "REC_EXTRA2": "67.305,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "128.958,40"
  },
  {
    "EOL": 400116,
    "UNIDADE": "CEI - JARDIM RODOLFO PIRANI",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "11.185,20",
    "BAS_2PAR": "11.185,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.185,20",
    "REC_EXTRA2": "67.111,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "128.666,80"
  },
  {
    "EOL": 400117,
    "UNIDADE": "CEI - JARDIM RODRIGO",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "10.990,80",
    "BAS_2PAR": "10.990,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "10.990,80",
    "REC_EXTRA2": "65.944,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "122.917,20"
  },
  {
    "EOL": 400118,
    "UNIDADE": "CEI - JARDIM ROSELY",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "11.228,40",
    "BAS_2PAR": "11.228,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.228,40",
    "REC_EXTRA2": "67.370,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.055,60"
  },
  {
    "EOL": 400119,
    "UNIDADE": "CEI - JARDIM RUTH",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "11.185,20",
    "BAS_2PAR": "11.185,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.185,20",
    "REC_EXTRA2": "67.111,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "128.666,80"
  },
  {
    "EOL": 400120,
    "UNIDADE": "CEI - JARDIM SANTA MARIA",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "11.203,20",
    "BAS_2PAR": "11.203,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.203,20",
    "REC_EXTRA2": "67.219,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "128.828,80"
  },
  {
    "EOL": 400121,
    "UNIDADE": "CEI - JARDIM SANTO ANDRE",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "11.307,60",
    "BAS_2PAR": "11.307,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.307,60",
    "REC_EXTRA2": "67.845,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.768,40"
  },
  {
    "EOL": 400122,
    "UNIDADE": "CEI - JARDIM SAO BENTO VELHO",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "11.156,40",
    "BAS_2PAR": "11.156,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.156,40",
    "REC_EXTRA2": "66.938,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "124.407,60"
  },
  {
    "EOL": 400123,
    "UNIDADE": "CEI - JARDIM SAO CARLOS",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "11.192,40",
    "BAS_2PAR": "11.192,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.192,40",
    "REC_EXTRA2": "67.154,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "128.731,60"
  },
  {
    "EOL": 400124,
    "UNIDADE": "CEI - JARDIM SAO JOAQUIM",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "11.242,80",
    "BAS_2PAR": "11.242,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.242,80",
    "REC_EXTRA2": "67.456,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.185,20"
  },
  {
    "EOL": 400125,
    "UNIDADE": "CEI - JARDIM SAO LUIZ I",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "11.318,40",
    "BAS_2PAR": "11.318,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.318,40",
    "REC_EXTRA2": "67.910,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.865,60"
  },
  {
    "EOL": 400126,
    "UNIDADE": "CEI - JARDIM SAO LUIZ II",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "11.286,00",
    "BAS_2PAR": "11.286,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.286,00",
    "REC_EXTRA2": "67.716,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.574,00"
  },
  {
    "EOL": 400127,
    "UNIDADE": "CEI - JARDIM SAO MANOEL",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "11.156,40",
    "BAS_2PAR": "11.156,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.156,40",
    "REC_EXTRA2": "66.938,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "124.407,60"
  },
  {
    "EOL": 400128,
    "UNIDADE": "CEI - JARDIM SAO MARTINHO",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "11.300,40",
    "BAS_2PAR": "11.300,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.300,40",
    "REC_EXTRA2": "67.802,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.703,60"
  },
  {
    "EOL": 400129,
    "UNIDADE": "CEI - JARDIM SAO PAULO",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "13.706,40",
    "BAS_2PAR": "13.706,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.706,40",
    "REC_EXTRA2": "82.238,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "155.357,60"
  },
  {
    "EOL": 400130,
    "UNIDADE": "CEI - JARDIM SAO PEDRO",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "11.206,80",
    "BAS_2PAR": "11.206,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.206,80",
    "REC_EXTRA2": "67.240,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "128.861,20"
  },
  {
    "EOL": 400132,
    "UNIDADE": "CEI - JARDIM SAO VICENTE",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "11.124,00",
    "BAS_2PAR": "11.124,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.124,00",
    "REC_EXTRA2": "66.744,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "124.116,00"
  },
  {
    "EOL": 400133,
    "UNIDADE": "CEI - SHANGRI-LA",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "11.242,80",
    "BAS_2PAR": "11.242,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.242,80",
    "REC_EXTRA2": "67.456,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.185,20"
  },
  {
    "EOL": 400134,
    "UNIDADE": "CEI - JARDIM SILVA TELLES",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "11.210,40",
    "BAS_2PAR": "11.210,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.210,40",
    "REC_EXTRA2": "67.262,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "128.893,60"
  },
  {
    "EOL": 400135,
    "UNIDADE": "CEI - JARDIM SILVEIRA",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "11.300,00",
    "BAS_2PAR": "11.300,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.300,00",
    "REC_EXTRA2": "67.802,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.702,00"
  },
  {
    "EOL": 400136,
    "UNIDADE": "CEI - JARDIM SOMARA",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "11.311,20",
    "BAS_2PAR": "11.311,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.311,20",
    "REC_EXTRA2": "67.867,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.800,80"
  },
  {
    "EOL": 400137,
    "UNIDADE": "CEI - JARDIM SOUZA",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "11.314,80",
    "BAS_2PAR": "11.314,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.314,80",
    "REC_EXTRA2": "67.888,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.833,20"
  },
  {
    "EOL": 400138,
    "UNIDADE": "CEI - JARDIM TAIPAS",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "11.282,40",
    "BAS_2PAR": "11.282,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.282,40",
    "REC_EXTRA2": "67.694,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.541,60"
  },
  {
    "EOL": 400139,
    "UNIDADE": "CEI - JARDIM TIETE",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "11.242,80",
    "BAS_2PAR": "11.242,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.242,80",
    "REC_EXTRA2": "67.456,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.185,20"
  },
  {
    "EOL": 400140,
    "UNIDADE": "CEI - JARDIM TRES CORACOES",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "11.487,60",
    "BAS_2PAR": "11.487,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.487,60",
    "REC_EXTRA2": "68.925,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "131.388,40"
  },
  {
    "EOL": 400141,
    "UNIDADE": "CEI - JARDIM TRES ESTRELAS",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "11.289,60",
    "BAS_2PAR": "11.289,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.289,60",
    "REC_EXTRA2": "67.737,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.606,40"
  },
  {
    "EOL": 400142,
    "UNIDADE": "CEI - JARDIM TRES MARIAS",
    "DRE": "PENHA",
    "BAS_1PAR": "11.296,80",
    "BAS_2PAR": "11.296,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.296,80",
    "REC_EXTRA2": "67.780,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.671,20"
  },
  {
    "EOL": 400143,
    "UNIDADE": "CEI - JARDIM UMARIZAL",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "11.271,60",
    "BAS_2PAR": "11.271,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.271,60",
    "REC_EXTRA2": "67.629,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.444,40"
  },
  {
    "EOL": 400144,
    "UNIDADE": "CEI - JARDIM UNIVERSITARIO",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "11.271,60",
    "BAS_2PAR": "11.271,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.271,60",
    "REC_EXTRA2": "67.629,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.444,40"
  },
  {
    "EOL": 400145,
    "UNIDADE": "CEI - JARDIM VERA CRUZ",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "11.260,80",
    "BAS_2PAR": "11.260,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.260,80",
    "REC_EXTRA2": "67.564,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.347,20"
  },
  {
    "EOL": 400146,
    "UNIDADE": "CEI - JARDIM VERONIA",
    "DRE": "PENHA",
    "BAS_1PAR": "11.282,40",
    "BAS_2PAR": "11.282,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.282,40",
    "REC_EXTRA2": "67.694,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.541,60"
  },
  {
    "EOL": 400147,
    "UNIDADE": "CEI - JARDIM VILA CARRAO",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "11.275,20",
    "BAS_2PAR": "11.275,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.275,20",
    "REC_EXTRA2": "67.651,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.476,80"
  },
  {
    "EOL": 400148,
    "UNIDADE": "CEI - JARDIM VILA PEDROSO",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "11.163,60",
    "BAS_2PAR": "11.163,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.163,60",
    "REC_EXTRA2": "66.981,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "128.472,40"
  },
  {
    "EOL": 400149,
    "UNIDADE": "CEI - JARDIM VISTA ALEGRE",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "11.361,60",
    "BAS_2PAR": "11.361,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.361,60",
    "REC_EXTRA2": "68.169,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.254,40"
  },
  {
    "EOL": 400150,
    "UNIDADE": "CEI - JOAO CARLOS FAIRBANKS, VER.",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "11.260,80",
    "BAS_2PAR": "11.260,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.260,80",
    "REC_EXTRA2": "67.564,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.347,20"
  },
  {
    "EOL": 400151,
    "UNIDADE": "CEI - JOAO FRANCISCO DE HARO, VER.",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "11.134,80",
    "BAS_2PAR": "11.134,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.134,80",
    "REC_EXTRA2": "66.808,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "124.213,20"
  },
  {
    "EOL": 400152,
    "UNIDADE": "CEI - JOAO TONIOLO, VER.",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "11.296,80",
    "BAS_2PAR": "11.296,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.296,80",
    "REC_EXTRA2": "67.780,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.671,20"
  },
  {
    "EOL": 400153,
    "UNIDADE": "CEI - JOAQUIM GOUVEIA FRANCO JR., VER.",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "11.379,60",
    "BAS_2PAR": "11.379,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.379,60",
    "REC_EXTRA2": "68.277,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.416,40"
  },
  {
    "EOL": 400154,
    "UNIDADE": "CEI - JOAQUIM THOME FILHO, VER.",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "11.372,40",
    "BAS_2PAR": "11.372,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.372,40",
    "REC_EXTRA2": "68.234,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.351,60"
  },
  {
    "EOL": 400155,
    "UNIDADE": "CEI - JOCELYNE GUIMARAES FERNANDES DE MELLO",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "11.318,40",
    "BAS_2PAR": "11.318,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.318,40",
    "REC_EXTRA2": "67.910,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.865,60"
  },
  {
    "EOL": 400156,
    "UNIDADE": "CEI - JOSE ADRIANO MARREY JR., VER.",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "11.196,00",
    "BAS_2PAR": "11.196,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.196,00",
    "REC_EXTRA2": "67.176,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "128.764,00"
  },
  {
    "EOL": 400157,
    "UNIDADE": "CEI - JOSE BUSTAMANTE, VER.",
    "DRE": "PENHA",
    "BAS_1PAR": "11.250,00",
    "BAS_2PAR": "11.250,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.250,00",
    "REC_EXTRA2": "67.500,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.250,00"
  },
  {
    "EOL": 400158,
    "UNIDADE": "CEI - JOSE DE MOURA, VER.",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "11.167,20",
    "BAS_2PAR": "11.167,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.167,20",
    "REC_EXTRA2": "67.003,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "128.504,80"
  },
  {
    "EOL": 400159,
    "UNIDADE": "CEI - JOSE FERREIRA KEFFER, VER.",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "11.286,00",
    "BAS_2PAR": "11.286,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.286,00",
    "REC_EXTRA2": "67.716,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.574,00"
  },
  {
    "EOL": 400160,
    "UNIDADE": "CEI - JOSE GOMES DE MORAES NETTO, VER.",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "11.145,50",
    "BAS_2PAR": "11.145,50",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.145,50",
    "REC_EXTRA2": "66.873,50",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "124.310,00"
  },
  {
    "EOL": 400161,
    "UNIDADE": "CEI - JOSE MOLINA JR., VER",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "11.268,00",
    "BAS_2PAR": "11.268,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.268,00",
    "REC_EXTRA2": "67.608,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.412,00"
  },
  {
    "EOL": 400162,
    "UNIDADE": "CEI - JOSE OLIVEIRA ALMEIDA DINIZ, VER.",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "11.174,40",
    "BAS_2PAR": "11.174,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.174,40",
    "REC_EXTRA2": "67.046,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "128.569,60"
  },
  {
    "EOL": 400163,
    "UNIDADE": "CEI - JOSE OZI, PROF.",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "11.001,50",
    "BAS_2PAR": "11.001,50",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.001,50",
    "REC_EXTRA2": "66.009,50",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "123.014,00"
  },
  {
    "EOL": 400164,
    "UNIDADE": "CEI - JOSE SALVADOR JULIANELLI, DEP.",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "11.347,20",
    "BAS_2PAR": "11.347,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.347,20",
    "REC_EXTRA2": "68.083,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.124,80"
  },
  {
    "EOL": 400165,
    "UNIDADE": "CEI - JUSCELINO KUBITSCHEK OLIVEIRA, PRES.",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "11.329,20",
    "BAS_2PAR": "11.329,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.329,20",
    "REC_EXTRA2": "67.975,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.962,80"
  },
  {
    "EOL": 400166,
    "UNIDADE": "CEI - LAERCIO CORTE, VER.",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "11.134,80",
    "BAS_2PAR": "11.134,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.134,80",
    "REC_EXTRA2": "66.808,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "124.213,20"
  },
  {
    "EOL": 400167,
    "UNIDADE": "CEI - LAUZANE PAULISTA",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "10.929,60",
    "BAS_2PAR": "10.929,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "10.929,60",
    "REC_EXTRA2": "65.577,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "122.366,40"
  },
  {
    "EOL": 400168,
    "UNIDADE": "CEI - LIBERO ANCONA LOPES, VER.",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "11.300,40",
    "BAS_2PAR": "11.300,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.300,40",
    "REC_EXTRA2": "67.802,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.703,60"
  },
  {
    "EOL": 400169,
    "UNIDADE": "CEI - LUIZ GONZAGA DO NASCIMENTO JR.",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "11.278,80",
    "BAS_2PAR": "11.278,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.278,80",
    "REC_EXTRA2": "67.672,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.509,20"
  },
  {
    "EOL": 400170,
    "UNIDADE": "CEI - MANOEL DA NOBREGA, PDE.",
    "DRE": "PENHA",
    "BAS_1PAR": "11.156,40",
    "BAS_2PAR": "11.156,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.156,40",
    "REC_EXTRA2": "66.938,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "124.407,60"
  },
  {
    "EOL": 400171,
    "UNIDADE": "CEI - MARCIA RICCO FERRAZ",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "11.404,80",
    "BAS_2PAR": "11.404,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.404,80",
    "REC_EXTRA2": "68.428,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.643,20"
  },
  {
    "EOL": 400172,
    "UNIDADE": "CEI - MARCOS MELEGA, VER.",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "11.145,60",
    "BAS_2PAR": "11.145,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.145,60",
    "REC_EXTRA2": "66.873,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "124.310,40"
  },
  {
    "EOL": 400173,
    "UNIDADE": "CEI - MARIA AUXILIADORA DA SILVA",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "11.390,40",
    "BAS_2PAR": "11.390,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.390,40",
    "REC_EXTRA2": "68.342,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.513,60"
  },
  {
    "EOL": 400174,
    "UNIDADE": "CEI - MARIA CURSI",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "11.318,40",
    "BAS_2PAR": "11.318,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.318,40",
    "REC_EXTRA2": "67.910,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.865,60"
  },
  {
    "EOL": 400175,
    "UNIDADE": "CEI - MARIA HENRIQUETA CATITE",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "11.336,40",
    "BAS_2PAR": "11.336,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.336,40",
    "REC_EXTRA2": "68.018,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.027,60"
  },
  {
    "EOL": 400176,
    "UNIDADE": "CEI - MARIA JOSE DE SOUZA",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "11.174,40",
    "BAS_2PAR": "11.174,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.174,40",
    "REC_EXTRA2": "67.046,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "128.569,60"
  },
  {
    "EOL": 400177,
    "UNIDADE": "CEI - MARIA JOSE VASCONCELOS MANKEL, PROFA.",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "11.397,60",
    "BAS_2PAR": "11.397,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.397,60",
    "REC_EXTRA2": "68.385,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.578,40"
  },
  {
    "EOL": 400178,
    "UNIDADE": "CEI - MARILIA DE DIRCEU",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "11.160,00",
    "BAS_2PAR": "11.160,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.160,00",
    "REC_EXTRA2": "66.960,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "124.440,00"
  },
  {
    "EOL": 400179,
    "UNIDADE": "CEI - MARIO CALDANA",
    "DRE": "PENHA",
    "BAS_1PAR": "11.235,60",
    "BAS_2PAR": "11.235,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.235,60",
    "REC_EXTRA2": "67.413,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.120,40"
  },
  {
    "EOL": 400180,
    "UNIDADE": "CEI - MARIO DA COSTA BARBOSA",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "11.340,00",
    "BAS_2PAR": "11.340,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.340,00",
    "REC_EXTRA2": "68.040,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.060,00"
  },
  {
    "EOL": 400181,
    "UNIDADE": "CEI - MENINO JESUS",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "11.206,80",
    "BAS_2PAR": "11.206,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.206,80",
    "REC_EXTRA2": "67.240,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "128.861,20"
  },
  {
    "EOL": 400182,
    "UNIDADE": "CEI - MITIKO MATSUSHITA NEVOEIRO",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "13.688,40",
    "BAS_2PAR": "13.688,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.688,40",
    "REC_EXTRA2": "82.130,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "155.195,60"
  },
  {
    "EOL": 400183,
    "UNIDADE": "CEI - MONUMENTO",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "11.044,80",
    "BAS_2PAR": "11.044,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.044,80",
    "REC_EXTRA2": "66.268,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "123.403,20"
  },
  {
    "EOL": 400184,
    "UNIDADE": "CEI - NATHALIA PEDROSO ROSEMBURG, DRA.",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "11.167,20",
    "BAS_2PAR": "11.167,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.167,20",
    "REC_EXTRA2": "67.003,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "128.504,80"
  },
  {
    "EOL": 400185,
    "UNIDADE": "CEI - NAZARE",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "11.232,00",
    "BAS_2PAR": "11.232,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.232,00",
    "REC_EXTRA2": "67.392,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.088,00"
  },
  {
    "EOL": 400186,
    "UNIDADE": "CEI - NAZIR MIGUEL, VER.",
    "DRE": "PENHA",
    "BAS_1PAR": "11.318,40",
    "BAS_2PAR": "11.318,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.318,40",
    "REC_EXTRA2": "67.910,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.865,60"
  },
  {
    "EOL": 400187,
    "UNIDADE": "CEI - NEIDE KETELHUT",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "12.566,40",
    "BAS_2PAR": "12.566,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "12.566,40",
    "REC_EXTRA2": "75.398,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "145.097,60"
  },
  {
    "EOL": 400188,
    "UNIDADE": "CEI - NINHO ALEGRE",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "11.275,20",
    "BAS_2PAR": "11.275,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.275,20",
    "REC_EXTRA2": "67.651,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.476,80"
  },
  {
    "EOL": 400189,
    "UNIDADE": "CEI - ODILIA ALVES ALMEIDA SANTOS",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "11.239,20",
    "BAS_2PAR": "11.239,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.239,20",
    "REC_EXTRA2": "67.435,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.152,80"
  },
  {
    "EOL": 400190,
    "UNIDADE": "CEI - OLGA BENARIO PRESTES",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "11.149,20",
    "BAS_2PAR": "11.149,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.149,20",
    "REC_EXTRA2": "66.895,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "124.342,80"
  },
  {
    "EOL": 400191,
    "UNIDADE": "CEI - ONADYR MARCONDES",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "12.620,40",
    "BAS_2PAR": "12.620,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "12.620,40",
    "REC_EXTRA2": "75.722,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "145.583,60"
  },
  {
    "EOL": 400192,
    "UNIDADE": "CEI - PARADA XV DE NOVEMBRO",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "11.329,20",
    "BAS_2PAR": "11.329,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.329,20",
    "REC_EXTRA2": "67.975,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.962,80"
  },
  {
    "EOL": 400193,
    "UNIDADE": "CEI - VANDA MARIA RODRIGUES DOS SANTOS, PROFA.",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "11.178,00",
    "BAS_2PAR": "11.178,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.178,00",
    "REC_EXTRA2": "67.068,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "128.602,00"
  },
  {
    "EOL": 400194,
    "UNIDADE": "CEI - PARQUE BOTURUSSU",
    "DRE": "PENHA",
    "BAS_1PAR": "11.257,20",
    "BAS_2PAR": "11.257,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.257,20",
    "REC_EXTRA2": "67.543,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.314,80"
  },
  {
    "EOL": 400195,
    "UNIDADE": "CEI - PARQUE CASA DE PEDRA",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "11.286,00",
    "BAS_2PAR": "11.286,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.286,00",
    "REC_EXTRA2": "67.716,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.574,00"
  },
  {
    "EOL": 400196,
    "UNIDADE": "CEI - PARQUE COCAIA",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "11.419,20",
    "BAS_2PAR": "11.419,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.419,20",
    "REC_EXTRA2": "68.515,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.772,80"
  },
  {
    "EOL": 400197,
    "UNIDADE": "CEI - PARQUE DAS PAINEIRAS",
    "DRE": "PENHA",
    "BAS_1PAR": "11.300,40",
    "BAS_2PAR": "11.300,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.300,40",
    "REC_EXTRA2": "67.802,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.703,60"
  },
  {
    "EOL": 400198,
    "UNIDADE": "CEI - PARQUE FERNANDA",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "11.314,80",
    "BAS_2PAR": "11.314,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.314,80",
    "REC_EXTRA2": "67.888,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.833,20"
  },
  {
    "EOL": 400199,
    "UNIDADE": "CEI - PARQUE FIGUEIRA GRANDE",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "11.232,00",
    "BAS_2PAR": "11.232,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.232,00",
    "REC_EXTRA2": "67.392,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.088,00"
  },
  {
    "EOL": 400200,
    "UNIDADE": "CEI - PARQUE FONGARO",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "11.340,00",
    "BAS_2PAR": "11.340,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.340,00",
    "REC_EXTRA2": "68.040,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.060,00"
  },
  {
    "EOL": 400201,
    "UNIDADE": "CEI - PARQUE GRAJAU",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "11.350,80",
    "BAS_2PAR": "11.350,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.350,80",
    "REC_EXTRA2": "68.104,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.157,20"
  },
  {
    "EOL": 400202,
    "UNIDADE": "CEI - PARQUE GUARANI",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "11.174,40",
    "BAS_2PAR": "11.174,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.174,40",
    "REC_EXTRA2": "67.046,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "128.569,60"
  },
  {
    "EOL": 400203,
    "UNIDADE": "CEI - PARQUE NOVA SANTO AMARO",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "11.235,60",
    "BAS_2PAR": "11.235,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.235,60",
    "REC_EXTRA2": "67.413,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.120,40"
  },
  {
    "EOL": 400204,
    "UNIDADE": "CEI - PARQUE NOVO MUNDO",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "11.332,80",
    "BAS_2PAR": "11.332,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.332,80",
    "REC_EXTRA2": "67.996,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.995,20"
  },
  {
    "EOL": 400205,
    "UNIDADE": "CEI - PARQUE REGINA",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "11.372,40",
    "BAS_2PAR": "11.372,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.372,40",
    "REC_EXTRA2": "68.234,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.351,60"
  },
  {
    "EOL": 400206,
    "UNIDADE": "CEI - MARIA DO CARMO PAZOS FERNANDEZ - MADU, PROFA.",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "11.196,00",
    "BAS_2PAR": "11.196,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.196,00",
    "REC_EXTRA2": "67.176,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "128.764,00"
  },
  {
    "EOL": 400207,
    "UNIDADE": "CEI - DORALICE PEREIRA DE SOUZA LEONARDO",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "11.163,60",
    "BAS_2PAR": "11.163,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.163,60",
    "REC_EXTRA2": "66.981,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "128.472,40"
  },
  {
    "EOL": 400208,
    "UNIDADE": "CEI - PARQUE SANTA MADALENA",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "11.232,00",
    "BAS_2PAR": "11.232,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.232,00",
    "REC_EXTRA2": "67.392,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.088,00"
  },
  {
    "EOL": 400209,
    "UNIDADE": "CEI - PARQUE SANTA MARGARIDA",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "11.275,20",
    "BAS_2PAR": "11.275,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.275,20",
    "REC_EXTRA2": "67.651,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.476,80"
  },
  {
    "EOL": 400210,
    "UNIDADE": "CEI - PARQUE SANTA RITA",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "11.228,40",
    "BAS_2PAR": "11.228,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.228,40",
    "REC_EXTRA2": "67.370,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.055,60"
  },
  {
    "EOL": 400211,
    "UNIDADE": "CEI - PARQUE SANTO ANTONIO",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "11.325,60",
    "BAS_2PAR": "11.325,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.325,60",
    "REC_EXTRA2": "67.953,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.930,40"
  },
  {
    "EOL": 400212,
    "UNIDADE": "CEI - PARQUE SAO RAFAEL II",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "11.224,80",
    "BAS_2PAR": "11.224,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.224,80",
    "REC_EXTRA2": "67.348,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.023,20"
  },
  {
    "EOL": 400213,
    "UNIDADE": "CEI - PARQUE SAVOY CITY",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "11.318,40",
    "BAS_2PAR": "11.318,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.318,40",
    "REC_EXTRA2": "67.910,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.865,60"
  },
  {
    "EOL": 400214,
    "UNIDADE": "CEI - PAULO CESAR FONTELLES DE LIMA",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "11.246,40",
    "BAS_2PAR": "11.246,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.246,40",
    "REC_EXTRA2": "67.478,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.217,60"
  },
  {
    "EOL": 400215,
    "UNIDADE": "CEI - PAULO E ADMAR",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "11.278,80",
    "BAS_2PAR": "11.278,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.278,80",
    "REC_EXTRA2": "67.672,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.509,20"
  },
  {
    "EOL": 400216,
    "UNIDADE": "CEI - PEDRO BRASIL BANDECCHI, VER.",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "11.242,80",
    "BAS_2PAR": "11.242,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.242,80",
    "REC_EXTRA2": "67.456,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.185,20"
  },
  {
    "EOL": 400217,
    "UNIDADE": "CEI - PENHA",
    "DRE": "PENHA",
    "BAS_1PAR": "11.217,60",
    "BAS_2PAR": "11.217,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.217,60",
    "REC_EXTRA2": "67.305,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "128.958,40"
  },
  {
    "EOL": 400218,
    "UNIDADE": "CEI - O PEQUENO SEAREIRO",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "11.336,40",
    "BAS_2PAR": "11.336,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.336,40",
    "REC_EXTRA2": "68.018,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.027,60"
  },
  {
    "EOL": 400219,
    "UNIDADE": "CEI - RAUL TABAJARA VIDIGAL LEITAO, VER.",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "11.408,40",
    "BAS_2PAR": "11.408,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.408,40",
    "REC_EXTRA2": "68.450,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.675,60"
  },
  {
    "EOL": 400220,
    "UNIDADE": "CEI - RENATO ANTONIO CHECCHIA, VER.",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "11.156,40",
    "BAS_2PAR": "11.156,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.156,40",
    "REC_EXTRA2": "66.938,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "124.407,60"
  },
  {
    "EOL": 400221,
    "UNIDADE": "CEI - ROBERTO ARANTES LANHOSO",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "11.487,50",
    "BAS_2PAR": "11.487,50",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.487,50",
    "REC_EXTRA2": "68.925,50",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "131.388,00"
  },
  {
    "EOL": 400222,
    "UNIDADE": "CEI - ROBERTO GOMES PEDROSA, VER.",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "11.174,40",
    "BAS_2PAR": "11.174,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.174,40",
    "REC_EXTRA2": "67.046,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "128.569,60"
  },
  {
    "EOL": 400223,
    "UNIDADE": "CEI - RUBENS GRANJA, VER.",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "11.340,00",
    "BAS_2PAR": "11.340,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.340,00",
    "REC_EXTRA2": "68.040,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.060,00"
  },
  {
    "EOL": 400224,
    "UNIDADE": "CEI - SALVADOR LO TURCO",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "11.368,50",
    "BAS_2PAR": "11.368,50",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.368,50",
    "REC_EXTRA2": "68.212,50",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.318,00"
  },
  {
    "EOL": 400225,
    "UNIDADE": "CEI - SAMIR RACHID SALIBA",
    "DRE": "PENHA",
    "BAS_1PAR": "11.340,00",
    "BAS_2PAR": "11.340,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.340,00",
    "REC_EXTRA2": "68.040,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.060,00"
  },
  {
    "EOL": 400226,
    "UNIDADE": "CEI - SANTA BARBARA",
    "DRE": "PENHA",
    "BAS_1PAR": "11.224,80",
    "BAS_2PAR": "11.224,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.224,80",
    "REC_EXTRA2": "67.348,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.023,20"
  },
  {
    "EOL": 400227,
    "UNIDADE": "CEI - MARIA AUGUSTA DE PAULA, PROFA.",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "11.318,40",
    "BAS_2PAR": "11.318,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.318,40",
    "REC_EXTRA2": "67.910,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.865,60"
  },
  {
    "EOL": 400228,
    "UNIDADE": "CEI - SAO JORGE ARPOADOR",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "11.368,50",
    "BAS_2PAR": "11.368,50",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.368,50",
    "REC_EXTRA2": "68.212,50",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.318,00"
  },
  {
    "EOL": 400229,
    "UNIDADE": "CEI - SAO LUIZ",
    "DRE": "PENHA",
    "BAS_1PAR": "11.282,40",
    "BAS_2PAR": "11.282,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.282,40",
    "REC_EXTRA2": "67.694,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.541,60"
  },
  {
    "EOL": 400230,
    "UNIDADE": "CEI - SILVIA COVAS",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "11.322,00",
    "BAS_2PAR": "11.322,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.322,00",
    "REC_EXTRA2": "67.932,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.898,00"
  },
  {
    "EOL": 400231,
    "UNIDADE": "CEI - SOLEDAD BARRET VIEDMA",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "11.271,60",
    "BAS_2PAR": "11.271,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.271,60",
    "REC_EXTRA2": "67.629,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.444,40"
  },
  {
    "EOL": 400232,
    "UNIDADE": "CEI - SUZANA CAMPOS TAUIL",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "11.188,80",
    "BAS_2PAR": "11.188,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.188,80",
    "REC_EXTRA2": "67.132,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "128.699,20"
  },
  {
    "EOL": 400233,
    "UNIDADE": "CEI - TANCREDO DE ALMEIDA NEVES, PRES.",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "11.383,20",
    "BAS_2PAR": "11.383,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.383,20",
    "REC_EXTRA2": "68.299,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.448,80"
  },
  {
    "EOL": 400234,
    "UNIDADE": "CEI - TEOTONIO VILELA",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "11.325,50",
    "BAS_2PAR": "11.325,50",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.325,50",
    "REC_EXTRA2": "67.953,50",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.930,00"
  },
  {
    "EOL": 400235,
    "UNIDADE": "CEI - VELEIROS",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "11.293,20",
    "BAS_2PAR": "11.293,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.293,20",
    "REC_EXTRA2": "67.759,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.638,80"
  },
  {
    "EOL": 400236,
    "UNIDADE": "CEI - VICENTINA VELASCO, AS. SOCIAL",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "11.322,00",
    "BAS_2PAR": "11.322,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.322,00",
    "REC_EXTRA2": "67.932,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.898,00"
  },
  {
    "EOL": 400237,
    "UNIDADE": "CEI - CRISTINA, ME.",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "11.422,80",
    "BAS_2PAR": "11.422,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.422,80",
    "REC_EXTRA2": "68.536,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.805,20"
  },
  {
    "EOL": 400238,
    "UNIDADE": "CEI - VILA BASILEIA",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "11.289,60",
    "BAS_2PAR": "11.289,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.289,60",
    "REC_EXTRA2": "67.737,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.606,40"
  },
  {
    "EOL": 400239,
    "UNIDADE": "CEI - VILA BRASILANDIA",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "11.127,60",
    "BAS_2PAR": "11.127,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.127,60",
    "REC_EXTRA2": "66.765,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "124.148,40"
  },
  {
    "EOL": 400240,
    "UNIDADE": "CEI - VILA CALU",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "12.584,40",
    "BAS_2PAR": "12.584,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "12.584,40",
    "REC_EXTRA2": "75.506,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "145.259,60"
  },
  {
    "EOL": 400241,
    "UNIDADE": "CEI - VILA CARMOSINA",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "11.340,00",
    "BAS_2PAR": "11.340,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.340,00",
    "REC_EXTRA2": "68.040,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.060,00"
  },
  {
    "EOL": 400242,
    "UNIDADE": "CEI - VILA CHUCA",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "11.275,20",
    "BAS_2PAR": "11.275,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.275,20",
    "REC_EXTRA2": "67.651,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.476,80"
  },
  {
    "EOL": 400243,
    "UNIDADE": "CEI - MARIA DA GLORIA FREIRE LEMOS",
    "DRE": "PENHA",
    "BAS_1PAR": "11.282,40",
    "BAS_2PAR": "11.282,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.282,40",
    "REC_EXTRA2": "67.694,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.541,60"
  },
  {
    "EOL": 400244,
    "UNIDADE": "CEI - ANGELA MARIA FERNANDES",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "13.666,80",
    "BAS_2PAR": "13.666,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.666,80",
    "REC_EXTRA2": "82.000,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "155.001,20"
  },
  {
    "EOL": 400245,
    "UNIDADE": "CEI - VILA CONSTANCA",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "11.347,20",
    "BAS_2PAR": "11.347,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.347,20",
    "REC_EXTRA2": "68.083,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.124,80"
  },
  {
    "EOL": 400246,
    "UNIDADE": "CEI - VILA CONSTANCIA",
    "DRE": "PENHA",
    "BAS_1PAR": "11.196,00",
    "BAS_2PAR": "11.196,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.196,00",
    "REC_EXTRA2": "67.176,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "128.764,00"
  },
  {
    "EOL": 400247,
    "UNIDADE": "CEI - PALMIRA DOS SANTOS ABRANTE",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "11.343,60",
    "BAS_2PAR": "11.343,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.343,60",
    "REC_EXTRA2": "68.061,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.092,40"
  },
  {
    "EOL": 400248,
    "UNIDADE": "CEI - VILA ERNESTINA",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "11.455,20",
    "BAS_2PAR": "11.455,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.455,20",
    "REC_EXTRA2": "68.731,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "131.096,80"
  },
  {
    "EOL": 400249,
    "UNIDADE": "CEI - VILA GUSTAVO",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "11.325,60",
    "BAS_2PAR": "11.325,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.325,60",
    "REC_EXTRA2": "67.953,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.930,40"
  },
  {
    "EOL": 400250,
    "UNIDADE": "CEI - VILA IMPERIO",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "11.415,60",
    "BAS_2PAR": "11.415,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.415,60",
    "REC_EXTRA2": "68.493,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.740,40"
  },
  {
    "EOL": 400251,
    "UNIDADE": "CEI - VILA LIBANESA",
    "DRE": "PENHA",
    "BAS_1PAR": "11.376,00",
    "BAS_2PAR": "11.376,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.376,00",
    "REC_EXTRA2": "68.256,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.384,00"
  },
  {
    "EOL": 400252,
    "UNIDADE": "CEI - DOMINGOS RUFINO DE SOUZA",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "11.329,20",
    "BAS_2PAR": "11.329,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.329,20",
    "REC_EXTRA2": "67.975,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.962,80"
  },
  {
    "EOL": 400253,
    "UNIDADE": "CEI - VILA MISSIONARIA",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "11.296,80",
    "BAS_2PAR": "11.296,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.296,80",
    "REC_EXTRA2": "67.780,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.671,20"
  },
  {
    "EOL": 400254,
    "UNIDADE": "CEI - VILA PENTEADO",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "11.376,00",
    "BAS_2PAR": "11.376,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.376,00",
    "REC_EXTRA2": "68.256,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.384,00"
  },
  {
    "EOL": 400255,
    "UNIDADE": "CEI - VILA PERUS",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "11.304,00",
    "BAS_2PAR": "11.304,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.304,00",
    "REC_EXTRA2": "67.824,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.736,00"
  },
  {
    "EOL": 400256,
    "UNIDADE": "CEI - VILA PRADO",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "11.379,60",
    "BAS_2PAR": "11.379,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.379,60",
    "REC_EXTRA2": "68.277,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.416,40"
  },
  {
    "EOL": 400257,
    "UNIDADE": "CEI - VILA PRAIA",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "11.185,20",
    "BAS_2PAR": "11.185,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.185,20",
    "REC_EXTRA2": "67.111,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "128.666,80"
  },
  {
    "EOL": 400258,
    "UNIDADE": "CEI - VILA PROGRESSO",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "11.278,80",
    "BAS_2PAR": "11.278,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.278,80",
    "REC_EXTRA2": "67.672,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.509,20"
  },
  {
    "EOL": 400259,
    "UNIDADE": "CEI - VILA RAMOS",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "11.286,00",
    "BAS_2PAR": "11.286,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.286,00",
    "REC_EXTRA2": "67.716,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.574,00"
  },
  {
    "EOL": 400260,
    "UNIDADE": "CEI - VILA SALETE",
    "DRE": "PENHA",
    "BAS_1PAR": "11.286,00",
    "BAS_2PAR": "11.286,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.286,00",
    "REC_EXTRA2": "67.716,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.574,00"
  },
  {
    "EOL": 400261,
    "UNIDADE": "CEI - VILA SANTA INES",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "11.228,40",
    "BAS_2PAR": "11.228,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.228,40",
    "REC_EXTRA2": "67.370,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.055,60"
  },
  {
    "EOL": 400262,
    "UNIDADE": "CEI - SANTA TERESA",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "11.124,00",
    "BAS_2PAR": "11.124,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.124,00",
    "REC_EXTRA2": "66.744,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "124.116,00"
  },
  {
    "EOL": 400263,
    "UNIDADE": "CEI - VILA SANTA TERESINHA",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "11.350,80",
    "BAS_2PAR": "11.350,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.350,80",
    "REC_EXTRA2": "68.104,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.157,20"
  },
  {
    "EOL": 400264,
    "UNIDADE": "CEI - VILA SAO FRANCISCO",
    "DRE": "PENHA",
    "BAS_1PAR": "11.221,20",
    "BAS_2PAR": "11.221,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.221,20",
    "REC_EXTRA2": "67.327,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "128.990,80"
  },
  {
    "EOL": 400265,
    "UNIDADE": "CEI - CELIA REGINA KUHL, PROFA.",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "12.573,60",
    "BAS_2PAR": "12.573,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "12.573,60",
    "REC_EXTRA2": "75.441,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "145.162,40"
  },
  {
    "EOL": 400266,
    "UNIDADE": "CEI - WILSON JOSE ABDALLA",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "11.242,80",
    "BAS_2PAR": "11.242,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.242,80",
    "REC_EXTRA2": "67.456,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.185,20"
  },
  {
    "EOL": 400267,
    "UNIDADE": "CEI - YOJIRO TAKAOKA",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "11.253,60",
    "BAS_2PAR": "11.253,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.253,60",
    "REC_EXTRA2": "67.521,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.282,40"
  },
  {
    "EOL": 400268,
    "UNIDADE": "CEI - YVONE LEMOS DE ALMEIDA FRAGA",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "11.361,50",
    "BAS_2PAR": "11.361,50",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.361,50",
    "REC_EXTRA2": "68.169,50",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.254,00"
  },
  {
    "EOL": 400269,
    "UNIDADE": "CEI - YVONE MALUHY JOSEPF SABGA",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "11.224,50",
    "BAS_2PAR": "11.224,50",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.224,50",
    "REC_EXTRA2": "67.348,50",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.022,00"
  },
  {
    "EOL": 400270,
    "UNIDADE": "CEI - ZACARIA MAURO FACCIO GONCALVES",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "11.221,20",
    "BAS_2PAR": "11.221,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.221,20",
    "REC_EXTRA2": "67.327,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "128.990,80"
  },
  {
    "EOL": 400272,
    "UNIDADE": "CEI - ROSA SABAG ADDAS",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "11.214,00",
    "BAS_2PAR": "11.214,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.214,00",
    "REC_EXTRA2": "67.284,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "128.926,00"
  },
  {
    "EOL": 400274,
    "UNIDADE": "CEI - CIDADE DE GENEBRA",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "11.322,00",
    "BAS_2PAR": "11.322,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.322,00",
    "REC_EXTRA2": "67.932,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.898,00"
  },
  {
    "EOL": 400275,
    "UNIDADE": "CEI - NICOLAI NICOLAEVICH KOCHERGIN",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "11.372,40",
    "BAS_2PAR": "11.372,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.372,40",
    "REC_EXTRA2": "68.234,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.351,60"
  },
  {
    "EOL": 400276,
    "UNIDADE": "CEI - GUILHERME HENRIQUE PINTO COELHO",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "11.361,60",
    "BAS_2PAR": "11.361,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.361,60",
    "REC_EXTRA2": "68.169,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.254,40"
  },
  {
    "EOL": 400277,
    "UNIDADE": "CEI - MARIELCIA FLORENCIO DE MORAIS, PROFA.",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "11.455,20",
    "BAS_2PAR": "11.455,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.455,20",
    "REC_EXTRA2": "68.731,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "131.096,80"
  },
  {
    "EOL": 400278,
    "UNIDADE": "CEI - MARIO PEREIRA COSTA, PROF.",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "11.329,20",
    "BAS_2PAR": "11.329,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.329,20",
    "REC_EXTRA2": "67.975,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.962,80"
  },
  {
    "EOL": 400279,
    "UNIDADE": "CEI - MARIA CONCEICAO MONTEIRO AYRES, PROFA.",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "11.271,60",
    "BAS_2PAR": "11.271,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.271,60",
    "REC_EXTRA2": "67.629,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.444,40"
  },
  {
    "EOL": 400280,
    "UNIDADE": "CEI - ADHEMAR FERREIRA DA SILVA",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "11.350,80",
    "BAS_2PAR": "11.350,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.350,80",
    "REC_EXTRA2": "68.104,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.157,20"
  },
  {
    "EOL": 400281,
    "UNIDADE": "CEI - GOITI",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "11.109,60",
    "BAS_2PAR": "11.109,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.109,60",
    "REC_EXTRA2": "66.657,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "123.986,40"
  },
  {
    "EOL": 400282,
    "UNIDADE": "CEI CEU - WALTER DE ANDRADE, PROF.",
    "DRE": "PENHA",
    "BAS_1PAR": "12.602,40",
    "BAS_2PAR": "12.602,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "12.602,40",
    "REC_EXTRA2": "75.614,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "145.421,60"
  },
  {
    "EOL": 400283,
    "UNIDADE": "CEI - BALNEARIO MAR PAULISTA",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "11.037,60",
    "BAS_2PAR": "11.037,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.037,60",
    "REC_EXTRA2": "66.225,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "123.338,40"
  },
  {
    "EOL": 400284,
    "UNIDADE": "CEI - LUCIANO MENDES DE ALMEIDA, DOM",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "11.372,40",
    "BAS_2PAR": "11.372,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.372,40",
    "REC_EXTRA2": "68.234,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.351,60"
  },
  {
    "EOL": 400285,
    "UNIDADE": "CEI - JARDIM ROSA MARIA",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "11.109,60",
    "BAS_2PAR": "11.109,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.109,60",
    "REC_EXTRA2": "66.657,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "123.986,40"
  },
  {
    "EOL": 400286,
    "UNIDADE": "CEI - JAMIR DAGIR",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "11.199,60",
    "BAS_2PAR": "11.199,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.199,60",
    "REC_EXTRA2": "67.197,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "128.796,40"
  },
  {
    "EOL": 400287,
    "UNIDADE": "CEI - CELSO DANIEL, PROF.",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "11.156,40",
    "BAS_2PAR": "11.156,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.156,40",
    "REC_EXTRA2": "66.938,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "124.407,60"
  },
  {
    "EOL": 400288,
    "UNIDADE": "CEI - EDUARDO DE CAMPOS ROSMANINHO, DR.",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "10.987,20",
    "BAS_2PAR": "10.987,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "10.987,20",
    "REC_EXTRA2": "65.923,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "122.884,80"
  },
  {
    "EOL": 400290,
    "UNIDADE": "CEI - CONJ. HABITACIONAL TEXIMA",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "11.199,60",
    "BAS_2PAR": "11.199,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.199,60",
    "REC_EXTRA2": "67.197,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "128.796,40"
  },
  {
    "EOL": 400291,
    "UNIDADE": "CEI - MATIAS BONAR GONZALEZ, PE.",
    "DRE": "PENHA",
    "BAS_1PAR": "11.228,40",
    "BAS_2PAR": "11.228,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.228,40",
    "REC_EXTRA2": "67.370,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.055,60"
  },
  {
    "EOL": 400292,
    "UNIDADE": "CEI - ABGAIL DA ROCHA MORENO, PROFA.",
    "DRE": "PENHA",
    "BAS_1PAR": "11.242,50",
    "BAS_2PAR": "11.242,50",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.242,50",
    "REC_EXTRA2": "67.456,50",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.184,00"
  },
  {
    "EOL": 400293,
    "UNIDADE": "CEI - RECANTO DOS HUMILDES",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "11.253,60",
    "BAS_2PAR": "11.253,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.253,60",
    "REC_EXTRA2": "67.521,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.282,40"
  },
  {
    "EOL": 400294,
    "UNIDADE": "CEI - JARDIM CLIMAX II",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "11.275,20",
    "BAS_2PAR": "11.275,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.275,20",
    "REC_EXTRA2": "67.651,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.476,80"
  },
  {
    "EOL": 400295,
    "UNIDADE": "CEI - VILA MARILENA",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "11.080,80",
    "BAS_2PAR": "11.080,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.080,80",
    "REC_EXTRA2": "66.484,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "123.727,20"
  },
  {
    "EOL": 400296,
    "UNIDADE": "CEI - TITO DE ALENCAR, FREI",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "11.210,40",
    "BAS_2PAR": "11.210,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.210,40",
    "REC_EXTRA2": "67.262,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "128.893,60"
  },
  {
    "EOL": 400297,
    "UNIDADE": "CEI - JOAO UBALDO RIBEIRO",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "11.264,40",
    "BAS_2PAR": "11.264,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.264,40",
    "REC_EXTRA2": "67.586,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.379,60"
  },
  {
    "EOL": 400298,
    "UNIDADE": "CEI - JARDIM DAS VERTENTES",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "11.311,00",
    "BAS_2PAR": "11.311,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.311,00",
    "REC_EXTRA2": "67.867,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.800,00"
  },
  {
    "EOL": 400299,
    "UNIDADE": "CEI - DOMINGUINHOS",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "11.188,80",
    "BAS_2PAR": "11.188,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.188,80",
    "REC_EXTRA2": "67.132,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "128.699,20"
  },
  {
    "EOL": 400300,
    "UNIDADE": "CEI - CITY JARAGUA IV",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "11.300,40",
    "BAS_2PAR": "11.300,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.300,40",
    "REC_EXTRA2": "67.802,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.703,60"
  },
  {
    "EOL": 400301,
    "UNIDADE": "CEI - AIRTON PEREIRA DA SILVA, FREI",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "11.354,40",
    "BAS_2PAR": "11.354,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.354,40",
    "REC_EXTRA2": "68.126,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.189,60"
  },
  {
    "EOL": 400302,
    "UNIDADE": "CEI - RIO PEQUENO II",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "11.232,00",
    "BAS_2PAR": "11.232,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.232,00",
    "REC_EXTRA2": "67.392,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.088,00"
  },
  {
    "EOL": 400303,
    "UNIDADE": "CEI - MADRE PAULINA",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "11.293,20",
    "BAS_2PAR": "11.293,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.293,20",
    "REC_EXTRA2": "67.759,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.638,80"
  },
  {
    "EOL": 400304,
    "UNIDADE": "CEI - ALICE APARECIDA DE SOUZA, PROFA.",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "11.232,00",
    "BAS_2PAR": "11.232,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.232,00",
    "REC_EXTRA2": "67.392,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.088,00"
  },
  {
    "EOL": 400305,
    "UNIDADE": "CEI - PINHEIROS",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "11.113,00",
    "BAS_2PAR": "11.113,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.113,00",
    "REC_EXTRA2": "66.679,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "124.018,00"
  },
  {
    "EOL": 400377,
    "UNIDADE": "CEI - PARQUE EDU CHAVES",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "11.376,00",
    "BAS_2PAR": "11.376,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.376,00",
    "REC_EXTRA2": "68.256,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.384,00"
  },
  {
    "EOL": 400461,
    "UNIDADE": "CEI - JARDIM DAS ORQUIDEAS",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "11.268,00",
    "BAS_2PAR": "11.268,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.268,00",
    "REC_EXTRA2": "67.608,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.412,00"
  },
  {
    "EOL": 400489,
    "UNIDADE": "CEI - VILA AIMORE",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "11.286,00",
    "BAS_2PAR": "11.286,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.286,00",
    "REC_EXTRA2": "67.716,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.574,00"
  },
  {
    "EOL": 400496,
    "UNIDADE": "CEI - 13 DE MAIO",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "11.091,60",
    "BAS_2PAR": "11.091,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.091,60",
    "REC_EXTRA2": "66.549,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "123.824,40"
  },
  {
    "EOL": 400505,
    "UNIDADE": "CEI - ICAMI TIBA",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "11.178,00",
    "BAS_2PAR": "11.178,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.178,00",
    "REC_EXTRA2": "67.068,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "128.602,00"
  },
  {
    "EOL": 400511,
    "UNIDADE": "CEI - PAULO COCHRANE SUPLICY",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "11.368,80",
    "BAS_2PAR": "11.368,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.368,80",
    "REC_EXTRA2": "68.212,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.319,20"
  },
  {
    "EOL": 400512,
    "UNIDADE": "CEI CEU - ALVARENGA",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "14.846,40",
    "BAS_2PAR": "14.846,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.846,40",
    "REC_EXTRA2": "89.078,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "169.617,60"
  },
  {
    "EOL": 400513,
    "UNIDADE": "CEI CEU - DIRCE MIGLIACCIO",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "12.631,20",
    "BAS_2PAR": "12.631,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "12.631,20",
    "REC_EXTRA2": "75.787,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "145.680,80"
  },
  {
    "EOL": 400514,
    "UNIDADE": "CEI CEU - BUTANTA",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "12.595,00",
    "BAS_2PAR": "12.595,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "12.595,00",
    "REC_EXTRA2": "75.571,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "145.356,00"
  },
  {
    "EOL": 400515,
    "UNIDADE": "CEI CEU - CIDADE DUTRA",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "12.580,80",
    "BAS_2PAR": "12.580,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "12.580,80",
    "REC_EXTRA2": "75.484,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "145.227,20"
  },
  {
    "EOL": 400516,
    "UNIDADE": "CEI CEU - JOSELY MARIA CARDOSO BENTO",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "14.770,80",
    "BAS_2PAR": "14.770,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.770,80",
    "REC_EXTRA2": "88.624,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "168.937,20"
  },
  {
    "EOL": 400517,
    "UNIDADE": "CEI CEU - EVANIR APARECIDA HILARIO, PROFA.",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "12.595,20",
    "BAS_2PAR": "12.595,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "12.595,20",
    "REC_EXTRA2": "75.571,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "145.356,80"
  },
  {
    "EOL": 400518,
    "UNIDADE": "CEI CEU - MENINOS",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "12.616,80",
    "BAS_2PAR": "12.616,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "12.616,80",
    "REC_EXTRA2": "75.700,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "145.551,20"
  },
  {
    "EOL": 400519,
    "UNIDADE": "CEI CEU - NAVEGANTES",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "12.660,00",
    "BAS_2PAR": "12.660,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "12.660,00",
    "REC_EXTRA2": "75.960,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "145.940,00"
  },
  {
    "EOL": 400520,
    "UNIDADE": "CEI CEU - PARQUE SAO CARLOS",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "12.660,00",
    "BAS_2PAR": "12.660,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "12.660,00",
    "REC_EXTRA2": "75.960,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "145.940,00"
  },
  {
    "EOL": 400521,
    "UNIDADE": "CEI CEU - PARQUE VEREDAS",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "12.588,00",
    "BAS_2PAR": "12.588,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "12.588,00",
    "REC_EXTRA2": "75.528,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "145.292,00"
  },
  {
    "EOL": 400522,
    "UNIDADE": "CEI CEU - PERA MARMELO",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "14.824,80",
    "BAS_2PAR": "14.824,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.824,80",
    "REC_EXTRA2": "88.948,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "169.423,20"
  },
  {
    "EOL": 400523,
    "UNIDADE": "CEI CEU - PERUS",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "12.598,80",
    "BAS_2PAR": "12.598,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "12.598,80",
    "REC_EXTRA2": "75.592,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "145.389,20"
  },
  {
    "EOL": 400524,
    "UNIDADE": "CEI CEU - SAO MATEUS",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "14.767,20",
    "BAS_2PAR": "14.767,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.767,20",
    "REC_EXTRA2": "88.603,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "168.904,80"
  },
  {
    "EOL": 400525,
    "UNIDADE": "CEI CEU - SAO RAFAEL",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "13.756,80",
    "BAS_2PAR": "13.756,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.756,80",
    "REC_EXTRA2": "82.540,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "155.811,20"
  },
  {
    "EOL": 400526,
    "UNIDADE": "CEI CEU - TRES LAGOS",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "14.814,00",
    "BAS_2PAR": "14.814,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.814,00",
    "REC_EXTRA2": "88.884,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "169.326,00"
  },
  {
    "EOL": 400527,
    "UNIDADE": "CEI CEU - VILA ATLANTICA",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "13.746,00",
    "BAS_2PAR": "13.746,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.746,00",
    "REC_EXTRA2": "82.476,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "155.714,00"
  },
  {
    "EOL": 400528,
    "UNIDADE": "CEI CEU - ANA LUCIA DE HOLANDA GAMBOA, PROFA.",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "12.573,60",
    "BAS_2PAR": "12.573,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "12.573,60",
    "REC_EXTRA2": "75.441,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "145.162,40"
  },
  {
    "EOL": 400529,
    "UNIDADE": "CEI CEU - ROSA DA CHINA",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "13.724,40",
    "BAS_2PAR": "13.724,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.724,40",
    "REC_EXTRA2": "82.346,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "155.519,60"
  },
  {
    "EOL": 400534,
    "UNIDADE": "CEI CEU - CASA BLANCA",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "14.788,80",
    "BAS_2PAR": "14.788,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.788,80",
    "REC_EXTRA2": "88.732,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "169.099,20"
  },
  {
    "EOL": 400535,
    "UNIDADE": "CEI CEU - PAZ",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "14.778,00",
    "BAS_2PAR": "14.778,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "14.778,00",
    "REC_EXTRA2": "88.668,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "16.000,00",
    "TOTAL": "169.002,00"
  },
  {
    "EOL": 400536,
    "UNIDADE": "CEI CEU - CAMPO LIMPO",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "12.562,80",
    "BAS_2PAR": "12.562,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "12.562,80",
    "REC_EXTRA2": "75.376,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "145.065,20"
  },
  {
    "EOL": 400543,
    "UNIDADE": "CEI - INOCOOP IPIRANGA",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "11.253,60",
    "BAS_2PAR": "11.253,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.253,60",
    "REC_EXTRA2": "67.521,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.282,40"
  },
  {
    "EOL": 400549,
    "UNIDADE": "CEI - VILA SAO JOAO",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "11.358,00",
    "BAS_2PAR": "11.358,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.358,00",
    "REC_EXTRA2": "68.148,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.222,00"
  },
  {
    "EOL": 400550,
    "UNIDADE": "CEI - VILA CURUCA II",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "11.055,50",
    "BAS_2PAR": "11.055,50",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.055,50",
    "REC_EXTRA2": "66.333,50",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "123.500,00"
  },
  {
    "EOL": 400553,
    "UNIDADE": "CEI - JARDIM REPUBLICA",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "11.242,80",
    "BAS_2PAR": "11.242,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.242,80",
    "REC_EXTRA2": "67.456,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "129.185,20"
  },
  {
    "EOL": 400554,
    "UNIDADE": "CEI - PARQUE AMERICA",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "11.163,60",
    "BAS_2PAR": "11.163,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.163,60",
    "REC_EXTRA2": "66.981,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "128.472,40"
  },
  {
    "EOL": 400564,
    "UNIDADE": "CEI - RAQUEL ZUMBANO ALTMAN",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "11.422,80",
    "BAS_2PAR": "11.422,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.422,80",
    "REC_EXTRA2": "68.536,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "130.805,20"
  },
  {
    "EOL": 400569,
    "UNIDADE": "CEI - REYNALDO DE MARIA FREITAS E SILVA, PROF.",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "11.163,50",
    "BAS_2PAR": "11.163,50",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.163,50",
    "REC_EXTRA2": "66.981,50",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "128.472,00"
  },
  {
    "EOL": 400596,
    "UNIDADE": "CEI CEU - AGUA AZUL",
    "DRE": "GUAIANASES",
    "BAS_1PAR": "12.512,40",
    "BAS_2PAR": "12.512,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "12.512,40",
    "REC_EXTRA2": "75.074,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "144.611,60"
  },
  {
    "EOL": 400597,
    "UNIDADE": "CEI CEU - AZUL DA COR DO MAR",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "12.516,00",
    "BAS_2PAR": "12.516,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "12.516,00",
    "REC_EXTRA2": "75.096,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "144.644,00"
  },
  {
    "EOL": 400598,
    "UNIDADE": "CEI CEU - YOLANDA DE SOUZA SANTALUCIA, PROFA.",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "12.487,20",
    "BAS_2PAR": "12.487,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "12.487,20",
    "REC_EXTRA2": "74.923,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "144.384,80"
  },
  {
    "EOL": 400599,
    "UNIDADE": "CEI CEU - VILA RUBI",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "11.487,60",
    "BAS_2PAR": "11.487,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.487,60",
    "REC_EXTRA2": "68.925,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "131.388,40"
  },
  {
    "EOL": 400600,
    "UNIDADE": "CEI CEU - JACANA",
    "DRE": "JAÇANÃ/TREMEMBÉ",
    "BAS_1PAR": "13.782,00",
    "BAS_2PAR": "13.782,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.782,00",
    "REC_EXTRA2": "82.692,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "156.038,00"
  },
  {
    "EOL": 400669,
    "UNIDADE": "CEI CEU - GUARAPIRANGA",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "11.455,20",
    "BAS_2PAR": "11.455,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.455,20",
    "REC_EXTRA2": "68.731,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "131.096,80"
  },
  {
    "EOL": 400670,
    "UNIDADE": "CEI CEU - CANTOS DO AMANHECER",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "11.487,60",
    "BAS_2PAR": "11.487,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.487,60",
    "REC_EXTRA2": "68.925,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "131.388,40"
  },
  {
    "EOL": 400671,
    "UNIDADE": "CEI CEU - VILA DO SOL",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "12.616,80",
    "BAS_2PAR": "12.616,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "12.616,80",
    "REC_EXTRA2": "75.700,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "145.551,20"
  },
  {
    "EOL": 400672,
    "UNIDADE": "CEI CEU - JARDIM PAULISTANO",
    "DRE": "FREGUESIA/BRASILÂNDIA",
    "BAS_1PAR": "13.634,40",
    "BAS_2PAR": "13.634,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.634,40",
    "REC_EXTRA2": "81.806,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "154.709,60"
  },
  {
    "EOL": 400674,
    "UNIDADE": "CEI CEU - QUINTA DO SOL",
    "DRE": "PENHA",
    "BAS_1PAR": "11.498,40",
    "BAS_2PAR": "11.498,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.498,40",
    "REC_EXTRA2": "68.990,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "131.485,60"
  },
  {
    "EOL": 400675,
    "UNIDADE": "CEI CEU - RUBEN ALVES",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "12.494,40",
    "BAS_2PAR": "12.494,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "12.494,40",
    "REC_EXTRA2": "74.966,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "144.449,60"
  },
  {
    "EOL": 400676,
    "UNIDADE": "CEI CEU - TRES PONTES",
    "DRE": "SÃO MIGUEL PAULISTA",
    "BAS_1PAR": "11.484,00",
    "BAS_2PAR": "11.484,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.484,00",
    "REC_EXTRA2": "68.904,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "131.356,00"
  },
  {
    "EOL": 400684,
    "UNIDADE": "CEI CEU - CAMINHO DO MAR",
    "DRE": "SANTO AMARO",
    "BAS_1PAR": "13.695,60",
    "BAS_2PAR": "13.695,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "13.695,60",
    "REC_EXTRA2": "82.173,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "155.260,40"
  },
  {
    "EOL": 400688,
    "UNIDADE": "CEI CEU - CAPAO REDONDO",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "11.502,00",
    "BAS_2PAR": "11.502,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.502,00",
    "REC_EXTRA2": "69.012,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "131.518,00"
  },
  {
    "EOL": 400689,
    "UNIDADE": "CEI CEU - PARAISOPOLIS",
    "DRE": "CAMPO  LIMPO",
    "BAS_1PAR": "11.451,60",
    "BAS_2PAR": "11.451,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.451,60",
    "REC_EXTRA2": "68.709,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "131.064,40"
  },
  {
    "EOL": 400690,
    "UNIDADE": "CEI CEU - PARQUE BRISTOL",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "12.577,20",
    "BAS_2PAR": "12.577,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "12.577,20",
    "REC_EXTRA2": "75.463,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "145.194,80"
  },
  {
    "EOL": 400691,
    "UNIDADE": "CEI CEU - ADELAIDE TERESA LOPES SIMONARI",
    "DRE": "ITAQUERA",
    "BAS_1PAR": "11.502,00",
    "BAS_2PAR": "11.502,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.502,00",
    "REC_EXTRA2": "69.012,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "131.518,00"
  },
  {
    "EOL": 400692,
    "UNIDADE": "CEI CEU - UIRAPURU",
    "DRE": "BUTANTÃ",
    "BAS_1PAR": "12.548,00",
    "BAS_2PAR": "12.548,00",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "12.548,00",
    "REC_EXTRA2": "75.290,00",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "144.934,00"
  },
  {
    "EOL": 400694,
    "UNIDADE": "CEI CEU - PARQUE ANHANGUERA",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "12.559,20",
    "BAS_2PAR": "12.559,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "12.559,20",
    "REC_EXTRA2": "75.355,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "145.032,80"
  },
  {
    "EOL": 400695,
    "UNIDADE": "CEI CEU - JAGUARE",
    "DRE": "PIRITUBA/JAGUARÉ",
    "BAS_1PAR": "11.469,60",
    "BAS_2PAR": "11.469,60",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.469,60",
    "REC_EXTRA2": "68.817,60",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "131.226,40"
  },
  {
    "EOL": 400696,
    "UNIDADE": "CEI CEU - ALTO ALEGRE",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "12.634,80",
    "BAS_2PAR": "12.634,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "12.634,80",
    "REC_EXTRA2": "75.808,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "145.713,20"
  },
  {
    "EOL": 400710,
    "UNIDADE": "CEI CEU - PARELHEIROS",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "12.562,80",
    "BAS_2PAR": "12.562,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "12.562,80",
    "REC_EXTRA2": "75.376,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "145.065,20"
  },
  {
    "EOL": 400713,
    "UNIDADE": "CEI - INDIANOPOLIS",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "11.210,40",
    "BAS_2PAR": "11.210,40",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.210,40",
    "REC_EXTRA2": "67.262,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "8.000,00",
    "TOTAL": "128.893,60"
  },
  {
    "EOL": 400753,
    "UNIDADE": "CEI - GREGORIO WESTRUPP, PE.",
    "DRE": "IPIRANGA",
    "BAS_1PAR": "11.131,20",
    "BAS_2PAR": "11.131,20",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "11.131,20",
    "REC_EXTRA2": "66.787,20",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "4.000,00",
    "TOTAL": "124.180,80"
  },
  {
    "EOL": 400762,
    "UNIDADE": "CEI - MARIA APARECIDA NASCIMENTO",
    "DRE": "SÃO MATEUS",
    "BAS_1PAR": "12.616,80",
    "BAS_2PAR": "12.616,80",
    "REC_EXTRA": "15.000,00",
    "BAS_3PAR": "12.616,80",
    "REC_EXTRA2": "75.700,80",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "145.551,20"
  },
  {
    "EOL": 400875,
    "UNIDADE": "CEMEI - JARDIM IPORANGA",
    "DRE": "CAPELA DO SOCORRO",
    "BAS_1PAR": "",
    "BAS_2PAR": "",
    "REC_EXTRA": "",
    "BAS_3PAR": "13.208,40",
    "REC_EXTRA2": "79.250,40",
    "EM": "",
    "SALA_LEITURA": "5.000,00",
    "MAT_PEDAG": "12.000,00",
    "TOTAL": "109.458,80"
  }
]
