export const STOP_WORDS = [
  "CEI",
  "CEMEI",
  "CMEI",
  "EMEI",
  "CCI",
  "EMEF",
  "EMEFM",
  "CIEJA",
  "CMCT",
  "CECI",
  "EMEB",
  "EMEBS",
  "CEU",
  "CEEI",
  "CEU CEI",
  "CEU EMEI",
  "CEU EMEF"
];
